
    <section class="cmn-innerpage-wrapper chart-accounts">
        <div class="head">
            <div class="search-wrapper">
                <input type="text" class="cmn-header-search" placeholder="Search" [formControl]="searchInputControl" />
            </div>
            <button class="cmn-add-btn" (click)="navigateTo('/new-chart-of-accounts')">
                <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New Account
            </button>
            <button class="cmn-filter-btn" (click)="toggleDropdown($event)"
                [ngClass]="{ 'class-clicked': isDropdownOpen }">
                <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
                <!-- <span *ngIf="selectedCount > 0">{{ selectedCount }}</span> -->
            </button>
            <!-- Filter Popup -->
            <div [ngClass]="{ show: isDropdownOpen }" class="common-dropdown filter-position" #dropdown>
                <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
                <div>
                    <form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
                        <h6 class="cmn-popup-heading">Filter <span (click)="clearFilter()">Clear All</span></h6>
                        <h2>Account Type</h2>
                        <div class="filter-inner-content">
                            <div formArrayName="accountType" class="check-wrapper">
                                <div class="check-box" *ngFor="let account of accountTypes; let i = index">
                                    <label class="checkbox-wrapper">
                                        <input type="checkbox" [formControlName]="i" />
                                        {{ account?.accountType }}
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="btn-wrapper">
                            <button class="cmn-apply-btn" type="submit" (click)="toggleDropdown($event)">Apply</button>
                        </div>
                    </form>
                </div>

                <!-- Filter Popup -->
            </div>
        </div>
        <div class="tab-inner-content">
            <table>
                <thead>
                    <tr>
                        <th>Account Name</th>
                        <th>Account Code</th>
                        <th>Account Type</th>
                        <th>Parent Account Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let rootNode of data">
                        <tr *ngFor="let node of getTableRows(rootNode)" [ngClass]="getNodeClass(node)"
                            [routerLink]="['/chart-of-accounts/chart-of-accounts-details', node.id]">
                            <td [ngStyle]="{ 'padding-left': ((node.level ?? 0) * 20) + 'px' }">{{ node.name }}</td>
                            <td>{{ node.code }}</td>
                            <td>{{ node.accountType }}</td>
                            <td>{{ node.parentAccount || '--'}}</td>
                            <td *ngIf="node.isEditable"><span (click)="onEdit(node.id)"><img src="assets/images/icons/icon-edit.svg"></span>
                                <span (click)="onConfirmDelete(node.id)"><img
                                        src="assets/images/icons/icon-delete.svg"></span>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </section>
