export function pymentGenerateNo(): string {
  const timestampPart = Date.now().toString().slice(-3);
  const randomPart = Math.floor(100 + Math.random() * 900);
  return `PY-${timestampPart}${randomPart}`;
}

export function loggedInUserId(): string | null {
  const loginEmployee = localStorage.getItem('loggedInUser');
  if (loginEmployee) {
    try {
      const employee = JSON.parse(loginEmployee);
      return employee?.employeeId || null;
    } catch (error) {
      console.error('Error parsing loggedInUser from localStorage:', error);
      return null;
    }
  }
  return null;
}

export function generatedNo(pre: string): string {
  const timestampPart = Date.now().toString().slice(-3);
  const randomPart = Math.floor(100 + Math.random() * 900);
  return `${pre}-${timestampPart}${randomPart}`;
}
