import { CurrencyAdjustmentService } from './../../features/currency-adjustments/services/currency-adjustment.service';
import {
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { DialogRef } from '@angular/cdk/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-currency-adjustments',
  standalone: true,
  imports: [CommonModule, LayoutComponent, AgGridAngular, RouterModule],
  templateUrl: './currency-adjustments.component.html',
  styleUrl: './currency-adjustments.component.scss',
})
export class CurrencyAdjustmentsComponent implements OnInit {
  constructor(
    private router: Router,
    private currencyService: CurrencyAdjustmentService,
    private toastr: ToastrService
  ) {}

  isDropdownOpen = false;
  public rowData: any[] | null = null;
  public loading: boolean = true;
  public error: any = null;
  private searchSubject = new Subject<string>();

  readonly dialog = inject(MatDialog);

  public columnDefs = [
    {
      headerName: 'Currency Code',
      field: 'currencyCode',
      flex: 1,
    },
    { headerName: 'Currency Name', field: 'currencyName', flex: 1 },
    { headerName: 'Currency Symbol', field: 'currencySymbol', flex: 1 },
    { headerName: 'Exchange Rate', field: 'exchangeRate', flex: 1 },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      cellRenderer: (params: any) => {
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.alignItems = 'center';
        container.style.gap = '20px';

        const editIcon = document.createElement('img');
        editIcon.src = 'assets/images/icons/icon-edit.svg';
        editIcon.style.cursor = 'pointer';
        editIcon.setAttribute('data-action', 'edit');

        const deleteIcon = document.createElement('img');
        deleteIcon.src = 'assets/images/icons/icon-delete.svg';
        deleteIcon.style.cursor = 'pointer';
        deleteIcon.setAttribute('data-action', 'delete');
        container.appendChild(editIcon);
        container.appendChild(deleteIcon);
        return container;
      },
      floatingFilter: false,
      filter: false,
    },
  ];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

  ngOnInit(): void {
    this.fetchCurrencyData();

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchCurrencyData(searchTerm);
      });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  fetchCurrencyData(search: string = '', filter: any = {}) {
    this.loading = true;
    this.currencyService.fetchCurrencies(search, filter).subscribe({
      next: (currencies) => {
        this.rowData = currencies.map((currency: any) => {
          return {
            id: currency?.id,
            currencyCode: currency?.currencyCode,
            currencyName: currency?.currencyName,
            currencySymbol: currency?.currencySymbol,
            exchangeRate: currency?.exchangeRate,
            isPrimary: currency?.isPrimary,
          };
        });
        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching currencies:', err);
        this.loading = false;
      },
    });
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  openUploadDialog() {
    const dialogRef = this.dialog.open(UploadAdjustmentsDialog);
    dialogRef.afterClosed().subscribe((data) => {
      this.fetchCurrencyData();
    });
  }

  openAddCurrencyDialog() {
    const dialogRef = this.dialog.open(AddCurrencyDialogComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.fetchCurrencyData();
      }
    });
  }

  openEditDialog(currencyData: any) {
    console.log('currencyData::', currencyData);
    const dialogRef = this.dialog.open(AddCurrencyDialogComponent, {
      data: currencyData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.fetchCurrencyData();
      }
      dialogRef.close();
    });
  }

  onDeleteCurrency(currencyData: any): void {
    if (currencyData.isPrimary) {
      this.toastr.error('Primary currency cannot be deleted.');
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.removeCurrency(currencyData?.id);
      }
    });
  }

  private removeCurrency(currencyId: number): void {
    this.currencyService.removeCurrencyById(currencyId).subscribe({
      next: () => {
        this.toastr.success(
          'Currency adjustment removed successfully!',
          'Success'
        );
        this.fetchCurrencyData();
      },
      error: () => {
        this.toastr.error(
          'Failed to remove currency adjustment. Please try again.',
          'Error'
        );
      },
    });
  }

  onCellClicked(params: any) {
    const currencyId = params.data?.id;
    if (
      params.column.colId === 'action' &&
      params.event.target.dataset.action
    ) {
      let action = params.event.target.dataset.action;

      if (action === 'edit') {
        if (params?.data?.isPrimary) {
          this.toastr.warning('Primary currencies cannot be edited.');
        } else {
          this.openEditDialog(params?.data);
        }
      } else if (action === 'delete') {
        this.onDeleteCurrency(params?.data);
      }
    } else {
      this.router.navigate([
        `currency-adjustments/exchange-logs/${currencyId}`,
      ]);
    }
  }
}

@Component({
  selector: 'app-add-currency',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatDialogModule,
  ],
  templateUrl: './add-currency-dialog.html',
  styleUrl: './add-currency-dialog.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AddCurrencyDialogComponent implements OnInit {
  addCurrencyForm!: FormGroup;
  successMessage: string | null = null;
  errorMessage: string | null = null;
  loading: boolean = false;
  currencyData: any;

  constructor(
    private currencyService: CurrencyAdjustmentService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddCurrencyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.currencyData = this.data;
    }
    this.initForm();
  }

  initForm(): void {
    this.addCurrencyForm = this.fb.group({
      id: [this.currencyData?.id || ''],
      currencyCode: [
        this.currencyData?.currencyCode || '',
        Validators.required,
      ],
      currencyName: [
        this.currencyData?.currencyName || '',
        Validators.required,
      ],
      currencySymbol: [
        this.currencyData?.currencySymbol || '',
        Validators.required,
      ],
      exchangeRate: [
        this.currencyData?.exchangeRate || '',
        [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)],
      ],
      isPrimary: [this.currencyData?.isPrimary ?? false],
    });
  }

  onSubmit(): void {
    if (this.currencyData?.isPrimary) {
      this.toastr.warning('Primary currencies cannot be edited.');
      return;
    }
    if (!this.currencyData) {
      this.addCurrencyForm.markAllAsTouched();
      if (this.addCurrencyForm.valid) {
        const { currencyCode, currencyName, currencySymbol, exchangeRate } =
          this.addCurrencyForm.value;
        const createCurrencyInput = {
          currencyCode,
          currencyName,
          currencySymbol,
          exchangeRate: parseFloat(exchangeRate),
          isPrimary: false,
        };
        this.currencyService.createCurrency(createCurrencyInput).subscribe(
          (response) => {
            this.toastr.success(
              'Currency Adjustment created successfully.',
              'Success'
            );
            this.dialogRef.close('success');
          },
          (error) => {
            this.errorMessage =
              'Failed to create currency adjustment. Please try again';
            if (error && error.message) {
              this.errorMessage = error.message;
            } else {
              this.errorMessage =
                'An unknown error occurred while creating currency adjustment.';
            }
            this.toastr.error(
              this.errorMessage ?? 'Failed to create currency adjustment'
            );
            this.loading = false;
          }
        );
      }
    } else {
      this.addCurrencyForm.markAllAsTouched();
      if (this.addCurrencyForm.valid) {
        const updateCurrencyInput = {
          ...this.addCurrencyForm.value,
          id: this.currencyData?.id,
          exchangeRate: parseFloat(this.addCurrencyForm.value.exchangeRate),
        };
        this.currencyService.updateCurrencyById(updateCurrencyInput).subscribe(
          (resonse) => {
            this.toastr.success(
              'Currency Adjustment updated successfully.',
              'Success'
            );
            this.dialogRef.close('success');
          },
          (error) => {
            this.errorMessage =
              'Failed to update currency adjustment. Please try again';
            if (error && error.message) {
              this.errorMessage = error.message;
            } else {
              this.errorMessage =
                'An unknown error occurred while updating currency adjustment.';
            }
            this.toastr.error(
              this.errorMessage ?? 'Failed to update currency adjustment'
            );
            this.loading = false;
          }
        );
      }
    }
  }

  onCancel() {
    this.addCurrencyForm.reset();
  }
}

@Component({
  selector: 'upload-adjustments-dialog',
  templateUrl: 'upload-adjustments-dialog.html',
  styleUrls: ['./currency-adjustments.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class UploadAdjustmentsDialog {
  file: any = null;
  url: any = null;
  loading: boolean = false;
  uploadForm: FormGroup;

  constructor(
    private toastr: ToastrService,
    private currencyService: CurrencyAdjustmentService,
    private dialogRef: DialogRef,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.uploadForm = this.fb.group({
      file: [null, Validators.required],
    });
    document.addEventListener('dragover', this.preventDefaultBehavior);
    document.addEventListener('drop', this.preventDefaultBehavior);
  }

  preventDefaultBehavior(event: DragEvent) {
    event.preventDefault();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();

    if (event.dataTransfer?.files.length) {
      this.onFileSelected({ target: { files: event.dataTransfer.files } });
    }
  }

  onFileSelected(event: any): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
    this.uploadForm.get('file')!.updateValueAndValidity();
  }

  uploadDocuments(csvFile: any) {
    this.loading = true;
    this.currencyService.bulkCreateCurrency(csvFile).subscribe({
      next: (response) => {
        console.log(response);
        this.loading = false;
        if (response && !response.errors) {
          this.toastr.success(
            'Currency adjustments updated successfully!'
          );
          this.dialogRef.close();
        } else if (response?.errors) {
          this.toastr.error(response.errors[0]?.message, 'Error');
          this.dialogRef.close();
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Bulk currency creation failed');
        this.dialogRef.close();
        this.loading = false;
      },
    });
  }

  removeFile(): void {
    this.file = null;
    const input = document.querySelector(
      'input[type="file"]'
    ) as HTMLInputElement;
    if (input) {
      input.value = '';
    }
    this.uploadForm.get('file')!.updateValueAndValidity();
  }

  onUpload() {
    if (this.uploadForm.invalid && !this.file) {
      this.uploadForm.markAllAsTouched();
      return;
    }
    this.uploadDocuments(this.file);
  }

  ngOnDestroy() {
    document.removeEventListener('dragover', this.preventDefaultBehavior);
    document.removeEventListener('drop', this.preventDefaultBehavior);
  }
}
