import { gql } from 'apollo-angular';

export const REMOVE_INVOICE_BY_ID = gql`
  mutation removeInvoice($id: Int!) {
    removeInvoice(id: $id)
  }
`;

export const REMOVE_INVOICE_ATTACHMENT_BY_ID = gql`
  mutation removeInvoiceAttachment($id: Int!) {
    removeInvoiceAttachment(id: $id)
  }
`;

export const SEND_MAIL_INVOICE = gql`
  mutation sendEmailWithTemplate(
    $invoiceId: Int!
    $to: [String!]!
    $cc: [String!]
    $subject: String!
    $body: String!
  ) {
    sendEmailWithTemplate(
      invoiceId: $invoiceId
      to: $to
      cc: $cc
      subject: $subject
      body: $body
    )
  }
`;

export const CREATE_INVOICE = gql`
  mutation CreateInvoice(
    $createInvoiceInput: CreateInvoiceInput!
    $createInvoiceItemInput: [CreateInvoiceItemInput!]
    $createInvoiceAttachmentInput: [CreateInvoiceAttachmentInput!]
    $employeeId: Int
  ) {
    createInvoice(
      createInvoiceInput: $createInvoiceInput
      createInvoiceItemInput: $createInvoiceItemInput
      createInvoiceAttachmentInput: $createInvoiceAttachmentInput
      employeeId: $employeeId
    ) {
      id
      invoiceNo
      totalPrice
      balance
      status
      invoiceDate
      dueDate
    }
  }
`;

export const UPDATE_INVOICE = gql`
  mutation updateInvoice(
    $updateInvoiceInput: UpdateInvoiceInput!
    $employeeId: Int
  ) {
    updateInvoice(
      updateInvoiceInput: $updateInvoiceInput
      employeeId: $employeeId
    ) {
      id
      projectId
      quoteId
      journalId
      partnerId
      partnerData {
        id
        partnerType
        customerType
        displayName
        companyName
        email
        phone
        mobile
        currency
        emirateID
        openingBalance
        paymentStatus
        status
        primaryContactId
      }
      billingAddressId
      billingAddressData {
        id
        partnerId
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }
      shippingAddressId
      shippingAddressData {
        id
        partnerId
        attention
        addressType
        addressLine1
        addressLine2
        city
        state
        country
        pinCode
        phone
        fax
      }
      invoiceNo
      orderNo
      invoiceDate
      paymentTerm
      paymentStatus
      reason
      dueDate
      subject
      customerNote
      isPayment
      subTotal
      tax
      adjustment
      totalPrice
      balance
      status
      createdAt
      updatedAt
      deletedAt
      invoiceItems {
        id
        invoiceId
        description
        quantity
        rate
        discount
        amount
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

export const CREATE_ADDRESS = gql`
  mutation createAddress($createAddressInput: CreateAddressInput!) {
    createAddress(createAddressInput: $createAddressInput) {
      id
      partnerId
    }
  }
`;
