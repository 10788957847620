import { Component } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomerService } from '../../features/customers/services/customer.service';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { InventoryItemService } from '../../features/inventory/services/inventory.service';
import { ChartAccountsService } from '../../features/chart-of-accounts/services/chart-accounts.service';
import { UploadFileService } from '../../shared/services/file-upload.service';
import { ToastrService } from 'ngx-toastr';
import { BillService } from '../../features/bills/services/bills.service';
import { ActivatedRoute, Router } from '@angular/router';
import { addDays, addMonths, endOfMonth } from 'date-fns';
import { PAYMENT_TERMS } from '../../helpers/helper-file';
import { positiveNumberValidator } from '../../shared/services/validations';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-bill-new',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
  ],
  templateUrl: './bill-new.component.html',
  styleUrl: './bill-new.component.scss'
})
export class BillNewComponent {
  public arr = [1, 2, 3, 4, 5, 6, 7]
  search: any;
  vendors: any;
  filteredVendors: any = [];
  newBillForm: FormGroup;
  itemServiceForm: FormGroup;
  notesForm: FormGroup;
  vendorControl = new FormControl();
  vendorSearchControl = new FormControl();
  filter: any;
  items: any;
  customers: any;
  type: any;
  accounts: any;
  addedItems: any[] = [];
  file: any;
  fileUrl: any;
  billId: string | null;
  billData: any;
  itemData: any;
  subTotal: any;
  totalPrice: any;
  billAttachments: any[] = [];
  fileName: string;
  isManualDateChange = true;
  paymentTermsValue = PAYMENT_TERMS;
  itemValue: any;
  files: any = [];

  constructor(
    private customerService: CustomerService,
    private billService: BillService,
    private itemService: InventoryItemService,
    private chartAccountService: ChartAccountsService,
    private documentUploadService: UploadFileService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.initForm();
    this.fetchVendors();
    this.fetchItems();
    this.fetchAccounts();
    this.vendorSearchControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((searchText) => {
        this.filterVendors(searchText);
      });
    this.newBillForm.get('paymentTerm')?.valueChanges.subscribe((term: string) => {
      this.onPaymentTermChange(term);
    });


    this.route.paramMap.subscribe(params => {
      this.billId = params.get('id');
      if (this.billId) {
        this.fetchBillById(this.billId);
      }
    });
  }

  initForm() {
    const billDate = this.billData && this.billData.billDate ? new Date(parseInt(this.billData.billDate)) : null;
    const dueDate = this.billData && this.billData.dueDate ? new Date(parseInt(this.billData.dueDate)) : null;

    if (this.billId && this.billData) {
      this.newBillForm = this.fb.group({
        id: [this.billData.id],
        billStatus: [this.billData.billStatus],
        partnerId: [this.billData.partnerId, Validators.required],
        billNo: [this.billData.billNo, Validators.required],
        orderNo: [this.billData.orderNo],
        billDate: [billDate, Validators.required],
        dueDate: [dueDate],
        paymentTerm: [this.billData.paymentTerm],
        subject: [this.billData.subject],
        customerNote: [this.billData.customerNote],
        subTotal: [this.billData.subTotal],
        totalPrice: [this.billData.totalPrice],
        billPaymentStatus: [this.billData.billPaymentStatus],
      });

      this.itemServiceForm = this.fb.group({
        id: [this.billData.billItems.itemId, Validators.required],
        billId: [this.billData.billItems.billId],
        itemId: [this.billData.billItems.itemId],
        itemName: [this.billData.billItems.itemName],
        quantity: [this.billData.billItems.quantity, [Validators.required, positiveNumberValidator()]],
        rate: [this.billData.billItems.rate],
        amount: [this.billData.billItems.amount],
        status: [this.billData.billItems.status]
      });

      if (this.billData?.billAttachments?.length > 0) {
        this.fileName = this.billData?.billAttachments[0]?.fileName;
      }

      this.notesForm = this.fb.group({
        notes: [this.billData.customerNote],
        fileUrl: [this.fileName || '']
      });

    } else {
      this.newBillForm = this.fb.group({
        billStatus: ['Open'],
        partnerId: ['', Validators.required],
        billNo: ['', Validators.required],
        orderNo: [''],
        billDate: ['', Validators.required],
        dueDate: [''],
        paymentTerm: [''],
        subject: [''],
        customerNote: [''],
        subTotal: [0],
        totalPrice: [0],
        billPaymentStatus: ['Open'],
      });

      this.itemServiceForm = this.fb.group({
        itemId: ['', Validators.required],
        billId: [''],
        itemName: [''],
        quantity: ['1', [Validators.required, positiveNumberValidator()]],
        rate: [0],
        amount: [0],
        status: [1]
      });

      this.notesForm = this.fb.group({
        notes: [''],
        fileUrl: ['']
      });
    }

    this.itemServiceForm.get('itemId')?.valueChanges.subscribe((itemId) => {
      if (itemId) {
        this.fetchItem(itemId);
      }
    });
    this.setupAutoCalculateAmount();
    this.calculateAmount();
  }


  onRemoveDocument(id: any): void {
    if (id) {
      const dialogRef = this.dialog.open(ConfirmDialog);
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.billService.removeBillAttachment(Number(id)).subscribe({
            next: (data) => {
              if (data) {
                this.toastr.success('Document removed successfully', 'Success');
                this.fetchBillById(this.billId);
              } else {
                this.toastr.error('Failed to remove document', 'Error');
              }
            },
            error: (error) => this.toastr.error(error, 'Error'),
          });
        }
      });
    }
  }

  onPaymentTermChange(term: string): void {
    // const currentDate = new Date();
    let dueDate: Date | null = null;

    const billDate = this.newBillForm.get('billDate')?.value;
    if (!billDate) {
      return; 
    }
  
    const billDateObj = new Date(billDate);

    switch (term) {
      case 'Net 15':
        dueDate = addDays(billDate, 15);
        break;
      case 'Net 30':
        dueDate = addDays(billDate, 30);
        break;
      case 'Net 45':
        dueDate = addDays(billDate, 45);
        break;
      case 'Net 60':
        dueDate = addDays(billDate, 60);
        break;
      case 'Due On Receipt':
        dueDate = billDate;
        break;
      case 'Due end of the month':
        dueDate = endOfMonth(billDate);
        break;
      case 'Due end of next month':
        dueDate = endOfMonth(addMonths(billDate, 1));
        break;
      case 'Custom':
        dueDate = null;
        break;
      default:
        dueDate = null;
    }
    if (dueDate) {
      this.isManualDateChange = false;
      this.newBillForm.get('dueDate')?.setValue(dueDate);
    }

    // const isReadOnly = term !== 'Custom';
    // this.newBillForm.get('dueDate')?.[isReadOnly ? 'disable' : 'enable']();
  }

  fetchVendors() {
    this.customerService.fetchPartners(this.search).subscribe({
      next: (data) => {
        this.vendors = data.filter((item: any) => item.partnerType === 'Vendor');
        this.customers = data.filter((item: any) => item.partnerType === 'Customer');
        this.filteredVendors = [...this.vendors];
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  onViewDocument(url: any) {
    const token = localStorage.getItem('AUTH_TOKEN');
    fetch(url, {
      method: 'GET',
      headers: {
        'authorization': token ? `Bearer ${token}` : '',
      }
    })
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      })
      .catch(error => console.error('Error:', error));
  }

  fetchItems() {
    const search: any = '';
    const filter: any = {};
    const updatedFilter = { ...filter, isPurchaseActive: true };

    this.itemService.fetchItems(search, updatedFilter).subscribe({
      next: (items) => {
        this.items = items;
      },
      error: (error) => console.error(error),
    });
  }

  fetchAccounts() {
    this.chartAccountService.fetchAccounts(this.type, this.search).subscribe({
      next: (accounts: any) => {
        this.accounts = accounts;
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }

  filterVendors(searchText: string) {
    if (searchText) {
      this.filteredVendors = this.vendors.filter((vendor: any) =>
        vendor.displayName.toLowerCase().includes(searchText.toLowerCase())
      );
    } else {
      this.filteredVendors = [...this.vendors];
    }
  }

  setupAutoCalculateAmount() {
    this.itemServiceForm.get('quantity')?.valueChanges.subscribe(value => {
      this.calculateAmount();
    });

    this.itemServiceForm.get('rate')?.valueChanges.subscribe(value => {
      this.calculateAmount();
    });
  }

  calculateAmount() {
    const quantity = +this.itemServiceForm.get('quantity')?.value || 0;
    const rate = +this.itemServiceForm.get('rate')?.value || 0;

    const amount = quantity * rate;
    this.itemServiceForm.patchValue({
      amount: amount
    })
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.file = file;
      this.fileName = file?.name;
    }
  }

  removeFile(): void {
    const dialogRef = this.dialog.open(ConfirmDialog);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.file = '';
        this.fileName = '';
        const input = document.querySelector('input[type="file"]') as HTMLInputElement;
        if (input) {
          input.value = '';
        }
      }
    })
  }


  fetchItemById(id: any): void {
    console.log('inside');

    if (id !== null) {
      console.log('id', id);

      this.itemService.fetchItemById(Number(id)).subscribe({
        next: (data) => {
          this.itemData = data;
          console.log(this.itemData);
          this.itemServiceForm.patchValue({
            rate: String(this.itemData.sellingPrice)
          });

          if (this.billId) {
            const item = {
              billId: Number(this.billId),
              itemId: this.itemData.id,
              quantity: this.itemServiceForm.value.quantity,
              rate: this.itemServiceForm.value.rate,
              amount: this.itemServiceForm.value.amount,
              status: 1,
            };
            console.log(item);

            this.billService.createBillItem(item).subscribe({
              next: (data) => {
                if (data) {
                  this.toastr.success('Bill item added successfully!', 'Success');
                  this.itemServiceForm.reset();
                  this.fetchBillById(this.billId);
                }
              },
              error: (error) => {
                console.error(error, 'Error');
              }
            })
          } else {
            this.itemServiceForm.patchValue({
              id: this.itemData.id,
              itemName: this.itemData.itemName,
              rate: String(this.itemData.sellingPrice),
              status: 1,
            })
            this.addedItems.push(this.itemServiceForm.value);
            this.itemServiceForm.reset();
            this.setupAutoCalculateAmount();
            this.calculateSubTotal();
          }
        },
        error: (error) => console.error(error),
      });
    } else {
      console.warn('Item ID is null; cannot load item data.');
    }
  }

  fetchItem(id: any) {
    this.itemService.fetchItemById(Number(id)).subscribe({
      next: (data) => {
        this.itemValue = data;
        this.itemServiceForm.patchValue({
          rate: this.itemValue.sellingPrice
        });
        this.setupAutoCalculateAmount();
        this.calculateAmount();
      }
    })
  }

  calculateSubTotal() {
    if (this.billData) {
      this.subTotal = this.billData.billItems.reduce((total: number, item: { amount: number }) =>
        total + (item.amount || 0), 0
      );
      this.totalPrice = this.subTotal;
    } else {
      this.subTotal = this.addedItems.reduce((total: number, item: { amount: number }) =>
        total + (item.amount || 0), 0
      );
      this.totalPrice = this.subTotal;
    }
    this.newBillForm.patchValue({
      subTotal: this.subTotal,
      totalPrice: this.totalPrice,
      billDate: this.datePipe.transform(this.newBillForm.value.billDate, 'YYYY-MM-dd'),
      dueDate: this.datePipe.transform(this.newBillForm.value.dueDate, 'YYYY-MM-dd'),
    });
  }

  onAddItem() {
    if (this.itemServiceForm.invalid) {
      this.itemServiceForm.markAllAsTouched();
      return;
    }
    this.itemServiceForm.patchValue({
      // quantity: this.itemServiceForm.value.quantity, 
      rate: this.itemServiceForm.value.rate,
      amount: this.itemServiceForm.value.amount,
    });
    this.fetchItemById(this.itemServiceForm.value.itemId);
  }

  onRemoveItem(index: number, id: any) {
    console.log('index', index, 'and', 'id', id);

    if (index > -1 && index < this.addedItems.length) {
      this.addedItems.splice(index, 1);
      this.calculateSubTotal();
    }
    this.billService.removeBillItem(id).subscribe({
      next: (data) => {
        if (data) {
          this.toastr.success('Item removed successfully', 'Success');
          this.fetchBillById(this.billId);
        }
      },
      error: (error) => {
        this.toastr.error(error, 'Error');
      }
    })
  }

  onRemoveAddedItem(index: number) {
    console.log('index', index);

    if (index > -1 && index < this.addedItems.length) {
      this.addedItems.splice(index, 1);
      this.calculateSubTotal();
    }
  }

  fetchBillById(id: any) {
    this.billService.fetchBillById(Number(id)).subscribe({
      next: (data) => {
        this.billData = data;
        this.billData.billItems.map((item: any) => {
          if (item.status === 1) {
            const newItem = {
              id: item.id,
              itemName: item.itemData.itemName || '',
              quantity: item.quantity || 0,
              rate: item.rate || 0,
              amount: item.amount || 0,
              status: item.status || 1
            };

            this.addedItems.push(newItem);

            console.log('added', this.addedItems);

          }
        })
        console.log(this.billData.billAttachments.length);
        if (this.billData?.billAttachments?.length > 0) {
          this.fileName = this.billData?.billAttachments[0]?.fileName;
          this.fileUrl = this.billData?.billAttachments[0]?.fileUrl;
        }
        this.calculateSubTotal();
        this.initForm();
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

  async onUpload() {
    if (!this.file) {
      this.toastr.error('No file selected for upload.', 'Error');
      return;
    }

    try {
      const response = await this.documentUploadService.uploadDocument(this.file, 'bills').toPromise();

      if (response && !response.errors && response.url) {
        this.fileUrl = response.url;
        this.billAttachments = [
          {
            fileUrl: this.fileUrl,
            fileName: this.fileName
          }
        ];
        if(this.billId){
          const documentData = {
            billId: Number(this.billId),
            fileUrl: this.fileUrl,
            fileName: this.fileName
          }
        this.billService.createBillAttachment(
          documentData
        ).subscribe({
          next: (data) => {
            if (data) {
              this.fetchBillById(this.billId);
            }
          },
          error: (error) => this.toastr.error(error, 'Error'),
        });
      }
      } else if (response?.errors) {
        this.toastr.error(response.errors[0]?.message || 'Unknown error occurred', 'Error');
      }
    } catch (error: any) {
      this.toastr.error(error.message || 'Bill upload failed', 'Error');
    }
  }

  async onSaveAsOpen() {
    if (this.newBillForm.invalid) {
      this.newBillForm.markAllAsTouched();
    }

    if (this.newBillForm.invalid) {
      return;
    }
    if (this.file) {
      await this.onUpload();
    }
    this.newBillForm.patchValue({
      billStatus: 'Open',
      customerNote: this.notesForm.value.notes,
    })
    const billInput = this.newBillForm.value;
    const billItems = this.addedItems.map(item => {
      const { itemName, billId, ...rest } = item;
      return rest;
    });
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;

    if (this.billId && this.billData) {
      if (this.billData) {
        if (this.billData?.billAttachments?.length > 0) {
          this.billAttachments = [
            {
              id: this.billData.billAttachments[0].id,
              fileUrl: this.fileUrl
            }
          ];
        } else {
          this.billAttachments = [];
        }
      }
      const billItems: any = [];
      this.billService.updateBill(billInput, employeeId, billItems, this.billAttachments).subscribe({
        next: (data: any) => {
          if (data) {
            this.toastr.success('Bill updated successfully!', 'Success');
            this.router.navigate([`/bills/bill-details/${this.billId}`]);
          }
        },
        error: (error: any) => {
          this.toastr.error(error, 'Error');
        }
      })
    } else {
      if (billItems.length > 0) {
        this.billService.createBill(billInput, employeeId, billItems, this.billAttachments).subscribe({
          next: (data: any) => {
            if (data) {
              this.toastr.success('Bill created successfully!', 'Success');
              this.newBillForm.reset();
              this.itemServiceForm.reset();
              this.notesForm.reset();
              this.addedItems = [];
              this.fileName = '';
              this.subTotal = 0;
              this.totalPrice = 0;
              this.router.navigate([`/bills`]);
            }
          },
          error: (error: any) => {
            this.toastr.error(error, 'Error');
          }
        })
      } else {
        this.toastr.warning('No items selected. Please add an item with quantity and details before proceeding.', 'Warning');
      }
    }
  }

  async onSaveAsDraft() {
    if (this.newBillForm.invalid) {
      this.newBillForm.markAllAsTouched();
    }

    if (this.newBillForm.invalid) {
      return;
    }
    if (this.file) {
      await this.onUpload();
    }
    this.newBillForm.patchValue({
      billStatus: 'Draft',
      customerNote: this.notesForm.value.notes,
    })
    const billInput = this.newBillForm.value;
    const billItems = this.addedItems.map(item => {
      const { itemName, billId, ...rest } = item;
      return rest;
    });
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;

    if (this.billId && this.billData) {
      if (this.billData) {
        if (this.billData?.billAttachments?.length > 0) {
          this.billAttachments = [
            {
              id: this.billData.billAttachments[0].id,
              fileUrl: this.fileUrl
            }
          ];
        } else {
          this.billAttachments = [];
        }
      }
      const billItems: any = [];
      this.billService.updateBill(billInput, employeeId, billItems, this.billAttachments).subscribe({
        next: (data: any) => {
          if (data) {
            this.toastr.success('Bill updated successfully!', 'Success');
            this.router.navigate([`/bills/bill-details/${this.billId}`]);
          }
        },
        error: (error: any) => {
          this.toastr.error(error, 'Error');
        }
      })
    } else {
      if (billItems.length > 0) {
        this.billService.createBill(billInput, employeeId, billItems, this.billAttachments).subscribe({
          next: (data: any) => {
            if (data) {
              this.toastr.success('Bill created successfully!', 'Success');
              this.newBillForm.reset();
              this.itemServiceForm.reset();
              this.notesForm.reset();
              this.addedItems = [];
              this.fileName = '';
              this.subTotal = 0;
              this.totalPrice = 0;
              this.router.navigate([`/bills`]);
            }
          },
          error: (error: any) => {
            this.toastr.error(error, 'Error');
          }
        })
      } else {
        this.toastr.warning('No items selected. Please add an item with quantity and details before proceeding.', 'Warning');
      }
    }
  }

}
