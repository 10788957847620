import { Component } from '@angular/core';
import { CoreModule } from '../../core/core.module';
import { HeaderComponent } from '../../core/header/header.component';
import { SidebarComponent } from '../../core/sidebar/sidebar.component';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { AuthService } from './../../core/services/auth.service'
import { DashboardService } from '../../features/dashboard/services/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmPunchOutDialog } from '../../shared/components/confirm-dialog/confirm-punchout';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CoreModule,
    HeaderComponent,
    SidebarComponent,
    CommonModule,
    LayoutComponent,

  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
  user: any;
  uaeTime: string;

  constructor(
    private authService: AuthService,
    public dashboardService: DashboardService,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) {
    this.user = this.authService.getLoggedInUser();
  }

  ngOnInit() {
    // Restore the timer if already punched in
    if (!this.dashboardService.isPunchedOut && this.dashboardService.punchTime) {
      this.resumeTimer();
    }
  }

  togglePunch() {
    if (this.dashboardService.isPunchedOut) {
      const punchTime = this.updateTime();
      const punchDate = this.updateDate();
      this.dashboardService.punchTime = punchTime;
      this.dashboardService.isPunchedOut = false;

      this.logPunchInTime(punchTime, punchDate);
      this.resumeTimer(); // Start timer
    } else {
      const punchTime = this.updateTime();
      const punchDate = this.updateDate();
      this.logPunchOutTime(punchTime, punchDate);

      clearInterval(this.dashboardService.interval); // Stop timer
      this.dashboardService.isPunchedOut = true;
      this.dashboardService.punchTime = null;
      this.dashboardService.timeDifference = '00:00:00';
    }
  }

  onConfirmPunchOut(): void {
    if (!this.dashboardService.isPunchedOut) {
      const dialogRef = this.dialog.open(ConfirmPunchOutDialog);
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.togglePunch();
        }
      });
    }
  }

  updateTime() {
    return new Date().toLocaleTimeString('en-US', {
      timeZone: 'Asia/Dubai',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
  }

  updateDate() {
    const currentDate = new Date().toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const [month, day, year] = currentDate.split('/');
    return `${year}-${month}-${day}`;
  }

  timeDiff(): void {
    const currentTime = this.updateTime();
    
    if (!this.dashboardService.punchTime) {
      this.dashboardService.timeDifference = '00:00:00';
      return;
    }
  
    const [hours1, minutes1, seconds1] = currentTime.split(':').map(Number);
    const [hours2, minutes2, seconds2] = this.dashboardService.punchTime
      .split(':')
      .map(Number);
  
    const totalSeconds1 = hours1 * 3600 + minutes1 * 60 + seconds1;
    const totalSeconds2 = hours2 * 3600 + minutes2 * 60 + seconds2;
  
    let differenceInSeconds = Math.abs(totalSeconds2 - totalSeconds1);
  
    const hours = Math.floor(differenceInSeconds / 3600);
    differenceInSeconds %= 3600;
    const minutes = Math.floor(differenceInSeconds / 60);
    const seconds = differenceInSeconds % 60;
  
    this.dashboardService.timeDifference = `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
  }
  
  resumeTimer() {
    this.dashboardService.interval = setInterval(() => this.timeDiff(), 1000);
  }

  logPunchInTime(time: any, date: any) {
    this.dashboardService.punchIn(time, date).subscribe({
      next: (data) => {
        console.log(data);
        
        if(data){
          this.toastr.success('Punched in successfully!');
        } 
      },
      error: (error) => {
        this.toastr.error(error, 'Error');
      },
    });
  }

  logPunchOutTime(time: any, date: any) {
    this.dashboardService.punchOut(time, date).subscribe({
      next: (data) => {
        if(data){
          this.toastr.success('Punched in successfully!');
        } 
      },
      error: (error) => {
        this.toastr.error(error, 'Error');
      },
    });
  }

  pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
}

