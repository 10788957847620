<div class="address-main-wrapper">
  <form [formGroup]="billingAddressForm" (ngSubmit)="onSubmitBilling()">
    <div class="form-box">
      <div class="form-sub-head">
        <h2 class="cmn-inner-heading">Billing Address</h2>
      </div>
      <div class="form-wrapper">
        <label for="">Attention</label>
        <mat-form-field>
          <input matInput placeholder="Attention" formControlName="attention" />
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Country/Region</label>
        <mat-form-field>
          <mat-select placeholder="Select country" formControlName="country">
            @for (country of countries; track country) {
            <mat-option [value]="country.name">{{ country.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Address</label>
        <mat-form-field>
          <textarea matInput placeholder="Street 1" formControlName="addressLine1"></textarea>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <mat-form-field>
          <textarea matInput placeholder="Street 2" formControlName="addressLine2"></textarea>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">City</label>
        <mat-form-field>
          <input matInput placeholder="Enter city" formControlName="city" />
          <mat-error *ngIf="
      billingAddressForm.get('city')!.touched &&
      billingAddressForm.get('city')!.hasError('maxlength')
    ">
            City name must be at most 10 characters long.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">State</label>
        <mat-form-field>
          <input matInput placeholder="Enter state" formControlName="state" />
          <mat-error *ngIf="
          billingAddressForm.get('state')!.touched &&
          billingAddressForm.get('state')!.hasError('maxlength')
        ">
            State name must be at most 10 characters long.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">P.O Box Number</label>
        <mat-form-field>
          <input matInput placeholder="Enter number" formControlName="pinCode" />
      
          <!-- Max Length Error - only if value exceeds maximum length -->
          <mat-error *ngIf="
            billingAddressForm.get('pinCode')!.touched &&
            billingAddressForm.get('pinCode')!.hasError('maxlength')
          ">
            P.O box must be at most 8 characters long.
          </mat-error>
      
          <!-- Pattern Error - only if value is not numeric -->
          <mat-error *ngIf="
            billingAddressForm.get('pinCode')!.touched &&
            billingAddressForm.get('pinCode')!.hasError('pattern')
          ">
            Invalid P.O box number. Only digits are allowed.
          </mat-error>
        </mat-form-field>
      </div>
      
      <div class="form-wrapper">
        <label for="">Fax Number</label>
        <mat-form-field>
          <input matInput placeholder="Enter number" formControlName="fax" />
          <mat-error *ngIf="
          billingAddressForm.get('fax')!.touched &&
          billingAddressForm.get('fax')!.hasError('maxlength')
        ">
            Fax number must be at most 15 characters long.
          </mat-error>
          <mat-error
            *ngIf="billingAddressForm.get('fax')!.touched && billingAddressForm.get('fax')!.hasError('pattern')">
            Invalid fax number. Only digits are allowed.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Phone</label>
        <mat-form-field>
          <input matInput placeholder="Enter mobile number" formControlName="phone" />

          <!-- Required Error - only if field is empty -->
          <mat-error
            *ngIf="billingAddressForm.get('phone')!.touched && billingAddressForm.get('phone')!.hasError('required')">
            Mobile number is required.
          </mat-error>

          <!-- Min Length Error - only if value is present but below minimum length -->
          <mat-error
            *ngIf="billingAddressForm.get('phone')!.touched && billingAddressForm.get('phone')!.hasError('minlength')">
            Mobile number must be at least 10 digits.
          </mat-error>

          <!-- Max Length Error - only if value is present but above maximum length -->
          <mat-error
            *ngIf="billingAddressForm.get('phone')!.touched && billingAddressForm.get('phone')!.hasError('maxlength')">
            Mobile number cannot exceed 15 digits.
          </mat-error>

          <!-- Pattern Error - only if value is present but invalid pattern -->
          <mat-error
            *ngIf="billingAddressForm.get('phone')!.touched && billingAddressForm.get('phone')!.hasError('pattern')">
            Invalid mobile number. Only digits are allowed.
          </mat-error>
        </mat-form-field>
      </div>

    </div>
  </form>
  <form [formGroup]="shippingAddressForm" (ngSubmit)="onSubmitShipping()">
    <div class="form-box">
      <div class="form-sub-head">
        <h2 class="cmn-inner-heading">Shipping Address</h2>
        <div class="check-wrapper">
            <div class="check-box">
                <label class="checkbox-wrapper">
                    <input type="checkbox" (change)="onCopyBillingAddress($event)"/>
                    Copy billing address
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
    </div>
      <div class="form-wrapper">
        <label for="">Attention</label>
        <mat-form-field>
          <input matInput placeholder="Attention" formControlName="attention" />
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Country/Region</label>
        <mat-form-field>
          <mat-select placeholder="Select country" formControlName="country">
            @for (country of countries; track country) {
            <mat-option [value]="country.name">{{ country.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Address</label>
        <mat-form-field>
          <textarea matInput placeholder="Street 1" formControlName="addressLine1"></textarea>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <mat-form-field>
          <textarea matInput placeholder="Street 2" formControlName="addressLine2"></textarea>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">City</label>
        <mat-form-field>
          <input matInput placeholder="Enter city" formControlName="city" />
          <mat-error *ngIf="
      shippingAddressForm.get('city')!.touched &&
      shippingAddressForm.get('city')!.hasError('maxlength')
    ">
            City name must be at most 10 characters long.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">State</label>
        <mat-form-field>
          <input matInput placeholder="Enter state" formControlName="state" />
          <mat-error *ngIf="
          shippingAddressForm.get('state')!.touched &&
          shippingAddressForm.get('state')!.hasError('maxlength')
        ">
            State name must be at most 10 characters long.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">P.O Box Number</label>
        <mat-form-field>
          <input matInput placeholder="Enter number" formControlName="pinCode" />
      
          <!-- Max Length Error - only if value exceeds maximum length -->
          <mat-error *ngIf="
            shippingAddressForm.get('pinCode')!.touched &&
            shippingAddressForm.get('pinCode')!.hasError('maxlength')
          ">
            P.O box must be at most 8 characters long.
          </mat-error>
      
          <!-- Pattern Error - only if value is not numeric -->
          <mat-error *ngIf="
            shippingAddressForm.get('pinCode')!.touched &&
            shippingAddressForm.get('pinCode')!.hasError('pattern')
          ">
            Invalid P.O box number. Only digits are allowed.
          </mat-error>
        </mat-form-field>
      </div>
      
      <div class="form-wrapper">
        <label for="">Fax Number</label>
        <mat-form-field>
          <input matInput placeholder="Enter number" formControlName="fax" />
          <mat-error *ngIf="
          shippingAddressForm.get('fax')!.touched &&
          shippingAddressForm.get('fax')!.hasError('maxlength')
        ">
            Fax number must be at most 15 characters long.
          </mat-error>
          <mat-error
            *ngIf="shippingAddressForm.get('fax')!.touched && shippingAddressForm.get('fax')!.hasError('pattern')">
            Invalid fax number. Only digits are allowed.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Phone</label>
        <mat-form-field>
          <input matInput placeholder="Enter mobile number" formControlName="phone" />

          <!-- Min Length Error - only if value is present but below minimum length -->
          <mat-error
            *ngIf="shippingAddressForm.get('phone')!.touched && shippingAddressForm.get('phone')!.hasError('minlength')">
            Mobile number must be at least 10 digits.
          </mat-error>

          <!-- Max Length Error - only if value is present but above maximum length -->
          <mat-error
            *ngIf="shippingAddressForm.get('phone')!.touched && shippingAddressForm.get('phone')!.hasError('maxlength')">
            Mobile number cannot exceed 15 digits.
          </mat-error>

          <!-- Pattern Error - only if value is present but invalid pattern -->
          <mat-error
            *ngIf="shippingAddressForm.get('phone')!.touched && shippingAddressForm.get('phone')!.hasError('pattern')">
            Invalid mobile number. Only digits are allowed.
          </mat-error>
        </mat-form-field>
      </div>

    </div>
  </form>
</div>