
  <section class="cmn-innerpage-wrapper inventory-new">
    <h2 class="cmn-inner-heading">
      {{ itemId ? "Update Item" : "New Item" }}
    </h2>
    <form [formGroup]="inventoryItemForm" (ngSubmit)="onCreateItem()">
      <label class="radio-label">Item Type</label>
      <div class="type-selection">
        <label *ngFor="let itemType of itemTypes" class="radiobtn-wrapper">
          <input
            type="radio"
            [value]="itemType.value"
            formControlName="itemType"
            name="itemType"
          />

          <span class="radiomark"></span>
          {{ itemType.label }}
          <mat-error
            *ngIf="
              inventoryItemForm.get('itemType')!.touched &&
              inventoryItemForm.get('itemType')!.hasError('required')
            "
          >
            Item Type is required.
          </mat-error>
        </label>
      </div>

      <div class="chart-account-form-wrapper">
        <div class="form-wrapper">
          <label for="">Item Name</label>
          <mat-form-field>
            <input
              matInput
              placeholder="Enter name"
              formControlName="itemName"
              maxlength="20"
            />
            <mat-error
              *ngIf="
                inventoryItemForm.get('itemName')!.touched &&
                inventoryItemForm.get('itemName')!.hasError('required')
              "
            >
              Item Name is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">SKU</label>
          <mat-form-field>
            <input
              matInput
              placeholder="Enter SKU Code"
              formControlName="skuCode"
              maxlength="20"
            />
            <mat-error
              *ngIf="
                inventoryItemForm.get('skuCode')!.touched &&
                inventoryItemForm.get('skuCode')!.hasError('required')
              "
            >
              SKU is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-wrapper" *ngIf="!hideStatusAndQuantity">
          <label for="">Item Status</label>
          <mat-form-field>
            <mat-select
              placeholder="Select ItemStatus"
              formControlName="status"
            >
              <mat-option
                *ngFor="let type of itemStatuses"
                [value]="type.value"
              >
                {{ type.label }}
              </mat-option>
            </mat-select>
            <!-- <mat-error
              *ngIf="
                inventoryItemForm.get('status')!.touched &&
                inventoryItemForm.get('status')!.hasError('required')
              "
            >
              Status is required.
            </mat-error> -->
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Unit</label>
          <mat-form-field>
            <mat-select
              placeholder="Choose unit"
              formControlName="unit"
              maxlength="7"
            >
              <mat-option *ngFor="let unit of unitTypes" [value]="unit.value">
                {{ unit.value }}
              </mat-option>
            </mat-select>
            <!-- <mat-error
              *ngIf="
                inventoryItemForm.get('unit')!.touched &&
                inventoryItemForm.get('unit')!.hasError('required')
              "
            >
              Unit is required.
            </mat-error> -->
          </mat-form-field>
        </div>
        <div class="form-wrapper" *ngIf="!hideStatusAndQuantity">
          <label for="">Quantity</label>
          <mat-form-field>
            <input
              matInput
              placeholder="Quantity"
              formControlName="quantity"
              type="number"
              maxlength="10"
            />
            <mat-error
              *ngIf="inventoryItemForm.get('quantity')?.hasError('nonNegative')"
            >
              Quantity cannot be negative.
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- Error Message for At Least One Required -->
      <div
        *ngIf="inventoryItemForm.errors?.['atLeastOneRequired']"
        class="error"
      >
        Either Sales or Purchase Information must be selected.
      </div>

      <div class="information-type-form-wrapper">
        <div class="form-box">
          <div class="check-wrapper">
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input type="checkbox" formControlName="isSalesActive" />
                Sales Information
                <span class="checkmark"></span
                ><mat-error
                  *ngIf="
                    inventoryItemForm.get('isSalesActive')?.hasError('required')
                  "
                >
                  Sales Information is required.
                </mat-error>
              </label>
            </div>
          </div>
          <div class="form-wrapper">
            <label for="">Selling Price</label>
            <div class="split-parent">
              <div class="left-set">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="0.00"
                    formControlName="sellingPrice"
                    type="number"
                    maxlength="15"
                  />
                  <mat-error
                    *ngIf="
                      inventoryItemForm
                        .get('sellingPrice')
                        ?.hasError('required')
                    "
                  >
                    Selling Price is required.
                  </mat-error>
                  <mat-error
                    *ngIf="
                      inventoryItemForm
                        .get('sellingPrice')
                        ?.hasError('nonNegative')
                    "
                  >
                    Selling Price cannot be negative.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="right-set">
                <mat-form-field>
                  <input matInput value="AED" readonly />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="form-wrapper">
            <mat-form-field>
              <mat-select
                placeholder="Select an account"
                formControlName="salesAccountId"
              >
                <mat-option
                  *ngFor="let account of accountData"
                  [value]="account.id"
                >
                  {{ account.accountName }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  inventoryItemForm.get('salesAccountId')?.hasError('required')
                "
              >
                Sales Account is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label for="">Description</label>
            <mat-form-field>
              <textarea
                matInput
                placeholder="Type Something"
                formControlName="salesDescription"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="form-box">
          <div class="check-wrapper">
            <div class="check-box">
              <label class="checkbox-wrapper">
                <input type="checkbox" formControlName="isPurchaseActive" />
                Purchase Information
                <span class="checkmark"></span>
                <mat-error
                  *ngIf="
                    inventoryItemForm
                      .get('isPurchaseActive')
                      ?.hasError('required')
                  "
                >
                  Purchase Information is required.
                </mat-error>
              </label>
            </div>
          </div>
          <div class="form-wrapper">
            <label for="">Cost Price</label>
            <div class="split-parent">
              <div class="left-set">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="0.00"
                    formControlName="costPrice"
                    type="number"
                    maxlength="15"
                  />
                  <mat-error
                    *ngIf="
                      inventoryItemForm
                        .get('costPrice')
                        ?.hasError('nonNegative')
                    "
                  >
                    Cost Price cannot be negative.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="right-set">
                <mat-form-field>
                  <input matInput value="AED" readonly />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="form-wrapper">
            <mat-form-field>
              <mat-select
                placeholder="Select an account"
                formControlName="purchaseAccountId"
              >
                <mat-option
                  *ngFor="let account of accountData"
                  [value]="account.id"
                >
                  {{ account.accountName }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  inventoryItemForm
                    .get('purchaseAccountId')
                    ?.hasError('required')
                "
              >
                Purchase Account ID is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label for="">Description</label>
            <mat-form-field>
              <textarea
                matInput
                placeholder="Type Something"
                formControlName="purchaseDescription"
              ></textarea>
            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label for="">Preferred Vendor</label>
            <mat-form-field>
              <mat-select
                placeholder="Select an account"
                formControlName="vendorId"
              >
                <mat-option
                  *ngFor="let vendor of vendorData"
                  [value]="vendor.id"
                >
                  {{ vendor.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="submit-btn-wrapper">
        <button class="cmn-cancel-btn" type="button" (click)="onCancel()">
          Cancel
        </button>
        <button class="cmn-next-btn" type="submit" [disabled]="loading">
          <span *ngIf="!loading">{{ itemId ? "Update" : "Save" }}</span>
          <span *ngIf="loading">Loading...</span>
        </button>
      </div>
    </form>
  </section>

