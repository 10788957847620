
    <section class="cmn-innerpage-wrapper quotation-new">
        <h2 class="cmn-inner-heading">New Quotation</h2>
        <div class="invoice-new-form-wrapper">
            <div class="form-wrapper invoice-form-wrapper">

                <div class="cmn-Search-select-container">
                    <label for="">Customer Name</label>
                    <mat-form-field>
                        <mat-select placeholder="Search Customer" #singleSelect1>
                            <mat-option>
                                <ngx-mat-select-search></ngx-mat-select-search>
                            </mat-option>

                            <mat-option value="Single1">Client</mat-option>
                            <mat-option value="Married2">Client</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button>
                        <div><img src="../../../assets/images/icons/icon-add-color.svg" alt=""></div>
                        <span>Add Customer</span>
                    </button>
                </div>
                <div>
                    <label for="">Quote Number</label>
                    <mat-form-field>
                        <input matInput placeholder="QT-000102" />
                    </mat-form-field>
                </div>
                <div>
                    <label for="">Reference</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter project name" />
                    </mat-form-field>
                </div>
                <div>
                    <label for="">Quote Date</label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker1" placeholder="Select Date" />
                        <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>

                    </mat-form-field>
                </div>
                <div>
                    <label for="">Expiry Date</label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker2" placeholder="Select Date" />
                        <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>

                    </mat-form-field>
                </div>
                <div>
                    <label for="">Branch Name</label>
                    <mat-form-field>
                        <mat-select placeholder="Search branch">
                            <mat-option value="Single">Single</mat-option>
                            <mat-option value="Married">Married</mat-option>
                            <mat-option value="Divorced">Divorced</mat-option>
                            <mat-option value="Widowed">Widowed</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <label for="">Subject</label>
                    <mat-form-field>
                        <input matInput placeholder="Quote subject" type="date" />
                    </mat-form-field>
                </div>
                <div>
                    <label for="">Project Duration</label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker3" placeholder="Select Date" />
                        <mat-datepicker-toggle matIconSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="cmn-Search-select-container">
                    <label for="">Project Name</label>
                    <mat-form-field>
                        <mat-select placeholder="Select project" #singleSelect2>
                            <mat-option>
                                <ngx-mat-select-search></ngx-mat-select-search>
                            </mat-option>

                            <mat-option value="Single">Project 1</mat-option>
                            <mat-option value="Married">project 2</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="billing-shipping-address-wrapper">

            <div>

                <div class="header-wrapper">
                    <h5>Billing Address</h5>
                    <div><img src="../../../assets/images/icons/icon-edit-accounts.svg" alt=""></div>
                </div>
                <div>
                    <h6>Mohammed Al Quasimi</h6>

                    <ul>
                        <li>
                            Vila 42,Al Wasl Road
                        </li>
                        <li>
                            Jumeirah 1
                        </li>
                        <li>
                            P.O. Box 12345
                        </li>
                        <li>
                            United Arab Emirates
                        </li>
                        <li>
                            Phone : +971 9654 6954
                        </li>
                    </ul>
                </div>
            </div>

            <div>

                <div class="header-wrapper">
                    <h5>Shipping Address</h5>
                    <div><img src="../../../assets/images/icons/icon-edit-accounts.svg" alt=""></div>
                </div>
                <div>

                    <h6>Mohammed Al Quasimi</h6>

                    <ul>
                        <li>
                            Vila 42,Al Wasl Road
                        </li>
                        <li>
                            Jumeirah 1
                        </li>
                        <li>
                            P.O. Box 12345
                        </li>
                        <li>
                            United Arab Emirates
                        </li>
                        <li>
                            Phone : +971 9654 6954
                        </li>
                    </ul>
                </div>
            </div>


        </div>


        <div class="item-service-adding-table-wrapper">

            <table>
                <thead>
                    <tr>
                        <th>
                            Item/Service
                        </th>
                        <th>
                            Unit </th>
                        <th>
                            Quantity </th>
                        <th>
                            Rate </th>
                        <th>
                            Discount </th>
                        <th>
                            Amount </th>
                        <th>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>
                            <div class="form-wrapper">
                                <div>
                                    <mat-form-field>
                                        <mat-select placeholder="Select Item">
                                            <mat-option value="Single">Item 1</mat-option>
                                            <mat-option value="Married">Item 2</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field>
                                        <textarea matInput placeholder="Enter item description"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="form-wrapper">

                                <mat-form-field>
                                    <mat-select placeholder="Sq.ft">
                                        <mat-option value="Single">Single</mat-option>
                                        <mat-option value="Married">Married</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </td>
                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput placeholder="0923423" />
                                </mat-form-field>
                            </div>
                        </td>
                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput placeholder="0923423" />
                                </mat-form-field>
                            </div>
                        </td>
                        <td>
                            <div class="form-wrapper">
                                <div class="split-parent">
                                    <div class="left-set">
                                        <mat-form-field>
                                            <input matInput placeholder="3.67" />
                                        </mat-form-field>
                                    </div>
                                    <div class="right-set">
                                        <mat-form-field>
                                            <mat-select placeholder="%">
                                                <mat-option value="inr">%</mat-option>
                                                <mat-option value="usd">&</mat-option>
                                                <mat-option value="eur">$</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput placeholder="0923423" />
                                </mat-form-field>
                            </div>
                        </td>
                        <td>
                            <img src="../../../assets/images/icons/icon-add-color.svg" alt="">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="quotation-table-container">
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                            </th>
                            <th>Item/Service</th>
                            <th>Description</th>
                            <th>Unit</th>
                            <th>Quantity</th>
                            <th>Rate</th>
                            <th>Discount</th>
                            <th>Amount</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of arr">
                            <td>{{item}}</td>
                            <td>Floor Cleaning</td>
                            <td>Cleaning</td>
                            <td>pcs</td>
                            <td>1</td>
                            <td><sub>aed</sub>150</td>
                            <td>5%</td>
                            <td><sub>aed</sub>150</td>
                            <td><img src="../../../assets/images/icons/icon-edit-accounts.svg" alt=""></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


        <div class="notes-tatal-amount-container">
            <div class="form-container">
                <div class="form-wrapper">
                    <label for="">Customer Notes</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Looking forward for your business.."></textarea>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Terms & Conditions</label>
                    <mat-form-field>
                        <textarea matInput
                            placeholder="Enter the terms & conditions of your business to be displayed in your transaction."></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div>

                <div class="form-wrapper">
                    <label for="">VAT</label>
                    <div class="split-parent">
                        <div class="left-set">
                            <mat-form-field>
                                <input matInput placeholder="3.67" />
                            </mat-form-field>
                        </div>
                        <div class="right-set">
                            <mat-form-field>
                                <mat-select placeholder="%">
                                    <mat-option value="inr">%</mat-option>
                                    <mat-option value="usd">&</mat-option>
                                    <mat-option value="eur">$</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="amount-table-div">
                    <div>
                        <h6>Discount <span>: AED -50.00</span></h6>
                        <h6>VAT amount <span>: AED +50.00</span></h6>
                        <h6>Adjustment <span>: AED 0.00</span></h6>
                    </div>
                    <div>
                        <h4>Total(AED) <span>: AED 590.00</span></h4>
                    </div>
                </div>
            </div>
        </div>


        <div class="payment-mode-wrapper">
            <div class="add-email-wrapper">
                <h6>Email Connection</h6>
                <div class="flex-wrapper-container">
                    <div class="email-account-div">
                        <div class="profile-image">
                            <img src="../../../assets/images/profile.png" alt="">
                        </div>
                        <span>
                            rikson&#64;gmail.com
                        </span>
                        <div class="close-btn">
                            <img src="../../../assets/images/icons/icon-close.svg" alt="">
                        </div>
                    </div>
                    <button>
                        <div>
                            <img src="../../../assets/images/icons/icon-add-color.svg" alt="">
                        </div>
                        <span>Add email</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="upload-file-group">
            <h2>Documents</h2>
            <input #fileInput type="file" style="display: none" />
            <button class="cmn-upload-btn" (click)="fileInput.click()">
                <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                <span>Upload File</span>
            </button>
            <div class="uploaded-file">
                <h6>
                    file-name.pdf
                    <img src="assets/images/icons/icon-close.svg" alt="Close Icon" />
                </h6>
            </div>
        </div>

        <div class="submit-btn-wrapper">
            <button class="cmn-cancel-btn">Cancel</button>
            <button class="cmn-draft-btn">Save as Draft</button>
            <button class="cmn-next-btn" type="submit">Next</button>
        </div>



    </section>
