import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CustomerService } from '../../../../features/customers/services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-statements',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule
  ],
  templateUrl: './statements.component.html',
  styleUrl: '../../client-details.component.scss'
})
export class StatementsComponent {
  partnerDetails: any;
  customerId: any;
  statements: any;

  constructor(
    private customerService: CustomerService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ){}

  ngOnInit(){
    this.route.paramMap.subscribe(params => {
      this.customerId = params.get('id');
      if (this.customerId) {
        this.partnerDetailById(Number(this.customerId));
        }
      });
  }

  
  partnerDetailById(id: any) {
    this.customerService.partnerDetails(id).subscribe({
      next: (data: any) => {
        this.partnerDetails = data;
        console.log(this.partnerDetails);
        
      },
      error: (error) => {
        this.toastr.error(error?.message);
      },
    });
  }
}
