
    <section class="cmn-innerpage-wrapper invoice-new">
      <h2 class="cmn-inner-heading">Edit GRN</h2>
      <div class="invoice-new-form-wrapper">
        
        <div class="form-wrapper invoice-form-wrapper">
  
          <!-- Client Name Section -->
          <div class="cmn-Search-select-container">
            <label for="clientName">Vendor Name</label>
            <mat-form-field>
              <mat-select placeholder="Search Client" id="clientName">
                <mat-option>
                  <ngx-mat-select-search></ngx-mat-select-search>
                </mat-option>
                <mat-option value="client1">Client 1</mat-option>
                <mat-option value="client2">Client 2</mat-option>
              </mat-select>
            </mat-form-field>
            <button>
              <div><img src="../../../assets/images/icons/icon-add-color.svg" alt="Add client"></div>
              <span>Add client</span>
            </button>
          </div>
        
          <!-- Invoice Number Section -->
          <div>
            <label for="invoiceNumber">Pruchase Order Number</label>
            <mat-form-field>
              <input matInput placeholder="IN-000212" id="invoiceNumber" />
            </mat-form-field>
          </div>
        
          <!-- Order Number Section -->
          <div>
            <label for="orderNumber">Reference</label>
            <mat-form-field>
              <input matInput placeholder="0923423" id="orderNumber" />
            </mat-form-field>
          </div>
        
          <!-- Invoice Date Section -->
          <div>
            <label for="invoiceDate">Expected Delivery Date</label>
            <mat-form-field>
              <input matInput [matDatepicker]="invoiceDatePicker" placeholder="Select Date" id="invoiceDate" />
              <mat-datepicker-toggle matIconSuffix [for]="invoiceDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #invoiceDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
        
          <!-- Terms Section -->
          <div>
            <label for="paymentTerms">Payment Terms</label>
            <mat-form-field>
              <mat-select placeholder="Due on receipt" id="paymentTerms">
                <mat-option value="Due on receipt">Due on receipt</mat-option>
                <mat-option value="Net 30">Net 30</mat-option>
                <mat-option value="Net 60">Net 60</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        
          <!-- Due Date Section -->
          <div>
            <label for="dueDate">Shipment Preference</label>
            <mat-form-field>
              <input matInput [matDatepicker]="dueDatePicker" placeholder="Select Date" id="dueDate" />
              <mat-datepicker-toggle matIconSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #dueDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
        
          <!-- Subject Section -->
          <div>
            <label for="subject">Project Name</label>
            <mat-form-field>
              <input matInput placeholder="Invoice subject" id="subject" />
            </mat-form-field>
          </div>
       
          
         
        
          <!-- Milestone Section -->
          <div>
            <label for="milestone">Milestone</label>
            <mat-form-field>
              <mat-select placeholder="Choose milestone" id="milestone">
                <mat-option value="milestone1">Milestone 1</mat-option>
                <mat-option value="milestone2">Milestone 2</mat-option>
                <mat-option value="milestone3">Milestone 3</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <label for="subject">Subject</label>
            <mat-form-field>
              <mat-select placeholder="Choose milestone" id="subject">
                <mat-option value="milestone1">Milestone 1</mat-option>
                <mat-option value="milestone2">Milestone 2</mat-option>
                <mat-option value="milestone3">Milestone 3</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        
        </div>

        <div class="service-address-wrapper">
            <h5>Service Address</h5>
            <div>
                <div class="form-label-group" >
                    <label for="">
                        <input type="radio" id="radio" name="radio">
                        <span>Organization</span>
                    </label>

                    <div>
                        <h6>Dans Designs

                        </h6>
                        <div>
                            <img src="../../../assets/images/icons/icon-edit-accounts.svg" alt="">
                        </div>
                    </div>

                    <p>United Arab Emirates</p>
                    <p>Abu Dhabi 12345</p>
                </div>
                <div>
                    <label for="">
                        <input type="radio" id="radio1" name="radio">
                        <span>Customer</span>
                    </label>
                </div>
            </div>
        </div>
  
      </div>
  

  
  
      <div class="item-service-adding-table-wrapper">
  
        <table>
          <thead>
            <tr>
              <th>
                Item/Service
              </th>
              <th>
                Unit </th>
              <th>
                Quantity </th>
              <th>
                Rate </th>
              <th>
                Discount </th>
              <th>
                Amount </th>
              <th>
              </th>
            </tr>
          </thead>
  
          <tbody>
            <tr>
              <td>
                <div class="form-wrapper">
                  <div>
                    <mat-form-field>
                      <mat-select placeholder="Select Item">
                        <mat-option value="item1">Item 1</mat-option>
                        <mat-option value="item2">Item 2</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div>
                    <mat-form-field>
                      <textarea matInput placeholder="Enter item description"></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </td>
              <td>
                <div class="form-wrapper">
  
                  <mat-form-field>
                    <mat-select placeholder="Sq.ft" >
                      <mat-option value="Sq">Sq</mat-option>
                      <mat-option value="Kg">Kg</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>
              <td>
                <div class="form-wrapper">
                  <mat-form-field>
                    <input matInput placeholder="quantity" />
                  </mat-form-field>
                </div>
              </td>
              <td>
                <div class="form-wrapper">
                  <mat-form-field>
                    <input matInput placeholder="rate" />
                  </mat-form-field>
                </div>
              </td>
              <td>
                <div class="form-wrapper">
                  <div class="split-parent">
                    <div class="left-set">
                      <mat-form-field>
                        <input matInput placeholder="3.67" />
                      </mat-form-field>
                    </div>
                    <div class="right-set">
                      <mat-form-field>
                        <mat-select placeholder="%">
                          <mat-option value="inr">%</mat-option>
                          <mat-option value="usd">&</mat-option>
                          <mat-option value="eur">$</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
  
              </td>
              <td>
                <div class="form-wrapper">
                  <mat-form-field>
                    <input matInput placeholder="100" />
                  </mat-form-field>
                </div>
              </td>
              <td>
                <img src="../../../assets/images/icons/icon-add-color.svg" alt="">
              </td>
            </tr>
          </tbody>
        </table>
  
      </div>
  
      <div class="quotation-table-container">
  
        <div>
          <table>
  
            <thead>
              <tr>
                <th>
  
                </th>
                <th>Item/Service</th>
                <th>Description</th>
                <th>Unit</th>
                <th>Quantity</th>
                <th>Rate</th>
                <th>Discount</th>
                <th>Amount</th>
                <th></th>
              </tr>
            </thead>
  
            <tbody>
  
              <tr *ngFor="let item of arr">
                <td>{{item}}</td>
                <td>Floor Cleaning</td>
                <td>Cleaning</td>
                <td>pcs</td>
                <td>1</td>
                <td><sub>aed</sub>150</td>
                <td>5%</td>
                <td><sub>aed</sub>150</td>
                <td><img src="../../../assets/images/icons/icon-edit-accounts.svg" alt=""></td>
              </tr>
  
  
            </tbody>
          </table>
        </div>
  
      </div>
  
  
      <div class="notes-tatal-amount-container">
        <div class="form-container">
          <div class="form-wrapper">
            <label for="customerNotes">Customer Notes</label>
            <mat-form-field>
              <textarea matInput placeholder="Looking forward to your business"></textarea>
            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label for="termsConditions">Terms & Conditions</label>
            <mat-form-field>
              <textarea matInput placeholder="Enter the terms & conditions"></textarea>
            </mat-form-field>
          </div>
          
        </div>
        <div class="amount-table-div">
          <div>
            <h6>Discount <span>: AED -50.00</span></h6>
            <h6>VAT amount <span>: AED +50.00</span></h6>
            <h6>Adjustment <span>: AED 0.00</span></h6>
          </div>
          <div>
            <h4>Total(AED) <span>: AED 590.00</span></h4>
          </div>
        </div>
      </div>
      
  
  
      <div class="payment-mode-wrapper">
  
     
      
        <!-- Email Connection Section -->
        <div class="add-email-wrapper">
          <h6>Email Connection</h6>
          <div class="flex-wrapper-container">
            <div class="email-account-div">
              <div class="profile-image">
                <img src="../../../assets/images/profile.png" alt="Profile Image">
              </div>
              <span>rikson&#64;gmail.com</span>
              <div class="close-btn">
                <img src="../../../assets/images/icons/icon-close.svg" alt="Remove Email">
              </div>
            </div>
            <button>
              <div>
                <img src="../../../assets/images/icons/icon-add-color.svg" alt="Add Email">
              </div>
              <span>Add email</span>
            </button>
          </div>
        </div>
      
      </div>
      
  
      <div class="submit-btn-wrapper">
        <button class="cmn-cancel-btn">Cancel</button>
      
        <button class="cmn-next-btn" type="submit">Update</button>
      </div>
  
  
  
    </section>
  