import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { GET_LEAVES, GET_LEAVES_BY_ID, GET_LEAVE_COUNT, LEAVE_APPROVALS, TIMELINES } from '../graphql/query/leave.queries';
import { APPROVE_LEAVE, CREATE_LEAVE, REMOVE_LEAVE, UPDATE_LEAVE, UPDATE_MY_LEAVE } from '../graphql/mutation/leave.mutation';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LeaveService {
  constructor(private apollo: Apollo) {}

  fetchLeaves(filter: {}): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_LEAVES,
        variables: { filter },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.leaves || [])
      );
  }

  fetchLeaveById(id: any): Observable<any> {
    const leaveId = Number(id);
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_LEAVES_BY_ID,
        variables: { id: leaveId },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.leave || [])
      );
  }
  
  getLeaveCount(id: any): Observable<any> {
    const employeeId = Number(id);
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_LEAVE_COUNT,
        variables: { employeeId: employeeId },
      })
      .valueChanges.pipe(
        map((result) => result.data?.getLeaveCount || [])
      );
  }

  getLeaveApprovals(id: any, type: any): Observable<any> {
    const requestId = Number(id);
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: LEAVE_APPROVALS,
        variables: { 
          requestId: requestId, 
          type: type
        },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.leaveApprovals || [])
      );
  }

  createLeave(leaveData: any, files: File[]): Observable<any> {
    const token = localStorage.getItem('AUTH_TOKEN'); 
    const formData = new FormData();
  
    const query = `
    mutation createLeave( $createLeaveInput: CreateLeaveManagementInput!) {
      createLeave(createLeaveInput: $createLeaveInput) {
        id
        employeeId
        employee {
          id
          firstName
          lastName
          middleName
          dateOfBirth
          gender
          employeeApprovers{
            employee{
              firstName
              lastName
            }
          }
        }
        leaveType
        startDate
        endDate
        totalLeaveDays
        leaveStatus
        employeeComment
        supportingDocuments
        createdAt
        updatedAt
        deletedAt
      }
    }
    `;
  
    const variables: any = {
      createLeaveInput: {
        employeeId: leaveData.employeeId,
        leaveType: leaveData.leaveType,
        startDate: leaveData.startDate,
        endDate: leaveData.endDate,
        employeeComment: leaveData.employeeComment,
        ...(files.length > 0 && { supportingDocumentFile: null }) 
      }
    };
  
    formData.append('operations', JSON.stringify({
      query,
      variables
    }));
  
    const map: any = {};
    files.forEach((file, index) => {
      map[index] = [`variables.createLeaveInput.supportingDocumentFile`]; // Correct mapping
    });
    formData.append('map', JSON.stringify(map));
    files.forEach((file, index) => {
      formData.append(index.toString(), file);
    });
  
    return from(
      fetch(environment.url.hrmsModuleUrl, {
        method: 'POST',
        body: formData, 
        headers: {
          'authorization': token ? `Bearer ${token}` : '', 
          'x-apollo-operation-name': 'uploadFile'
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(result => result.data?.createLeave || result)
    );
  }

  updateLeave(leaveData: any, files: File[]): Observable<any> {
    const token = localStorage.getItem('AUTH_TOKEN'); 
    const formData = new FormData();
  
    const query = `
    mutation updateLeave( $updateLeaveInput: UpdateLeaveManagementInput!) {
      updateLeave(updateLeaveInput: $updateLeaveInput) 
    {
      id
      employeeId
      employee {
        id
        firstName
        lastName
        middleName
        dateOfBirth
        gender
        employeeApprovers{
          employee{
            firstName
          }
        }
        email
        phoneNumber
        alternatePhoneNumber
        address
        permanentAddress
        dateOfJoining
        employmentType
        employeeType
        employeeStatus
        profilePicture
        maritalStatus
        nationality
        passportNumber
        visaType
        visaIssueDate
        visaExpiryDate
        salary
        bankName
        bankAccountNumber
        IFSCCode
        departmentId
      }
      leaveType
      startDate
      endDate
      totalLeaveDays
      leaveStatus
      employeeComment
      supportingDocuments
      createdAt
      updatedAt
      deletedAt
    }
  }
    `;
  
    const variables: any = {
      updateLeaveInput: {
        id: leaveData.id,
        leaveType: leaveData.leaveType,
        startDate: leaveData.startDate,
        endDate: leaveData.endDate,
        employeeComment: leaveData.employeeComment,
        ...(files.length > 0 && { supportingDocumentFile: null }) 
      }
    };
  
    formData.append('operations', JSON.stringify({
      query,
      variables
    }));
  
    const map: any = {};
    files.forEach((file, index) => {
      map[index] = [`variables.updateLeaveInput.supportingDocumentFile`]; // Correct mapping
    });
    formData.append('map', JSON.stringify(map));
    files.forEach((file, index) => {
      formData.append(index.toString(), file);
    });
  
    return from(
      fetch(environment.url.hrmsModuleUrl, {
        method: 'POST',
        body: formData, 
        headers: {
          'authorization': token ? `Bearer ${token}` : '', 
          'x-apollo-operation-name': 'uploadFile'
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(result => result.data?.updateLeave || result)
    );
  }
  
  removeLeave(id: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .mutate({
        mutation: REMOVE_LEAVE,
        variables: { 
          id: id
        },
      })
      .pipe(
        map((result: any) => result.data.removeLeave || [])
      );
  }

  updateLeaveStatus(id: any, status: any, employeeComment: string): Observable<any> {
    const leaveId = Number(id);
    return this.apollo
      .use('hrms')
      .mutate({
        mutation: APPROVE_LEAVE,
        variables: { 
          id: leaveId,
          status: status,
          comment: employeeComment
        },
      })
      .pipe(
        map((result: any) => result.data.updateLeaveStatus || [])
      );
  }

  updateMyLeaveStatus(id: any, status: any): Observable<any> {
    const leaveId = Number(id);
    return this.apollo
      .use('hrms')
      .mutate({
        mutation: UPDATE_MY_LEAVE,
        variables: { 
          id: leaveId,
          status: status,
        },
      })
      .pipe(
        map((result: any) => result.data.updateMyLeaveStatus || [])
      );
  }

  
  fetchTimelines(type: any, refId: any, activityType: any, search: any): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: TIMELINES,
        variables: { 
          type,
          refId,
          activityType,
          search
         },
        fetchPolicy: 'network-only'
      })
      .valueChanges.pipe(
        map((result) => result.data?.timelines || [])
      );
  }

}
