
    <section class="cmn-innerpage-wrapper new-chart-accounts">
        <h2>Create Account</h2>
        <form [formGroup]="createAccountForm">
            <div class="chart-account-form-wrapper">
                <div class="form-wrapper">
                    <label for="">Account type</label>
                    <mat-form-field>
                        <mat-select placeholder="Select Account" formControlName="accountType">
                            <mat-option *ngFor="let account of accounts" value={{account?.accountName}}>{{account?.accountName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div
                    *ngIf="
                      createAccountForm.get('accountType')!.invalid &&
                      createAccountForm.get('accountType')!.touched
                    "
                    class="error"
                  >
                    Account type is required.
                  </div>
                </div>
                <div class="form-wrapper">
                    <label for="">Account name</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter name" formControlName="accountName"/>
                    </mat-form-field>
                    <div
                    *ngIf="
                      createAccountForm.get('accountName')!.invalid &&
                      createAccountForm.get('accountName')!.touched
                    "
                    class="error"
                  >
                    Account name is required.
                  </div>
                    <mat-checkbox (change)="toggleSubAccount($event.checked)">Make this a sub-account</mat-checkbox>
                </div>
                   <div class="form-wrapper" *ngIf="isSubAccount">
                    <label for="">Parent Account</label>
                    <mat-form-field>
                        <mat-select placeholder="Select Account" formControlName="parentAccountId">
                            <mat-option *ngFor="let chartAccount of chartAccounts" value={{chartAccount?.id}}>{{chartAccount?.accountName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Account Code</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter code" formControlName="accountCode"/>
                    </mat-form-field>
                    <div
                    *ngIf="
                      createAccountForm.get('accountCode')!.invalid &&
                      createAccountForm.get('accountCode')!.touched
                    "
                    class="error"
                  >
                    Account code is required.
                  </div>
                </div>
                <div class="form-wrapper">
                    <label for="">Description</label>
                    <mat-form-field>
                        <textarea matInput placeholder="max 500 characters" formControlName="description"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="submit-btn-wrapper">
                <button class="cmn-cancel-btn">Cancel</button>
                <button class="cmn-next-btn" (click)="onSave()">Save</button>
            </div>
        </form>
    </section>
