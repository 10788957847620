import { gql } from "apollo-angular";

export const LIST_ACCOUNTS_CASH_AND_BANK = gql`
  query listAllChartOfAccountsOfBankAndCash($search: String ) {
    listAllChartOfAccountsOfBankAndCash(search: $search) {
    chartOfAccount {
      id
      accountType
      accountName
      accountCode
      description
      amount
      parentAccountId
      isSubAccount
      isEditable
      status
      createdAt
      updatedAt
      deletedAt
    }
    uncategorized
    pending
    amount
  }
  }
`;

export const NET_AMOUNT_CASH_AND_BANK = gql`
  query getNetAmountOfChartOfAccountsOfBankAndCash {
    getNetAmountOfChartOfAccountsOfBankAndCash  {
    bank
    cash
    uncategorizedCount
  }
  }
`;

export const TRANSACTIONS_OF_CHART_ACCOUNTS = gql`
  query findAllTransactionsOfAChartOfAccount($id: Int!) {
    findAllTransactionsOfAChartOfAccount(id: $id)   {
    chartOfAccount {
      id
      accountType
      accountName
      accountCode
      description
      amount
      transactions{
        id
        date
        referenceNumber
        description
        offsetAccount{
          accountName
          accountType
        }
        partner{
          partnerType
          displayName
        }
        transactionStatus
        amount
        flow
      }
      parentAccountId
      isSubAccount
      isEditable
      status
      createdAt
      updatedAt
      deletedAt
    }
    uncategorizedTransactionsCount
    amount
  }
  }
`;