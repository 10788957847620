import { Component, OnInit, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { ManualJournalService } from '../../features/manual-journals/services/manual-journal.service';
import { JournalStatusEnum } from '../../../assets/journalStatus';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-manual-journal-details',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatInputModule, MatTabsModule],
  templateUrl: './manual-journal-details.component.html',
  styleUrl: './manual-journal-details.component.scss',
})
export class ManualJournalDetailsComponent implements OnInit {
  selectedTabIndex = 0; // Default to the first tab
  journalId!: any;
  journalData: any = {};
  allJournals: any[] = [];
  journalEntries: any[] = [];
  journalDate: string;
  contactName: string;
  debitTotal: number = 0;
  creditTotal: number = 0;
  private searchSubject = new Subject<string>();
  public loading: boolean = true;
  public journalStatuses = Object.values(JournalStatusEnum);
  public selectedStatuses: JournalStatusEnum[] = [];
  public journalStatusValue: string = 'All Journals';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private journalService: ManualJournalService,
    private date: DatePipe
  ) {}

  ngOnInit(): void {
    this.fetchAllJournals();
    this.route.paramMap.subscribe((params) => {
      this.journalId = params.get('id');
      if (this.journalId) {
        this.fetchAllJournals();
      }
    });
    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchAllJournals(searchTerm);
      });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  // New getter to compute the journal title based on the filter
  get journalTitle(): string {
    return this.selectedStatuses.length
      ? this.selectedStatuses[0] + ' Journals'
      : 'All Journals';
  }

  applyFilter(event: Event) {
    event.preventDefault();

    // Update journalStatusValue based on selected status
    this.journalStatusValue = this.selectedStatuses.length
      ? this.selectedStatuses[0]
      : 'All Journals';

    this.fetchAllJournals();
    this.toggleDropdown();
  }

  clearFilter() {
    this.selectedStatuses = [];
    this.journalStatusValue = 'All Journals'; // Reset displayed status
    this.fetchAllJournals();
  }

  onStatusChange(status: JournalStatusEnum, event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const isChecked = inputElement.checked;

    if (isChecked) {
      this.selectedStatuses.push(status);
    } else {
      this.selectedStatuses = this.selectedStatuses.filter((s) => s !== status);
    }
  }

  // Method to navigate to the Edit page
  navigateToEditPage(): void {
    const selectedJournal = this.allJournals[this.selectedTabIndex];
    const journalId = selectedJournal.id;

    // Navigate to the edit page and pass the journal ID
    this.router.navigate([`manual-journals/edit/${journalId}`]);
  }

  fetchAllJournals(search: string = '', filter: any = {}): void {
    this.loading = true;

    const filterCriteria = {
      ...filter,
      status: this.selectedStatuses.length
        ? this.selectedStatuses[0]
        : undefined,
    };

    this.journalService.fetchManualJournals(search, filterCriteria).subscribe({
      next: (journals) => {
        this.allJournals = journals;
        // if (this.allJournals.length > 0) {
        //   this.selectTab(0);
        // }
        if (this.journalId) {
          const targetId = +this.journalId;
          this.selectedTabIndex = this.allJournals.findIndex((c: any) => {
            return c.id === targetId;
          });
          if (this.selectedTabIndex === -1) {
            this.selectedTabIndex = 0;
          }
        } else {
          this.selectedTabIndex = 0;
        }
      },
      error: (err) => {
        console.error('Error fetching all journals:', err);
      },
    });
  }

  selectTab(index: number): void {
    this.selectedTabIndex = index;
    this.journalData = this.allJournals[index];
    this.journalEntries = this.journalData?.journalEnteries || [];
    if (this.journalData) {
      this.router.navigate([`manual-journals/detail/${this.journalData.id}`]);
    }
    this.calculateTotals();
  }

  calculateTotals(): void {
    this.debitTotal = this.journalEntries.reduce(
      (total, entry) => total + (entry.debit || 0),
      0
    );
    this.creditTotal = this.journalEntries.reduce(
      (total, entry) => total + (entry.credit || 0),
      0
    );
  }

  readonly dialog = inject(MatDialog);

  getJournalStatusImage(status: string | undefined): string {
    if (status === 'Published') {
      return 'assets/images/published.svg';
    } else {
      return 'assets/images/img-draft.svg';
    }
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadJournalDialog);
  }

  isDropdownOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}

@Component({
  selector: 'upload-journal-details-dialog',
  templateUrl: 'upload-journal-details-dialog.html',
  styleUrls: ['./manual-journal-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadJournalDialog {
  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }
}
