import { gql } from "apollo-angular";

export const CREATE_PARTNER = gql`
  mutation createPartner(
    $createPartnerInput: CreatePartnerInput!,
    $createContactInputs: [CreateContactInput],
    $createAddressInputs: [CreateAddressInput],
    $createDocumentInput: CreateDocumentInput,
    $createRemarkInput: CreateRemarkInput!,
    ) {
    createPartner(
      createPartnerInput: $createPartnerInput,
      createContactInputs: $createContactInputs,
      createAddressInputs: $createAddressInputs,
      createDocumentInput: $createDocumentInput,
      createRemarkInput: $createRemarkInput,
      )  {
    id
    partnerType
    customerType
    displayName
    email
    phone
    mobile
    currency
    emirateID
    openingBalance
    paymentStatus
    status
    primaryContactId
    primaryContact {
      id
      salutation
      firstName
      lastName
      email
      phone
      mobile
      primaryContact
      status
      partnerId
    }
    contacts {
      id
      salutation
      firstName
      lastName
      email
      phone
      mobile
      primaryContact
      status
      partnerId
    }
    invoices {
      id
      projectId
      orderNo
      invoiceDate
      paymentTerm
      paymentStatus
      reason
      dueDate
      subject
      customerNote
      isPayment
      subTotal
      tax
      adjustment
      totalPrice
      status
      createdAt
      updatedAt
      deletedAt
    }
    sales {
      id
      projectId
      orderId
      reference
      orderDate
      expectedDate
      paymentTerm
      customerNote
      subTotal
      tax
      adjustment
      totalPrice
      status
      createdAt
      updatedAt
      deletedAt
    }
  }
  }
`;

export const UPDATE_PARTNER = gql`
  mutation updatePartner(
    $updatePartnerInput: UpdatePartnerInput!,
    $updateContactInputs: [UpdateContactInput],
    $updateAddressInputs: [UpdateAddressInput],
    $updateDocumentInput: UpdateDocumentInput,
    $updateRemarkInput: UpdateRemarkInput,
    ) {
      updatePartner(
      updatePartnerInput: $updatePartnerInput,
      updateContactInputs: $updateContactInputs,
      updateAddressInputs: $updateAddressInputs,
      updateDocumentInput: $updateDocumentInput,
      updateRemarkInput: $updateRemarkInput,
      )  {
    id
    partnerType
    customerType
    displayName
    companyName
    email
    phone
    mobile
    currency
    emirateID
    openingBalance
    paymentStatus
    status
    primaryContactId
    primaryContact {
      id
      salutation
      firstName
      lastName
      email
      phone
      mobile
      primaryContact
      status
      partnerId
    }
    contacts {
      id
      salutation
      firstName
      lastName
      email
      phone
      mobile
      primaryContact
      status
      partnerId
    }
    invoices {
      id
      projectId
      invoiceNo
      orderNo
      invoiceDate
      paymentTerm
      paymentStatus
      reason
      dueDate
      subject
      customerNote
      isPayment
      subTotal
      tax
      adjustment
      totalPrice
      status
      createdAt
      updatedAt
      deletedAt
    }
    sales {
      id
      projectId
      orderId
      reference
      orderDate
      expectedDate
      paymentTerm
      customerNote
      subTotal
      tax
      adjustment
      totalPrice
      status
      createdAt
      updatedAt
      deletedAt
    }
    remarks {
      id
      remarkContent
      status
      createdAt
      updatedAt
      deletedAt
      partnerId
    }
    addresses {
      id
      partnerId
      attention
      addressType
      addressLine1
      addressLine2
      city
      state
      country
      pinCode
      phone
      fax
    }
    documents {
      id
      partnerId
      documentName
      documentUrl
      createdAt
      updatedAt
      deletedAt
    }
  }
  }
`;

export const REMOVE_PARTNER = gql`
  mutation removePartner($id: Int!) {
    removePartner(id: $id)  {
    id
    partnerType
    customerType
    displayName
    companyName
    email
    phone
    mobile
    currency
    emirateID
    openingBalance
    paymentStatus
    status
    primaryContactId
    primaryContact {
      id
      salutation
      firstName
      lastName
      email
      phone
      mobile
      primaryContact
      status
      partnerId
    }
    contacts {
      id
      salutation
      firstName
      lastName
      email
      phone
      mobile
      primaryContact
      status
      partnerId
    }
    invoices {
      id
      projectId
      invoiceNo
      orderNo
      invoiceDate
      paymentTerm
      paymentStatus
      reason
      dueDate
      subject
      customerNote
      isPayment
      subTotal
      tax
      adjustment
      totalPrice
      status
      createdAt
      updatedAt
      deletedAt
    }
    sales {
      id
      projectId
      orderId
      reference
      orderDate
      expectedDate
      paymentTerm
      customerNote
      subTotal
      tax
      adjustment
      totalPrice
      status
      createdAt
      updatedAt
      deletedAt
    }
    remarks {
      id
      remarkContent
      status
      createdAt
      updatedAt
      deletedAt
      partnerId
    }
    addresses {
      id
      partnerId
      attention
      addressType
      addressLine1
      addressLine2
      city
      state
      country
      pinCode
      phone
      fax
    }
    documents {
      id
      partnerId
      documentName
      documentUrl
      createdAt
      updatedAt
      deletedAt
    }
  }
  }
`;

export const CREATE_DOCUMENT = gql`
  mutation createDocument($createDocumentInput: CreateDocumentInput!) {
    createDocument(createDocumentInput: $createDocumentInput){
    id
    partnerId
    partnerData {
      id
      partnerType
      customerType
      displayName
      companyName
      email
      phone
      mobile
      currency
      emirateID
      openingBalance
      paymentStatus
      status
      primaryContactId
    }
    documentName
    documentUrl
    createdAt
    updatedAt
    deletedAt
  }
  }
`;

export const REMOVE_DOCUMENT = gql`
  mutation removeDocument($id: Int!) {
    removeDocument(id: $id)
  }
`;

export const CREATE_COMMENT = gql`
  mutation createComment($createCommentInput: CreateCommentInput!) {
    createComment(createCommentInput: $createCommentInput) 
    {
    id
    employeeId
  }
  }
`;

export const CREATE_CONTACT = gql`
  mutation createContact($createContactInput: CreateContactInput!) {
    createContact(createContactInput: $createContactInput)  {
    id
    salutation
    firstName
    lastName
    email
    phone
    mobile
    primaryContact
    status
    partnerId
    partner {
      id
      partnerType
      customerType
      displayName
      companyName
      email
      phone
      mobile
      currency
      emirateID
      openingBalance
      paymentStatus
      status
      primaryContactId
    }
  }
  }
`;

export const REMOVE_CONTACT = gql`
  mutation removeContact($id: Int!) {
    removeContact(id: $id)
  }
`;

export const UPDATE_CONTACT = gql`
  mutation updateContact($updateContactInput: UpdateContactInput!) {
    updateContact(updateContactInput: $updateContactInput){
    id
    salutation
    firstName
    lastName
    email
    phone
    mobile
    primaryContact
    status
    partnerId
    partner {
      id
      partnerType
      customerType
      displayName
      paymentTerm
      companyName
      email
      phone
      mobile
      currency
      emirateID
      openingBalance
      paymentStatus
      status
      primaryContactId
    }
  }
  }
`;