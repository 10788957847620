import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Router } from '@angular/router';

@Component({
  selector: 'app-debit-note-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    MatSelectModule,
    MatIconModule,
    MatMenuModule
  ],
  templateUrl: './debit-note-details.component.html',
  styleUrl: './debit-note-details.component.scss'
})
export class DebitNoteDetailsComponent {

  constructor(
    private router: Router
  ) {}

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  openDebitNoteCreditApplyDialog() {
    const dialogDebitNoteCreditApplyDialogItemRef = this.dialog.open(DebitNoteCreditApplyDialog);
  }

  selectedOption = 'today';

  selectedTabIndex = 0; // Default to the first tab

  selectTab(index: number) {
    this.selectedTabIndex = index;
  }

  readonly dialog = inject(MatDialog);



  openDialog() {
    const dialogRef = this.dialog.open(UploadDebitNoteDialog);
  }

  isDropdownOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  


}



@Component({
  selector: 'upload-debit-note-dialog',
  templateUrl: 'upload-debit-note-dialog.html',
  styleUrls: ['./debit-note-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadDebitNoteDialog {

  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }
  
}


@Component({
  selector: 'debit-note-apply-credit-dialog',
  templateUrl: 'debit-note-apply-credit-dialog.html',
  styleUrls: ['./debit-note-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, ReactiveFormsModule],
})



export class DebitNoteCreditApplyDialog {
}