import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Router } from '@angular/router';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-credit-note',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatInputModule, AgGridAngular],
  templateUrl: './credit-note.component.html',
  styleUrl: './credit-note.component.scss',
})
export class CreditNoteComponent {
  constructor(private router: Router) {}

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  rowData = [
    {
      date: '10-1-24',
      creditNote: 'CR-001',
      referenceNumber: '124335111',
      customerName: 'Vaishnav',
      invoice: 'IN_001',
      status: 'Active',
      amount: '100',
      balance: '200',
      action: '200',
    },
  ];

  columnDefs = [
    { headerName: 'Date', field: 'date', flex: 1 },
    { headerName: 'Credit Note#', field: 'creditNote', flex: 1 },
    { headerName: 'Reference Number', field: 'referenceNumber', flex: 1 },
    {
      headerName: 'Customer Name',
      field: 'customerName',
      flex: 1,
      cellRenderer: (params: any) => {
        return `
          <div style="display: flex; align-items: center;">
            <img src="${params.data.imageUrl}" alt="Profile Image" style="width: 35px; height: 35px; margin-right: 10px; border-radius: 50%; object-fit: cover;">
            <span>${params.data.name}</span>
            </div>
            `;
      },
    },
    { headerName: 'Invoice#', field: 'invoice', flex: 1 },
    {
      headerName: 'Status',
      field: 'status',
      flex: 1,
      cellRenderer: (params: any) => {
        const status = params.data.status;
        const statusClass = status === 'On Leave' ? 'on-leave' : 'working'; // Apply class if "On Leave"
        return `<span class="${statusClass}">${status}</span>`;
      },
    },
    { headerName: 'Amount', field: 'amount', flex: 1 },
    { headerName: 'Balance', field: 'balance', flex: 1 },
    {
      headerName: 'Actions',
      field: 'action',
      flex: 1,
      cellRenderer: (params: any) => {
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.alignItems = 'center';
        container.style.gap = '20px';

        const editIcon = document.createElement('img');
        editIcon.src = 'assets/images/icons/icon-edit.svg';
        editIcon.style.cursor = 'pointer';
        editIcon.setAttribute('data-action', 'edit');

        const deleteIcon = document.createElement('img');
        deleteIcon.src = 'assets/images/icons/icon-delete.svg';
        deleteIcon.style.cursor = 'pointer';
        deleteIcon.setAttribute('data-action', 'delete');
        container.appendChild(editIcon);
        container.appendChild(deleteIcon);
        return container;
      },
      floatingFilter: false,
      filter: false,
    },
  ];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';
  readonly dialog = inject(MatDialog);

  onDeleteEmployee(employeeId: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        // this.deleteEmployee(employeeId); // api call
      }
    });
  }

  onCellClicked(params: any) {
    const customerId = params.data?.id;
    if (
      params.column.colId === 'action' &&
      params.event.target.dataset.action
    ) {
      let action = params.event.target.dataset.action;

      if (action === 'edit') {
        // this.onEdit(customerId);
      } else if (action === 'delete') {
        this.onConfirmDelete(customerId);
      }
    } else {
      this.router.navigate([`credit-note/credit-note-details`]);
    }
  }

  onConfirmDelete(id: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        // this.onDelete(id);
      }
    });
  }
}
