<section class="vacation-apply-wrapper">
    <form [formGroup]="vacationApplyForm">
    <div class="vacation-inputs">
        <div class="form-wrapper">
            <label for="">From Date</label>
            <mat-form-field>
                <input matInput [matDatepicker]="pickerFrom" formControlName="startDate" [matDatepickerFilter]="dateFilter" placeholder="Choose date"/>
                <mat-datepicker-toggle matIconSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom></mat-datepicker>
            </mat-form-field>
            <div
            *ngIf="
              vacationApplyForm.get('startDate')!.invalid &&
              vacationApplyForm.get('startDate')!.touched
            "
            class="error"
          >
            From date is required.
          </div>
        </div>
        <div class="form-wrapper">
            <label for="">To Date</label>
            <mat-form-field>
                <input matInput [matDatepicker]="pickerTo" [matDatepickerFilter]="dateFilter" formControlName="endDate" placeholder="Choose date"/>
                <mat-datepicker-toggle matIconSuffix [for]="pickerTo"></mat-datepicker-toggle>
                <mat-datepicker #pickerTo></mat-datepicker>
            </mat-form-field>
            <div
            *ngIf="
              vacationApplyForm.get('endDate')!.invalid &&
              vacationApplyForm.get('endDate')!.touched
            "
            class="error"
          >
            To date is required.
          </div>
        </div>
        <div class="form-wrapper">
            <label for="">Notes</label>
            <mat-form-field>
                <textarea matInput placeholder="Type something.." formControlName="notes"></textarea>
              </mat-form-field>
        </div>
    </div>
    <div class="submit-btn-wrapper">
        <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
        <button class="cmn-next-btn" (click)="onApplyVacation()">Apply</button>
      </div>
    </form>
</section>