import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeService } from '../../../../../../features/employees/services/employee.service';

@Component({
  selector: 'app-branch-access-profile',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './branch-access-profile.html',
  styleUrls: ['../../../../employee-details.component.scss'],
})
export class BranchAccessProfileComponent implements OnChanges {
  @Input() employeeData: any;

  value: string = '123456'; 
  hiddenValue: string = '******'; 
  isVisible: boolean = false;
  profileId: number | undefined;
  profileName: string | undefined;

  constructor(private employeeService: EmployeeService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['employeeData'] && this.employeeData) {
      this.profileId = this.employeeData.user?.profileId;
      if (this.profileId) {
        this.fetchProfileName(this.profileId);
      }
    }
  }

  fetchProfileName(profileId: number) {
    this.employeeService.getUserRoleById(profileId).subscribe({
      next: (profile) => {
        this.profileName = profile?.profileName;
        console.log('Profile Name:', this.profileName);
      },
      error: (error) => {
        console.error('Error fetching profile:', error);
      },
    });
  }

  toggleClass() {
    this.isVisible = !this.isVisible;
  }
}
