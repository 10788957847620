
  <section class="cmn-innerpage-wrapper manual-journals">
    <div class="head">
      <div class="search-wrapper">
        <input
          type="text"
          class="cmn-header-search"
          placeholder="Search"
          (input)="onSearch($event)"
        />
      </div>
      <button class="cmn-add-btn" (click)="navigateTo('/manual-journals/add')">
        <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New
        Journal
      </button>
      <button
        class="cmn-filter-btn"
        (click)="toggleDropdown($event)"
        [ngClass]="{ 'class-clicked': isDropdownOpen }"
      >
        <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
      </button>
      <!-- Filter Popup -->
      <div
        [ngClass]="{ show: isDropdownOpen }"
        class="common-dropdown filter-position"
        #dropdown
      >
        <button
          class="close-dropdown"
          (click)="toggleDropdown($event)"
        ></button>
        <form>
          <h6 class="cmn-popup-heading">
            Filter <span (click)="clearFilter()">Clear All</span>
          </h6>
          <div class="filter-inner-content">
            <h2>Journal Status</h2>
            <div class="check-wrapper">
              <div class="check-box" *ngFor="let status of journalStatuses">
                <label class="checkbox-wrapper">
                  <input
                    type="checkbox"
                    (change)="onStatusChange(status, $event)"
                    [checked]="selectedStatuses.includes(status)"
                  />
                  {{ status }}
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="btn-wrapper">
            <button class="cmn-apply-btn" (click)="applyFilter($event)">
              Apply
            </button>
          </div>
        </form>
      </div>
      <!-- Filter Popup -->
    </div>
    <div class="inner-content">
      <ag-grid-angular
        style="width: 100%"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowSelection]="rowSelection"
        [suppressRowClickSelection]="true"
        [pagination]="true"
        [paginationPageSize]="paginationPageSize"
        [paginationPageSizeSelector]="paginationPageSizeSelector"
        [class]="themeClass"
        (rowClicked)="onRowClicked($event)"
      />
    </div>
  </section>
