import { Component, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Router } from '@angular/router';
import { BillService } from '../../features/bills/services/bills.service';

@Component({
  selector: 'app-bills',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    AgGridAngular
  ],
  templateUrl: './bills.component.html',
  styleUrl: './bills.component.scss'
})
export class BillsComponent {
  search: any;
  public rowData: any[] | null = null;

  constructor(
    private router: Router,
    private billService: BillService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.fetchBills();
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }


  readonly dialog = inject(MatDialog);



  openDialog() {
    const dialogRef = this.dialog.open(UploadBillsDialog);
  }

  isDropdownOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onCellClicked(params: any) {
    const billId = params.data?.id;
    this.router.navigate([`/bills/bill-details/${billId}`]);
  }

  
  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.search = input.value;
    this.fetchBills();
  }

  columnDefs = [
    {
      headerName: 'Date',
      field: 'date',
      flex: 1,
    },
    { headerName: 'Bill#', field: 'billNo', flex: 1 },
    { headerName: 'Order No', field: 'orderNo', flex: 1 },
    { headerName: 'Vendor Name', field: 'vendorName', flex: 1 },
    { headerName: 'Status', field: 'billStatus', flex: 1 },
    { headerName: 'Due Date', field: 'dueDate', flex: 1 },
    {
      headerName: 'Amount', field: 'amount', flex: 1,
      cellRenderer: (params: any) => {
        return `
          <span>AED ${params.data.amount}</span>
      `;
      }
    },
    {
      headerName: 'Balance', field: 'balance', flex: 1,
      cellRenderer: (params: any) => {
        return `
          <span>AED ${params.data.balance}</span>
      `;
      }
    },

  ];
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';

  fetchBills() {
    this.billService.fetchBills(this.search).subscribe({
      next: (data) => {
        this.rowData = data
          .map((data: any) => {
            return {
              id: data?.id || '--',
              date: this.datePipe.transform(data?.createdAt, 'dd/MM/YYYY') || '--',
              billNo: data?.billNo || '--',
              orderNo: data?.orderNo || '--',
              vendorName: data?.partnerData?.displayName || '--',
              billStatus: data?.billStatus || '--',
              dueDate: this.datePipe.transform(data?.dueDate, 'dd/MM/YYYY') || '--',
              amount: data?.totalPrice || '--',
              balance: data?.balance || '0.00',
            };
          })
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

}



@Component({
  selector: 'upload-bills-dialog',
  templateUrl: 'upload-bills-dialog.html',
  styleUrls: ['./bills.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadBillsDialog {

  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }

}
