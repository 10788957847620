import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CustomerService } from '../../../../features/customers/services/customer.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-address',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './address.component.html',
  styleUrl: '../../vendor-new.component.scss'
})
export class AddressComponent {
  billingAddressForm: FormGroup;
  shippingAddressForm: FormGroup;
  @Input() vendorData: any = {};
  @Output() formSubmit = new EventEmitter<any>();
  search: string | undefined;
  filter: {};
  currencyData: any;
  file: any;
  countries: any[] = [];
  vendorId: string | null;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private customerService: CustomerService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.initForm();
    this.route.paramMap.subscribe(params => {
      this.vendorId = params.get('id');
      if (this.vendorId) {
        this.fetchPartnerById();
      }
    });
    this.loadCountries();
  }

  initForm() {
    this.billingAddressForm = this.fb.group({
      attention: [''],
      addressType: ['BILLING'],
      addressLine1: [''],
      addressLine2: [''],
      city: ['', [Validators.maxLength(10)]],
      state: ['', [Validators.maxLength(10)]],
      country: [''],
      pinCode: ['', [Validators.maxLength(8), Validators.pattern(/^\d+$/)]],
      fax: ['', [Validators.maxLength(15), Validators.pattern(/^\d+$/)]],
      phone: ['',  [
        Validators.minLength(10),
        Validators.maxLength(15),
        Validators.pattern(/^\d+$/) // Ensures only digits are allowed
      ]],
    });
  
    this.shippingAddressForm = this.fb.group({
      attention: [''],
      addressType: ['SHIPPING'],
      addressLine1: [''],
      addressLine2: [''],
      city: ['', [Validators.maxLength(10)]],
      state: ['', [Validators.maxLength(10)]],
      country: [''],
      pinCode: ['', [Validators.maxLength(8), Validators.pattern(/^\d+$/)]],
      fax: ['', [Validators.maxLength(15), Validators.pattern(/^\d+$/)]],
      phone: ['',  [
        Validators.minLength(10),
        Validators.maxLength(15),
        Validators.pattern(/^\d+$/) // Ensures only digits are allowed
      ]],
    });
  }

  
  fetchPartnerById() {
    this.customerService.fetchPartnerById(Number(this.vendorId)).subscribe({
      next: (data) => {
        this.vendorData = data;
        console.log(this.vendorData);
        this.populateFormWithVendorData();
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }
  
  populateFormWithVendorData() {
    console.log(this.vendorData);
    
    if (this.vendorData && this.vendorData.addresses) {
      this.vendorData.addresses.map((address: any) => {
        console.log(address);
        
        if (address.addressType === 'BILLING') {
          console.log(address.addressType);
          
          this.billingAddressForm.patchValue({
            ...(address.id && { id: address.id }),  
            attention: address.attention || '',
            addressLine1: address.addressLine1 || '',
            addressLine2: address.addressLine2 || '',
            city: address.city || '',
            state: address.state || '',
            country: address.country || '',
            pinCode: address.pinCode || '',
            fax: address.fax || '',
            phone: address.phone || '',
          });
        } else if (address.addressType === 'SHIPPING') {
          this.shippingAddressForm.patchValue({
            ...(address.id && { id: address.id }),  
            attention: address.attention || '',
            addressLine1: address.addressLine1 || '',
            addressLine2: address.addressLine2 || '',
            city: address.city || '',
            state: address.state || '',
            country: address.country || '',
            pinCode: address.pinCode || '',
            fax: address.fax || '',
            phone: address.phone || '',
          });
        }
      })
    }
  }

  onCopyBillingAddress  (event: Event) {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      const billingValues = { ...this.billingAddressForm.value };
      delete billingValues.addressType;
      this.shippingAddressForm.patchValue({
        ...billingValues,
        addressType: 'SHIPPING'
      });
    } else {
      this.shippingAddressForm.reset({
        addressType: 'SHIPPING'
      });
    }
  }

  loadCountries(): void {
    this.http.get<any[]>('assets/json/countries.json').subscribe(
      (data) => {
        this.countries = data;
      },
      (error) => {
        console.log('Error loading country data----', error);
      }
    );
  }

  onSubmitBilling(): boolean {
    if(this.billingAddressForm.invalid){
      this.billingAddressForm.markAllAsTouched();
      return false;
    }
    const billingFormValue = {
      ...this.billingAddressForm.value,
      ...(this.vendorData && this.vendorData.addresses?.find((address: any) => address.addressType === 'BILLING')?.id 
        ? { id: this.vendorData.addresses.find((address: any) => address.addressType === 'BILLING').id } 
        : {})
    };
    this.formSubmit.emit({ type: 'billing', value: billingFormValue });
    return true;
  }
  
  onSubmitShipping(): boolean {
    if(this.shippingAddressForm.invalid){
      this.shippingAddressForm.markAllAsTouched();
      return false;
    }
    const shippingFormValue = {
      ...this.shippingAddressForm.value,
      ...(this.vendorData && this.vendorData.addresses?.find((address: any) => address.addressType === 'SHIPPING')?.id 
        ? { id: this.vendorData.addresses.find((address: any) => address.addressType === 'SHIPPING').id } 
        : {})
    };
    this.formSubmit.emit({ type: 'shipping', value: shippingFormValue });
    return true;
  }

  onCancel() {
    this.billingAddressForm.reset();
    this.shippingAddressForm.reset();
  }
}
