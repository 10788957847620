
    <section class="cmn-innerpage-wrapper banking">
        <div class="head">
            <div class="search-wrapper">
                <input type="text" class="cmn-header-search" placeholder="Search"/>
            </div>
            <!-- <button class="cmn-upload-btn" (click)="openDialog()">
                <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                <span>Upload</span>
            </button> -->
            <div class="cmn-add-transaction-dropdown">
                <form>
                    <mat-form-field>
                        <mat-select [(ngModel)]="selectedTransaction" (selectionChange)="toggleAddTransaction($event)"
                            name="select">
                            <mat-option value="default" disabled>Add Transactions</mat-option>
                            <mat-option value="expense">Expense</mat-option>
                            <mat-option value="vendorAdvance">Vendor Advance</mat-option>
                            <mat-option value="vendorPayment">Vendor Payment</mat-option>
                            <mat-option value="transferToAnotherAccount">Transfer To Another Account</mat-option>
                            <mat-option value="cardPayment">Card Payment</mat-option>
                            <mat-option value="ownerDrawings">Owner Drawings</mat-option>
                            <mat-option value="creditNoteRefund">Credit Note Refund</mat-option>
                            <mat-option value="paymentRefund">Payment Refund</mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>

            <!-- <button class="cmn-add-btn" (click)="toggleAddTransaction($event)">
                <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New Transaction
            </button> -->
            <!-- <button class="cmn-filter-btn" (click)="toggleDropdown($event)"
                [ngClass]="{ 'class-clicked': isDropdownOpen }">
                <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
            </button> -->
            <!-- <span *ngIf="selectedCount > 0">{{ selectedCount }}</span> -->
            <!-- Filter -->
            <!-- <div [ngClass]="{ show: isDropdownOpen }" class="common-dropdown filter-position" #dropdown>
                <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
                <form>
                    <h6 class="cmn-popup-heading">
                        Filter <span>Clear All</span>
                    </h6>
                    <div class="filter-inner-content">
                        <h2>Branch</h2>
                        <div class="check-wrapper">
                            <div class="check-box">
                                <label class="checkbox-wrapper">
                                    <input type="checkbox" />
                                    test
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrapper">
                        <button class="cmn-apply-btn" type="submit">Apply</button>
                    </div>
                </form>
            </div> -->
            <!-- Filter -->
        </div>
        <div class="sub-head">
            <div class="branch-wrapper">
                <form>
                    <mat-form-field>
                        <mat-select [(value)]="selectedOption">
                            <mat-option value="today"> All Accounts </mat-option>
                            <mat-option value="week"> Active Accounts </mat-option>
                            <mat-option value="week"> Inactive Accounts </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>
        </div>
        <div class="cash-details-wrapper">
            <div class="cmn-box cash-box">
                <div class="img-wrapper">
                    <img src="assets/images/cash.svg" alt="Cash Balance">
                </div>
                <div class="detail-box">
                    <h2>AED <span>{{ transactionData?.amount }}</span></h2>
                    <h3>Amount In {{ transactionData?.chartOfAccount?.accountName }}</h3>
                </div>
            </div>
            <div class="cmn-box bank-box">
                <div class="img-wrapper">
                    <img src="assets/images/bank.svg" alt="Bank Balance">
                </div>
                <div class="detail-box">
                    <h2>{{ transactionData?.chartOfAccount?.accountName }}</h2>
                    <h3>Associated Branches</h3>
                </div>
            </div>
        </div>
        <div class="inner-content">
            <div class="banking-details-tab-wrapper">
                <button class="tab-custom-button" [class.active]="selectedTabIndex === 0" (click)="selectTab(0)">
                    <h2> <span>{{ transactionData?.uncategorizedTransactionsCount }}</span> Uncategorized Transactions
                    </h2>
                    <h3>From Bank Statements</h3>
                </button>
                <button class="tab-custom-button" [class.active]="selectedTabIndex === 1" (click)="selectTab(1)">
                    <h2> All Transactions</h2>
                    <h3>In {{ transactionData?.chartOfAccount?.accountName }}</h3>
                </button>

            </div>
            <div class="inner-tab-content-wrapper"
                *ngIf="transactionAmount && transactionAmount.length > 0; else noData">
                <div class="banking-table-wrapper" *ngIf="selectedTabIndex === 0 ">
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Statement Details</th>
                                <th>Deposits</th>
                                <th>Withdrawals</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let transaction of transactionAmount">
                            <tr (click)="toggleCategorize($event)"
                                *ngIf="transaction?.transactionStatus === 'Uncategorized'">
                                <td>{{ transaction?.date | date: 'dd/MM/YYYY' }}</td>
                                <td>
                                    <p style="display: block; line-height: 1.5">Reference#:
                                        {{transaction?.referenceNumber}}</p>
                                    <p style="display: block; line-height: 1.5">Description:
                                        {{transaction?.description}}</p>
                                    <p style="display: block; line-height: 1.5">Payee:
                                        {{transaction?.partner?.displayName}}</p>
                                </td>
                                <td>{{ transaction?.deposit ? 'AED ' + transaction?.deposit : '-' }}</td>
                                <td>{{ transaction?.withdrawal ? 'AED ' + transaction?.withdrawal : '-' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="banking-table-wrapper" *ngIf="selectedTabIndex === 1">
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Reference#</th>
                                <th>Type</th>
                                <th>Status</th>
                                <th>Deposits</th>
                                <th>Withdrawals</th>
                                <th>Running Balance</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let transaction of transactionAmount">
                            <tr (click)="toggleAllTransaction($event)"
                                *ngIf="transaction?.transactionStatus !== 'Uncategorized'">
                                <td>{{ transaction?.date | date: 'dd/MM/YYYY' }}</td>
                                <td>{{ transaction?.referenceNumber || '-' }}</td>
                                <td>
                                    <div>
                                        <h3>{{ transaction?.offsetAccount?.accountName || '-' }}</h3>
                                        <h4>{{ transaction?.offsetAccount?.accountType || '-' }}</h4>
                                    </div>
                                </td>
                                <td> {{ transaction?.transactionStatus || '-' }} </td>
                                <td>{{ transaction?.deposit ? 'AED ' + transaction?.deposit : '-' }}</td>
                                <td>{{ transaction?.withdrawal ? 'AED ' + transaction?.withdrawal : '-' }}</td>
                                <td>{{ transaction?.runningBalance ? 'AED ' + transaction?.runningBalance : '-' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <ng-template #noData>
                <div class="no-data-message">
                    No data found.
                </div>
            </ng-template>
        </div>

        <!-- Categorize Manually -->
        <div [ngClass]="{ show: showUncategorize }" class="banking-side-dialog" #dropdown>
            <button class="close-dropdown" (click)="toggleCategorize($event)"></button>
            <div class="common-noimage-tab-wrapper">
                <mat-tab-group animationDuration="0ms">
                    <!-- Mat Tab One -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Match Transactions</h2>
                        </ng-template>
                        <div class="banking-sidedialog-content-wrapper">
                            <div class="content-head">
                                <div class="left-set">
                                    <h2>Possible Matches</h2>
                                    <h3>Transactions upto 26 Aug 2024</h3>
                                </div>
                                <div class="right-set">
                                    <span class="hyper-span">Select All</span>
                                </div>
                            </div>
                            <div class="banking-tab-inner-content">
                                <!-- ngFor -->
                                <div class="content-group">
                                    <div class="left-set">
                                        <h4>Bill for AED 3,000.00</h4>
                                        <h5>Dated 26 August 2024 </h5>
                                        <h5>Munawel Interiors LLC</h5>
                                        <h5>Ref#GA00234551 <span class="hyper-span">Bill# 0021251</span></h5>
                                        <h5>0314254</h5>
                                    </div>
                                    <div class="right-set">
                                        <div class="check-wrapper">
                                            <div class="check-box">
                                                <label class="checkbox-wrapper">
                                                    <input type="checkbox" />Select
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- ngFor -->
                            </div>
                            <div class="submit-btn-wrapper">
                                <button class="cmn-cancel-btn">Cancel</button>
                                <button class="cmn-next-btn">Match</button>
                            </div>
                        </div>


                    </mat-tab>
                    <!-- Mat Tab Two -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Categorize Manually</h2>
                        </ng-template>
                        <div class="banking-sidedialog-content-wrapper">
                            <div class="banking-tab-inner-content-form">
                                <div class="manually-form-wrapper">
                                    <div class="form-wrapper">
                                        <label for="">Category</label>
                                        <mat-form-field>
                                            <mat-select placeholder="Expense">
                                                <mat-option value="Single">Single</mat-option>
                                                <mat-option value="Married">Married</mat-option>
                                                <mat-option value="Divorced">Divorced</mat-option>
                                                <mat-option value="Widowed">Widowed</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="form-wrapper">
                                        <label for="">Expense Account</label>
                                        <mat-form-field>
                                            <mat-select placeholder="Expense">
                                                <mat-option value="Single">Single</mat-option>
                                                <mat-option value="Married">Married</mat-option>
                                                <mat-option value="Divorced">Divorced</mat-option>
                                                <mat-option value="Widowed">Widowed</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="form-wrapper">
                                        <label for="">Vendor</label>
                                        <mat-form-field>
                                            <mat-select placeholder="Expense">
                                                <mat-option value="Single">Single</mat-option>
                                                <mat-option value="Married">Married</mat-option>
                                                <mat-option value="Divorced">Divorced</mat-option>
                                                <mat-option value="Widowed">Widowed</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="form-wrapper">
                                        <label for="">Date</label>
                                        <mat-form-field>
                                            <input matInput [matDatepicker]="picker" placeholder="Choose date" />
                                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="form-wrapper amount-wrapper">
                                        <label for="">Date of Birth</label>
                                        <h6>AED 3,500.00*</h6>
                                    </div>
                                    <div class="form-wrapper">
                                        <label for="">Invoice#</label>
                                        <mat-form-field>
                                            <input matInput placeholder="IN-0023" />
                                        </mat-form-field>
                                    </div>
                                    <div class="form-wrapper">
                                        <label for="">Description</label>
                                        <mat-form-field>
                                            <textarea matInput placeholder="Enter description"></textarea>
                                        </mat-form-field>
                                    </div>
                                    <div class="form-wrapper">
                                        <label for="">Customer</label>
                                        <mat-form-field>
                                            <mat-select placeholder="Choose Customer">
                                                <mat-option value="Single">Single</mat-option>
                                                <mat-option value="Married">Married</mat-option>
                                                <mat-option value="Divorced">Divorced</mat-option>
                                                <mat-option value="Widowed">Widowed</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="form-wrapper upload-file-group">
                                        <input #fileInput type="file" style="display: none" />
                                        <button class="cmn-upload-btn" (click)="fileInput.click()">
                                            <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                                            <span>Upload File</span>
                                        </button>
                                        <div class="uploaded-file">
                                            <h6>
                                                file-name.pdf
                                                <img src="assets/images/icons/icon-close.svg" alt="Close Icon" />
                                            </h6>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="submit-btn-wrapper">
                                <button class="cmn-cancel-btn">Cancel</button>
                                <button class="cmn-next-btn">Save</button>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
        <!-- Categorize Manually -->  

        <!-- Transaction Details-->
        <div [ngClass]="{ show: showAllTransaction }" class="banking-side-dialog" #dropdown>
            <button class="close-dropdown" (click)="toggleAllTransaction($event)"></button>
            <div class="common-noimage-tab-wrapper">
                <mat-tab-group animationDuration="0ms">
                    <!-- Mat Tab One -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Transaction Details</h2>
                        </ng-template>
                        <div class="all-transaction-sidedialog-content-wrapper">
                            <div class="sub-head">
                                <ul>
                                    <li><img src="assets/images/icons/icon-edit-accounts.svg" alt="Edit"> Edit</li>
                                    <li><img src="assets/images/icons/icon-delete-li.svg" alt="Edit"> Delete</li>
                                </ul>
                            </div>
                            <div class="type-head">
                                <div>
                                    <h2>AED 3,500.00</h2>
                                    <h3>On 26 Aug 2024</h3>
                                </div>
                                <span>Invoice Payment</span>
                            </div>
                            <div class="transaction-table-wrapper">
                                <table>
                                    <tr>
                                        <td>Customer</td>
                                        <td>Bank Charges</td>
                                        <td>Received Via</td>
                                    </tr>
                                    <tr class="bold border">
                                        <td>Laura Dizler</td>
                                        <td>AED 0.00</td>
                                        <td>Cash</td>
                                    </tr>
                                    <tr>
                                        <td>Invoice Details</td>
                                        <td></td>
                                        <td>Payment</td>
                                    </tr>
                                    <tr class="bold">
                                        <td>IN-0012455</td>
                                        <td></td>
                                        <td>AED 3,500.00</td>
                                    </tr>
                                    <tr class="bold">
                                        <td>AED 0.00</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr class="border">
                                        <td>02/06/2024</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr class="title">
                                        <td>Journal</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Account</td>
                                        <td>Debit</td>
                                        <td>Credit</td>
                                    </tr>
                                    <tr class="bold">
                                        <td>Accounts Receivable</td>
                                        <td>AED 0.00</td>
                                        <td>AED 3,500.00</td>
                                    </tr>
                                    <tr class="bold border">
                                        <td>Personal (Current)</td>
                                        <td>AED 3,500.00</td>
                                        <td>AED 0.00</td>
                                    </tr>
                                    <tr class="bold">
                                        <td></td>
                                        <td>AED 3,500.00</td>
                                        <td>AED 3,500.00</td>
                                    </tr>
                                </table>
                            </div>
                        </div>


                    </mat-tab>
                    <!-- Mat Tab Two -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Comments & History</h2>
                        </ng-template>
                        <div class="all-transaction-sidedialog-content-wrapper">
                            <div class="timelinne-wrapper">
                                <!-- ngFor -->
                                <div class="timeline-box">
                                    <div class="timeline-head">
                                        <ul>
                                            <li>tesrt</li>
                                            <li>24/08/2024</li>
                                        </ul>
                                        <h3>04:25 am</h3>
                                    </div>
                                    <div class="timeline-content">
                                        <h4>Ronald Richards : Their is no pending works for Robertson so am okay with
                                            his leave request. : Their is no pending works for Robertson so am okay with
                                            his leave request.</h4>
                                    </div>
                                </div>
                                <!-- ngFor -->
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
        <!-- Transaction Details -->

        <!-- Add Transaction -->
        <div [ngClass]="{ show: showAddTransaction}" class="banking-side-dialog" #dropdown>
            <button class="close-dropdown" (click)="closeDropdown()"></button>
            <div class="common-noimage-tab-wrapper">
                <h2 class="leave-tab-title">{{ getDisplayName(selectedTransaction) }}</h2>
                <div class="banking-sidedialog-content-wrapper">
                    <div class="banking-tab-inner-content-form">
                        <form [formGroup]="transactionForm">
                        <div class="manually-form-wrapper">
                            <div class="form-wrapper" *ngIf="
                                    selectedTransaction === 'transferToAnotherAccount' ||
                                    selectedTransaction === 'cardPayment' 
                                    ">
                                    <label for="">From Account</label>
                                    <mat-form-field>
                                        <mat-select placeholder="Select Account" formControlName="fromAccount">
                                            <mat-option *ngFor="let account of chartOfAccounts" [value]="account.id">
                                                {{ account?.accountName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="form-wrapper" *ngIf="
                                    selectedTransaction === 'expense' ||
                                    selectedTransaction === 'transferToAnotherAccount' ||
                                    selectedTransaction === 'cardPayment' ||
                                    selectedTransaction === 'ownerDrawings' 
                                    ">
                                    <label for="">{{selectedTransaction === 'expense' ? 'Expense Account' :
                                        selectedTransaction === 'transferToAnotherAccount' ? 'To Account' : 'To Account'}}</label>
                                    <mat-form-field>
                                        <mat-select placeholder="Select Account" formControlName="chartOfAccount">
                                            <mat-option *ngFor="let account of chartOfAccounts" [value]="account.id">
                                                {{ account?.accountName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="form-wrapper"
                                *ngIf="
                                selectedTransaction === 'vendorAdvance' || 
                                selectedTransaction === 'vendorPayment' ||
                                selectedTransaction === 'expense' 
                                ">
                                    <label for="">Vendor</label>
                                    <mat-form-field>
                                        <mat-select placeholder="Select Vendor" formControlName="vendor">
                                            <mat-option *ngFor="let account of chartOfAccounts" [value]="account.id">
                                                {{ account?.accountName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="form-wrapper" *ngIf="selectedTransaction === 'vendorAdvance'">
                                    <label for="">TDS</label>
                                    <mat-form-field>
                                        <mat-select placeholder="Select a Tax" formControlName="tds">
                                            <mat-option *ngFor="let account of chartOfAccounts" [value]="account.id">
                                                {{ account?.accountName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="form-wrapper"
                                *ngIf="selectedTransaction === 'vendorAdvance' || selectedTransaction === 'vendorPayment'">
                                    <label for="">Payment#</label>
                                    <mat-form-field>
                                        <input matInput placeholder="Enter Payment" formControlName="payment" />
                                    </mat-form-field>
                                </div>
                                <div class="form-wrapper"
                                *ngIf="
                                selectedTransaction === 'vendorAdvance' || 
                                selectedTransaction === 'vendorPayment' ||
                                selectedTransaction === 'creditNoteRefund' || 
                                selectedTransaction === 'paymentRefund'
                                ">
                                    <label for="">Paid Via</label>
                                    <mat-form-field>
                                        <mat-select placeholder="Select a Payment" formControlName="paid">
                                            <mat-option *ngFor="let account of chartOfAccounts" [value]="account.id">
                                                {{ account?.accountName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="form-wrapper" *ngIf="selectedTransaction === 'vendorAdvance'">
                                    <label for="">Deposit To</label>
                                    <mat-form-field>
                                        <mat-select placeholder="Deposit To" formControlName="deposit">
                                            <mat-option *ngFor="let account of chartOfAccounts" [value]="account.id">
                                                {{ account?.accountName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="form-wrapper" *ngIf="
                                selectedTransaction === 'expense' ||
                                selectedTransaction === 'creditNoteRefund' ||
                                selectedTransaction === 'paymentRefund'
                                ">
                                    <label for="">Customer</label>
                                    <mat-form-field>
                                        <mat-select placeholder="Select Customer" formControlName="customer">
                                            <mat-option *ngFor="let account of chartOfAccounts" [value]="account.id">
                                                {{ account?.accountName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <!-- date -->
                                <div class="form-wrapper">
                                    <label>Date <span class="red">*</span></label>
                                    <mat-form-field>
                                        <input matInput [matDatepicker]="picker1" formControlName="date"
                                            placeholder="Choose Date" (dateInput)="onDateChange($event)">
                                        <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <!-- amount -->
                                <div class="form-wrapper">
                                    <label for="">Amount<span class="red">*</span></label>
                                    <mat-form-field>
                                        <input matInput placeholder="Enter amount" formControlName="amount" />
                                    </mat-form-field>
                                </div>
                                <!-- reference -->
                                <div class="form-wrapper">
                                    <label for="">{{selectedTransaction === 'expense' ? 'Invoice#' :
                                        'Reference#'}}<span class="red">*</span></label>
                                    <mat-form-field>
                                        <input matInput placeholder="Enter invoice" formControlName="invoice" />
                                    </mat-form-field>
                                </div>
                                <!-- description -->
                                <div class="form-wrapper">
                                    <label for="">Description</label>
                                    <mat-form-field>
                                        <input matInput placeholder="Enter description" formControlName="description" />
                                    </mat-form-field>
                                </div>
                                <!-- attach file -->
                                <div class="form-wrapper upload-file-group" 
                                *ngIf="
                                selectedTransaction === 'expense' ||
                                selectedTransaction === 'transferToAnotherAccount' ||
                                selectedTransaction === 'cardPayment'  ||
                                selectedTransaction === 'ownerDrawings'
                                ">
                                    <label for="">Attach Receipt</label>
                                    <input #fileInput type="file"  formControlName = "file" (change)="onFileSelected($event)" style="display: none" />
                                    <button class="cmn-upload-btn" (click)="fileInput.click()">
                                        <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                                        <span>Upload File</span>
                                    </button>
                                    <div class="uploaded-file" *ngIf="file">
                                        <h6>
                                            {{file?.name}}
                                            <img src="assets/images/icons/icon-close.svg" alt="Close Icon" (click)="removeFile()"/>
                                        </h6>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="submit-btn-wrapper">
                        <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
                        <button class="cmn-next-btn" (click)="onSave()">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Add Transaction -->
    </section>
