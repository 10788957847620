
  <section class="cmn-innerpage-wrapper dashboard" *ngIf="user">
    <div class="head">
      <div class="left-set">
        <h2 class="cmn-inner-heading">Welcome back, {{user?.firstName}}!</h2>
        <p>Let's make today productive and successful.</p>
      </div>
      <div class="right-set" *ngIf="user?.profileData?.profileName !== 'admin'">
        <div class="punch-in-wrapper" [class.punch-active]="dashboardService.isPunchedOut">
          <h2>Total working hours</h2>
          <h3>{{ dashboardService.timeDifference || '00:00:00' }}</h3>
          <button (click)="dashboardService.isPunchedOut ? togglePunch() : onConfirmPunchOut()">
            {{ dashboardService.isPunchedOut ? 'Punch In' : 'Punch Out' }}
            <span><img src="assets/images/icons/icon-punch-in.svg" alt="Punching"></span>
          </button>
        </div>
      </div>
    </div>
  </section>
