import { CommonModule } from "@angular/common";
import { Component, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { PayrollService } from "../../features/payroll/services/payroll.service";
import { ToastrService } from "ngx-toastr";
import { PayrollListTableComponent } from "../../shared/components/payroll-list-table/payroll-list-table";

@Component({
  selector: 'initiate-payroll-dialog',
  templateUrl: 'upload-payroll.component.html',
  styleUrls: ['./payroll.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
  ],
})
export class initiatePayrollDialog {
  @ViewChild(PayrollListTableComponent) payrollDetailData!: PayrollListTableComponent;
  file: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private payrollService: PayrollService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<initiatePayrollDialog>,
  ) { }

  ngOnInit() {
    document.addEventListener('dragover', this.preventDefaultBehavior);
    document.addEventListener('drop', this.preventDefaultBehavior);
  }

  preventDefaultBehavior(event: DragEvent) {
    event.preventDefault();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();

    if (event.dataTransfer?.files.length) {
      this.onFileSelected({ target: { files: event.dataTransfer.files } });
    }
  }

  onFileSelected(event: any): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.file = file;
    }
  }

  removeFile(): void {
    this.file = null;
    const input = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

  initiatePayroll() {
    const payrollData = {
      year: String(this.data.year),
      month: this.data.month
    }
    this.payrollService.initiatePayroll(payrollData).subscribe({
      next: (data) => {
        if(data){
          if(this.file){
            this.payrollService.bulkCreateEmployeePayroll(this.file).subscribe({
              next: (response) => {
                console.log(response);
                if (response && !response.errors) {
                  this.toastr.success('Employee payroll initiated successfully!', 'Success');
                  this.dialogRef.close(response);
                }
                else if (response?.errors) {
                  this.toastr.error(response?.errors[0]?.message, 'Error');
                }
              },
              error: (error) => {
                this.toastr.error(error, 'Bulk payroll creation failed');
                this.dialogRef.close();
              },
            });
          }
        }
      },
      error: (error) => {
        this.dialogRef.close();
        this.toastr.error(error, 'Error')
      }
    })
    }
}