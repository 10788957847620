import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { AddressComponent } from './components/address/address.component';
import { ContactPersonsComponent } from './components/contact-persons/contact-persons.component';
import { OtherDetailsComponent } from './components/other-details/other-details.component';
import { RemarksComponent } from './components/remarks/remarks.component';
import { BankDetailsComponent } from './components/bank-details/bank-details.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomerService } from '../../features/customers/services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { customEmailValidator } from '../../shared/services/validations';

@Component({
  selector: 'app-vendor-new',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    AddressComponent,
    ContactPersonsComponent,
    OtherDetailsComponent,
    RemarksComponent,
    BankDetailsComponent,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './vendor-new.component.html',
  styleUrl: './vendor-new.component.scss'
})
export class VendorNewComponent {
  @ViewChild(OtherDetailsComponent) otherDetailsComponent!: OtherDetailsComponent;
  @ViewChild(AddressComponent) addressComponent!: AddressComponent;
  @ViewChild(ContactPersonsComponent) contactPersonsComponent!: ContactPersonsComponent;
  @ViewChild(RemarksComponent) remarksComponent!: RemarksComponent;

  newVendorForm: FormGroup;
  vendorData: any = {};
  addressDetails: any;
  contactDetails: any;
  remarkDetails: any;
  documentDetails: any = {};
  vendorId: any;
  fileName: any;
  contacts: any;
  contactPersons: any;
  validationError: boolean = false;

  constructor(
    private fb: FormBuilder,
    private customerService: CustomerService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.initForm();
    this.route.paramMap.subscribe(params => {
      this.vendorId = params.get('id');
      if (this.vendorId) {
        this.fetchPartnerById();
      }
    });
  }

  initForm() {
    if (this.vendorData && this.contacts) {
      console.log('inside', this.vendorData);

      this.newVendorForm = this.fb.group({
        id: Number(this.vendorId),
        customerType: [this.vendorData.customerType || '', Validators.required],
        primaryContact: [this.contacts.salutation || 'Mr'],
        firstName: [this.contacts.firstName || ''],
        lastName: [this.contacts.lastName || ''],
        companyName: [this.vendorData.companyName || ''],
        displayName: [this.vendorData.displayName || '', Validators.required],
        email: [this.vendorData.email || '',[Validators.required, customEmailValidator()]],
        phone: [this.vendorData.phone || '', [Validators.required,
        Validators.minLength(10),    // Minimum length for phone number
        Validators.maxLength(15),    // Maximum length for phone number
        Validators.pattern('^[0-9]+$')]],
        mobile: [this.vendorData.mobile || '', [
          Validators.required,
          Validators.minLength(10),    // Minimum length for phone number
          Validators.maxLength(15),    // Maximum length for phone number
          Validators.pattern('^[0-9]+$')
        ]],
        emiratesId: [this.vendorData.emirateId || ''],
        currency: [this.vendorData.currency || 'AED'],
        openingBalance: [this.vendorData.openingBalance || 0],
        paymentTerm: [this.vendorData.paymentTerm || 'Net 30'],
        file: [this.fileName ? this.fileName : null],
      })
    } else {
      this.newVendorForm = this.fb.group({
        customerType: ['Bussiness', Validators.required],
        primaryContact: ['Mr'],
        firstName: ['', [Validators.required, Validators.maxLength(50)]],
        lastName: ['', [Validators.required, Validators.maxLength(50)]],
        companyName: ['',[ Validators.maxLength(50)]],
        displayName: ['', [Validators.required, Validators.maxLength(50)]],
        email: ['', [Validators.required, customEmailValidator(), Validators.maxLength(50)]],
        phone: ['', [
          Validators.required,
          Validators.minLength(10),    // Minimum length for phone number
          Validators.maxLength(15),    // Maximum length for phone number
          Validators.pattern('^[0-9]+$')
        ]],
        mobile: ['', [
          Validators.required,
          Validators.minLength(10),    // Minimum length for phone number
          Validators.maxLength(15),    // Maximum length for phone number
          Validators.pattern('^[0-9]+$')
        ]],
        emiratesId: [''],
        currency: [''],
        openingBalance: [0],
        paymentTerm: [''],
        file: [null],
      })
    }

    this.newVendorForm.valueChanges.subscribe((formValue) => {
      this.contactPersonsComponent.updateFirstContactPerson(
        formValue.primaryContact,
        formValue.firstName,
        formValue.lastName,
        formValue.email,
        formValue.phone,
        formValue.mobile,
      );
    });
  };

  fetchPartnerById() {
    this.customerService.fetchPartnerById(Number(this.vendorId)).subscribe({
      next: (data) => {
        this.vendorData = data;
        console.log(this.vendorData);

        if (this.vendorData.documents && this.vendorData.documents.length > 0) {
          this.fileName = this.vendorData.documents[0].documentName;
        }
        this.vendorData.contacts.map((contact: any) => {
          if (contact?.primaryContact) {
            this.contacts = contact;
          }
        })
        console.log(this.contacts);
        this.initForm();
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }

  async collectOtherDetails(data: any) {
    // this.otherDetailsData = data;
    const newvendorData = { ...this.vendorData };
    const allData = { ...this.vendorData };
    newvendorData.otherDetails = data;
    this.vendorData = newvendorData;
    console.log('this.vendorData', allData);

    if (this.vendorData?.documents?.length > 0) {
      this.documentDetails = {
        id: Number(this.vendorData?.documents[0]?.id),
        documentName: data?.fileData?.documentName,
        documentUrl: data?.fileData?.documentUrl,
      }
    } else if (data?.fileData?.documentName) {
      this.documentDetails = {
        documentName: data?.fileData?.documentName,
        documentUrl: data?.fileData?.documentUrl,
      }
    } else {
      this.documentDetails = {}
    }
    await this.newVendorForm.patchValue({
      emiratesId: this.vendorData.otherDetails.emiratesId,
      currency: this.vendorData.otherDetails.currency,
      openingBalance: this.vendorData.otherDetails.openingBalance,
      paymentTerm: this.vendorData.otherDetails.paymentTerm,
    })
  }

  collectAddress(data: any) {
    // Initialize addressDetails if it doesn't exist
    this.vendorData.addressDetails = this.vendorData.addressDetails || {};

    // Assign address based on type
    this.vendorData.addressDetails[data.type] = data.value;

    // Collect addresses into an array
    this.addressDetails = [
      ...(this.vendorData.addressDetails.billing ? [this.vendorData.addressDetails.billing] : []),
      ...(this.vendorData.addressDetails.shipping ? [this.vendorData.addressDetails.shipping] : [])
    ];
  }

  collectContactDetails(data: any) {
    data.contactPersons.map((contact: any) => {
      this.contactPersons = data?.contactPersons;
      if (contact?.id) {
        this.contactDetails.push(contact);
      }
    });
  }

  collectRemarkDetails(data: any) {
    this.remarkDetails = data;
  }

  async onSubmit() {
    this.validationError = false;
    if (this.newVendorForm.invalid) {
      this.newVendorForm.markAllAsTouched();
      this.validationError = true;
      this.toastr.warning('Please fill mandatory fields!','Warning');
      return;
    }
    await this.otherDetailsComponent.onSubmit();
    this.contactPersonsComponent.onSubmit();
    this.remarksComponent.onSubmit();
  
    const billingValid = this.addressComponent.onSubmitBilling();
    const shippingValid = this.addressComponent.onSubmitShipping();
    if (!billingValid || !shippingValid) {
      this.validationError = true;
      this.toastr.warning('Please fill mandatory fields!','Warning');
      return;
    }

    const customerDetails = {
      partnerType: 'Vendor',
      customerType: this.newVendorForm.value.customerType,
      displayName: this.newVendorForm.value.displayName,
      companyName: this.newVendorForm.value.companyName,
      phone: this.newVendorForm.value.phone,
      email: this.newVendorForm.value.email,
      mobile: this.newVendorForm.value.mobile,
      currency: this.newVendorForm.value.currency,
      emirateID: this.newVendorForm.value.emiratesId,
      openingBalance: this.newVendorForm.value.openingBalance,
      paymentTerm: this.newVendorForm.value.paymentTerm,
      paymentStatus: "Pending",
    };

    console.log('this.documentDetails', this.documentDetails);

    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;
    if (this.vendorId && this.vendorData) {
      const customerDetails = {
        id: Number(this.vendorId),
        partnerType: 'Vendor',
        customerType: this.newVendorForm.value.customerType,
        displayName: this.newVendorForm.value.displayName,
        companyName: this.newVendorForm.value.companyName,
        phone: this.newVendorForm.value.phone,
        email: this.newVendorForm.value.email,
        mobile: this.newVendorForm.value.mobile,
        currency: this.newVendorForm.value.currency,
        emirateID: this.newVendorForm.value.emiratesId,
        openingBalance: this.newVendorForm.value.openingBalance,
        paymentTerm: this.newVendorForm.value.paymentTerm,
        paymentStatus: "Pending",
      };
      const filteredAddressDetails = this.addressDetails.filter((address: any) => address.id);
      if (!this.remarkDetails?.id) {
        this.remarkDetails = {};
      }
      this.customerService.updatePartner(
        customerDetails,
        this.contactDetails,
        filteredAddressDetails,
        this.remarkDetails,
        employeeId,
        this.documentDetails
      ).subscribe({
        next: async (data) => {
          if (data) {
            this.toastr.success('Vendor updated successfully', 'Success');
            await this.addNewContactPersons();
            this.onCancel();
            this.router.navigate([`/vendors/vendor-details/${this.vendorId}`]);
          }
        },
        error: (error) => this.toastr.error(error, 'Error'),
      });

    } else {
      const newContacts = this.contactPersons.filter((contact: any) => !contact.id);
      this.customerService.createPartner(
        customerDetails,
        newContacts,
        this.addressDetails,
        this.remarkDetails,
        this.documentDetails,
        employeeId
      ).subscribe({
        next: (data) => {
          this.toastr.success('Vendor created successfully', 'Success');
          this.onCancel();
          this.router.navigate([`/vendors`]);
        },
        error: (error) => this.toastr.error(error, 'Error'),
      });
    }
  }

  async addNewContactPersons() {
    const newContacts = this.contactPersons.filter((contact: any) => !contact.id);
    console.log('newContacts', newContacts);
    if (newContacts && newContacts.length > 0) {
      const validNewContacts = newContacts.filter((contact: any) =>
        contact.firstName && contact.lastName && contact.email
      );

      validNewContacts.map(async (data: any) => {
      const contactPerson ={...data,partnerId: Number(this.vendorId)};
      await this.customerService.createContact(contactPerson).toPromise().then((data) => {
        if (data) {
          // this.toastr.success('Contact added successfully!', 'Success');
        }
      }).catch(error => {
        this.toastr.error(error, 'Error');
      });
      this.fetchPartnerById();
    })
  }  else {
    this.contactPersons.map((data: any) => {
      if (data?.id) {
        this.customerService.updateContact(data).toPromise().then((data) => {
          if (data) {
            // this.toastr.success('Contact updated successfully!', 'Success');
          }
        }).catch(error => {
          this.toastr.error(error, 'Error');
        });
        this.fetchPartnerById();
      }
    });
  }
}

  onCancel() {
    this.newVendorForm.reset();
    this.otherDetailsComponent.onCancel();
    this.addressComponent.onCancel();
    this.addressComponent.onCancel();
    this.contactPersonsComponent.onCancel();
    this.remarksComponent.onCancel();
  }
}
