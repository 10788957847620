import { Component, Input, OnInit, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import {TooltipPosition, MatTooltipModule} from '@angular/material/tooltip';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    MatTooltipModule

  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit {
  mattabnumber = 0
  currentPath: string = '';
 allowedPath =  ['/dashboard','/inventory ','/banking' ,'/branch','/branch','/assets','/reports']

  @Input() sidebarData: any;
  @Output() triggerSidebarAction = new EventEmitter<void>();
  // Using ViewChildren to select all expansion panels
  @ViewChildren(MatExpansionPanel) expansionPanels: QueryList<MatExpansionPanel>;
  profileName: any;

  constructor(private router: Router) { }
  // 

  ngOnInit() {
    // console.log('ngOnInit:', this.sidebarData);
    this.currentPath = this.router.url;
    const savedTab = localStorage.getItem('panelState')
    this.mattabnumber = savedTab ? JSON.parse(savedTab) : 0;
    console.log('Current Path:', this.currentPath);
    if(this.allowedPath.includes(this.currentPath)){
      this.mattabnumber = 0
    }

    const loggedInUser: any = localStorage.getItem('loggedInUser');
    const userData = JSON.parse(loggedInUser)
    this.profileName = userData?.profileData?.profileName;
    console.log('profileName', this.profileName);
    
  }

  onPanelClick() {
    if (window.innerWidth > 768) {
      this.triggerSidebarAction.emit();
    }
  }


  // Toggle all expansion panels
  toggleExpansionPanel() {
    this.expansionPanels.forEach((panel) => {
      if (panel.expanded) {
        panel.close();
      }
    });
  }

  navigateTo(route: string, matnumber: number) {
    this.router.navigate([route]);

    if (matnumber) {
      this.mattabnumber = matnumber
      localStorage.setItem('panelState', JSON.stringify(this.mattabnumber));
    } else {
      matnumber = 0
      localStorage.setItem('panelState', JSON.stringify(0));
    }
  }

  isRouteActive(route: string): boolean {
    return this.router.isActive(route, true);
  }

  isRouteActive2(routes: string[]): boolean {
    const currentRoute = this.router.url;
    return routes.some(route => currentRoute.startsWith(route));
  }

}
