import { Component } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { LeaveService } from '../../../../../../features/leave/services/leave.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Params } from '@angular/router';
import { UploadFileService } from '../../../../../../shared/services/file-upload.service';
import { HolidayCalendarService } from '../../../../../../features/holiday-calendar/services/holiday-calendar.service';

@Component({
  selector: 'app-leave-apply',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    TextFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    ReactiveFormsModule
  ],
  templateUrl: './leave-apply.component.html',
  styleUrl: '../../../../employee-details.component.scss'
})

export class LeaveApplyComponent {
  leaveApplyForm: FormGroup;
  csvFile: File[] = [];
  employeeId: number;
  url: string = '';
  holidays: any;
  today: Date = new Date();
  
  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private leaveService: LeaveService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private holidayService: HolidayCalendarService,
    private documentUploadService: UploadFileService,
  ) {
    this.route.params.subscribe((params: Params) => {
      this.employeeId = params['id'];
    })
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.leaveApplyForm = this.fb.group({
      employeeId: [''],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      leaveType: ['', Validators.required],
      employeeComment: [''],
      supportingDocumentFile: [],
      // firstSession: ['', Validators.required],
      // secondSession: ['', Validators.required],
    });
  }

  removeFile(index: any) {
    this.csvFile.splice(index, 1);
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.csvFile.push(file);
    }
    this.uploadDocuments(this.csvFile);
  }

  uploadDocuments(csvFile: any) {
    console.log(csvFile);
    csvFile.forEach((file: any) => {
      this.documentUploadService.uploadDocument(file, "leaves").subscribe({
        next: (url) => {
          this.url = url;
        },
        error: (error) => {
          console.log(error);
          this.toastr.error(error, 'File upload failed');
        },
      });
    });
  }

  fetchHolidays(){
    this.holidayService.getHolidayCalendarList().subscribe({
      next: (data) => {
        this.holidays = data.map((holiday: any) => new Date(holiday.holidayDate));
      },
      error: (error) => {
        console.error(error,'Error');
      }
    })
  }

  dateFilter = (d: Date | null): boolean => {
    this.fetchHolidays();
    const currentDate = d || new Date();
    
    if (!this.holidays || !Array.isArray(this.holidays)) {
      return true; 
    }
    const isHoliday = this.holidays.some(
      (holidayDate: Date) => holidayDate.getTime() === currentDate.getTime()
    );
    
    return !isHoliday;
  };

  onApplyLeave() {
    if (this.leaveApplyForm.invalid) {
      this.leaveApplyForm.markAllAsTouched();
      return;
    }

    const startDate = this.datePipe.transform(this.leaveApplyForm.value.startDate, "yyyy-MM-dd");
    const endDate = this.datePipe.transform(this.leaveApplyForm.value.endDate, "yyyy-MM-dd");

    const isDateValid = (startDate && endDate)
      ? new Date(endDate) >= new Date(startDate)
        ? true
        : this.toastr.error('To date cannot be earlier than from date', 'Error')
      : this.toastr.error('Choose a valid date', 'Error');

    if (isDateValid === true) {
      this.leaveApplyForm.patchValue({
        startDate: startDate,
        endDate: endDate,
        employeeId: Number(this.employeeId),
      });

      const leaveData = this.leaveApplyForm.value;
      const csvFile = this.csvFile ? this.csvFile : [];
      if (leaveData) {
        this.leaveService.createLeave(leaveData, csvFile).subscribe(
          (response) => {
            if (response && !response.errors) {
              this.toastr.success('Leave applied successfully!', 'Success');
              this.leaveApplyForm.reset();
              this.csvFile = [];
            } else if (response?.errors) {
              this.toastr.error(response?.errors[0]?.message, 'Error');
            }
          },
          (error) => {
            this.toastr.error(error?.message, 'Error');
          }
        );
      }
    }

  }

  onCancel() {
    this.leaveApplyForm.reset();
  }
}
