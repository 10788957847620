import { Component, Inject, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { EmployeeService } from '../../features/employees/services/employee.service';
import { PayrollService } from '../../features/payroll/services/payroll.service';
import { ToastrService } from 'ngx-toastr';
import { ImageService } from '../../shared/services/image.service';
import { PayrollPrecessingDialog } from '../payroll/payroll.component';

@Component({
  selector: 'app-payroll-individual',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatDialogModule,
    RouterModule
  ],
  templateUrl: './payroll-individual.component.html',
  styleUrl: './payroll-individual.component.scss'
})
export class PayrollIndividualComponent {
  data: any;
  employeeId: any;
  employeeData: any;
  profilePicture: string;
  selectedMonth: any;
  selectedYear: any;

  constructor(
    private route: ActivatedRoute,
    private payrollService: PayrollService,
    private toastr: ToastrService,
    private imageService: ImageService,
  ){}

  readonly dialog = inject(MatDialog);

  ngOnInit(){
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      console.log(id);
      this.employeeId = id;
      this.employeePayrollById(id);
    }

    this.route.queryParams.subscribe(params => {
      this.selectedMonth = params['month'];
      this.selectedYear = params['year'];
    });
  }

  employeePayrollById(id: any){
    this.payrollService.employeePayrollById(Number(id)).subscribe({
      next: async (data) => {
        let profilePicture;
              console.log("profilePicture", profilePicture);
              if (data?.employees.profilePicture) {
                try {
                  profilePicture = await this.imageService.getImageAsBase64(
                    data?.employees.profilePicture
                  );
                } catch (error) {
                  profilePicture = 'assets/images/dummy-profile-image.jpg';
                }
              } else {
                profilePicture = 'assets/images/dummy-profile-image.jpg';
              }
        this.data = data;
        this.profilePicture = profilePicture;
      },
      error: (error) => {
        this.toastr.error('Error fetching payroll details', 'Error')
      }
    })
  }

  openDialog(status: any) {
    if(status === true){
      const dialogRef = this.dialog.open(PayrollAdjustmentDialog,{
        data: {deduction : true, reimbursement: false}
      });
    } else {
      const dialogRef = this.dialog.open(PayrollAdjustmentDialog,{
        data: {deduction : false, reimbursement: true}
      });
    }
  }

  onProcessPayment(){
    console.log(this.data);
    
    const dialogRef = this.dialog.open(PayrollPrecessingDialog,{
      data: { 
        id: this.data.employees.id,
        year: this.selectedYear,
        month: this.selectedMonth
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dialog.open(PayrollPrecessingDialog)
        }
        else {
          console.error('Employee salary details not updated');
        }
    });
  }
}



@Component({
  selector: 'payroll-adjustment-dialog',
  templateUrl: 'payroll-adjustment-dialog.html',
  styleUrls: ['./payroll-individual.component.scss'],
  standalone: true,
  imports: [
    CommonModule, 
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule
  ],
})
export class PayrollAdjustmentDialog {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){}

  ngOnInit(){
    if(this.data){
      console.log(this.data);
      
    }
  }



}