import {
  Component,
  HostListener,
  OnDestroy,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  FormBuilder,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule, Location } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '../services/auth.service';
import { Router, RouterModule } from '@angular/router';
import { BreadcrumbService } from '../services/breadcrumb.service';
import { EmployeeService } from '../../features/employees/services/employee.service';
import { Subscription } from 'rxjs';
import { ImageService } from '../../shared/services/image.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatButtonModule,
    RouterModule,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy, OnInit {
  form = new FormGroup({
    selectedOption: new FormControl('option2'), // Default value
  });
  branchForm: FormGroup

  branches = [
    { value: 'option1', viewValue: 'Creative Spaces' },
    { value: 'option2', viewValue: 'Artisan Interiors' },
    { value: 'option3', viewValue: 'Elysian Designs' },
    { value: 'option4', viewValue: 'Luxury Spaces' },
  ];

  isDropdownOpen = false;
  breadcrumbs: Array<any> = [];
  loggedInUser: any;
  userId: number;
  userData: any;
  profilePicture: any;
  private subscriptions: Subscription = new Subscription();
  public branchData: any[] = [];

  @Input() sidebarData: any;

  constructor(
    private elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private employeeService: EmployeeService,
    private imageService: ImageService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    console.log('ngOnInit:', this.sidebarData);
    this.initForm();
    this.loadBranches();
    this.breadcrumbs = this.breadcrumbService.getBreadcrumbs();
    this.loggedInUser = this.authService.getLoggedInUser();
    const loggedInUser = this.authService.getLoggedInUser();
    this.userId = loggedInUser.employeeData.id;

    // Subscribe to the employee data
    this.subscriptions.add(
      this.employeeService
        .getEmployeeById(this.userId)
        .subscribe(async (data) => {
          try {
            this.userData = data;

            let profilePicturePromise;

            if (this.userData.profilePicture) {
              profilePicturePromise = this.imageService
                .getImageAsBase64(this.userData.profilePicture)
                .catch((error) => {
                  console.error('Error loading profile picture:', error);
                  return 'assets/images/dummy-profile-image.jpg';
                });
            } else {
              profilePicturePromise = Promise.resolve(
                'assets/images/dummy-profile-image.jpg'
              );
            }

            const [profilePicture] = await Promise.all([profilePicturePromise]);
            this.profilePicture = profilePicture;
          } catch (error) {
            console.error('Error loading employee data:', error);
          }
        })
    );
  }
  private initForm(): void {
    this.branchForm = this.fb.group({
      branchName: [''],
    });
  }
  private loadBranches() {
    this.employeeService.getBranches().subscribe({
      next: (branches) => {
        this.branchData = branches;
      },
      error: (error) => console.error(error),
    });
  }

  toggleDropdown(event: MouseEvent) {
    event.stopPropagation();
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  handleDropdownClick(event: MouseEvent) {
    event.stopPropagation();
    this.router.navigate([`/my-profile/${this.userId}`]);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
    window.location.reload();
  }

  notificationRoute() {
    this.router.navigate(['/notifications']);
  }

  // // Updated goBack method to use router navigation
  // goBack(): void {
  //   if (this.breadcrumbs.length > 1) {
  //     const previousBreadcrumb = this.breadcrumbs[this.breadcrumbs.length - 2];
  //     this.router.navigate([previousBreadcrumb.path]);
  //   }
  // }

  // // Helper method to determine if the back button should be shown
  // shouldShowBackButton(): boolean {
  //   return this.breadcrumbs.length > 1; // Show back button only if there's more than one breadcrumb
  // }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
