
  <section class="cmn-innerpage-wrapper manual-journal-details">
    <div class="head">
      <div class="search-wrapper">
        <input
          type="text"
          class="cmn-header-search"
          placeholder="Search"
          (input)="onSearch($event)"
        />
      </div>
      <!-- <button class="cmn-upload-btn" (click)="openDialog()">
        <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
        <span>Upload</span>
      </button> -->
      <button class="cmn-add-btn" (click)="navigateTo('/manual-journals/add')">
        <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New
        Journal
      </button>
      <button
        class="cmn-filter-btn"
        (click)="toggleDropdown($event)"
        [ngClass]="{ 'class-clicked': isDropdownOpen }"
      >
        <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
        <!-- <span *ngIf="selectedCount > 0">{{ selectedCount }}</span> -->
      </button>
      <!-- Filter -->
      <div
        [ngClass]="{ show: isDropdownOpen }"
        class="common-dropdown filter-position"
        #dropdown
      >
        <button
          class="close-dropdown"
          (click)="toggleDropdown($event)"
        ></button>
        <form>
          <h6 class="cmn-popup-heading">
            Filter <span (click)="clearFilter()">Clear All</span>
          </h6>
          <div class="filter-inner-content">
            <h2>Journal Status</h2>
            <div class="check-wrapper">
              <div class="check-box" *ngFor="let status of journalStatuses">
                <label class="checkbox-wrapper">
                  <input
                    type="checkbox"
                    (change)="onStatusChange(status, $event)"
                    [checked]="selectedStatuses.includes(status)"
                  />
                  {{ status }}
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="btn-wrapper">
            <button class="cmn-apply-btn" (click)="applyFilter($event)">
              Apply
            </button>
          </div>
        </form>
      </div>
      <!-- Filter -->
    </div>
    <div class="sub-head">
      <ul>
        <li class="main">{{ journalStatusValue }}</li>
        <li class="edit">
          <img src="assets/images/icons/icon-send.svg" alt="Mail" /> Send to
          mail
        </li>
        <li class="edit" (click)="navigateToEditPage()">
          <img src="assets/images/icons/icon-edit-accounts.svg" alt="Edit" />
          Edit
        </li>
        <li class="edit">
          <img src="assets/images/icons/icon-publish.svg" alt="Publish" />
          Publish
        </li>
      </ul>
    </div>
    <div class="manual-journal-details-tab-wrapper">
      <div class="tab-left">
        <div class="tab-box-main-wrapper">
          <button
            *ngFor="let journal of allJournals; let i = index"
            class="tab-custom-button"
            [class.active]="selectedTabIndex === i"
            (click)="selectTab(i)"
          >
            <div class="inn-box">
              <h2>{{ journal?.journalNo }}</h2>
              <h3>{{ journal?.reference }}</h3>
            </div>
            <div class="inn-box">
              <h6>{{ journal?.date | date : 'dd MMM yyyy' }}</h6>
              <h5>
                {{ journal?.currencyData?.currencySymbol }}
                <span>{{ debitTotal }}</span>
              </h5>
            </div>
            <div class="inn-box">
              <h4 class="green">{{ journal?.status }}</h4>
            </div>
          </button>
        </div>
      </div>
      <div class="manual-journal-details-content-wrapper">
        <div *ngIf="journalData">
          <div class="chart-tab-content">
            <div class="tab-inner">
              <h2>JOURNAL</h2>
              <h3>{{ journalData?.journalNo }}</h3>
              <!-- <h3>{{journalData?.date | date : 'dd MMM yyyy' }}</h3> -->
              <div class="journal-details">
                <div class="left-set">
                  <div class="details-box">
                    <h4>Date</h4>
                    <h5>:</h5>
                    <h6>{{ journalData?.date | date : 'dd MMM yyyy' }}</h6>
                  </div>
                  <div class="details-box">
                    <h4>Notes</h4>
                    <h5>:</h5>
                    <h6>{{ journalData?.notes }}</h6>
                  </div>
                </div>
                <div class="right-set">
                  <div class="details-box">
                    <h4>Amount</h4>
                    <h5>:</h5>
                    <h6>
                      {{ journalData?.currencyData?.currencyCode }}
                      {{ this.debitTotal }}
                    </h6>
                  </div>
                  <div class="details-box">
                    <h4>Reference</h4>
                    <h5>:</h5>
                    <h6>{{ journalData?.reference }}</h6>
                  </div>
                </div>
              </div>
              <table>
                <tr>
                  <th>Account</th>
                  <th>Contact</th>
                  <th>Debits</th>
                  <th>Credits</th>
                </tr>
                <tr class="title" *ngFor="let entry of journalEntries">
                  <td>{{ entry?.ChartOfAccount?.accountName }}</td>
                  <td>{{ entry?.Partner?.displayName }}</td>
                  <td>{{ entry?.debit || "--" }}</td>
                  <td>{{ entry?.credit || "--" }}</td>
                </tr>
                <tr class="sub-total">
                  <td></td>
                  <td>Sub Total</td>
                  <td>AED {{ this.debitTotal }}</td>
                  <td>AED {{ this.creditTotal }}</td>
                </tr>
                <tr class="total">
                  <td></td>
                  <td>Total</td>
                  <td>AED {{ this.debitTotal }}</td>
                  <td>AED {{ this.creditTotal }}</td>
                </tr>
              </table>
              <span class="published-note"
                ><img
                  [src]="getJournalStatusImage(journalData?.status)"
                  [alt]="journalData?.status"
              /></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

