import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {
  GET_ACCOUNTS_DEPOSIT,
  GET_CLIENTS,
  GET_INVOICES,
  GET_INVOICE_BY_ID,
  GET_INVOICE_DASHBOARD,
  GET_INVOICE_DETAILS,
  GET_PROJECTS,
  MARK_AS_SENT,
} from '../graphql/invoice.queries';
import {
  CREATE_ADDRESS,
  CREATE_INVOICE,
  REMOVE_INVOICE_ATTACHMENT_BY_ID,
  REMOVE_INVOICE_BY_ID,
  SEND_MAIL_INVOICE,
  UPDATE_INVOICE,
} from '../graphql/invoice.mutations';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(private http: HttpClient, private apollo: Apollo) {}

  createInvoice(
    createInvoiceInput: any,
    createInvoiceItemInput: any[],
    createInvoiceAttachmentInput: any[],
    employeeId: any
  ): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: CREATE_INVOICE,
      variables: {
        createInvoiceInput: createInvoiceInput,
        createInvoiceItemInput: createInvoiceItemInput,
        createInvoiceAttachmentInput: createInvoiceAttachmentInput,
        employeeId: employeeId,
      },
    });
  }

  updateInvoice(updateInvoiceInput: any, employeeId: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: UPDATE_INVOICE,
      variables: {
        updateInvoiceInput: updateInvoiceInput,
        employeeId: employeeId,
      },
    });
  }

  sendInvoiceMail(
    invoiceId: any,
    to: any[],
    cc: any[],
    subject: any,
    body: any
  ): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: SEND_MAIL_INVOICE,
      variables: {
        invoiceId: invoiceId,
        to: to,
        cc: cc,
        subject: subject,
        body: body,
      },
      fetchPolicy: 'no-cache',
    });
  }

  fetchInvoices(search: string = ''): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_INVOICES,
        variables: { search },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data?.invoices));
  }

  fetchInvoiceDashboard(): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_INVOICE_DASHBOARD,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data?.getInvoicesDashboard)
      );
  }

  //remove invoice attachment
  removeInvoiceAttachmentById(id: number): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: REMOVE_INVOICE_ATTACHMENT_BY_ID,
      variables: { id },
    });
  }

  //fetch partners
  fetchClients(search: string): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_CLIENTS,
        variables: { search },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data.getPartnersDropDownItems)
      );
  }

  fetchInvoiceById(id: number): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_INVOICE_BY_ID,
        variables: { id: id },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((response) => response.data?.invoice));
  }

  removeInvoiceById(id: number): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: REMOVE_INVOICE_BY_ID,
      variables: { id },
    });
  }

  fetchProjects(): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        query: GET_PROJECTS,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data.getProjectDropDownItems)
      );
  }

  fetchAccountsToDeposit(search: string): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_ACCOUNTS_DEPOSIT,
        variables: { search },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((response) => response.data.getChartOfAccountDropDownItems)
      );
  }

  getInvoiceDetails(id: number): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_INVOICE_DETAILS,
        variables: { id: id },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data?.getInvoiceDetails));
  }

  markAsSent(id: number, employeeId: number): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: MARK_AS_SENT,
      variables: { id: id, employeeId: employeeId },
    });
  }

  createAddress(createAddressInput: any): Observable<any> {
    return this.apollo.use('project').mutate({
      mutation: CREATE_ADDRESS,
      variables: { createAddressInput: createAddressInput },
    });
  }
}
