import { Component } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  PAYMENT_MODES,
  PartnerTypesEnum,
  PaymentTypesEnum,
} from '../../../../helpers/helper-file';
import { PaymentsItemService } from '../../../../features/payments/services/payments.service';
import { UploadFileService } from '../../../../shared/services/file-upload.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { pymentGenerateNo } from '../../../../helpers/helper';

@Component({
  selector: 'app-bill-payment',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
  ],
  templateUrl: './bill-payment.component.html',
  styleUrl: '../../bill-payment-new.component.scss',
})
export class BillPaymentComponent {
  billForm: FormGroup;
  vendorList: any;
  private partnerTypes = PartnerTypesEnum;
  private paymentType = PaymentTypesEnum;
  paymentModeList: any = PAYMENT_MODES;
  getAccountList: any;
  partnerBillData: any;
  fullAmount: number = 0.0;
  documentFile: any = null;
  documentFileName: any = null;
  documentFileId: any = null;
  documentFileUrl: any = null;
  isLoading = false;
  vendorSearchControl = new FormControl();
  maxDate: Date = new Date();
  splitAmounts: number[] = []; // Array to store split values
  totalSplitAmount = 0; // Variable to store the total
  globalbillId: number;

  constructor(
    private fb: FormBuilder,
    private itemService: PaymentsItemService,
    private documentUploadService: UploadFileService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.initForm();
    this.fetchPartners();
    this.fetchGetAccount();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const id = params['id'];
      const vendorId = params['vendorId'];
      const billId = params['billId'];
      this.globalbillId = parseInt(billId);
      if (id) {
        this.billForm.get('id')?.setValue(id);
        this.loadInvoiceData();
      } else {
        const generatedId = pymentGenerateNo();
        this.billForm.get('payment')?.setValue(generatedId);
        this.billForm.get('id')?.setValue('');
      }

      if (vendorId && billId) {
        this.getDueBillsByPartner(parseInt(vendorId));
        this.billForm.get('vendorName')?.setValue(parseInt(vendorId));
      }
    });

    this.vendorSearchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((searchText) => {
        this.fetchPartners(searchText);
      });

    // Recalculate splits and total whenever amountReceived changes
    this.billForm.get('paymentMade')?.valueChanges.subscribe(() => {
      this.calculateSplits();
    });

    // Initial calculation
    this.calculateSplits();

    //calculateExcessAmount
    this.billForm.get('paymentMade')?.valueChanges.subscribe(() => {
      this.calculateExcessAmount();
      this.usedAmount();
    });
  }

  initForm(): void {
    this.billForm = this.fb.group({
      id: [''],
      vendorName: ['', Validators.required],
      payment: ['', Validators.required],
      paymentMade: ['', Validators.required],
      paymentDate: ['', Validators.required],
      paymentMode: ['', Validators.required],
      depositTo: ['', Validators.required],
      reference: [''],

      isFullAmount: [false],
    });
  }

  private loadInvoiceData(): void {
    const id = this.billForm.get('id')?.value;
    if (id) {
      this.itemService.fetchPayment(parseInt(id)).subscribe({
        next: (data) => {
          this.getDueBillsByPartner(data?.partnerId);

          this.documentFileName = data?.paymentAttachments?.[0]?.fileName;
          this.documentFileId = data?.paymentAttachments?.[0]?.id;
          this.documentFileUrl = data?.paymentAttachments?.[0]?.fileUrl;

          this.billForm.patchValue({
            id: data?.id,
            vendorName: data?.partnerId,
            paymentDate:
              this.datePipe.transform(data?.paymentDate, 'yyyy/MM/dd') || '--',
            paymentMade: parseFloat(data?.amount),
            paymentType: data?.paymentType,
            paymentMode: data.paymentMode,
            depositTo: data.depositId,
            paymentNo: data.payment,
            referenceNo: data.reference,
            // paymentMade: data.totalAmount,
            note: data?.note,
          });
        },
        error: (error) => console.error(error),
      });
    }
  }

  private fetchPartners(search: string = '') {
    this.itemService
      .fetchPartners(search, this.partnerTypes.Vendor)
      ?.subscribe({
        next: (vendor: any) => {
          this.vendorList = vendor;
        },
        error: (error: any) => console.error(error),
      });
  }
  private fetchGetAccount() {
    this.itemService.fetchDepositTo('', false, true)?.subscribe({
      next: (getAccount: any) => {
        this.getAccountList = getAccount;
      },
      error: (error: any) => console.error(error),
    });
  }

  private getDueBillsByPartner(id: number) {
    this.itemService.getDueBillsByPartner(id, {})?.subscribe({
      next: (invoiceData: any) => {
        if (this.globalbillId) {
          this.partnerBillData = invoiceData.filter(
            (item: any) => item.id === this.globalbillId
          );
        } else {
          this.partnerBillData = invoiceData;
        }
        this.fullAmount = this.partnerBillData?.reduce(
          (total: number, item: { balance: number }) => total + item.balance,
          0
        );
      },
      error: (error: any) => console.error(error),
    });
  }

  calculateSplits(): void {
    const amountReceived = this.billForm.get('paymentMade')?.value || 0;
    const totalEntries = this.partnerBillData?.length;
    const splitAmount = totalEntries > 0 ? amountReceived / totalEntries : 0;

    // Populate the splitAmounts array
    this.splitAmounts = Array(totalEntries).fill(splitAmount);

    // Calculate the total of split amounts
    this.totalSplitAmount = this.splitAmounts.reduce(
      (acc, curr) => acc + curr,
      0
    );
  }

  onCustomerSelected(event: MatSelectChange) {
    const selectedCustomerId = event.value;
    this.billForm.get('paymentMade')?.setValue('');
    this.billForm.get('isFullAmount')?.setValue(false);
    this.getDueBillsByPartner(selectedCustomerId);
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.documentFileName = file.name;
      this.documentFile = file;
    }
  }

  toggleFullAmount(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.billForm.get('paymentMade')?.setValue(this.fullAmount);
    } else {
      this.billForm.get('paymentMade')?.setValue('');
    }
  }

  calculateExcessAmount(): number {
    const amountReceived = this.billForm.get('paymentMade')?.value || 0;
    return amountReceived - this.totalSplitAmount;
  }

  usedAmount(): number {
    const amountReceived = this.billForm.get('paymentMade')?.value || 0;
    return amountReceived;
  }

  clearDocument() {
    this.documentFile = null;
    this.documentFileName = null;
  }

  onCreate(): void {
    if (this.billForm.invalid) {
      this.billForm.markAllAsTouched();
      return;
    }

    this.documentUploadService
      .uploadDocument(this.documentFile, 'payment-received')
      .subscribe({
        next: (url) => {
          if (url) {
            this.documentFileUrl = url?.url;
          }
          const data = this.billForm.value;

          const billIds = this.partnerBillData?.map((item: any) => item?.id);

          const itemInputData = {
            partnerId: data?.vendorName,
            paymentDate:
              this.datePipe.transform(data?.paymentDate, 'yyyy/MM/dd') || '--',
            amount: parseFloat(data?.paymentMade) ?? 0,
            paymentType: this.paymentType.BILL,
            paymentMode: data?.paymentMode,
            depositId: data?.depositTo,
            paymentNo: data?.payment,
            referenceNo: data?.reference,
            totalAmount: parseFloat(data?.paymentMade) ?? 0,
            note: data?.note,
          };

          const createPaymentAttachment = {
            fileUrl: this.documentFileUrl,
            fileName: this.documentFileName,
          };

          const billIdsValue = billIds;
          this.isLoading = true;

          const invoiceIds: any = null;

          if (data?.id) {
            //updateing
            const itemInputUpdateData = {
              id: data?.id,
              ...itemInputData,
            };

            const createPaymentUpdateAttachment = {
              id: this.documentFileId ?? null,
              ...createPaymentAttachment,
            };

            this.itemService
              .updatePayment(
                itemInputUpdateData,
                createPaymentUpdateAttachment,
                invoiceIds,
                billIdsValue
              )
              .subscribe({
                next: () => {
                  this.isLoading = false;
                  this.toastr.success('Payment made updated successfully.');
                  this.router.navigate(['/payment-received']);
                },
                error: (error) => {
                  this.isLoading = false;
                  this.toastr.error(error.message || 'Failed to update item.');
                },
              });
          } else {
            this.itemService
              .createPayment(
                itemInputData,
                createPaymentAttachment,
                invoiceIds,
                billIdsValue
              )
              .subscribe({
                next: () => {
                  this.isLoading = false;
                  this.toastr.success('Payment made Created successfully.');
                  this.router.navigate(['/payments-made']);
                },
                error: (error) => {
                  this.isLoading = false;
                  this.toastr.error(error.message || 'Failed to update item.');
                },
              });
          }
        },
        error: (error) => {
          this.toastr.error('upload failed', error);
        },
      });
  }
}
