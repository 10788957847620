<mat-dialog-content>
    <section class="upload-employees-wrapper">
      <h2 class="cmn-popup-title">Upload Transactions</h2>
      <button class="cmn-close" mat-dialog-close></button>
      <form [formGroup] = "importStatementForm">
        <div class="form-wrapper">
          <mat-form-field>
              <mat-select placeholder="Select an account" formControlName="accountName">
                  <mat-option *ngFor="let account of accounts" [value]="account?.chartOfAccount?.id">
                      {{ account?.chartOfAccount?.accountName }}
                  </mat-option>
              </mat-select>
          </mat-form-field>
          <div *ngIf="
          importStatementForm.get('accountName')!.invalid &&
          importStatementForm.get('accountName')!.touched
          " class="error">
              Account is required.
          </div>
      </div>
      <div class="cmn-upload-box" (dragover)="onDragOver($event)" 
      (drop)="onDrop($event)">
        <img src="assets/images/upload-image.png" alt="Upload Image" />
        <h3>Drag and drop your file here</h3>
        <h4>or</h4>
        <input
          #fileInput
          type="file"
          formControlName="file"
          (change)="onFileSelected($event)"
          style="display: none"
          accept=".csv"
        />
        <button class="cmn-choose-file-btn" (click)="fileInput.click()">
          <img
            src="assets/images/icons/icon-add-color.svg"
            alt="Icon Add"
          />Choose a File
        </button>
      </div>
      <div class="uploaded-file" *ngIf="file">
        <h6>
          {{file?.name}}
          <img
            src="assets/images/icons/icon-close.svg"
            alt="Close Icon"
            (click)="removeFile()"
          />
        </h6>
      </div>
      <div class="upload-info">
        <h5>CSV Format only supported</h5>
        <a
          href="assets/sample-files/transactions.csv"
          class="download-sample"
          download="transactions.csv"
          >Download Sample CSV</a
        >
      </div>
  
      <button class="cmn-upload-submit-btn active" (click)="onUpload()">
        Upload Transactions
      </button>
    </form>
    </section>
  </mat-dialog-content>