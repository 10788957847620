
  <section class="cmn-innerpage-wrapper send-mail">
    <div class="send-mailer">
      <form [formGroup]="emailForm" (ngSubmit)="sendEmail()">
        <div class="mail-form-head">
          <div class="form-box">
            <label for="">From</label>
            <div class="input-box">
              <input type="text" disabled value="admin@munawel.com" />
            </div>
          </div>
          <div class="form-box">
            <label for="">Send To</label>
            <div class="input-box">
              <div class="email-input-container">
                <div class="email-chips">
                  <span *ngFor="let email of toEmails" class="email-chip">
                    {{ email }}
                    <button type="button" (click)="removeEmail(email, 'to')">
                      ×
                    </button>
                  </span>
                </div>
                <input formControlName="toEmailControl" (keyup.enter)="addEmail('to')" placeholder="Add email" />
              </div>
            </div>
          </div>
          <div class="form-box with-bcc">
            <label for="">Cc</label>
            <div class="input-box">
              <div class="email-input-container">
                <div class="email-chips">
                  <span *ngFor="let email of ccEmails" class="email-chip">
                    {{ email }}
                    <button type="button" (click)="removeEmail(email, 'cc')">
                      ×
                    </button>
                  </span>
                </div>
                <input formControlName="ccEmailControl" (keyup.enter)="addEmail('cc')" placeholder="Add email" />
              </div>
            </div>
            <!-- <button class="add-bcc" (click)="toggleBcc()">Bcc</button> -->
          </div>
          <!-- <div class="form-box" *ngIf="showBcc">
          <label for="">Bcc</label>
          <div class="input-box">
            <div class="email-input-container">
              <div class="email-chips">
                <span *ngFor="let email of bccEmails" class="email-chip">
                  {{ email }}
                  <button type="button" (click)="removeEmail(email, 'bcc')">
                    ×
                  </button>
                </span>
              </div>
              <input
                formControlName="bccEmailControl"
                (keyup.enter)="addEmail('bcc')"
                placeholder="Add email"
              />
            </div>
          </div>
        </div> -->
          <div class="form-box">
            <label for="">Subject</label>
            <div class="input-box">
              <!-- <h3>{{ invoiceData?.invoiceNo }} {{ invoiceData?.subject }}</h3> -->
              <input type="text" id="subject" formControlName="subject" />
            </div>
          </div>
        </div>
        <quill-editor [formControl]="invoiceTemplateControl"></quill-editor>

        <!-- <div class="bottom-content">
          <div class="check-box">
            <label class="checkbox-wrapper">
              <input type="checkbox" />
              Attach Invoice PDF
              <span class="checkmark"></span>
            </label>
          </div>
        </div> -->
        <div class="submit-btn-wrapper">
          <button class="cmn-cancel-btn" type="reset">Cancel</button>
          <button class="cmn-next-btn" type="submit">
            Send
          </button>
        </div>
      </form>
    </div>
  </section>
