<section class="cmn-innerpage-wrapper invoice-new">
    <h2 class="cmn-inner-heading">New Credit Note</h2>
    <form [formGroup]="FormGroupItems" (ngSubmit)="onFormubmit()">

        <div class="invoice-new-form-wrapper">

            <div class="form-wrapper invoice-form-wrapper">

                <!-- Client Name Section -->
                <div class="cmn-Search-select-container">
                    <label for="clientName">Customer Name</label>
                    <mat-form-field>
                        <mat-select placeholder="Customer Name" id="clientName" formControlName="customerName"
                            [ngClass]="{
                            'mat-select-error': FormGroupItems.get('customerName')!.touched &&
                                                FormGroupItems.get('customerName')!.hasError('required')
                          }">
                            <mat-option>
                                <ngx-mat-select-search></ngx-mat-select-search>
                            </mat-option>
                            <mat-option value="client1">Client 1</mat-option>
                            <mat-option value="client2">Client 2</mat-option>
                        </mat-select>
                        <mat-error *ngIf="
                  FormGroupItems.get('customerName')!.touched &&
                  FormGroupItems.get('customerName')!.hasError('required')
                ">
                            Customer Name is required.
                        </mat-error>
                    </mat-form-field>
                    <button>
                        <div><img src="../../../assets/images/icons/icon-add-color.svg" alt="Add client"></div>
                        <span>Add client</span>
                    </button>
                </div>

                <!-- Invoice Number Section -->
                <div>
                    <label for="invoiceNumber">Credit Note#</label>
                    <mat-form-field>
                        <input matInput placeholder="IN-000212" id="invoiceNumber" formControlName="creditNoteNo" />
                        <mat-error *ngIf="
                          FormGroupItems.get('creditNoteNo')!.touched &&
                          FormGroupItems.get('creditNoteNo')!.hasError('required')
                        ">
                            Credit Note is required.
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- Order Number Section -->
                <div>
                    <label for="reference">Reference#</label>
                    <mat-form-field>
                        <input matInput placeholder="0923423" id="reference" formControlName="reference" />
                    </mat-form-field>
                </div>

                <!-- Invoice Date Section -->
                <div>
                    <label for="creditNoteDate">Credit Note Date</label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="invoiceDatePicker" placeholder="Select Date"
                            id="creditNoteDate" formControlName="creditNoteDate" />
                        <mat-datepicker-toggle matIconSuffix [for]="invoiceDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #invoiceDatePicker></mat-datepicker>
                        <mat-error *ngIf="
                          FormGroupItems.get('creditNoteDate')!.touched &&
                          FormGroupItems.get('creditNoteDate')!.hasError('required')
                        ">
                            Credit Note Date is required.
                        </mat-error>
                    </mat-form-field>
                </div>




                <!-- Subject Section -->
                <div>
                    <label for="subject">Sales Person</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter Sales Person" id="salesPerson" formControlName="salesPerson" />
                        <mat-error *ngIf="
                        FormGroupItems.get('salesPerson')!.touched &&
                        FormGroupItems.get('salesPerson')!.hasError('required')
                      ">
                            Sales Person is required.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div>
                    <label for="subject">Subject</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter Subject" id="subject" formControlName="subject" />
                        <mat-error *ngIf="
                        FormGroupItems.get('subject')!.touched &&
                        FormGroupItems.get('subject')!.hasError('required')
                      ">
                            Subject is required.
                        </mat-error>
                    </mat-form-field>
                </div>



            </div>

        </div>


        <div class="item-service-adding-table-wrapper">

            <table>
                <thead>
                    <tr>
                        <th>
                            Item/Service
                        </th>
                        <th>
                            Unit </th>
                        <th>
                            Quantity </th>
                        <th>
                            Rate </th>
                        <th>
                            Discount </th>
                        <th>
                            Amount </th>
                        <th>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>
                            <div class="form-wrapper">
                                <div>
                                    <mat-form-field>
                                        <mat-select placeholder="Select Item">
                                            <mat-option value="item1">Item 1</mat-option>
                                            <mat-option value="item2">Item 2</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field>
                                        <textarea matInput placeholder="Enter item description"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="form-wrapper">

                                <mat-form-field>
                                    <mat-select placeholder="Sq.ft">
                                        <mat-option value="Sq">Sq</mat-option>
                                        <mat-option value="Kg">Kg</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </td>
                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput placeholder="quantity" />
                                </mat-form-field>
                            </div>
                        </td>
                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput placeholder="rate" />
                                </mat-form-field>
                            </div>
                        </td>
                        <td>
                            <div class="form-wrapper">
                                <div class="split-parent">
                                    <div class="left-set">
                                        <mat-form-field>
                                            <input matInput placeholder="3.67" />
                                        </mat-form-field>
                                    </div>
                                    <div class="right-set">
                                        <mat-form-field>
                                            <mat-select placeholder="%">
                                                <mat-option value="inr">%</mat-option>
                                                <mat-option value="usd">&</mat-option>
                                                <mat-option value="eur">$</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                        </td>
                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput placeholder="100" />
                                </mat-form-field>
                            </div>
                        </td>
                        <td>
                            <img src="../../../assets/images/icons/icon-add-color.svg" alt="">
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>

        <div class="quotation-table-container">

            <div>
                <table>

                    <thead>
                        <tr>
                            <th>

                            </th>
                            <th>Item/Service</th>
                            <th>Description</th>
                            <th>Unit</th>
                            <th>Quantity</th>
                            <th>Rate</th>
                            <th>Discount</th>
                            <th>Amount</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr *ngFor="let item of arr">
                            <td>{{item}}</td>
                            <td>Floor Cleaning</td>
                            <td>Cleaning</td>
                            <td>pcs</td>
                            <td>1</td>
                            <td><sub>aed</sub>150</td>
                            <td>5%</td>
                            <td><sub>aed</sub>150</td>
                            <td><img src="../../../assets/images/icons/icon-edit-accounts.svg" alt=""></td>
                        </tr>


                    </tbody>
                </table>
            </div>

        </div>


        <div class="notes-tatal-amount-container">
            <div class="form-container">
                <div class="form-wrapper">
                    <label for="customerNotes">Customer Notes</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Looking forward to your business"></textarea>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="termsConditions">Terms & Conditions</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Enter the terms & conditions"></textarea>
                    </mat-form-field>
                </div>

            </div>
            <div class="amount-table-div">
                <div>
                    <h6>Discount <span>: AED -50.00</span></h6>
                    <h6>VAT amount <span>: AED +50.00</span></h6>
                    <h6>Adjustment <span>: AED 0.00</span></h6>
                </div>
                <div>
                    <h4>Total(AED) <span>: AED 590.00</span></h4>
                </div>
            </div>
        </div>

        <div class="submit-btn-wrapper">
            <button class="cmn-cancel-btn" type="reset">Cancel</button>
            <button class="cmn-draft-btn" type="submit">Save as Draft</button>
            <button class="cmn-next-btn" type="submit">Save as Open</button>
        </div>
    </form>


</section>