import { gql } from 'apollo-angular';

export const CREATE_JOURNAL = gql`
  mutation createJournal(
    $createJournalInput: CreateJournalInput!
    $createJournalEnteryInput: [CreateJournalEntryInput!]!
    $createJournalAttachmentInput: [CreateJournalAttachmentInput!]!
  ) {
    createJournal(
      createJournalInput: $createJournalInput
      createJournalEnteryInput: $createJournalEnteryInput
      createJournalAttachmentInput: $createJournalAttachmentInput
    ) {
      id
      date
      journalNo
      reference
      notes
      currencyId
      currencyData {
        id
        currencyCode
        currencyName
        currencySymbol
        exchangeRate
        status
        isPrimary
      }
      inCash
      status
      createdAt
      updatedAt
      deletedAt
      journalEnteries {
        id
        chartOfAccountId
        journalId
        partnerId
        description
        debit
        credit
      }
      journalAttachments {
        id
        journalId
        fileName
        fileUrl
      }
    }
  }
`;
export const UPDATE_JOURNAL = gql`
  mutation updateJournal(
    $updateJournalInput: UpdateJournalInput!
    $createJournalEntriesInput: [CreateJournalEntryInput!]
    $updateJournalEntriesInput: [UpdateJournalEntryInput!]
    $createJournalAttachmentsInput: [CreateJournalAttachmentInput!]
    $updateJournalAttachmentsInput: [UpdateJournalAttachmentInput!]
  ) {
    updateJournal(
      updateJournalInput: $updateJournalInput
      createJournalEntriesInput: $createJournalEntriesInput
      updateJournalEntriesInput: $updateJournalEntriesInput
      createJournalAttachmentsInput: $createJournalAttachmentsInput
      updateJournalAttachmentsInput: $updateJournalAttachmentsInput
    ) {
      id
      date
      journalNo
      reference
      notes
      currencyId
      inCash
      status
      createdAt
      updatedAt
      deletedAt
      journalEnteries {
        id
        chartOfAccountId
        journalId
        partnerId
        description
        debit
        credit
        createdAt
        updatedAt
        deletedAt
      }
      journalAttachments {
        id
        journalId
        fileName
        fileUrl
      }
    }
  }
`;

export const REMOVE_JOURNAL_ENTRY_BY_ID = gql`
mutation removeJournalEntery($id: Int!) {
  removeJournalEntery(id: $id)
}
`;

export const REMOVE_JOURNAL_ATTACHMENT_BY_ID = gql`
  mutation removeJournalAttachment($id: Int!) {
    removeJournalAttachment(id: $id)
  }
`;