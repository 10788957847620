import { gql } from 'apollo-angular';

export const CREATE_PAYMENT = gql`
  mutation createPayment(
    $createPaymentInput: CreatePaymentInput!
    $createPaymentAttachment: CreatePaymentAttachmentInput!
    $invoiceIds: [Int!]
    $billIds: [Int!]
  ) {
    createPayment(
      createPaymentInput: $createPaymentInput
      createPaymentAttachment: $createPaymentAttachment
      invoiceIds: $invoiceIds
      billIds: $billIds
    ) {
      id
      partnerId
      paymentType
      amount
      bankCharge
      paymentDate
      paymentNo
      referenceNo
      paymentMode
      totalAmount
      receiptUrl
      note
    }
  }
`;

export const UPDATE_PAYMENT = gql`
  mutation updatePayment(
    $updatePaymentInput: UpdatePaymentInput!
    $updatePaymentAttachment: UpdatePaymentAttachmentInput!
    $invoiceIds: [Int!]
    $billIds: [Int!]
  ) {
    updatePayment(
      updatePaymentInput: $updatePaymentInput
      updatePaymentAttachment: $updatePaymentAttachment
      invoiceIds: $invoiceIds
      billIds: $billIds
    ) {
      id
      partnerId
      paymentType
      amount
      bankCharge
      paymentDate
      paymentNo
      referenceNo
      paymentMode
      totalAmount
      receiptUrl
      note
    }
  }
`;

export const CREATE_COMMENT = gql`
  mutation createComment($createCommentInput: CreateCommentInput!) {
    createComment(createCommentInput: $createCommentInput) {
      id
      employeeId
    }
  }
`;
