import { Component, OnInit, inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Router, TitleStrategy } from '@angular/router';
import { Subject, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InvoiceService } from '../../features/invoice/services/invoice.service';
import { PaginationHelper } from '../../shared/services/pagination.service';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';

@Component({
  selector: 'app-invoice',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatInputModule, AgGridAngular],
  templateUrl: './invoice.component.html',
  styleUrl: './invoice.component.scss',
})
export class InvoiceComponent implements OnInit {
  public rowData: any[] = [];
  public loading: boolean = true;
  public error: any = null;
  private searchSubject = new Subject<string>();
  readonly dialog = inject(MatDialog);
  invoiceDashboardData: any;

  columnDefs = [
    {
      headerName: 'Date',
      field: 'invoiceDate',
      flex: 1,
    },
    { headerName: '#Invoice', field: 'invoiceNo', flex: 1 },
    { headerName: 'Order Number', field: 'orderNo', flex: 1 },
    { headerName: 'Customer Name', field: 'customerName', flex: 1 },
    { headerName: 'Status', field: 'status', flex: 1 },
    { headerName: 'Due Date', field: 'dueDate', flex: 1 },
    {
      headerName: 'Amount', field: 'amount', flex: 1, valueFormatter: (params: any) => {
        const value =
          params.value === null || params.value === 0
            ? 0
            : parseFloat(params.value);
        return value === 0 ? '0.00' : `AED ${value.toFixed(2)}`;
      },
    },
    {
      headerName: 'Balance Due', field: 'balanceDue', flex: 1, valueFormatter: (params: any) => {
        const value =
          params.value === null || params.value === 0
            ? 0
            : parseFloat(params.value);
        return value === 0 ? '0.00' : `AED ${value.toFixed(2)}`;
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      cellRenderer: (params: any) => {
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.alignItems = 'center';
        container.style.gap = '20px';

        const editIcon = document.createElement('img');
        editIcon.src = 'assets/images/icons/icon-edit.svg';
        editIcon.style.cursor = 'pointer';
        editIcon.setAttribute('data-action', 'edit');

        // const deleteIcon = document.createElement('img');
        // deleteIcon.src = 'assets/images/icons/icon-delete.svg';
        // deleteIcon.style.cursor = 'pointer';
        // deleteIcon.setAttribute('data-action', 'delete');
        container.appendChild(editIcon);
        // container.appendChild(deleteIcon);
        return container;
      },
      floatingFilter: false,
      filter: false,
    },
  ];
  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10];
  public themeClass: string = 'ag-theme-quartz';

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private invoiceService: InvoiceService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.fetchInvoices();
    this.fetchInvoiceDashboard();
    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchInvoices(searchTerm);
      });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  private fetchInvoiceDashboard() {
    this.loading = true;
    this.invoiceService.fetchInvoiceDashboard().subscribe({
      next: (data) => {
        this.invoiceDashboardData = data;
      },
    });
  }

  fetchInvoices(search: string = '') {
    this.loading = true;
    this.invoiceService.fetchInvoices(search).subscribe({
      next: (invoices) => {
        this.rowData = invoices.map((invoice: any) => {
          return {
            id: invoice?.id,
            invoiceDate:
              this.datePipe.transform(invoice?.invoiceDate, 'dd MMM yyyy') ||
              '--',
            invoiceNo: invoice?.invoiceNo,
            orderNo: invoice?.orderNo,
            customerName: invoice?.partnerData?.displayName,
            dueDate:
              this.datePipe.transform(invoice?.dueDate, 'dd MMM yyyy') || '--',
            amount: invoice?.totalPrice,
            status: invoice?.status,
            balanceDue: invoice?.balance
          };
        });
        // Update pagination options based on the rowData length
        this.paginationPageSizeSelector =
          PaginationHelper.getPaginationPageSizeOptions(this.rowData);

        this.loading = false;
      },
    });
  }

  openEdit(invoiceData: any) {
    const invoiceId = invoiceData?.id;
    if (invoiceId) {
      this.router.navigate([`/invoice/invoice-edit/${invoiceId}`]);
    }
  }

  onDeleteInvoice(invoiceData: any): void {
    const dialogRef = this.dialog.open(ConfirmDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.removeInvoice(invoiceData?.id);
      }
    });
  }

  private removeInvoice(invoiceId: number): void {
    this.invoiceService.removeInvoiceById(invoiceId).subscribe({
      next: () => {
        this.toastr.success('Invoice removed successfully.');
        this.fetchInvoices();
      },
      error: () => {
        this.toastr.error(
          'Failed to remove invoice. Please try again.',
          'Error'
        );
      },
    });
  }

  onCellClicked(params: any) {
    const invoiceId = params.data?.id;
    if (
      params.column.colId === 'action' &&
      params.event.target.dataset.action
    ) {
      let action = params.event.target.dataset.action;

      if (action === 'edit') {
        this.openEdit(params?.data);
      } else if (action === 'delete') {
        this.onDeleteInvoice(params?.data);
      }
    } else {
      const invoiceId = params.data?.id;
      this.router.navigate([`invoice/invoice-details/${invoiceId}`]);
    }
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  isDropdownOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}
