import {
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    ViewChild,
    inject
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutComponent} from '../../core/layout/layout.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectChange, MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';

import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {
    FormArray,
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators
} from '@angular/forms';
import {InvoiceService} from '../../features/invoice/services/invoice.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {ManualJournalService} from '../../features/manual-journals/services/manual-journal.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {ClientNewComponent} from '../client-new/client-new.component';
import {MatTabsModule} from '@angular/material/tabs';
import {OtherDetailsComponent} from '../client-new/components/other-details/other-details.component';
import {AddressComponent} from '../client-new/components/address/address.component';
import {ContactPersonsComponent} from '../client-new/components/contact-persons/contact-persons.component';
import {RemarksComponent} from '../client-new/components/remarks/remarks.component';
import {AddressTypeEnum, PAYMENT_MODES, PAYMENT_TERMS, PaymentTypesEnum} from '../../helpers/helper-file';
import {InventoryItemService} from '../../features/inventory/services/inventory.service';
import {addDays, addMonths, endOfMonth} from 'date-fns';
import {Subject, debounceTime, distinctUntilChanged} from 'rxjs';
import {CustomerService} from '../../features/customers/services/customer.service';
import {HttpClient} from '@angular/common/http';
import {EmployeeService} from '../../features/employees/services/employee.service';
import {ConfirmDialog} from '../../shared/components/confirm-dialog/confirm-dialog';
import {customEmailValidator, nonNegativeValidator} from '../../shared/services/validations';
import {UploadFileService} from '../../shared/services/file-upload.service';

@Component({
    selector: 'app-invoice-new',
    standalone: true,
    imports: [
        CommonModule,
        LayoutComponent,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMatSelectSearchModule,
    ],
    templateUrl: './invoice-new.component.html',
    styleUrl: './invoice-new.component.scss'
})
export class InvoiceNewComponent implements OnInit {
    public arr = [
        1,
        2,
        3,
        4,
        5,
        6,
        7
    ];
    filter : {} = {};
    search : string = '';
    loading : boolean = false;
    invoiceForm : FormGroup;
    invoiceData : any;
    invoiceId : number | null = null;
    public partnersData : any[] = [];
    public projectData : any[] = [];
    public itemData : any[] = [];
    public employeesData : any[] = [];
    public depositAccountData : any[] = [];
    readonly dialog = inject(MatDialog);
    paymentModes = PAYMENT_MODES;
    paymentTermsValue = PAYMENT_TERMS;
    isManualDateChange = true;
    private searchSubject = new Subject<string>();
    public selectedBillingAddress : any = {};
    public selectedShippingAddress : any = {};
    showIsPaymentCheckbox = false;
    showPaymentTable = false;
    public billingAddressList : any[];
    public shippingAddressList : any[];
    selectedCustomerId : any;

    file : any = null;
    csvFile : File[] = [];
    url : string = '';
    fileDetailsArray : {
        fileName: string;
        fileUrl: string;
        id?: number;
        isNew: boolean;
    }[] = [];

    @ViewChild('fileInput')fileInput !: ElementRef;
    currentFiles : File[] = [];

    private status : 'DRAFT' | 'SENT' = 'SENT';

    constructor(private invoiceService : InvoiceService, private fb : FormBuilder, private toastr : ToastrService, private route : ActivatedRoute, private router : Router, private itemService : InventoryItemService, private employeeService : EmployeeService, private documentUploadService : UploadFileService) {}

    ngOnInit(): void {
        this.initForm();
        this.fetchPartners();
        this.fetchAccountsDeposit();
        this.fetchItems();
        this.fetchEmployees(this.search, this.filter);
        this.generateInvoiceNumber();

        this.invoiceForm.get('partnerId') ?. valueChanges.subscribe((partnerId) => {
            this.setAddresses(partnerId);
        });

        this.route.paramMap.subscribe((params) => {
            const id = params.get('id');
            if (id) {
                this.invoiceId = + id;
                this.loadInvoiceData();
            }
        });

        if (!this.invoiceId) {
            this.addItem();
        }
        if (!this.invoiceId) {
            this.addPayment();
        }

        this.searchSubject.pipe(debounceTime(600), distinctUntilChanged()).subscribe((searchTerm) => {
            this.fetchPartners(searchTerm);
        });

        this.invoiceForm.get('paymentTerm') ?. valueChanges.subscribe((term) => {
            this.onPaymentTermChange(term);
        });

        this.invoiceForm.get('dueDate') ?. valueChanges.subscribe((selectedDate) => {
            if (this.isManualDateChange && selectedDate) {
                this.invoiceForm.get('paymentTerm') ?. setValue('Custom', {emitEvent: false});
            }
            this.isManualDateChange = true;
        });

        this.items.valueChanges.subscribe(() => {
            this.getTotalAmount();
        });
    }

    initForm(): void {
        this.invoiceForm = this.fb.group({
            id: [this.invoiceData ?. id || ''],
            partnerId: [
                this.invoiceData ?. partnerId,
                Validators.required
            ],
            // employeeId: [''],
            shippingAddressId: this.selectedShippingAddress.id,
            billingAddressId: this.selectedBillingAddress.id,
            invoiceNo: [
                this.invoiceData ?. invoiceNo,
                Validators.required
            ],
            orderNo: [this.invoiceData ?. orderNo],
            invoiceDate: [
                this.invoiceData ?. invoiceDate,
                Validators.required
            ],
            paymentTerm: [
                this.invoiceData ?. paymentTerm,
                Validators.required
            ],
            dueDate: [
                this.invoiceData ?. dueDate,
                Validators.required
            ],
            subject: [''],
            items: this.fb.array([]),
            payments: this.fb.array([]),
            invoiceAttachments: this.fb.array([]),
            customerNote: [''],
            isPayment: [false]
        });
    }

    onSearch(event : Event) {
        const input = event.target as HTMLInputElement;
        const searchTerm = input.value;
        this.searchSubject.next(searchTerm);
    }

    onCustomerSelected(event : MatSelectChange) {
        const selectedCustomerId = event.value;
        this.selectedCustomerId = selectedCustomerId;

        // this.dialog.open(SelectBillingAddressDialog, {
        // data: { selectedCustomerId },
        // });
    }

    get items(): FormArray {
        return this.invoiceForm.get('items')as FormArray;
    }

    get payments(): FormArray {
        return this.invoiceForm.get('payments')as FormArray;
    }

    generateInvoiceNumber(): void {
        const randomSuffix = Math.floor(1000 + Math.random() * 9000);
        const invoiceNo = `INV${randomSuffix}`;
        this.invoiceForm.patchValue({invoiceNo});
    }

    getTotalAmount(): number {
        return this.items.controls.reduce((total, itemGroup) => {
            return total + (itemGroup.get('amount') ?. value || 0);
        }, 0);
    }

    onPaymentTermChange(term : string): void {
        const currentDate = new Date();
        let dueDate: Date |null = null;

        switch (term) {
            case 'Net 15': dueDate = addDays(currentDate, 15);
                break;
            case 'Net 30': dueDate = addDays(currentDate, 30);
                break;
            case 'Net 45': dueDate = addDays(currentDate, 45);
                break;
            case 'Net 60': dueDate = addDays(currentDate, 60);
                break;
            case 'Due On Receipt': dueDate = currentDate;
                break;
            case 'Due end of the month': dueDate = endOfMonth(currentDate);
                break;
            case 'Due end of next month': dueDate = endOfMonth(addMonths(currentDate, 1));
                break;
            case 'Custom': dueDate = null;
                break;
            default: dueDate = null;
        }
        if (dueDate) {
            this.isManualDateChange = false;
            this.invoiceForm.get('dueDate') ?. setValue(dueDate);
        }
    }
    addItem(): void {
        const itemGroup = this.fb.group({
            itemId: [
                '', Validators.required
            ],
            quantity: [
                '1',
                [
                    Validators.required, Validators.pattern(/^\d+(\.\d+)?$/), nonNegativeValidator,
                ],
            ],
            rate: [
                '0',
                [
                    Validators.required, nonNegativeValidator
                ]
            ],
            discount: [0],
            amount: [
                0,
                [
                    Validators.required, nonNegativeValidator
                ]
            ]
        });

        this.items.push(itemGroup);

        itemGroup.get('itemId') ?. valueChanges.subscribe((itemId) => {
            const selectedItem = this.itemData.find((item) => item.id === itemId);
            if (selectedItem) {
                itemGroup.patchValue({rate: selectedItem.sellingPrice.toString(), quantity: '1'});
                this.calculateAmount(itemGroup);
            }
        });

        itemGroup.get('rate') ?. valueChanges.subscribe(() => this.calculateAmount(itemGroup));
        itemGroup.get('quantity') ?. valueChanges.subscribe(() => this.calculateAmount(itemGroup));
        itemGroup.get('discount') ?. valueChanges.subscribe(() => this.calculateAmount(itemGroup));
    }

    calculateAmount(itemGroup : FormGroup): void {
        const rate = parseFloat(itemGroup.get('rate') ?. value || '0');
        const quantity = parseFloat(itemGroup.get('quantity') ?. value || '0');
        const discount = itemGroup.get('discount') ?. value || 0;
        let amount = rate * quantity;
        if (discount > 0) {
            amount -= (amount * discount) / 100;
        }
        itemGroup.patchValue({amount});
    }

    addPayment(): void {
        const paymentData = this.fb.group({
            paymentMode: [''],
            depositId: [''],
            amount: [0, Validators.pattern(/^\d+(\.\d+)?$/)]
        });
        this.payments.push(paymentData);
    }

    setAddresses(partnerId : number): void {
        const selectedPartner = this.partnersData.find((p) => p.id === partnerId);
        if (selectedPartner && selectedPartner.addresses) {
            this.selectedBillingAddress = selectedPartner.addresses.find((addr : any) => addr.addressType === 'BILLING');
            console.log('Selected billing address---', this.selectedBillingAddress);

            this.selectedShippingAddress = selectedPartner.addresses.find((addr : any) => addr.addressType === 'SHIPPING');
            console.log('Selected shipping address---', this.selectedShippingAddress);

            this.invoiceForm.patchValue({
                billingAddressId: this.selectedBillingAddress ?. id,
                shippingAddressId: this.selectedShippingAddress ?. id
            });
        }
    }

    private loadInvoiceData(): void {
        if (this.invoiceId !== null) {
            this.invoiceService.fetchInvoiceById(this.invoiceId).subscribe({
                next: (data) => {
                    this.invoiceData = data;

                    // Pre-populate the form with invoice data
                    this.invoiceForm.patchValue({
                        partnerId: this.invoiceData.partnerId,
                        shippingAddressId: this.invoiceData.shippingAddressId,
                        billingAddressId: this.invoiceData.billingAddressId,
                        invoiceNo: this.invoiceData.invoiceNo,
                        orderNo: this.invoiceData.orderNo,
                        invoiceDate: this.invoiceData.invoiceDate ? new Date(Number(this.invoiceData.invoiceDate)) : null,
                        paymentTerm: this.invoiceData.paymentTerm,
                        dueDate: this.invoiceData.dueDate ? new Date(Number(this.invoiceData.dueDate)) : null,
                        subject: this.invoiceData.subject,
                        customerNote: this.invoiceData.customerNote,
                        isPayment: this.invoiceData.isPayment
                    });

                    // Set the selected partner and addresses
                    this.setAddresses(this.invoiceData.partnerId);

                    // Populate items with change detection on each field
                    if (data.invoiceItems) {
                        data.invoiceItems.forEach((item : any) => {
                            const itemForm = this.fb.group({
                                itemId: [
                                    item.itemId, Validators.required
                                ],
                                quantity: [
                                    item.quantity, Validators.required
                                ],
                                rate: [
                                    item.rate, Validators.required
                                ],
                                discount: [
                                    item.discount, Validators.pattern(/^\d+(\.\d+)?$/)
                                ],
                                amount: [
                                    item.amount,
                                    [
                                        Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)
                                    ],
                                ]
                            });

                            // Add the item to the form array
                            this.items.push(itemForm);
                            this.calculateAmount(itemForm);

                            itemForm.get('rate') ?. valueChanges.subscribe(() => this.calculateAmount(itemForm));
                            itemForm.get('quantity') ?. valueChanges.subscribe(() => this.calculateAmount(itemForm));
                            itemForm.get('discount') ?. valueChanges.subscribe(() => this.calculateAmount(itemForm));
                        });
                    }

                    // initialize invoice attachments if available

                    // Populate payments if they exist
                    if (this.invoiceData.payments) {
                        this.invoiceData.payments.forEach((payment : any) => {
                            this.addPayment();
                            const paymentGroup = this.payments.at(this.payments.length - 1);
                            paymentGroup.patchValue({paymentMode: payment.paymentMode, depositId: payment.depositId, amount: payment.amount});
                        });
                    }
                },
                error: (error) => console.error(error)
            });
        }
    }

    onFileSelected(event : Event): void {
        const input = event.target as HTMLInputElement;
        if (input.files) {
            const newFiles = Array.from(input.files);

            newFiles.forEach((newFile) => { // Prevent duplicates based on name and size
                if (!this.fileDetailsArray.some((file) => file.fileName === newFile.name && file.isNew)) {
                    this.fileDetailsArray.push({fileName: newFile.name, fileUrl: '', isNew: true});
                }
            });

            // Upload the new files
            this.uploadDocuments(newFiles);
        }
    }

    uploadDocuments(files : File[]): void {
        files.forEach((file : File) => {
            this.documentUploadService.uploadDocument(file, 'journals').subscribe({
                next: (response) => {
                    const uploadedFile = this.fileDetailsArray.find((f) => f.fileName === file.name && f.isNew);
                    if (uploadedFile) {
                        uploadedFile.fileUrl = response.url;
                    }
                },
                error: (error) => {
                    this.toastr.error(error, 'File upload failed');
                }
            });
        });
        this.fileInput.nativeElement.value = '';
    }

    removeFile(index : number): void {
        const file = this.fileDetailsArray[index];
        if (file.id) { // Existing file with ID - call API to remove
            this.onRemoveitemAttachment(file.id);
        } else { // New file - simply remove from array
            this.fileDetailsArray.splice(index, 1);
        }
    }

    onRemoveitemAttachment(invoiceAttachmentId : number): void {
        const dialogRef = this.dialog.open(ConfirmDialog);
        dialogRef.afterClosed().subscribe((response) => {
            if (response === true) {
                this.invoiceService.removeInvoiceAttachmentById(invoiceAttachmentId).subscribe({
                    next: () => {
                        this.fileDetailsArray = this.fileDetailsArray.filter((file) => file.id !== invoiceAttachmentId);
                    },
                    error: () => {
                        this.toastr.error('Failed to remove journal attachment.');
                    }
                });
            }
        });
    }

    saveAsDraft(): void {
        this.status = 'DRAFT';
        this.createInvoice('invoice/invoice-details');
    }

    saveAndSend(): void {
        this.status = 'DRAFT';
        this.createInvoice('invoice/send-mail');
    }

    createInvoice(navigateTo : string): void {
        if (this.invoiceForm.invalid) {
            this.invoiceForm.markAllAsTouched();
            this.toastr.warning('Please fill all mandatory fields.');
            return;
        }
        const formValues = this.invoiceForm.value;

        const createInvoiceInput = {
            partnerId: formValues.partnerId,
            billingAddressId: formValues.billingAddressId,
            shippingAddressId: formValues.shippingAddressId,
            invoiceNo: formValues.invoiceNo,
            orderNo: formValues.orderNo,
            invoiceDate: formValues.invoiceDate,
            dueDate: formValues.dueDate,
            paymentTerm: formValues.paymentTerm,
            subject: formValues.subject,
            customerNote: formValues.customerNote,
            isPayment: formValues.isPayment
        };

        const createInvoiceItemInput = this.invoiceForm.value.items.filter((item : any) => !item.id);
        const loginEmployee: any = localStorage.getItem('loggedInUser');
        const employee = JSON.parse(loginEmployee);
        const employeeId = employee.employeeId;

        // const createInvoiceAttachmentInput = [
        // { fileUrl: 'https://example.com/invoice/attachment1.pdf' },
        // ];

        const createInvoiceAttachmentInput = this.fileDetailsArray.filter((file) => !file.id) // New files without ID go to createJournalAttachments.map((file) => ({fileName: file.fileName, fileUrl: file.fileUrl}));

        const updateInvoiceAttachmentInput = [{
                fileUrl: 'https://example.com/invoice/attachment1.pdf'
            },];

        // const updateInvoiceAttachmentInput = this.fileDetailsArray
        // .filter((file) => file.id) // Files with ID go to updateJournalAttachments
        // .map((file) => ({
        //     id: file.id,
        //     fileName: file.fileName,
        //     fileUrl: file.fileUrl,
        // }));

        const invoiceUpdateInput = {
            id: this.invoiceId,
            ... createInvoiceInput
        };

        if (this.invoiceId) {
            this.invoiceService.updateInvoice(invoiceUpdateInput, employeeId).subscribe({
                next: (response) => {
                    this.loading = false;
                    this.toastr.success('Invoice updated successfully.');
                    const updatedInvoiceId = response ?. data ?. updateInvoice ?. id;
                    if (updatedInvoiceId) {
                        this.router.navigate([`${navigateTo}/${updatedInvoiceId}`]);
                    } else {
                        console.error('Invoice ID is missing');
                    }
                },
                error: (error) => {
                    this.loading = false;
                    console.error('Error updating invoice:', error);
                    this.toastr.error(error.message || 'Failed to update invoice.');
                }
            });
        } else {
            this.invoiceService.createInvoice(createInvoiceInput, createInvoiceItemInput, createInvoiceAttachmentInput, employeeId).subscribe({
                next: (response) => {
                    this.loading = false;
                    this.toastr.success('Invoice created successfully');
                    console.log('Invoice creation response:', response);
                    // this.router.navigate([navigateTo]);
                    const createdInvoiceId = response ?. data ?. createInvoice ?. id;
                    if (createdInvoiceId) {
                        this.router.navigate([`${navigateTo}/${createdInvoiceId}`]);
                    } else {
                        console.error('Invoice ID is missing');
                    }
                },
                error: (error) => {
                    this.loading = false;
                    this.toastr.error(error.message || 'Failed to create invoice.');
                }
            });
        }
    }

    onCancel(): void {
        this.invoiceForm.reset();
    }

    onRemoveInvoice(index : number): void {
        const itemId = this.items.at(index).get('itemId') ?. value;
        if (itemId) {
            const dialogRef = this.dialog.open(ConfirmDialog);
            dialogRef.afterClosed().subscribe((response) => {
                if (response === true) {
                    this.removeItem(itemId, index);
                }
            });
            console.log(`Removing item with ID: ${itemId}`);
        } else {
            console.error('Item ID is null or undefined');
            this.items.removeAt(index);
        }
    }

    private removeItem(itemId : number, index : number): void {
        this.itemService.removeItemById(itemId).subscribe({
            next: () => {
                console.log('Journal Entry removed successfully');
                // Remove the entry from the form array
                this.items.removeAt(index);
            },
            error: () => {
                console.log('Failed to remove journal entry');
            }
        });
    }

    private fetchPartners(search : string = '') {
        this.invoiceService.fetchClients(search).subscribe({
            next: (partners) => {
                this.partnersData = partners.filter((data : any) => data.partnerType === 'Customer').map((data : any) => ({
                    id: data ?. id || '--',
                    name: data ?. displayName || '--'
                }));

                this.billingAddressList = partners ?. addresses ?. filter((address : any) => address.addressType == 'BILLING');
                this.shippingAddressList = partners ?. addresses ?. filter((address : any) => address.addressType == 'SHIPPING');
                console.log('Billing----', this.billingAddressList);
                console.log('Shipping----', this.shippingAddressList);
            },
            error: (error) => console.error(error)
        });
    }

    private fetchEmployees(search : string = '', filter : {}) {
        this.employeeService.getEmployees(search, filter).subscribe({
            next: (employees) => {
                this.employeesData = employees;
            },
            error: (error) => console.error(error)
        });
    }

    private fetchItems(search : string = '', filter : any = {}) {
        const updatedFilter = {
            ...filter,
            isSalesActive: true
        };

        this.itemService.fetchItems(search, updatedFilter).subscribe({
            next: (items) => {
                this.itemData = items;
            },
            error: (error) => console.error(error)
        });
    }

    private fetchAccountsDeposit(search : string = '') {
        this.invoiceService.fetchAccountsToDeposit(search).subscribe({
            next: (accounts) => {
                this.depositAccountData = accounts;
            },
            error: (error) => console.error(error)
        });
    }

    openCreateClientDialog() {
        const dialogRef = this.dialog.open(CreateClientDialog, {disableClose: true});

        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'success') {}
        });
    }
    openBillingAddressDialog(type : any) {
        const selectedClientId = this.invoiceForm.get('partnerId') ?. value;
        this.dialog.open(BillingAddressDialog, {
            width: '400px',
            data: {
                clientId: selectedClientId,
                type
            }
        });
    }
    openShippingAddressDialog(type : any) {
        const selectedClientId = this.invoiceForm.get('partnerId') ?. value;
        this.dialog.open(ShippingAddressDialog, {
            width: '400px',
            data: {
                clientId: selectedClientId,
                type
            }
        });
    }

    openSelectBillingAddressDialog() { // this.dialog.open(SelectBillingAddressDialog);
        const selectedCustomerId = this.selectedCustomerId;
        const type = 'BILLING';

        this.dialog.open(SelectBillingAddressDialog, {
            data: {
                selectedCustomerId,
                type
            }
        });
    }
    openSelectShippingAddressDialog() { // this.dialog.open(SelectShippingAddressDialog);
        const selectedCustomerId = this.selectedCustomerId;
        const type = 'SHIPPING';

        this.dialog.open(SelectShippingAddressDialog, {
            data: {
                selectedCustomerId,
                type
            }
        });
    }
}

@Component({
    selector: 'create-client-dialog',
    templateUrl: 'create-client-dialog.component.html',
    styleUrls: ['./invoice-new.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        ClientNewComponent,
        ReactiveFormsModule,
        FormsModule,
        MatInputModule,
        MatSelectModule,
        MatTabsModule,
        OtherDetailsComponent,
        AddressComponent,
        ContactPersonsComponent,
        RemarksComponent,
    ]
})
export class CreateClientDialog {
    @ViewChild(OtherDetailsComponent)
    otherDetailsComponent !: OtherDetailsComponent;
    @ViewChild(AddressComponent)addressComponent !: AddressComponent;
    @ViewChild(ContactPersonsComponent)
    contactPersonsComponent !: ContactPersonsComponent;
    @ViewChild(RemarksComponent)remarksComponent !: RemarksComponent;
    newCustomerForm : FormGroup;
    customerData : any = {};
    addressDetails : any;
    contactDetails : any;
    remarkDetails : any;
    documentDetails : any = {};
    fileName : any;
    contacts : any;
    customerId : any;

    constructor(private fb : FormBuilder, private customerService : CustomerService, private toastr : ToastrService, private route : ActivatedRoute, private dialogRef : MatDialogRef < CreateClientDialog >) {}

    ngOnInit() {
        this.initForm();
        this.route.paramMap.subscribe((params) => {
            this.customerId = params.get('id');
            if (this.customerId) {
                this.fetchPartnerById();
            }
        });
    }

    initForm() {
        if (this.customerData && this.contacts) {
            this.newCustomerForm = this.fb.group({
                id: Number(this.customerId),
                customerType: [
                    this.customerData.customerType || '',
                    Validators.required,
                ],
                primaryContact: [this.contacts ?. salutation || 'Mr'],
                firstName: [this.contacts ?. firstName || ''],
                lastName: [this.contacts ?. lastName || ''],
                companyName: [this.customerData.companyName || ''],
                displayName: [
                    this.customerData.displayName || '',
                    Validators.required
                ],
                email: [
                    this.customerData.email || '',
                    [
                        Validators.required, customEmailValidator()
                    ],
                ],
                phone: [
                    this.customerData.phone || '',
                    Validators.required
                ],
                mobile: [
                    this.customerData.mobile || '',
                    Validators.required
                ],
                emiratesId: [this.customerData.emirateId || ''],
                currency: [this.customerData.currency || 'AED'],
                openingBalance: [this.customerData.openingBalance || 0],
                paymentTerms: [this.customerData.paymentTerms || 'Net 30'],
                file: [this.fileName ? this.fileName : null]
            });
        } else {
            this.newCustomerForm = this.fb.group({
                customerType: [
                    'Bussiness', Validators.required
                ],
                primaryContact: ['Mr'],
                firstName: [
                    '', Validators.required
                ],
                lastName: [
                    '', Validators.required
                ],
                companyName: [''],
                displayName: [
                    '', Validators.required
                ],
                email: [
                    '',
                    [
                        Validators.required, customEmailValidator()
                    ]
                ],
                phone: [
                    '',
                    [
                        Validators.required,
                        Validators.minLength(10), // Minimum length for phone number
                        Validators.maxLength(15), // Maximum length for phone number
                        Validators.pattern('^[0-9]+$'), // Only allows numeric values
                    ],
                ],
                mobile: [
                    '',
                    [
                        Validators.required, Validators.minLength(10), // Minimum length for phone number
                        Validators.maxLength(15), // Maximum length for phone number
                        Validators.pattern('^[0-9]+$'),
                    ],
                ],
                emiratesId: [''],
                currency: [''],
                openingBalance: [0],
                paymentTerms: [''],
                file: [null]
            });
        }

        this.newCustomerForm.valueChanges.subscribe((formValue) => {
            this.contactPersonsComponent.updateFirstContactPerson(formValue.primaryContact, formValue.firstName, formValue.lastName, formValue.email, formValue.phone, formValue.mobile);
        });
    }

    fetchPartnerById() {
        this.customerService.fetchPartnerById(Number(this.customerId)).subscribe({
            next: (data) => {
                this.customerData = data;
                if (this.customerData.documents && this.customerData.documents.length > 0) {
                    this.fileName = this.customerData.documents[0].documentName;
                }
                if (this.customerData.contacts && this.customerData.contacts.length > 0) {
                    this.customerData.contacts.map((contact : any) => {
                        if (contact ?. primaryContact) {
                            this.contacts = contact;
                        }
                    });
                }
                this.initForm();
            },
            error: (error) => {
                console.error(error, 'Error');
            }
        });
    }

    async collectOtherDetails(data : any) { // this.otherDetailsData = data;
        const newCustomerData = {
            ...this.customerData
        };
        const allData = {
            ...this.customerData
        };
        newCustomerData.otherDetails = data;
        this.customerData = newCustomerData;
        console.log('this.customerData', allData);

        if (this.customerData ?. documents ?. length > 0) {
            this.documentDetails = {
                id: Number(this.customerData ?. documents[0] ?. id),
                documentName: data ?. fileData ?. documentName,
                documentUrl: data ?. fileData ?. documentUrl
            };
        } else if (data ?. fileData ?. documentName) {
            this.documentDetails = {
                documentName: data ?. fileData ?. documentName,
                documentUrl: data ?. fileData ?. documentUrl
            };
        } else {
            this.documentDetails = {};
        }
        await this.newCustomerForm.patchValue({emiratesId: this.customerData.otherDetails.emiratesId, currency: this.customerData.otherDetails.currency, openingBalance: this.customerData.otherDetails.openingBalance, paymentTerms: this.customerData.otherDetails.paymentTerms});
    }

    collectAddress(data : any) { // Initialize addressDetails if it doesn't exist
        this.customerData.addressDetails = this.customerData.addressDetails || {};

        // Assign address based on type
        this.customerData.addressDetails[data.type] = data.value;

        // Collect addresses into an array
        this.addressDetails = [
            ...(this.customerData.addressDetails.billing ? [this.customerData.addressDetails.billing] : []),
            ...(this.customerData.addressDetails.shipping ? [this.customerData.addressDetails.shipping] : []),
        ];
    }

    collectContactDetails(data : any) {
        this.contactDetails = data.contactPersons;
    }

    collectRemarkDetails(data : any) {
        this.remarkDetails = data;
    }

    async onSubmit() {
        if (this.newCustomerForm.invalid) {
            this.newCustomerForm.markAllAsTouched();
            return;
        }
        await this.otherDetailsComponent.onSubmit();
        this.addressComponent.onSubmitBilling();
        this.addressComponent.onSubmitShipping();
        this.contactPersonsComponent.onSubmit();
        this.remarksComponent.onSubmit();

        console.log('onSubmit', this.newCustomerForm.value);

        const customerDetails = {
            partnerType: 'Customer',
            customerType: this.newCustomerForm.value.customerType,
            displayName: this.newCustomerForm.value.displayName,
            companyName: this.newCustomerForm.value.companyName,
            phone: this.newCustomerForm.value.phone,
            email: this.newCustomerForm.value.email,
            mobile: this.newCustomerForm.value.mobile,
            currency: this.newCustomerForm.value.currency,
            emirateID: this.newCustomerForm.value.emiratesId,
            openingBalance: this.newCustomerForm.value.openingBalance,
            // paymentTerms: this.newCustomerForm.value.paymentTerms,
            paymentStatus: 'Pending'
        };

        const loginEmployee: any = localStorage.getItem('loggedInUser');
        const employee = JSON.parse(loginEmployee);
        const employeeId = employee ?. employeeData ?. id;
        if (this.customerId && this.customerData) {
            const customerDetails = {
                id: Number(this.customerId),
                partnerType: 'Customer',
                customerType: this.newCustomerForm.value.customerType,
                displayName: this.newCustomerForm.value.displayName,
                companyName: this.newCustomerForm.value.companyName,
                phone: this.newCustomerForm.value.phone,
                email: this.newCustomerForm.value.email,
                mobile: this.newCustomerForm.value.mobile,
                currency: this.newCustomerForm.value.currency,
                emirateID: this.newCustomerForm.value.emiratesId,
                openingBalance: this.newCustomerForm.value.openingBalance,
                // paymentTerms: this.newCustomerForm.value.paymentTerms,
                paymentStatus: 'Pending'
            };
            if (!this.remarkDetails ?. id) {
                this.remarkDetails = {};
            }
            this.customerService.updatePartner(customerDetails, this.contactDetails, this.addressDetails, this.remarkDetails, employeeId, this.documentDetails).subscribe({
                next: (data) => {
                    if (data) {
                        this.toastr.success('Customer updated successfully', 'Success');
                        this.onCancel();
                        this.dialogRef.close();
                    }
                },
                error: (error) => this.toastr.error(error, 'Error')
            });
        } else {
            this.customerService.createPartner(customerDetails, this.contactDetails, this.addressDetails, this.remarkDetails, this.documentDetails, employeeId).subscribe({
                next: (data) => {
                    this.toastr.success('Customer created successfully', 'Success');
                    this.dialogRef.close();
                    this.onCancel();
                },
                error: (error) => this.toastr.error(error, 'Error')
            });
        }
    }

    onCancel() {
        this.newCustomerForm.reset();
        this.otherDetailsComponent.onCancel();
        this.addressComponent.onCancel();
        this.addressComponent.onCancel();
        this.contactPersonsComponent.onCancel();
        this.remarksComponent.onCancel();
    }
}

@Component({
    selector: 'billing-address-dialog',
    templateUrl: 'billing-address-dialog.component.html',
    styleUrls: ['./invoice-new.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        CommonModule,
        MatInputModule,
        MatSelectModule,
    ]
})
export class BillingAddressDialog implements OnInit {
    billingAddressForm : FormGroup;
    @Input()customerData : any = {};
    @Output()formSubmit = new EventEmitter<any>();
    search : string | undefined;
    filter : {};
    currencyData : any;
    file : any;
    customerId : any;
    countries : any[] = [];
    addressType = AddressTypeEnum;

    constructor(private fb : FormBuilder, private http : HttpClient, private customerService : CustomerService, private route : ActivatedRoute, private toastr : ToastrService, private dialogRef : MatDialogRef < BillingAddressDialog >, private invoiceService : InvoiceService, @Inject(MAT_DIALOG_DATA)public data : {
        clientId: number;
        type: any
    }) {
        this.customerId = data.clientId;
    }

    ngOnInit(): void {
        this.initForm();
        this.populateFormWithCustomerData();
        this.loadCountries();
        this.fetchPartnerById();
    }

    initForm() {
        this.billingAddressForm = this.fb.group({
            attention: [''],
            addressType: ['BILLING'],
            addressLine1: [''],
            addressLine2: [''],
            city: [''],
            state: [''],
            country: [''],
            pinCode: [''],
            fax: [''],
            phone: ['']
        });
    }

    loadCountries(): void {
        this.http.get<any[]>('assets/json/countries.json').subscribe((data) => {
            this.countries = data;
        }, (error) => {
            console.log('Error loading country data----', error);
        });
    }

    fetchPartnerById() {
        this.customerService.fetchPartnerById(Number(this.customerId)).subscribe({
            next: (data) => {
                this.customerData = data;
                console.log(this.customerData);
                const type = this.data ?. type;
                if (type != 'add') {
                    this.populateFormWithCustomerData();
                }
            },
            error: (error) => {
                console.error(error, 'Error');
            }
        });
    }

    populateFormWithCustomerData() {
        console.log(this.customerData);

        if (this.customerData && this.customerData.addresses) {
            this.customerData.addresses.map((address : any) => {
                if (address.addressType === 'BILLING') {
                    this.billingAddressForm.patchValue({
                        id: Number(address.id),
                        attention: address.attention || '',
                        addressLine1: address.addressLine1 || '',
                        addressLine2: address.addressLine2 || '',
                        city: address.city || '',
                        state: address.state || '',
                        country: address.country || '',
                        pinCode: address.pinCode || '',
                        fax: address.fax || '',
                        phone: address.phone || ''
                    });
                }
            });
        }
    }

    onCancel() {
        this.billingAddressForm.reset();
    }

    onSubmitBillingAddress() {
        if (this.billingAddressForm.valid) {
            const value = this.billingAddressForm.value;
            const shippingFormValue = {
                partnerId: this.customerId,
                attention: value ?. attention,
                addressType: this.addressType.billing,
                addressLine1: value ?. addressLine1,
                addressLine2: value ?. addressLine2,
                city: value ?. city,
                state: value ?. state,
                country: value ?. country,
                pinCode: value ?. pinCode,
                phone: value ?. phone,
                fax: value ?. fax
            };

            this.invoiceService.createAddress(shippingFormValue).subscribe({
                next: () => {
                    this.toastr.success('Create Address Successfully');
                    this.dialogRef.close();
                },
                error: (error) => {
                    this.toastr.error(error.message || 'Failed to Create Address.');
                }
            });
        }
    }

}

@Component({
    selector: 'shipping-address-dialog',
    templateUrl: 'shipping-address-dialog.component.html',
    styleUrls: ['./invoice-new.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        CommonModule,
        MatInputModule,
        MatSelectModule,
    ]
})
export class ShippingAddressDialog implements OnInit {
    shippingAddressForm : FormGroup;
    @Input()customerData : any = {};
    @Output()formSubmit = new EventEmitter<any>();
    search : string | undefined;
    filter : {};
    currencyData : any;
    file : any;
    customerId : number;
    countries : any[] = [];
    addressType = AddressTypeEnum;

    constructor(private fb : FormBuilder, private http : HttpClient, private customerService : CustomerService, private route : ActivatedRoute, private toastr : ToastrService, private dialogRef : MatDialogRef < BillingAddressDialog >, private invoiceService : InvoiceService, @Inject(MAT_DIALOG_DATA)public data : {
        clientId: number;
        type: any
    }) {
        this.customerId = data.clientId;
    }

    ngOnInit(): void {
        this.initForm();
        this.populateFormWithCustomerData();
        this.loadCountries();
        this.fetchPartnerById();
    }

    initForm() {
        this.shippingAddressForm = this.fb.group({
            attention: [''],
            addressType: ['SHIPPING'],
            addressLine1: [''],
            addressLine2: [''],
            city: [''],
            state: [''],
            country: [''],
            pinCode: [''],
            fax: [''],
            phone: ['']
        });
    }

    loadCountries(): void {
        this.http.get<any[]>('assets/json/countries.json').subscribe((data) => {
            this.countries = data;
        }, (error) => {
            console.log('Error loading country data----', error);
        });
    }

    fetchPartnerById() {
        this.customerService.fetchPartnerById(Number(this.customerId)).subscribe({
            next: (data) => {
                this.customerData = data;
                console.log(this.customerData);
                const type = this.data ?. type;
                if (type != 'add') {
                    this.populateFormWithCustomerData();
                }
            },
            error: (error) => {
                console.error(error, 'Error');
            }
        });
    }

    populateFormWithCustomerData() {
        console.log(this.customerData);

        if (this.customerData && this.customerData.addresses) {
            this.customerData.addresses.map((address : any) => {
                if (address.addressType === 'SHIPPING') {
                    this.shippingAddressForm.patchValue({
                        id: Number(this.customerId),
                        attention: address.attention || '',
                        addressLine1: address.addressLine1 || '',
                        addressLine2: address.addressLine2 || '',
                        city: address.city || '',
                        state: address.state || '',
                        country: address.country || '',
                        pinCode: address.pinCode || '',
                        fax: address.fax || '',
                        phone: address.phone || ''
                    });
                }
            });
        }
    }

    onSubmitShippingAddress() {
        if (this.shippingAddressForm.valid) {
            const value = this.shippingAddressForm.value;
            const shippingFormValue = {
                partnerId: this.customerId,
                attention: value ?. attention,
                addressType: this.addressType.shipping,
                addressLine1: value ?. addressLine1,
                addressLine2: value ?. addressLine2,
                city: value ?. city,
                state: value ?. state,
                country: value ?. country,
                pinCode: value ?. pinCode,
                phone: value ?. phone,
                fax: value ?. fax
            };

            this.invoiceService.createAddress(shippingFormValue).subscribe({
                next: () => {
                    this.toastr.success('Create Address Successfully');
                    this.dialogRef.close();
                },
                error: (error) => {
                    this.toastr.error(error.message || 'Failed to Create Address.');
                }
            });
        }
    }

    onCancel() {
        this.shippingAddressForm.reset();
    }

}

@Component({
    selector: 'select-address-dialog',
    templateUrl: 'select-billing-address-dialog.html',
    styleUrls: ['./invoice-new.component.scss'],
    standalone: true,
    imports: [MatDialogModule, CommonModule]
})
export class SelectBillingAddressDialog {
    billingAddressList : any[];
    readonly dialog = inject(MatDialog);
    selectedAddressId : number | null = null;

    constructor(private customerService : CustomerService, private dialogRef : MatDialogRef < SelectBillingAddressDialog >, @Inject(MAT_DIALOG_DATA)public data : {
        selectedCustomerId: any;
        type: any
    }) {}

    ngOnInit(): void {
        this.fetchPartners();
    }

    // Set the selected address ID
    selectAddress(id : number): void {
        this.selectedAddressId = id;
        this.dialogRef.close();
    }

    openBillingAddressDialog(type : any) { // Close the current dialog
        this.dialogRef.close();

        // Open the new ShippingAddressDialog
        this.dialog.open(BillingAddressDialog, {
            width: '400px',
            data: {
                clientId: this.data ?. selectedCustomerId,
                type
            }
        });
    }

    private fetchPartners() {
        const id = this.data ?. selectedCustomerId;
        const type = this.data ?. type;

        this.customerService.fetchPartnerById(id).subscribe({
            next: (partners) => { // this.billingAddressList = partners?.addresses;
                this.billingAddressList = partners ?. addresses ?. filter((address : any) => address.addressType == type);
            },
            error: (error) => console.error(error)
        });
    }
}

@Component({
    selector: 'select-address-dialog',
    templateUrl: 'select-shipping-address-dialog.html',
    styleUrls: ['./invoice-new.component.scss'],
    standalone: true,
    imports: [MatDialogModule, CommonModule]
})
export class SelectShippingAddressDialog {
    shippingAddressList : any[];
    selectedAddressId : number | null = null;

    readonly dialog = inject(MatDialog);

    constructor(private customerService : CustomerService, private dialogRef : MatDialogRef < SelectShippingAddressDialog >, @Inject(MAT_DIALOG_DATA)public data : {
        selectedCustomerId: any;
        type: any
    }) {}

    ngOnInit(): void {
        this.fetchPartners();
    }

    // Set the selected address ID
    selectAddress(id : number): void {
        this.selectedAddressId = id;
        this.dialogRef.close();
    }

    openShippingAddressDialog(type : any): void { // Close the current dialog
        this.dialogRef.close();

        // Open the new ShippingAddressDialog
        this.dialog.open(ShippingAddressDialog, {
            width: '400px',
            data: {
                clientId: this.data ?. selectedCustomerId,
                type
            }
        });
    }

    private fetchPartners() {
        const id = this.data ?. selectedCustomerId;
        const type = this.data ?. type;
        this.customerService.fetchPartnerById(id).subscribe({
            next: (partners) => { // this.shippingAddressList = partners?.addresses;
                this.shippingAddressList = partners ?. addresses ?. filter((address : any) => address.addressType == type);
            },
            error: (error) => console.error(error)
        });
    }
}
