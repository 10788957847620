<div class="other-details-main">
    <form [formGroup]="otherDetailsForm" (ngSubmit)="onSubmit()">
        <div class="other-details-form-wrapper">
            <div class="form-wrapper">
                <label for="">Emirates ID</label>
                <mat-form-field>
                    <input matInput placeholder="Enter emirates ID" formControlName="emiratesId" />
                    <mat-error *ngIf="
                  otherDetailsForm.get('emiratesId')!.touched &&
                  otherDetailsForm.get('emiratesId')!.hasError('pattern')
                ">
                        Emirates ID must be 15 digits starting with 784.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-wrapper">
                <label for="">Currency</label>
                <mat-form-field>
                    <mat-select placeholder="Select Currency" formControlName="currency">
                        <mat-option *ngFor="let currency of currencyData" [value]="currency.currencyCode">
                            {{ currency?.currencyCode }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-wrapper">
                <label for="">Opening Balance</label>
                <mat-form-field>
                    <input matInput placeholder="AED" type="number" formControlName="openingBalance" />
                    <mat-error *ngIf="
                    otherDetailsForm.get('openingBalance')!.touched &&
                    otherDetailsForm.get('openingBalance')!.hasError('nonNegative')
                  ">
                        Opening balance must not be negative.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-wrapper">
                <label for="">Payment Terms</label>
                <mat-form-field>
                    <mat-select placeholder="Select Terms" formControlName="paymentTerm">
                        <mat-option value="Net 15">Net 15</mat-option>
                        <mat-option value="Net 30">Net 30</mat-option>
                        <mat-option value="Net 45">Net 45</mat-option>
                        <mat-option value="Net 60">Net 60</mat-option>
                        <mat-option value="Due on Receipt">Due on Receipt</mat-option>
                        <mat-option value="Due end of the month">Due end of the month</mat-option>
                        <mat-option value="Due end of next month">Due end of next month</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="upload-file-group">
            <h2>Documents</h2>
            <input #fileInput type="file" style="display: none" formControlName="file"
                (change)="onFileSelected($event)" accept=".pdf, .doc, .docx, image/*" />
            <button class="cmn-upload-btn" (click)="fileInput.click()">
                <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                <span>Upload File</span>
            </button>

            <!-- Display list of already uploaded documents -->
            <div class="uploaded-file" *ngIf="customerData?.documents?.length > 0">
                <h6 *ngFor="let document of customerData.documents">
                    <span (click)="viewDocument(document?.documentUrl)" style="cursor: pointer">
                        {{ document?.documentName }}
                    </span>
                    <img src="assets/images/icons/icon-close.svg" alt="Close Icon"
                        (click)="onRemoveDocument(document?.id)" />
                </h6>
            </div>

            <!-- Show newly selected file before it's uploaded -->
            <div class="uploaded-file" *ngIf="fileName && !customerId">
                <h6>
                    {{ fileName }}
                    <img src="assets/images/icons/icon-close.svg" alt="Close Icon" (click)="removeFile()" />
                </h6>
            </div>
        </div>

    </form>
</div>