<mat-dialog-content class="mat-typography">
    <section class="payroll-adjustment-dialog-wrapper">
        <h2 class="cmn-popup-title">Add {{data.deduction ? 'Deduction' : 'Reimbursement'}}</h2>
        <button class="cmn-close" mat-dialog-close></button>
        <!-- form div -->
        <div>
            <div class="payroll-form-wrapper">
                <div class="form-wrapper">
                    <mat-form-field>
                      <mat-select
                        placeholder="Select"
                      >
                        <mat-option value="TAX">TAX</mat-option>
                        <mat-option value="Bonus">Bonus</mat-option>
                        <mat-option value="Site allowance">Site allowance</mat-option>
                      </mat-select
                      >
                    </mat-form-field>
                  </div>
                  <div class="form-wrapper">
                    <mat-form-field>
                      <input
                        matInput
                        [matDatepicker]="picker"
                        placeholder="Date Range"
                      />
                      <mat-datepicker-toggle
                        matIconSuffix
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                  
                    </mat-form-field>
                  </div>
                <div class="form-wrapper">
                    <mat-form-field>
                        <input matInput placeholder="Project Name" />
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <mat-form-field>
                        <input matInput placeholder="Amount" />
                    </mat-form-field>
                </div>
                <div class="form-wrapper" *ngIf = "!data?.deduction">
                  <mat-form-field>
                      <input matInput placeholder="Site Location" />
                  </mat-form-field>
              </div>
            </div>
        </div>
        <div class="btn-wrapper">
            <button type="submit" class="cmn-popup-btn">Submit</button>
          </div>
        <!-- form div -->
    </section>
</mat-dialog-content>