
  <section class="cmn-innerpage-wrapper project-new">
    <h2 class="cmn-inner-heading">New Invoice</h2>
    <div class="invoice-new-form-wrapper">
      <div class="invoice-form-wrapper">
        <div class="form-wrapper">
          <label for="">Project Name</label>
          <mat-form-field>
            <input matInput placeholder="Enter name" />
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Project Code</label>
          <mat-form-field>
            <input matInput placeholder="000102" />
          </mat-form-field>
        </div>
        <div class="form-wrapper cmn-Search-select-container">
          <label for="">Client Name</label>
          <mat-form-field>
            <mat-select placeholder="Search Client" #singleSelect>
              <mat-option>
                <ngx-mat-select-search></ngx-mat-select-search>
              </mat-option>

              <mat-option value="Single">Client</mat-option>
              <mat-option value="Married">Client</mat-option>
            </mat-select>
          </mat-form-field>

          <button>
            <div><img src="assets/images/icons/icon-add-color.svg" alt=""></div>
            <span>Add client</span>
          </button>
        </div>
        <div class="form-wrapper">
          <label for="">Billing Method</label>
          <mat-form-field>
            <mat-select placeholder="Fixed cost for project">
              <mat-option value="Single">Single</mat-option>
              <mat-option value="Married">Married</mat-option>
              <mat-option value="Divorced">Divorced</mat-option>
              <mat-option value="Widowed">Widowed</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Total Project Cost</label>
          <mat-form-field>
            <input matInput placeholder="AED" />
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Cost center</label>
          <mat-form-field>
            <mat-select placeholder="Choose branch">
              <mat-option value="Single">Single</mat-option>
              <mat-option value="Married">Married</mat-option>
              <mat-option value="Divorced">Divorced</mat-option>
              <mat-option value="Widowed">Widowed</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Description</label>
          <mat-form-field>
            <textarea matInput placeholder="Type something.."></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>

    <h2 class="cmn-inner-heading">Budget</h2>

    <div class="invoice-new-form-wrapper">
      <div class="invoice-form-wrapper">
        <div class="form-wrapper">
          <label for="">Cost Budget</label>
          <mat-form-field>
            <input matInput placeholder="Enter amount" />
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Revenue Budget</label>
          <mat-form-field>
            <input matInput placeholder="Enter amount" />
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Threshold</label>
          <mat-form-field>
            <input matInput placeholder="Enter amount" />
          </mat-form-field>
        </div>
      </div>
    </div>


    <section class="dynamic-form-wrapper">
      <div class="main-content-wrapper">
        <h3>Users</h3>
        <div class="wrapper-container">
          <div class="form-wrapper flex-wrapper">
            <div>
              <label for="">
                <span>Users</span>
                <mat-form-field>
                  <input matInput placeholder="Enter  Name" />
                </mat-form-field>
              </label>
              <label for="" class="span-2">
                <span>Email</span>
                <mat-form-field>
                  <input matInput placeholder="Enter email address" />
                </mat-form-field>
              </label>
            </div>
            <button>
              <img src="../../../assets/images/icons/icon-add-color.svg" alt="">
            </button>
          </div>
          <div class="content-wrapper">
            <div class="column-1">
              <div>
                <span>S.No</span>
                <h6>1</h6>
              </div>
              <div>
                <span>User</span>
                <h6>Rickson</h6>
              </div>
            </div>
            <div class="span-2">
              <span>Email</span>
              <h6>rickson&#64;gmail.com</h6>
            </div>
            <button>
              <img src="assets/images/icons/icon-close.svg" alt="">
            </button>
          </div>
        </div>
      </div>
    </section>



    <section class="dynamic-form-wrapper">
      <div class="main-content-wrapper">
        <h3>Project Milestone</h3>
        <div class="wrapper-container">
          <div class="form-wrapper flex-wrapper">
            <div>
              <label for="">
                <span>Milestone</span>
                <mat-form-field>
                  <input matInput placeholder="Enter  Name" />
                </mat-form-field>
              </label>
              <label for="" class="span-2">
                <span>Description</span>
                <mat-form-field>
                  <textarea matInput placeholder="Enter email address"></textarea>
                </mat-form-field>
              </label>
            </div>
            <button>
              <img src="assets/images/icons/icon-add-color.svg" alt="">
            </button>
          </div>
          <div class="content-wrapper">
            <div class="column-1">
              <div>
                <span>S.No</span>
                <h6>1</h6>
              </div>
              <div>
                <span>User</span>
                <h6>Rickson</h6>
              </div>
            </div>
            <div class="span-2">
              <span>Email</span>
              <h6>rickson&#64;gmail.com</h6>
            </div>
            <button>
              <img src="assets/images/icons/icon-close.svg" alt="">
            </button>
          </div>
        </div>
      </div>
    </section>


    <div class="submit-btn-wrapper">
      <button class="cmn-cancel-btn">Cancel</button>
      <button class="cmn-next-btn">Save</button>
    </div>


  </section>
