import gql from 'graphql-tag';

export const CREATE_BANK = gql`
  mutation createBank($createBankInput: CreateBankInput!) {
    createBank(createBankInput: $createBankInput) {
    id
    bankType
    accountName
    accountCode
    accountNumber
    bankName
    routingNumber
    description
    isPrimary
    currencyId
    currency {
      id
      currencyCode
      currencyName
      currencySymbol
      exchangeRate
      status
      createdAt
      updatedAt
      deletedAt
    }
    createdAt
    updatedAt
    deletedAt
    status
  }
  }
`;

export const CREATE_TRANSACTION = gql`
  mutation createTransaction($createTransactionInput: CreateTransactionInput!) {
    createTransaction(createTransactionInput: $createTransactionInput) {
    id
    date
    referenceNumber
    accountTypeId
    transactionStatus
    amount
    flow
    runningBalance
    description
    partner {
      id
      displayName
    }
  }
  }
`;