import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ManualJournalService } from '../../features/manual-journals/services/manual-journal.service';
import { ToastrService } from 'ngx-toastr';
import { CurrencyAdjustmentService } from '../../features/currency-adjustments/services/currency-adjustment.service';
import { ChartAccountsService } from '../../features/chart-of-accounts/services/chart-accounts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadFileService } from '../../shared/services/file-upload.service';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';

@Component({
  selector: 'app-manual-journal-new',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './manual-journal-new.component.html',
  styleUrl: './manual-journal-new.component.scss',
})
export class ManualJournalNewComponent implements OnInit {
  accountType: any;
  parentAccountId: any;
  isSubAccount: any;
  newJournalForm!: FormGroup;
  successMessage: string | null = null;
  errorMessage: string | null = null;
  loading: boolean = false;
  journalData: any;
  search: string = '';
  filter: any = {};
  journalId: number | null = null;
  journalEntryId: number | null = null;
  public currencyData: any[] = [];
  public accountData: any[] = [];
  public partnersData: any[] = [];

  file: any = null;
  csvFile: File[] = [];
  url: string = '';
  fileDetailsArray: {
    fileName: string;
    fileUrl: string;
    id?: number;
    isNew: boolean;
  }[] = [];

  @ViewChild('fileInput') fileInput!: ElementRef;
  currentFiles: File[] = [];

  readonly dialog = inject(MatDialog);

  private status: 'Draft' | 'Published' = 'Published';

  constructor(
    private journalService: ManualJournalService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private currencyService: CurrencyAdjustmentService,
    private accountService: ChartAccountsService,
    private router: Router,
    private route: ActivatedRoute,
    private documentUploadService: UploadFileService
  ) {}

  ngOnInit(): void {
    this.fetchCurrencies();
    this.fetchAccounts();
    this.fetchPartners();
    this.initForm();

    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.journalId = +id;
        this.loadJournalData();
      }
    });
    if (!this.journalId) {
      this.addJournalEntry();
    }
  }

  initForm(): void {
    this.newJournalForm = this.fb.group({
      id: [null],
      date: ['', Validators.required],
      journalNo: ['', Validators.required],
      reference: ['', Validators.required],
      notes: ['', Validators.required],
      currencyId: ['', Validators.required],
      inCash: [false, Validators.required],
      journalEntries: this.fb.array([]),
      journalAttachments: this.fb.array([]),
    });
  }

  private loadJournalData(): void {
    if (this.journalId !== null) {
      this.journalService.getJournalById(this.journalId).subscribe({
        next: (data) => {
          this.journalData = data;
          this.populateForm();
        },
        error: (error) => console.error(error),
      });
    } else {
      console.warn('Journal ID is null; cannot load journal data.');
    }
  }

  private populateForm(): void {
    this.newJournalForm.patchValue({
      id: this.journalData.id,
      date: new Date(Number(this.journalData.date)),
      journalNo: this.journalData.journalNo,
      reference: this.journalData.reference,
      notes: this.journalData.notes,
      currencyId: this.journalData.currencyId,
      inCash: this.journalData.inCash,
    });

    // Initialize journal entries if available
    if (this.journalData.journalEnteries) {
      this.journalData.journalEnteries.forEach((entry: any) => {
        const journalEntry = this.fb.group({
          id: [entry.id || ''],
          chartOfAccountId: [entry.chartOfAccountId, Validators.required],
          partnerId: [entry.partnerId, Validators.required],
          description: [entry.description || ''],
          debit: [
            { value: entry.debit || 0, disabled: false },
            Validators.required,
          ],
          credit: [
            { value: entry.credit || 0, disabled: false },
            Validators.required,
          ],
        });
        this.journalEntries.push(journalEntry); // Ensure the control is added
      });
    }

    // Initialize journal attachments if available
    if (this.journalData.journalAttachments) {
      this.journalData.journalAttachments.forEach((attachment: any) => {
        const attachmentData = this.fb.group({
          id: attachment.id,
          fileName: attachment.fileName,
          fileUrl: attachment.fileUrl,
        });
        this.journalAttachments.push(attachmentData);
        this.fileDetailsArray.push({
          id: attachment.id,
          fileName: attachment.fileName,
          fileUrl: attachment.fileUrl,
          isNew: true,
        });
      });
    }
  }

  get journalEntries(): FormArray {
    return this.newJournalForm.get('journalEntries') as FormArray;
  }

  get journalAttachments(): FormArray {
    return this.newJournalForm.get('journalAttachments') as FormArray;
  }

  get totalDebit(): number {
    return this.journalEntries.controls.reduce((sum, entry) => {
      return sum + (entry.get('debit')?.value || 0);
    }, 0);
  }

  get totalCredit(): number {
    return this.journalEntries.controls.reduce((sum, entry) => {
      return sum + (entry.get('credit')?.value || 0);
    }, 0);
  }

  get difference(): number {
    return this.totalDebit - this.totalCredit;
  }

  addJournalEntry(): void {
    const journalEntry = this.fb.group({
      chartOfAccountId: ['', Validators.required],
      partnerId: ['', Validators.required],
      description: [''],
      debit: [{ value: 0, disabled: false }, Validators.required],
      credit: [{ value: 0, disabled: false }, Validators.required],
    });

    // Set conditional logic to disable one field when the other is filled
    journalEntry.get('debit')?.valueChanges.subscribe((debitValue) => {
      const creditControl = journalEntry.get('credit');

      if ((debitValue ?? 0) > 0) {
        if (creditControl?.enabled) {
          creditControl.setValue(0);
          creditControl.disable();
        }
      } else {
        if (creditControl?.disabled) {
          creditControl.enable();
        }
      }
    });

    journalEntry.get('credit')?.valueChanges.subscribe((creditValue) => {
      const debitControl = journalEntry.get('debit');

      // Ensure creditValue is not null or undefined before comparison
      if ((creditValue ?? 0) > 0) {
        // Set debit value to 0 and disable it if creditValue is greater than 0
        if (debitControl?.enabled) {
          debitControl.setValue(0);
          debitControl.disable();
        }
      } else {
        // Enable the debit field when credit value is not greater than 0
        if (debitControl?.disabled) {
          debitControl.enable();
        }
      }
    });

    this.journalEntries.push(journalEntry);
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const newFiles = Array.from(input.files);

      newFiles.forEach((newFile) => {
        // Prevent duplicates based on name and size
        if (
          !this.fileDetailsArray.some(
            (file) => file.fileName === newFile.name && file.isNew
          )
        ) {
          this.fileDetailsArray.push({
            fileName: newFile.name,
            fileUrl: '',
            isNew: true,
          });
        }
      });

      // Upload the new files
      this.uploadDocuments(newFiles);
    }
  }

  uploadDocuments(files: File[]): void {
    files.forEach((file: File) => {
      this.documentUploadService.uploadDocument(file, 'journals').subscribe({
        next: (response) => {
          const uploadedFile = this.fileDetailsArray.find(
            (f) => f.fileName === file.name && f.isNew
          );
          if (uploadedFile) {
            uploadedFile.fileUrl = response.url;
          }
        },
        error: (error) => {
          this.toastr.error(error, 'File upload failed');
        },
      });
    });
    this.fileInput.nativeElement.value = '';
  }

  removeFile(index: number): void {
    const file = this.fileDetailsArray[index];
    if (file.id) {
      // Existing file with ID - call API to remove
      this.onRemoveJournalAttachment(file.id);
    } else {
      // New file - simply remove from array
      this.fileDetailsArray.splice(index, 1);
    }
  }

  onSubmit(): void {
    if (this.newJournalForm.invalid) {
      this.newJournalForm.markAllAsTouched();
      return;
    }

    const journalInputData = {
      date: this.newJournalForm.value.date,
      journalNo: this.newJournalForm.value.journalNo,
      reference: this.newJournalForm.value.reference,
      notes: this.newJournalForm.value.notes,
      currencyId: this.newJournalForm.value.currencyId,
      inCash: this.newJournalForm.value.inCash,
      status: this.status,
    };

    // Separate new and existing entries based on ID
    const createJournalEntries =
      this.newJournalForm.value.journalEntries.filter(
        (entry: any) => !entry.id
      );
    const updateJournalEntries =
      this.newJournalForm.value.journalEntries.filter((entry: any) => entry.id);

    const createJournalAttachments = this.fileDetailsArray
      .filter((file) => !file.id) // New files without ID go to createJournalAttachments
      .map((file) => ({
        fileName: file.fileName,
        fileUrl: file.fileUrl,
      }));

    const updateJournalAttachments = this.fileDetailsArray
      .filter((file) => file.id) // Files with ID go to updateJournalAttachments
      .map((file) => ({
        id: file.id,
        fileName: file.fileName,
        fileUrl: file.fileUrl,
      }));

    // Prepare journal update input data
    const journalUpdateInput = {
      id: this.journalId,
      ...journalInputData,
    };

    // Conditional API call based on journalId
    if (this.journalId) {
      this.journalService
        .updateJournal(
          journalUpdateInput,
          createJournalEntries,
          updateJournalEntries,
          createJournalAttachments,
          updateJournalAttachments
        )
        .subscribe({
          next: () => {
            this.loading = false;
            this.toastr.success('Journal Updated Successfully');
            this.router.navigate(['/manual-journals']);
          },
          error: (error) => {
            this.loading = false;
            this.toastr.error(error.message || 'Failed to update journal.');
          },
        });
    } else {
      this.journalService
        .createJournal(
          journalInputData,
          createJournalEntries,
          createJournalAttachments
        )
        .subscribe({
          next: () => {
            this.loading = false;
            this.toastr.success('Journal created successfully!');
            this.router.navigate(['/manual-journals']);
          },
          error: (error) => {
            this.loading = false;
            this.toastr.error(error.message || 'Failed to create journal.');
          },
        });
    }
  }

  // Method for Save (Draft)
  saveAsDraft(): void {
    if (this.difference === 0) {
      this.status = 'Draft';
      this.onSubmit();
    } else {
      this.toastr.warning(
        'The total debit and credit must be equal (difference must be 0) to save as draft.'
      );
    }
  }

  // Method for Save (Published)
  save(): void {
    if (this.difference === 0) {
      this.status = 'Published';
      this.onSubmit();
    } else {
      this.toastr.warning(
        'The total debit and credit must be equal (difference must be 0) to publish the journal.'
      );
    }
  }

  onRemoveJournalEntry(journalEntryId: number, index: number): void {
    if (journalEntryId) {
      const dialogRef = this.dialog.open(ConfirmDialog);

      dialogRef.afterClosed().subscribe((response) => {
        if (response === true) {
          this.removeJournalEntry(journalEntryId, index);
        }
      });
    } else {
      this.journalEntries.removeAt(index);
    }
  }

  private removeJournalEntry(journalEntryId: number, index: number): void {
    this.journalService.removeJournalEntryById(journalEntryId).subscribe({
      next: () => {
        console.log('Journal Entry removed successfully');
        // Remove the entry from the form array
        this.journalEntries.removeAt(index);
      },
      error: () => {
        console.log('Failed to remove journal entry');
      },
    });
  }

  onRemoveJournalAttachment(journalAttachmentId: number): void {
    const dialogRef = this.dialog.open(ConfirmDialog);
    dialogRef.afterClosed().subscribe((response) => {
      if (response === true) {
        this.journalService
          .removeJournalAttachmentById(journalAttachmentId)
          .subscribe({
            next: () => {
              this.fileDetailsArray = this.fileDetailsArray.filter(
                (file) => file.id !== journalAttachmentId
              );
            },
            error: () => {
              this.toastr.error('Failed to remove journal attachment.');
            },
          });
      }
    });
  }

  onCancel(): void {
    this.newJournalForm.reset();
  }

  private fetchCurrencies() {
    this.currencyService.fetchCurrencies(this.search, this.filter).subscribe({
      next: (currency) => {
        this.currencyData = currency;
      },
      error: (error) => console.error(error),
    });
  }

  private fetchAccounts() {
    this.accountService
      .chartOfAccounts(
        this.accountType,
        this.search,
        this.parentAccountId,
        this.isSubAccount
      )
      .subscribe({
        next: (accounts) => {
          this.accountData = accounts;
        },
        error: (error) => console.error(error),
      });
  }

  private fetchPartners() {
    this.journalService.fetchPartners(this.search).subscribe({
      next: (partners) => {
        this.partnersData = partners;
      },
      error: (error) => console.error(error),
    });
  }
}
