<mat-dialog-content class="mat-typography">
  <section class="add-currency-dialog-wrapper">
    <h2 class="cmn-popup-title">
      {{ currencyData?.id ? "Update currency details" : "Add currency details"
      }}
    </h2>
    <button class="cmn-close" mat-dialog-close></button>
    <form [formGroup]="addCurrencyForm" (ngSubmit)="onSubmit()">
      <div class="currency-form-wrapper">
        <div class="form-wrapper">
          <label for="">Currency Code</label>
          <mat-form-field>
            <input
              matInput
              placeholder="Enter currency code"
              formControlName="currencyCode"
              maxlength="5"
            />
            <mat-error
              *ngIf="
                addCurrencyForm.get('currencyCode')!.touched &&
                addCurrencyForm.get('currencyCode')!.hasError('required')
              "
              >Currency Code is required.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Currency Name</label>
          <mat-form-field>
            <input
              matInput
              placeholder="Enter currency name"
              formControlName="currencyName"
            />
            <mat-error
              *ngIf="
                addCurrencyForm.get('currencyName')!.touched &&
                addCurrencyForm.get('currencyName')!.hasError('required')
              "
              >Currency Name is required.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Symbol</label>
          <mat-form-field>
            <input
              matInput
              placeholder="Enter currency symbol"
              formControlName="currencySymbol"
              maxlength="5"
            />
            <mat-error
              *ngIf="
                addCurrencyForm.get('currencySymbol')!.touched &&
                addCurrencyForm.get('currencySymbol')!.hasError('required')
              "
              >Currency Symbol is required.</mat-error
            >
          </mat-form-field>
        </div>
        <div class="form-wrapper">
          <label for="">Exchange rate</label>
          <div class="split-parent">
            <div class="left-set">
              <mat-form-field>
                <input
                  matInput
                  step="0.01"
                  placeholder="Enter exchange rate"
                  formControlName="exchangeRate"
                  maxlength="20"
                />
                <mat-error
                  *ngIf="
                    addCurrencyForm.get('exchangeRate')!.touched &&
                    addCurrencyForm.get('exchangeRate')!.hasError('required')
                  "
                  >Exchange Rate is required.</mat-error
                >
                <mat-error
                  *ngIf="
                    addCurrencyForm.get('exchangeRate')!.touched &&
                    addCurrencyForm.get('exchangeRate')!.hasError('pattern')
                  "
                  >Please enter a valid exchange rate.</mat-error
                >
              </mat-form-field>
            </div>
            <div class="right-set">
              <mat-form-field>
                <input matInput placeholder="AED" value="AED" readonly />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-wrapper">
        <button class="cmn-popup-btn" type="submit">
          {{ currencyData?.id ? "Update" : "Add" }}
        </button>
      </div>
    </form>
  </section>
</mat-dialog-content>
