import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { QuillModule } from 'ngx-quill';
import { InvoiceService } from '../../features/invoice/services/invoice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { PaymentsItemService } from '../../features/payments/services/payments.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-send-mail',
  standalone: true,
  imports: [CommonModule, LayoutComponent, QuillModule, ReactiveFormsModule],
  templateUrl: './send-mail.component.html',
  styleUrl: './send-mail.component.scss',
})
export class SendComponent implements OnInit {
  ID: number;
  public loading: boolean = true;
  invoiceTemplateControl = new FormControl();
  emailForm: FormGroup;

  documentFile: any = null;
  documentFileName: any = null;
  constructor(
    private itemService: PaymentsItemService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private router: Router,
    private location: Location
  ) {
    this.emailForm = this.fb.group({
      toEmailControl: ['', [Validators.required, Validators.email]],
      ccEmailControl: ['', Validators.email],
      bccEmailControl: ['', Validators.email],
      subject: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const id = params['paymentID'];
      const invoiceOrBill_ids = params['ids'];
      if (id && invoiceOrBill_ids) {
        this.ID = +id;
        this.getPaymentDetails(invoiceOrBill_ids?.map((item: string) => +item));
      }
    });
  }

  toEmails: string[] = [];
  ccEmails: string[] = [];
  bccEmails: string[] = [];

  toEmailControl = new FormControl('');
  ccEmailControl = new FormControl('');
  bccEmailControl = new FormControl('');

  showBcc = false;

  private getPaymentDetails(invoiceValue: any): void {
    if (this.ID !== null) {
      this.itemService.getPaymentDetails(this.ID, invoiceValue).subscribe({
        next: (data) => {
          this.toEmails = [data?.partner?.email];

          this.invoiceTemplateControl.setValue(
            data?.paymentTemplate?.templateBody
          );

          this.emailForm.patchValue({
            subject: data?.paymentTemplate?.templateSubject,
          });

          if (
            data.partner.primaryContact &&
            data.partner.primaryContact.email
          ) {
            this.ccEmails = [data.partner.primaryContact.email];
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  sendEmail() {
    const body = this.invoiceTemplateControl?.value;
    const fromEmail = 'admin@munawel.com';
    const templateId = 2;

    // console.log('Form values:', this.emailForm.value);

    // Ensure to filter out any empty or invalid emails
    const toEmails = [
      ...this.toEmails,
      ...this.emailForm.value.toEmailControl
        .split(',')
        .map((email: any) => email.trim())
        .filter((email: any) => this.isValidEmail(email)),
    ];

    const ccEmails = [
      ...this.ccEmails,
      ...this.emailForm.value.ccEmailControl
        .split(',')
        .map((email: any) => email.trim())
        .filter((email: any) => this.isValidEmail(email)),
    ];

    const subject = this.emailForm.value?.subject;

    this.itemService
      .sendPaymentRecievedMail(this.ID, toEmails, ccEmails, subject, body)
      .subscribe({
        next: (result) => {
          console.log('Email sent successfully:', result);
          this.toastr.success('Email sent successfully');
          this.location.back();
        },
        error: (error) => {
          console.error('Error sending email:', error);
          this.toastr.error('Failed to send email');
        },
      });
    this.loading = false;
  }

  addEmail(type: 'to' | 'cc' | 'bcc') {
    let control;
    let emailList;

    switch (type) {
      case 'to':
        control = this.toEmailControl;
        emailList = this.toEmails;
        break;
      case 'cc':
        control = this.ccEmailControl;
        emailList = this.ccEmails;
        break;
      case 'bcc':
        control = this.bccEmailControl;
        emailList = this.bccEmails;
        break;
      default:
        return;
    }

    // Only proceed if control.value is defined and not empty
    const email = control?.value?.trim();

    if (email && this.isValidEmail(email) && !emailList.includes(email)) {
      emailList.push(email); // Add the new email to the list
      console.log(`${type.toUpperCase()} Emails:`, emailList); // Log for debugging
      control.reset(); // Clear the input field after adding
    } else if (email && !this.isValidEmail(email)) {
      console.error('Invalid email format:', email);
    }
  }

  removeEmail(email: string, type: 'to' | 'cc' | 'bcc') {
    let emailList;

    switch (type) {
      case 'to':
        emailList = this.toEmails;
        break;
      case 'cc':
        emailList = this.ccEmails;
        break;
      case 'bcc':
        emailList = this.bccEmails;
        break;
    }

    const index = emailList.indexOf(email);
    if (index >= 0) {
      emailList.splice(index, 1);
    }
  }

  toggleBcc() {
    this.showBcc = !this.showBcc;
  }

  private isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.documentFileName = file.name;
      this.documentFile = file;
    }
  }

  clearDocument() {
    this.documentFile = null;
    this.documentFileName = null;
  }
}
