import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

// Custom email validation function
export function customEmailValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const email = control.value;
        // Regular expression to match a standard email pattern
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const valid = emailRegex.test(email);
        // Return an error object if invalid, otherwise null
        return valid ? null : {
            invalidEmail: true
        };
    };
}

export function maxValidator(maxValue: number): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const value = control.value;
        if (value != null && value > maxValue) {
            return {maxCTCExceeded: true}; // Error key to identify this validation
        }
        return null;
    };
}

export const nonNegativeValidator: ValidatorFn = (control : AbstractControl) : ValidationErrors | null => {
    const value = control.value;
    return value != null && value >= 0 ? null : {
        nonNegative: true
    };
};

export function alphabeticValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => { // const valid = /^[a-zA-Z]+$/.test(control.value);
        const valid = /^[a-zA-Z\s]+$/.test(control.value);
        return valid ? null : {
            invalidCharacters: {
                value: control.value
            }
        };
    };
}

export function numbersOnlyValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const valid = /^[0-9\s-]+$/.test(control.value);
        return valid ? null : {
            invalidNumber: {
                value: control.value
            }
        };
    };
}

export function phoneNumberValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const valid = /^[0-9\s-]*$/.test(control.value);
        return valid ? null : {
            invalidPhoneNumber: true
        };
    };
}

// Validator to ensure the passport number is alphanumeric and has 6 to 9 characters
export function passportNumberValidator(): ValidatorFn {
    return(control : AbstractControl) : {
        [key : string]: any
    } | null => {
        const passportNumberRegex = /^[A-Z0-9]{6,9}$/i; // Adjust based on country-specific rules
        const valid = passportNumberRegex.test(control.value);
        return valid ? null : {
            invalidPassportNumber: {
                value: control.value
            }
        };
    };
}

export function passportValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const passportNumber = control.value;

        if (! passportNumber) {
            return {required: true};
        }
        const passportRegex = /^[A-Z0-9]{6,15}$/i;

        const isValid = passportRegex.test(passportNumber);
        return isValid ? null : {
            invalidPassportNumber: {
                value: passportNumber
            }
        };
    };
}

// visa number validation
export function visaValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const visaNumber = control.value;

        if (! visaNumber) {
            return {required: true};
        }
        const visaRegex = /^[A-Z0-9]{6,15}$/i;

        const isValid = visaRegex.test(visaNumber);
        return isValid ? null : {
            invalidVisaNumber: {
                value: visaNumber
            }
        };
    };
}

// emirates id number validations
export function emiratesIdValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const emiratesId = control.value;

        if (! emiratesId) {
            return {required: true};
        }

        // Regular expression pattern to match the format: 784-XXXX-XXXXXXX-X
        const emiratesIdRegex = /^784-\d{4}-\d{7}-\d{1}$/;

        const isValid = emiratesIdRegex.test(emiratesId);
        return isValid ? null : {
            invalidEmiratesId: {
                value: emiratesId
            }
        };
    };
}

// validations for driving license number
export function drivingLicenseValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const licenseNumber = control.value;

        if (! licenseNumber) {
            return {required: true};
        }

        // Generic regex to match typical driving license formats (alphanumeric, 5-20 characters)
        const licenseRegex = /^[A-Z0-9\s-_]{5,20}$/i;

        const isValid = licenseRegex.test(licenseNumber);
        return isValid ? null : {
            invalidDrivingLicense: {
                value: licenseNumber
            }
        };
    };
}

// for alphabets and digits
export function alphanumericValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const valid = /^[a-zA-Z0-9]+$/.test(control.value);
        return valid ? null : {
            invalidCharacters: {
                value: control.value
            }
        };
    };
}

export function alternatePhoneValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const value = control.value;
        if (! value || value.trim() === '') {
            return null;
        }

        // Allow digits, spaces, and hyphens only
        const valid = /^[0-9\s-]+$/.test(value);
        return valid ? null : {
            invalidNumber: {
                value
            }
        };
    };
}

// General Bank Account Validator
export function bankAccountValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const accountNumber = control.value;

        if (! accountNumber) {
            return {required: true};
        }
        const ibanRegex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/;
        const bbanRegex = /^[A-Z0-9]{8,34}$/;

        const isValidIBAN = ibanRegex.test(accountNumber);
        const isValidBBAN = bbanRegex.test(accountNumber);
        return isValidIBAN || isValidBBAN ? null : {
            invalidBankAccount: {
                value: accountNumber
            }
        };
    };
}

export function passwordStrengthValidator(control: AbstractControl): ValidationErrors |null {
    const password = control.value;

    if (! password) 
        return null;
    

    // Return null if no password is entered

    const hasUpperCase = /[A-Z]+/.test(password);
    const hasLowerCase = /[a-z]+/.test(password);
    const hasNumeric = /[0-9]+/.test(password);
    // const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]+/.test(password);
    const validLength = password.length >= 6;

    const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && validLength;

    return ! passwordValid ? {
        passwordStrength: true
    } : null;
}

export function positiveNumberValidator(): ValidatorFn {
    return(control : AbstractControl) : ValidationErrors | null => {
        const value = control.value;
        return value > 0 ? null : {
            positiveNumber: true
        };
    };
}
