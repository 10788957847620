
    <section class="cmn-innerpage-wrapper purchase-order-details">
        <div class="head">
            <div class="search-wrapper">
                <input type="text" class="cmn-header-search" placeholder="Search" />
            </div>
            <button class="cmn-upload-btn" (click)="openDialog()">
                <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                <span>Upload</span>
            </button>
            <button class="cmn-add-btn" (click)="navigateTo('/purchase-order-new')">
                <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New  Purchase
            </button>
            <button class="cmn-filter-btn" (click)="toggleDropdown($event)"
                [ngClass]="{ 'class-clicked': isDropdownOpen }">
                <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
                <!-- <span *ngIf="selectedCount > 0">{{ selectedCount }}</span> -->
            </button>
            <!-- Filter -->
            <div [ngClass]="{ show: isDropdownOpen }" class="common-dropdown filter-position" #dropdown>
                <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
                <form>
                    <h6 class="cmn-popup-heading">
                        Filter <span>Clear All</span>
                    </h6>
                    <div class="filter-inner-content">
                        <h2>Branch</h2>
                        <div class="check-wrapper">
                            <div class="check-box">
                                <label class="checkbox-wrapper">
                                    <input type="checkbox" />
                                    test
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrapper">
                        <button class="cmn-apply-btn" type="submit">Apply</button>
                    </div>
                </form>
            </div>
            <!-- Filter -->
        </div>
        <div class="sub-head">
            <ul>
                <li class="main">All Orders</li>
                <li class="edit"><img src="assets/images/icons/icon-send.svg" alt="Mail"> Send to mail</li>
                <li class="edit" (click)="navigateTo('/purchase-order-new')"><img src="assets/images/icons/icon-edit-accounts.svg" alt="Edit"> Edit</li>
                <li class="edit"><img src="assets/images/icons/icon-record.svg" alt="Record"> Convert to Bill</li>
            </ul>
            <div class="mat-menu-wrapper">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item>
                        <span>Mark as Sent</span>
                    </button>
                    <button mat-menu-item>
                        <span>Credit Available</span>
                    </button>
                    <button mat-menu-item>
                        <span>Create Credit Note</span>
                    </button>
                    <button mat-menu-item>
                        <span>Credit Applied</span>
                    </button>
                    <button mat-menu-item>
                        <span>Clone</span>
                    </button>
                    <button mat-menu-item>
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </div>
        </div>
        <div class="manual-journal-details-tab-wrapper">
            <div class="tab-left">
                <div class="tab-box-main-wrapper">
                    <button class="tab-custom-button" [class.active]="selectedTabIndex === 0" (click)="selectTab(0)">
                        <div class="inn-box">
                            <h2>Henry joe</h2>
                            <h3>RE-000102</h3>
                        </div>
                        <div class="inn-box">
                            <h6>12-7-2024</h6>
                            <h5>AED <span>1200</span></h5>
                        </div>
                        <div class="inn-box">
                            <h4 class="grey">Draft</h4>
                        </div>
                    </button>
                    <button class="tab-custom-button" [class.active]="selectedTabIndex === 1" (click)="selectTab(1)">
                        <div class="inn-box">
                            <h2>Jerome Bell</h2>
                            <h3>RE-000102</h3>
                        </div>
                        <div class="inn-box">
                            <h6>12-7-2024</h6>
                            <h5>AED <span>1200</span></h5>
                        </div>
                        <div class="inn-box">
                            <h4 class="yellow">Due 15d</h4>
                        </div>
                    </button>
                    <button class="tab-custom-button" [class.active]="selectedTabIndex === 2" (click)="selectTab(2)">
                        <div class="inn-box">
                            <h2>Cody Fisher</h2>
                            <h3>RE-000102</h3>
                        </div>
                        <div class="inn-box">
                            <h6>12-7-2024</h6>
                            <h5>AED <span>1200</span></h5>
                        </div>
                        <div class="inn-box">
                            <h4 class="green">Paid</h4>
                        </div>
                    </button>
                    <button class="tab-custom-button" [class.active]="selectedTabIndex === 3" (click)="selectTab(3)">
                        <div class="inn-box">
                            <h2>Kathryn Murphy</h2>
                            <h3>RE-000102</h3>
                        </div>
                        <div class="inn-box">
                            <h6>12-7-2024</h6>
                            <h5>AED <span>1200</span></h5>
                        </div>
                        <div class="inn-box">
                            <h4 class="blue">Confirmerd</h4>
                        </div>
                    </button>
                    <button class="tab-custom-button" [class.active]="selectedTabIndex === 4" (click)="selectTab(4)">
                        <div class="inn-box">
                            <h2>Jerome Bell</h2>
                            <h3>RE-000102</h3>
                        </div>
                        <div class="inn-box">
                            <h6>12-7-2024</h6>
                            <h5>AED <span>1200</span></h5>
                        </div>
                        <div class="inn-box">
                            <h4 class="grey">Draft</h4>
                        </div>
                    </button>

                </div>
            </div>
            <div class="manual-journal-details-content-wrapper">
                <div *ngIf="selectedTabIndex === 0">
                    <div class="chart-tab-content">
                        <div class="tab-inner">
                            <div class="note-header">
                                <div class="left-set">
                                    <h4>Dans Designs</h4>
                                    <h3>United Arab Emirates</h3>
                                    <h3>Abu Dhabi 12345</h3>
                                </div>
                                <div class="right-set">
                                    <h2>PURCHASE ORDER</h2>
                                    <h3># J-000102</h3>
                                    <h3>02/08/2024</h3>
                                </div>
                            </div>

                            <div class="journal-details">
                                <div class="left-set">
                                    <div class="details-box">
                                        <h4>Bill To </h4>
                                        <h5>:</h5>
                                        <h6>Henry Joe</h6>
                                    </div>
                                    <div class="details-box">
                                        <h4>Subject</h4>
                                        <h5>:</h5>
                                        <h6>Regarding Purchase</h6>
                                    </div>
                                </div>
                                <!-- <div class="right-set">
                                    <div class="details-box">
                                        <h4>Expiry Date</h4>
                                        <h5>:</h5>
                                        <h6>14/08/24</h6>
                                    </div>
                                    <div class="details-box">
                                        <h4>Reference</h4>
                                        <h5>:</h5>
                                        <h6>PO-5315615</h6>
                                    </div>
                                </div> -->
                            </div>
                            <div class="crdit-note-page-table">
                            <table>
                                <tr>
                                    <th>Item/Service</th>
                                    <th>Description</th>
                                    <th>Quantity</th>
                                    <th>Rate</th>
                                    <th>Discount</th>
                                    <th>Amount</th>
                                </tr>
                                <tr>
                                    <td>Floor Patching</td>
                                    <td>Patch 120 sq.ft</td>
                                    <td>1 </td>
                                    <td>AED 150 </td>
                                    <td>25% </td>
                                    <td>AED 150 </td>
                                </tr>
                            </table>
                        </div>
                            <div class="page-footer-content">
                                <div class="left-set">
                                    <div class="details-box">
                                        <h4>Customer Notes :</h4>
                                        <h6>Looking forward for your business.</h6>
                                    </div>
                                    <div class="details-box">
                                        <h4>Terms & Conditions :</h4>
                                        <h6>Privacy Policy</h6>
                                    </div>
                                </div>
                                <div class="right-set">
                                    <div class="details-box">
                                        <h4>Sub total</h4>
                                        <h5>:</h5>
                                        <h6>PO-5315615</h6>
                                    </div>
                                    <div class="details-box">
                                        <h4>Total</h4>
                                        <h5>:</h5>
                                        <h6>PO-5315615</h6>
                                    </div>
                                    <div class="details-box">
                                        <h4>Credits Used</h4>
                                        <h5>:</h5>
                                        <h6>PO-5315615</h6>
                                    </div>
                                    <div class="details-box bold">
                                        <h4>Credits Remaining</h4>
                                        <h5>:</h5>
                                        <h6>AED 590 </h6>
                                    </div>
                                </div>
                            </div>

                            <p class="signature">Authorized Signature <span></span></p>
                            
                            <span class="published-note"><img src="assets/images/img-closed.svg" alt="Closed"></span>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedTabIndex === 1">
                    <div class="chart-tab-content">
                        content 2
                    </div>
                </div>
                <div *ngIf="selectedTabIndex === 2">
                    <div class="chart-tab-content">
                        content 3
                    </div>
                </div>
                <div *ngIf="selectedTabIndex === 3">
                    <div class="chart-tab-content">
                        content 4
                    </div>
                </div>
                <div *ngIf="selectedTabIndex === 4">
                    <div class="chart-tab-content">
                        content 5
                    </div>
                </div>
            </div>
        </div>
    </section>
