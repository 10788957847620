import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { ManualJournalService } from '../../features/manual-journals/services/manual-journal.service';
import { JournalStatusEnum } from '../../../assets/journalStatus';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { PaginationHelper } from '../../shared/services/pagination.service';

@Component({
  selector: 'app-manual-journals',
  standalone: true,
  imports: [CommonModule, LayoutComponent, MatInputModule, AgGridAngular],
  templateUrl: './manual-journals.component.html',
  styleUrls: ['./manual-journals.component.scss'],
})
export class ManualJournalsComponent implements OnInit {
  public rowData: any[] = [];
  public loading: boolean = true;
  public error: any = null;
  private searchSubject = new Subject<string>();

  public journalStatuses = Object.values(JournalStatusEnum); // Get all journal statuses from the enum
  public selectedStatuses: JournalStatusEnum[] = []; // Track selected statuses

  columnDefs = [
    { headerName: 'Date', field: 'date', flex: 1 },
    { headerName: 'Journal Number', field: 'journalNo', flex: 1 },
    { headerName: 'Reference Number', field: 'reference', flex: 1 },
    { headerName: 'Status', field: 'status', flex: 1 },
    { headerName: 'Currency Code', field: 'currencyCode', flex: 1 },
    { headerName: 'Currency Name', field: 'currencyName', flex: 1 },
    { headerName: 'Notes', field: 'notes', flex: 1 },
  ];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };

  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10];
  public themeClass: string = 'ag-theme-quartz';

  constructor(
    private router: Router,
    private journalService: ManualJournalService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.fetchJournalData();

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchJournalData(searchTerm);
      });
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  applyFilter(event: Event) {
    event.preventDefault();

    this.fetchJournalData();
    this.toggleDropdown();
  }

  clearFilter() {
    this.selectedStatuses = [];
    this.fetchJournalData();
  }

  onStatusChange(status: JournalStatusEnum, event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const isChecked = inputElement.checked;

    if (isChecked) {
      this.selectedStatuses.push(status);
    } else {
      this.selectedStatuses = this.selectedStatuses.filter((s) => s !== status);
    }
  }

  private fetchJournalData(search: string = '', filter: any = {}) {
    this.loading = true;

    const filterCriteria = {
      ...filter,
      status: this.selectedStatuses.length
        ? this.selectedStatuses[0]
        : undefined,
    };

    this.journalService.fetchManualJournals(search, filterCriteria).subscribe({
      next: (journals) => {
        this.rowData = journals.map((journal: any) => {
          return {
            id: journal?.id,
            date: this.datePipe.transform(journal?.date, 'dd/MM/yyyy'),
            journalNo: journal?.journalNo,
            reference: journal?.reference,
            status: journal?.status,
            currencyCode: journal?.currencyData?.currencyCode,
            currencyName: journal?.currencyData?.currencyName,
            notes: journal?.notes,
          };
        });

        // Update pagination options based on the rowData length
        this.paginationPageSizeSelector =
          PaginationHelper.getPaginationPageSizeOptions(this.rowData);

        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching journals:', err);
        this.loading = false;
      },
    });
  }

  onRowClicked(event: any) {
    const journalId = event.data.id;
    const rowIndex = event.rowIndex;
    this.router.navigate([`manual-journals/detail/${journalId}`]);
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  isDropdownOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}
