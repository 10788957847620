<mat-dialog-content>
    <section class="add-profile-main-wrapper">
        <h2 class="cmn-popup-title">Create New Profile</h2>
        <button class="cmn-close" mat-dialog-close></button>
        <div class="add-profile-dialog-wrapper">
            <div class="form-wrapper">
                <label for="">Profile Name</label>
                <mat-form-field>
                    <input matInput placeholder="Enter Name" />
                </mat-form-field>
            </div>
            <div class="form-wrapper">
                <label for="">Clone Profile</label>
                <mat-form-field>
                    <mat-select placeholder="Select profile">
                        <mat-option value="Single">Single</mat-option>
                        <mat-option value="Married">Married</mat-option>
                        <mat-option value="Divorced">Divorced</mat-option>
                        <mat-option value="Widowed">Widowed</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-wrapper">
                <label for="">Profile Description</label>
                <mat-form-field>
                    <textarea matInput placeholder="Type something.."></textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="btn-wrapper">
            <button class="cmn-popup-btn">
                Add Profile
            </button>
        </div>

    </section>
</mat-dialog-content>