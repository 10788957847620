
    <section class="cmn-innerpage-wrapper add-project-inventory">
        <h2 class="cmn-inner-heading">{{customerId ? 'Edit Customer' : 'New Customer'}}</h2>
        <form [formGroup]="newCustomerForm" (ngSubmit)="onSubmit()">
            <label class="radio-label">Customer type</label>
            <!-- Format Selection -->
            <div class="type-selection">
                <label class="radiobtn-wrapper">
                    <input type="radio" name="customerType" value="Bussiness" formControlName="customerType" />
                    <span class="radiomark"></span>
                    Business
                </label>
                <label class="radiobtn-wrapper">
                    <input type="radio" name="customerType" value="Individual" formControlName="customerType" />
                    <span class="radiomark"></span>
                    Individual
                </label>
                <div *ngIf="
                    newCustomerForm.get('customerType')!.invalid &&
                    newCustomerForm.get('customerType')!.touched
                    " class="error">
                    Customer Type is required.
                </div>
            </div>
            <div class="chart-account-form-wrapper">
                <div class="form-wrapper">
                    <label for="">Primary Contact</label>
                    <mat-form-field>
                        <mat-select placeholder="Salutation" formControlName="primaryContact">
                            <mat-option value="Mr">Mr</mat-option>
                            <mat-option value="Mrs">Mrs</mat-option>
                            <mat-option value="Miss">Miss</mat-option>
                            <mat-option value="Dr">Dr</mat-option>
                            <mat-option value="Prof">Prof</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">First Name</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter First Name" formControlName="firstName" />
                        <mat-error *ngIf="newCustomerForm.get('firstName')!.hasError('required')">
                            First name is required.
                          </mat-error>
                      
                          <mat-error *ngIf="newCustomerForm.get('firstName')!.hasError('maxlength')">
                            First name must be at most 50 characters long.
                          </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Last Name</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter Last Name" formControlName="lastName" />
                        <mat-error *ngIf="newCustomerForm.get('lastName')!.hasError('required')">
                            Last name is required.
                          </mat-error>
                        <mat-error *ngIf="
                        newCustomerForm.get('lastName')!.touched &&
                        newCustomerForm.get('lastName')!.hasError('maxlength')
                      ">
                           Last name must be at most 50 characters long.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Company Name</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter company name" formControlName="companyName" />
                        <mat-error *ngIf="
                        newCustomerForm.get('companyName')!.touched &&
                        newCustomerForm.get('companyName')!.hasError('maxlength')
                      ">
                           Company name must be at most 50 characters long.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Customer Display Name</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter name" formControlName="displayName" />
                        <mat-error *ngIf="newCustomerForm.get('displayName')!.hasError('required')">
                            Display name is required.
                          </mat-error>
                        <mat-error *ngIf="
                        newCustomerForm.get('displayName')!.touched &&
                        newCustomerForm.get('displayName')!.hasError('maxlength')
                      ">
                           Display name must be at most 50 characters long.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Customer Email</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter email address" formControlName="email" />
                        <mat-error *ngIf="
                        newCustomerForm.get('email')!.touched &&
                        newCustomerForm.get('email')!.hasError('maxlength')
                      ">
                           Email must be at most 50 characters long.
                        </mat-error>
                    </mat-form-field>
                    <div *ngIf="newCustomerForm.get('email')!.touched" class="error">
                        <!-- Required Error - shows only if empty -->
                        <div *ngIf="newCustomerForm.get('email')!.hasError('required')" class="error">
                            Email ID is required.
                        </div>
                        <!-- Invalid Email Format Error - shows only if not empty but invalid -->
                        <div *ngIf="newCustomerForm.get('email')!.hasError('invalidEmail') && !newCustomerForm.get('email')!.hasError('required')"
                            class="error">
                            Please enter a valid email address.
                        </div>
                    </div>
                </div>
                <div class="form-wrapper">
                    <label for="">Work Phone</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter phone number" formControlName="phone" />
                    </mat-form-field>
                    <div *ngIf="newCustomerForm.get('phone')!.touched" class="error">
                        <!-- Required Error - only if field is empty -->
                        <div *ngIf="newCustomerForm.get('phone')!.hasError('required')" class="error">
                            Work phone number is required.
                        </div>
                        <!-- Min Length Error - only if value is present but below minimum length -->
                        <div *ngIf="newCustomerForm.get('phone')!.hasError('minlength') && !newCustomerForm.get('phone')!.hasError('required')" class="error">
                            Work phone number must be at least 10 digits.
                        </div>
                        <!-- Max Length Error - only if value is present but above maximum length -->
                        <div *ngIf="newCustomerForm.get('phone')!.hasError('maxlength') && !newCustomerForm.get('phone')!.hasError('required')" class="error">
                            Work phone number cannot exceed 15 digits.
                        </div>
                        <!-- Pattern Error - only if value is present but invalid pattern -->
                        <div *ngIf="newCustomerForm.get('phone')!.hasError('pattern') && !newCustomerForm.get('phone')!.hasError('required')" class="error">
                            Invalid phone number. Only digits are allowed.
                        </div>
                    </div>
                </div>
                
                <div class="form-wrapper">
                    <label for="">Mobile</label>
                    <mat-form-field>
                        <input matInput placeholder="Enter mobile number" formControlName="mobile" />
                    </mat-form-field>
                    <div *ngIf="newCustomerForm.get('mobile')!.touched" class="error">
                        <!-- Required Error - only if field is empty -->
                        <div *ngIf="newCustomerForm.get('mobile')!.hasError('required')" class="error">
                            Mobile number is required.
                        </div>
                        <!-- Min Length Error - only if value is present but below minimum length -->
                        <div *ngIf="newCustomerForm.get('mobile')!.hasError('minlength') && !newCustomerForm.get('mobile')!.hasError('required')" class="error">
                            Mobile number must be at least 10 digits.
                        </div>
                        <!-- Max Length Error - only if value is present but above maximum length -->
                        <div *ngIf="newCustomerForm.get('mobile')!.hasError('maxlength') && !newCustomerForm.get('mobile')!.hasError('required')" class="error">
                            Mobile number cannot exceed 15 digits.
                        </div>
                        <!-- Pattern Error - only if value is present but invalid pattern -->
                        <div *ngIf="newCustomerForm.get('mobile')!.hasError('pattern') && !newCustomerForm.get('mobile')!.hasError('required')" class="error">
                            Invalid mobile number. Only digits are allowed.
                        </div>
                    </div>
                </div>
            </div>

            <div class="common-noimage-tab-wrapper">
                <mat-tab-group animationDuration="0ms">
                    <!-- Mat Tab One -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Other Details</h2>
                        </ng-template>
                        <app-other-details [customerData]="customerData"
                            (formSubmit)="collectOtherDetails($event)"></app-other-details>
                    </mat-tab>
                    <!-- Mat Tab Two -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Address</h2>
                        </ng-template>
                        <app-address [customerData]="customerData" (formSubmit)="collectAddress($event)"></app-address>
                    </mat-tab>
                    <!-- Mat Tab Three -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Contact Persons</h2>
                        </ng-template>
                        <app-contact-presons [customerData]="customerData"
                            (formSubmit)="collectContactDetails($event)"></app-contact-presons>
                    </mat-tab>
                    <!-- Mat Tab Four -->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <h2 class="leave-tab-title">Remarks</h2>
                        </ng-template>
                        <app-remarks [customerData]="customerData"
                            (formSubmit)="collectRemarkDetails($event)"></app-remarks>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <!-- <div *ngIf="validationError" class="error" style="text-align: center; margin: 0">
                Please fill mandatory fields!
              </div> -->
            <div class="submit-btn-wrapper">
                <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
                <button class="cmn-next-btn" type="submit">Save</button>
            </div>
        </form>
    </section>
