import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { PUNCH_IN, PUNCH_OUT } from '../mutation/dashboard.mutation';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  isPunchedOut = true;
  punchTime: string | null = null;
  interval: any = null;
  timeDifference: string | null = null;

  constructor(private apollo: Apollo) { }

  clearInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }
  
  punchIn(time: any, date: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: PUNCH_IN,
      variables: {
        time,
        date
      },
      fetchPolicy: 'network-only'
    }).pipe(
      map((result: any) => result.data.punchIn || [])
    );
  }

  punchOut(time: any, date: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: PUNCH_OUT,
      variables: {
        time,
        date
      },
      fetchPolicy: 'network-only'
    }).pipe(
      map((result: any) => result.data.punchOut || [])
    );
  }


}