
  <section class="cmn-innerpage-wrapper invoice">
    <div class="head">
      <div class="search-wrapper">
        <input
          type="text"
          class="cmn-header-search"
          placeholder="Search"
          (input)="onSearch($event)"
        />
      </div>
      <button class="cmn-add-btn" (click)="navigateTo('invoice/invoice-add')">
        <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New
        Invoice
      </button>
      <button
        class="cmn-filter-btn"
        (click)="toggleDropdown($event)"
        [ngClass]="{ 'class-clicked': isDropdownOpen }"
      >
        <img src="assets/images/icons/icon-filter.svg" alt="Filter Icon" />
        <!-- <span *ngIf="selectedCount > 0">{{ selectedCount }}</span> -->
      </button>
      <!-- Filter -->
      <!-- <div
        [ngClass]="{ show: isDropdownOpen }"
        class="common-dropdown filter-position"
        #dropdown
      >
        <button
          class="close-dropdown"
          (click)="toggleDropdown($event)"
        ></button>
        <form>
          <h6 class="cmn-popup-heading">Filter <span>Clear All</span></h6>
          <div class="filter-inner-content">
            <h2>Branch</h2>
            <div class="check-wrapper">
              <div class="check-box">
                <label class="checkbox-wrapper">
                  <input type="checkbox" />
                  test
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="btn-wrapper">
            <button class="cmn-apply-btn" type="submit">Apply</button>
          </div>
        </form>
      </div> -->
      <!-- Filter -->
    </div>
    <div class="sub-head">
      <div class="head-box total-box">
        <img src="assets/images/img-invoice.svg" alt="Invoice" />
        <div>
          <h2>
            Total
            <span
              >AED {{ invoiceDashboardData?.totalOutstandingReceivables }}</span
            >
          </h2>
          <h3>Outstanding Receivables</h3>
        </div>
      </div>
      <div class="head-box">
        <h4>Due Today</h4>
        <h5>AED {{ invoiceDashboardData?.dueToday }}</h5>
      </div>
      <div class="head-box">
        <h4>Due Within 30 Days</h4>
        <h6>AED {{ invoiceDashboardData?.dueWithin30Days }}</h6>
      </div>
      <div class="head-box">
        <h4>Overdue Invoice</h4>
        <h6>AED {{ invoiceDashboardData?.overdueInvoice }}</h6>
      </div>
    </div>
    <div class="inner-content">
      <ag-grid-angular
        style="width: 100%"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowSelection]="rowSelection"
        [suppressRowClickSelection]="true"
        [pagination]="true"
        [paginationPageSize]="paginationPageSize"
        [paginationPageSizeSelector]="paginationPageSizeSelector"
        [class]="themeClass"
        (cellClicked)="onCellClicked($event)"
      />
    </div>
  </section>

