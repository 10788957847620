<div class="overview-main-wrapper">
    <div class="content">
        <h2>
            {{partnerDetails?.partner?.displayName }} <span>{{partnerDetails?.partner?.companyName}}</span>
        </h2>
        <h3>{{partnerDetails?.partner?.customerType }}</h3>
        <h4>
            <img src="assets/images/icons/icon-mail.svg" alt="Icon Mail" />
            {{partnerDetails?.partner?.email }}
        </h4>
    </div>
    <div class="client-details-overview-wrapper">
        <div class="details-wrapper">
            <h2>Phone Number</h2>
            <h3>{{partnerDetails?.partner?.mobile }}</h3>
        </div>
        <div class="details-wrapper">
            <h2>Work Phone</h2>
            <h3>{{partnerDetails?.partner?.phone }}</h3>
        </div>
        <div class="details-wrapper">
            <h2>Emirates Id</h2>
            <h3>{{partnerDetails?.partner?.emirateID }}</h3>
        </div>
    </div>

    <div class="address-details-wrapper" *ngFor="let partnerDetail of partnerDetails?.partner?.addresses">
        <div *ngIf="partnerDetail?.addressType === 'BILLING'">
            <h2>Billing Address</h2>
            <h3>{{partnerDetail?.attention }}</h3>
            <h4>{{partnerDetail?.addressLine1 }} {{partnerDetail?.addressLine2 }}</h4>
            <h4>{{partnerDetail?.state }} {{partnerDetail?.country }} {{partnerDetail?.pinCode }}</h4>
        </div>
        <div *ngIf="partnerDetail?.addressType === 'SHIPPING'">
            <h2>Shipping Address</h2>
            <h3>{{partnerDetail?.attention }}</h3>
            <h4>{{partnerDetail?.addressLine1 }} {{partnerDetail?.addressLine2 }}</h4>
            <h4>{{partnerDetail?.state }} {{partnerDetail?.country }} {{partnerDetail?.pinCode }}</h4>
        </div>
    </div>
    <div *ngFor="let partnerDetail of partnerDetails?.partner?.contacts">
        <div class="content">
            <h1>Contact Person</h1>
            <h2>
                {{partnerDetail?.salutation}} {{partnerDetail?.firstName}}
                {{partnerDetail?.lastName}}<span>{{partnerDetails?.partner?.companyName}}</span>
            </h2>
            <h3>{{partnerDetails?.partner?.customerType}}</h3>
            <h4>
                <img src="assets/images/icons/icon-mail.svg" alt="Icon Mail" />
                {{partnerDetail?.email}}
            </h4>
        </div>
        <div class="client-details-overview-wrapper">
            <div class="details-wrapper">
                <h2>Phone Number</h2>
                <h3> {{partnerDetail?.mobile}}</h3>
            </div>
            <div class="details-wrapper">
                <h2>Work Phone</h2>
                <h3> {{partnerDetail?.phone}}</h3>
            </div>
        </div>
    </div>

</div>