<div class="tab-content-wrapper">
  <form [formGroup]="branchAccessForm" (ngSubmit)="onNext()">
    <div class="employee-form-wrapper">
      <div class="form-wrapper">
        <label for="">Select Branch</label>
        <mat-form-field>
          <mat-select placeholder="Select Branch" formControlName="branchName">
            <mat-option *ngFor="let branch of branchData" [value]="branch.id">
              {{ branch.branchName }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              branchAccessForm.get('branchName')!.touched &&
              branchAccessForm.get('branchName')!.hasError('required')
            "
            >Branch Name is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Select Employee Approver</label>
        <mat-form-field>
          <mat-select
            placeholder="Select Employee Approver"
            (selectionChange)="onApproverSelected($event)"
            formControlName="employeeApprover"
          >
            <mat-option *ngFor="let approver of approversData" [value]="approver?.id">
              {{ approver.firstName + ' ' + approver.lastName }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="branchAccessForm.get('employeeApprover')!.touched && branchAccessForm.get('employeeApprover')!.hasError('required')"
          >
            Employee Approver is required.
          </mat-error>
        </mat-form-field>
      </div>
      
      <div *ngIf="approverNames.length > 0">
        <p *ngFor="let names of approverNames; let i = index">
          Level {{ i + 1 }} Approver:
          {{ names }}
        </p>
      </div>
      <div *ngIf="approverNamesData && approverNames.length === 0">
        <p *ngFor="let names of approverNamesData; let i = index">
          Level {{ i + 1 }} Approver:
          {{ names }}
        </p>
      </div>
      
    </div>

    <div class="form-wrapper">
      <label class="checkbox-wrapper">
        <input type="checkbox" formControlName="isPortalUser" />Is Portal User
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="employee-form-wrapper">
      <div
        *ngIf="branchAccessForm.get('isPortalUser')?.value"
        class="form-wrapper"
      >
        <label for="">Select User Role</label>
        <mat-form-field>
          <mat-select placeholder="Select User Role" formControlName="userRole">
            <mat-option *ngFor="let role of userRolesData" [value]="role.id">
              {{ role.profileName }}
            </mat-option> </mat-select
          ><mat-error
            *ngIf="
              branchAccessForm.get('userRole')!.touched &&
              branchAccessForm.get('userRole')!.hasError('required')
            "
            >User role is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div
        *ngIf="
          branchAccessForm.get('isPortalUser')?.value &&
          (!isEditMode || !initialIsPortalUser)
        "
        class="form-wrapper"
      >
        <label for="">Password</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Password"
            formControlName="password"
          />
          <mat-error
            *ngIf="
              branchAccessForm.get('password')!.touched &&
              branchAccessForm.get('password')!.hasError('required')
            "
          >
            Password is required.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="submit-btn-wrapper">
      <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
      <button class="cmn-next-btn" type="submit">Next</button>
    </div>
  </form>
</div>
