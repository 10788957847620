
    <section class="cmn-innerpage-wrapper bill-details">
        <div class="head">
            <div class="search-wrapper">
                <input type="text" class="cmn-header-search" placeholder="Search" (input)="onSearch($event)" />
            </div>
            <!-- <button class="cmn-upload-btn" (click)="openDialog()">
                <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                <span>Upload</span>
            </button> -->
            <button class="cmn-add-btn" (click)="navigateTo('/bills/bill-new')">
                <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New Bill
            </button>
            <!-- <button class="cmn-filter-btn" (click)="toggleDropdown($event)"
                [ngClass]="{ 'class-clicked': isDropdownOpen }">
                <img src="assets/images/icons/icon-filter.`svg" alt="Filter Icon" />
            </button> -->
            <!-- <span *ngIf="selectedCount > 0">{{ selectedCount }}</span> -->
            <!-- Filter -->
            <!-- <div [ngClass]="{ show: isDropdownOpen }" class="common-dropdown filter-position" #dropdown>
                <button class="close-dropdown" (click)="toggleDropdown($event)"></button>
                <form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
                    <h6 class="cmn-popup-heading">Filter <span (click)="clearFilter()">Clear All</span></h6>
                    <div class="filter-inner-content">
                        <h2>Branch</h2>
                        <div class="check-wrapper">
                            <div class="check-box">
                                <label class="checkbox-wrapper">
                                    <input type="checkbox" />
                                    test
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrapper">
                        <button class="cmn-apply-btn" type="submit">Apply</button>
                    </div>
                </form>
            </div> -->
            <!-- Filter -->
        </div>
        <div class="sub-head">
            <ul>
                <li class="main">All Bills</li>
                <li class="edit" (click)="toggleMailDropdown($event)"><img src="assets/images/icons/icon-send.svg" alt="Mail">Mail Logs
                </li>
                <li class="edit" (click)="onEdit()"><img src="assets/images/icons/icon-edit-accounts.svg" alt="Edit">
                    Edit</li>
                <li class="edit" (click)="onRecordPayment()"><img src="assets/images/icons/icon-record.svg"
                        alt="Record"> Record Payment</li>
            </ul>
            <div [ngClass]="{ show: isMailDropdownOpen }" class="common-dropdown filter-position" #dropdown>
                <button class="close-dropdown" (click)="toggleMailDropdown($event)"></button>
                <form [formGroup]="mailForm" (ngSubmit)="onMail()">
                    <h6 class="cmn-popup-heading">
                        Mails
                    </h6>
                    <div class="filter-inner-content">
                        <div class="form-wrapper">
                            <mat-form-field>
                                <textarea matInput placeholder="Add Your Mail Here..."
                                    formControlName="mails"></textarea>
                            </mat-form-field>
                        </div>
                        <div class="btn-wrapper">
                            <button class="cmn-apply-btn" type="submit">Comment</button>
                        </div>
                        <div class="timelinne-wrapper">
                            <!-- ngFor -->
                            <div class="timeline-box" *ngFor="let mail of mails">
                                <div class="timeline-head">
                                    <ul>
                                        <li>{{mail?.employee?.firstName || '--'}}</li>
                                        <li>{{mail?.createdAt | date: 'dd/MM/YYYY'}}</li>
                                    </ul>
                                    <h3>{{mail?.createdAt | date: 'hh:mm a'}}</h3>
                                </div>
                                <div class="timeline-content">
                                    <h4>{{mail?.note || '--'}}</h4>
                                </div>
                            </div>
                            <!-- ngFor -->
                        </div>
                    </div>
                </form>
            </div>
            <div class="mat-menu-wrapper">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="openBiilsApplyCreditDialog()">
                        <span>Use Credit</span>
                    </button>
                    <button mat-menu-item [routerLink]="['/purchase-order-edit']">
                        <span>View Purchase Order</span>
                    </button>
                    <button mat-menu-item [routerLink]="['/debit-note-edit']">
                        <span>Create Debit Note</span>
                    </button>
                    <button mat-menu-item *ngIf="billDetails?.journalId != null" (click)="editJournal()">
                        <span>View Journal</span>
                    </button>
                    <!-- <button mat-menu-item>
                        <span>Clone</span>
                    </button> -->
                    <button mat-menu-item *ngIf="billDetails?.billStatus === 'Draft'" (click)="markAsSent()">
                        <span>Mark As Sent</span>
                    </button>
                    <button mat-menu-item (click)="onConfirmDelete()">
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </div>
        </div>
        <div class="manual-journal-details-tab-wrapper">
            <div class="tab-left">
                <div class="tab-box-main-wrapper" *ngFor="let bill of allBills; let i = index">
                    <button class="tab-custom-button" [class.active]="selectedTabIndex === i" (click)="selectTab(i)">
                        <div class="inn-box">
                            <h2>{{bill?.partnerData?.displayName}}</h2>
                            <h6>{{bill?.billDate | date:'dd/MM/YYYY'}}</h6>
                        </div>
                        <div class="inn-box">
                            <div>
                                <h3>{{bill?.billNo}} -</h3>
                                <!-- <h4 class="red">Over Due 19 days</h4> -->
                                <h4 class="red">Due on {{bill?.dueDate | date:'dd/MM/YYYY' }}</h4>
                            </div>
                            <h5>AED <span>{{bill?.subTotal}}</span></h5>
                        </div>
                    </button>
                </div>
            </div>
            <div class="manual-journal-details-content-wrapper">
                <div>
                    <div class="chart-tab-content">
                        <div class="tab-container">
                            <div class="tab-inner">
                                <div class="note-header">
                                    <div class="left-set">
                                        <h4>{{billDetails?.billingAddressData?.attention}}</h4>
                                        <h3>{{billDetails?.billingAddressData?.state}}</h3>
                                        <h3>{{billDetails?.billingAddressData?.country}}
                                            {{billDetails?.billingAddressData?.pinCode}}</h3>
                                    </div>
                                    <div class="right-set">
                                        <h2>BILL</h2>
                                        <h3>#{{billDetails?.billNo}}</h3>
                                        <h3>{{billDetails?.billDate}}</h3>
                                    </div>
                                </div>

                                <div class="journal-details">
                                    <div class="left-set">
                                        <div class="details-box">
                                            <h4>Bill from </h4>
                                            <h5>:</h5>
                                            <h6>{{billDetails?.partnerData?.displayName}}</h6>
                                        </div>
                                    </div>
                                    <div class="right-set">
                                        <div class="details-box">
                                            <h5>Balance Due</h5>
                                            <h6>AED {{billDetails?.balance || '0.00'}}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="page-footer-content">
                                    <div class="right-set">
                                        <div class="details-box">
                                            <h4>Order Number</h4>
                                            <h5>:</h5>
                                            <h6>{{billDetails?.orderNo || '--'}}</h6>
                                        </div>
                                        <div class="details-box">
                                            <h4>Bill Date</h4>
                                            <h5>:</h5>
                                            <h6>{{billDetails?.billDate | date:'dd/MM/YYYY'}}</h6>
                                        </div>
                                        <div class="details-box">
                                            <h4>Due Date</h4>
                                            <h5>:</h5>
                                            <h6>{{billDetails?.dueDate | date:'dd/MM/YYYY' }}</h6>
                                        </div>
                                        <div class="details-box">
                                            <h4>Terms</h4>
                                            <h5>:</h5>
                                            <h6>{{billDetails?.paymentTerm || '--'}}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="crdit-note-page-table">
                                    <table>
                                        <tr>
                                            <th>#</th>
                                            <th>Item & Description</th>
                                            <th>Qty</th>
                                            <th>Rate</th>
                                            <th>Amount</th>
                                        </tr>
                                        <tr *ngFor="let item of billDetails?.billItems">
                                            <td>{{item?.id}}</td>
                                            <td>{{item?.itemData?.itemName}}</td>
                                            <td>{{item?.quantity}}</td>
                                            <td>AED {{item?.rate}} </td>
                                            <td>AED {{item?.amount}} </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="page-footer-content">
                                    <div class="right-set">
                                        <div class="details-box">
                                            <h4>Sub total</h4>
                                            <h5>:</h5>
                                            <h6>AED {{billDetails?.subTotal || '0.00'}}</h6>
                                        </div>
                                        <div class="details-box">
                                            <h4>Total</h4>
                                            <h5>:</h5>
                                            <h6>AED {{billDetails?.totalPrice || '0.00'}}</h6>
                                        </div>
                                        <div class="details-box">
                                            <h4>Credits Used</h4>
                                            <h5>:</h5>
                                            <h6>AED {{billDetails?.credit || '0.00'}}</h6>
                                        </div>
                                    </div>
                                </div>

                                <p class="signature">Authorized Signature <span></span></p>

                                <span class="published-note" *ngIf="billDetails?.billStatus === 'Open'"><img
                                        src="assets/images/img-open.svg" alt="Open"></span>
                                <span class="published-note" *ngIf="billDetails?.billStatus === 'Draft'"><img
                                        src="assets/images/img-draft.svg" alt="Draft"></span>
                                <span class="published-note" *ngIf="billDetails?.billStatus === 'Paid'"><img
                                        src="assets/images/img-overdue.svg" alt="Overdue"></span>
                            </div>
                        </div>
                        <div class="credit-note-footer-table-wrapper">
                            <table>
                                <tr class="title">
                                    <td>Journal</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Account</td>
                                    <td>Debit</td>
                                    <td>Credit</td>
                                </tr>
                                <tr class="bold" *ngFor="let journal of billDetails?.journalData">
                                    <td>{{journal?.accountName || '--' }}</td>
                                    <td>AED {{journal?.debit || '--' }}</td>
                                    <td>AED {{journal?.credit || '--' }}</td>
                                </tr>
                                <!-- <tr class="bold border">
                                    <td>Personal (Current)</td>
                                    <td>AED 3,500.00</td>
                                    <td>AED 0.00</td>
                                </tr>
                                <tr class="bold">
                                    <td></td>
                                    <td>AED 3,500.00</td>
                                    <td>AED 3,500.00</td>
                                </tr> -->
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <button type="button" (click)="openBiilsApplyCreditDialog()">Dialog</button> -->
    </section>
