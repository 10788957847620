import {
    Component,
    EventEmitter,
    Inject,
    OnInit,
    Output,
    inject,
    HostListener
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutComponent} from '../../core/layout/layout.component';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef} from 'ag-grid-community';
import {Subject, debounceTime, distinctUntilChanged} from 'rxjs';
import {BranchService} from '../../features/branch/services/branch.service';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {HttpClient} from '@angular/common/http';
import {MatOptionModule} from '@angular/material/core';
import {customEmailValidator, numbersOnlyValidator} from '../../shared/services/validations';
import {ConfirmDialog} from '../../shared/components/confirm-dialog/confirm-dialog';
import {Router} from '@angular/router';
import {PaginationHelper} from '../../shared/services/pagination.service';

@Component({
    selector: 'app-branch',
    standalone: true,
    imports: [
        LayoutComponent, AgGridAngular, MatButtonModule, MatDialogModule
    ],
    templateUrl: './branch.component.html',
    styleUrls: ['./branch.component.scss']
})
export class BranchComponent {
    public viewPortSize : boolean = false;
    public rowData : any[] = [];
    public columnDefs : ColDef[] = [
        {
            field: 'branchName',
            headerName: 'Branch Name',
            flex: this.viewPortSize ? 1 : 0
        },
        {
            field: 'location',
            headerName: 'Location',
            flex: this.viewPortSize ? 1 : 0
        },
        {
            field: 'branchCode',
            headerName: 'Branch Code',
            flex: this.viewPortSize ? 1 : 0
        },
        {
            field: 'employeeCount',
            headerName: 'Total Employees',
            filter: 'agNumberColumnFilter',
            flex: this.viewPortSize ? 1 : 0
        }, {
            field: 'projectInProgress',
            headerName: 'Projects In Progress',
            filter: 'agNumberColumnFilter',
            flex: this.viewPortSize ? 1 : 0
        }, {
            field: 'action',
            headerName: 'Action',
            flex: this.viewPortSize ? 1 : 0,
            cellRenderer: (params : any) => {
                const div = document.createElement('div');
                div.style.display = 'flex';
                div.style.alignItems = 'center';
                div.style.gap = '20px';

                const createButton = (iconSrc : string, onClick : () => void) => {
                    const span = document.createElement('span');
                    span.style.cursor = 'pointer';
                    span.style.display = 'flex';
                    span.innerHTML = `<img src="${iconSrc}"/>`;
                    span.addEventListener('click', onClick);
                    return span;
                };
                div.appendChild(createButton('assets/images/icons/icon-edit.svg', () => this.openEditDialog(params ?. data)));
                div.appendChild(createButton('assets/images/icons/icon-delete.svg', () => this.onDeleteBranch(params.data ?. id)));

                return div;
            },
            floatingFilter: false,
            filter: false
        },
    ];
    public defaultColDef : ColDef = {
        filter: 'agTextColumnFilter',
        floatingFilter: true
    };
    public rowSelection : 'single' | 'multiple' = 'multiple';
    public paginationPageSize = 10;
    public paginationPageSizeSelector : number[] | boolean = [10];
    public themeClass : string = 'ag-theme-quartz';
    public loading : boolean = true;
    public error : any = null;

    private searchSubject = new Subject<string>();

    constructor(private branchService : BranchService, private toastr : ToastrService) {}

    ngOnInit() {
        this.fetchBranchData();

        this.searchSubject.pipe(debounceTime(600), distinctUntilChanged()).subscribe((searchTerm) => {
            this.fetchBranchData(searchTerm);
        });

        this.checkViewportSize();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event : any) {
        this.checkViewportSize();
    }

    checkViewportSize() {
        if (window.innerWidth > 1200) {
            this.viewPortSize = true;
        } else {
            this.viewPortSize = false;
        }
        this.setAGColumn();
    }

    setAGColumn() {
        this.columnDefs = [
            {
                field: 'branchName',
                headerName: 'Branch Name',
                flex: this.viewPortSize ? 1 : 0
            },
            {
                field: 'location',
                headerName: 'Location',
                flex: this.viewPortSize ? 1 : 0
            },
            {
                field: 'branchCode',
                headerName: 'Branch Code',
                flex: this.viewPortSize ? 1 : 0
            },
            {
                field: 'employeeCount',
                headerName: 'Total Employees',
                filter: 'agNumberColumnFilter',
                flex: this.viewPortSize ? 1 : 0
            }, {
                field: 'projectInProgress',
                headerName: 'Projects In Progress',
                filter: 'agNumberColumnFilter',
                flex: this.viewPortSize ? 1 : 0
            }, {
                field: 'action',
                headerName: 'Action',
                flex: this.viewPortSize ? 1 : 0,
                cellRenderer: (params : any) => {
                    const div = document.createElement('div');
                    div.style.display = 'flex';
                    div.style.alignItems = 'center';
                    div.style.gap = '20px';

                    const createButton = (iconSrc : string, onClick : () => void) => {
                        const span = document.createElement('span');
                        span.style.cursor = 'pointer';
                        span.style.display = 'flex';
                        span.innerHTML = `<img src="${iconSrc}"/>`;
                        span.addEventListener('click', onClick);
                        return span;
                    };
                    div.appendChild(createButton('assets/images/icons/icon-edit.svg', () => this.openEditDialog(params ?. data)));
                    div.appendChild(createButton('assets/images/icons/icon-delete.svg', () => this.onDeleteBranch(params.data ?. id)));

                    return div;
                },
                floatingFilter: false,
                filter: false
            },
        ];
    }

    onDeleteBranch(branchId : number): void {
        const dialogRef = this.dialog.open(ConfirmDialog);

        dialogRef.afterClosed().subscribe((result) => {
            if (result === true) {
                this.deleteBranch(branchId);
            }
        });
    }

    private deleteBranch(branchId : number): void {
        this.branchService.removeBranchById(branchId).subscribe({
            next: () => {
                this.toastr.success('Branch deleted successfully!', 'Success');
                this.fetchBranchData();
            },
            error: () => {
                this.toastr.error('Failed to delete branch. Please try again.', 'Error');
            }
        });
    }

    private fetchBranchData(search : string = '') {
        this.loading = true;
        this.branchService.fetchBranches(search).subscribe({
            next: (branches) => {
                let inProgressCount = 0;

                // Sort the branches by 'id' in descending order so the newest branch comes first
                this.rowData = branches.map((branch : any) => {
                    const projectsInProgress = Array.isArray(branch ?. projects) ? branch.projects.filter((project
                    : any) => project.status === 'In-Progress') : [];

                    inProgressCount += projectsInProgress.length;

                    return {
                        id: branch ?. id || '--',
                        branchName: branch ?. branchName || '--',
                        location: branch ?. city || '--',
                        employeeCount: Array.isArray(branch ?. employee) ? branch.employee.length : 0,
                        branchCode: branch ?. branchCode || '--',
                        projectInProgress: projectsInProgress.length,
                        address: branch ?. address,
                        city: branch ?. city,
                        state: branch ?. state,
                        country: branch ?. country,
                        email: branch ?. email,
                        zipCode: branch ?. zipCode,
                        phoneNumber: branch ?. phoneNumber
                    };
                }).sort((a : any, b : any) => b.id - a.id);
                // Sort by ID in descending order

                // Update pagination options based on the rowData length
                this.paginationPageSizeSelector = PaginationHelper.getPaginationPageSizeOptions(this.rowData);

                this.loading = false;
            },
            error: (error) => {
                this.error = error;
                this.loading = false;
            }
        });
    }

    onSearch(event : Event) {
        const input = event.target as HTMLInputElement;
        const searchTerm = input.value;
        this.searchSubject.next(searchTerm);
    }

    readonly dialog = inject(MatDialog);

    openConfirm() {
        const dialogRef = this.dialog.open(ConfirmDialog);
    }

    openDialog() {
        const dialogRef = this.dialog.open(AddBranchDialog, {disableClose: true});

        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'success') {
                this.fetchBranchData();
            }
        });
    }

    openEditDialog(branchId : any) {
        const dialogRef = this.dialog.open(AddBranchDialog, {
            disableClose: true,
            data: branchId
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'success') {
                this.fetchBranchData();
            }
        });
    }
}

const gridDiv = document.querySelector<HTMLElement>('#myGrid')!;

@Component({
    selector: 'add-branch-dialog',
    templateUrl: './add-branch-dialog.html',
    styleUrls: ['./branch.component.scss'],
    standalone: true,
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatOptionModule,
        CommonModule,
    ]
})
export class AddBranchDialog implements OnInit {
    @Output()branchAdded = new EventEmitter<any>();
    branchForm !: FormGroup;
    countries : any[] = [];
    branchData : any;
    loading : boolean = false;

    constructor(private branchService : BranchService, private toastr : ToastrService, private dialogRef : MatDialogRef < AddBranchDialog >, private http : HttpClient, private fb : FormBuilder, @Inject(MAT_DIALOG_DATA)public data : any) {}

    ngOnInit(): void {
        if (this.data) {
            this.branchData = this.data;
        }
        this.initForm();
        this.loadCountries();
        this.generateBranchCode();
    }

    initForm(): void {
        this.branchForm = this.fb.group({
            id: [this.branchData ?. id || ''],
            branchName: [
                this.branchData ?. branchName || '',
                Validators.required
            ],
            address: [
                this.branchData ?. address || '',
                Validators.required
            ],
            city: [
                this.branchData ?. city || '',
                Validators.required
            ],
            state: [
                this.branchData ?. state || '',
                Validators.required
            ],
            country: [
                this.branchData ?. country || '',
                Validators.required
            ],
            zipCode: [
                this.branchData ?. zipCode || '',
                Validators.required
            ],
            phoneNumber: [
                this.branchData ?. phoneNumber || '',
                [
                    Validators.required, Validators.minLength(7), Validators.maxLength(15), numbersOnlyValidator(),
                ],
            ],
            email: [
                this.branchData ?. email || '',
                [
                    Validators.required, customEmailValidator()
                ],
            ],
            branchCode: [
                this.branchData ?. branchCode || '',
                Validators.required
            ]
        });
    }

    loadCountries(): void {
        this.http.get<any[]>('assets/json/countries.json').subscribe((data) => {
            this.countries = data;
        }, (error) => {
            console.log('Error loading country data----', error);
        });
    }

    generateBranchCode(): void {
        const randomSuffix = Math.floor(1000 + Math.random() * 9000);
        const branchCode = `BR${randomSuffix}`;
        this.branchForm.patchValue({branchCode});
    }

    onAddBranch(): void {
        if (!this.branchData) {
            this.loading = true;
            this.branchForm.markAllAsTouched();
            if (this.branchForm.valid) {
                const branchData = {
                    ...this.branchForm.value,
                    isHeadOffice: 0
                };
                let {
                    branchName,
                    address,
                    city,
                    state,
                    country,
                    zipCode,
                    phoneNumber,
                    email,
                    branchCode
                } = this.branchForm.value;

                const createBranchInput = {
                    branchName,
                    address,
                    city,
                    state,
                    country,
                    zipCode,
                    phoneNumber,
                    email,
                    branchCode
                };
                this.branchService.createBranch(createBranchInput).subscribe((response) => {
                    this.loading = false;
                    this.toastr.success('Branch added successfully!', 'Success', {
                        timeOut: 5000,
                        progressBar: true,
                        closeButton: true
                    });
                    this.dialogRef.close('success');
                }, (error) => {
                    this.loading = false;
                    let errorMessage = 'Failed to add branch. Please try again.';
                    if (error.error && error.error.message) {
                        errorMessage = error.error.message;
                    } else if (error.message === 'Branch is not created!') {
                        errorMessage = 'Branch already exist with this email!';
                    } else if (error.message) {
                        errorMessage = error.message;
                    }

                    this.toastr.error(errorMessage, 'Error', {
                        timeOut: 5000,
                        progressBar: true,
                        closeButton: true
                    });
                });
            }
        } else {
            this.branchForm.markAllAsTouched();
            if (this.branchForm.valid) {
                this.loading = true;
                const branchData = {
                    ...this.branchForm.value,
                    isHeadOffice: 0
                };
                this.branchService.updateBranch(branchData).subscribe((response) => {
                    this.loading = false;
                    this.toastr.success('Branch updated successfully!', 'Success', {
                        timeOut: 5000,
                        progressBar: true,
                        closeButton: true
                    });
                    this.dialogRef.close('success');
                }, (error) => {
                    this.loading = false;
                    let errorMessage = 'Failed to update branch. Please try again.';
                    if (error.error && error.error.message) {
                        errorMessage = error.error.message;
                    } else if (error.message) {
                        errorMessage = error.message;
                    }

                    this.toastr.error('Failed to update branch. Please try again.', 'Error', {
                        timeOut: 5000,
                        progressBar: true,
                        closeButton: true
                    });
                });
            }
        }
    }
}
