import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { OverviewComponent } from './components/overview/overview.component';
import { StatementsComponent } from './components/statements/statements.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { transactionsComponent } from './components/transactions/transactions.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../../features/customers/services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { DialogRef } from '@angular/cdk/dialog';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadFileService } from '../../shared/services/file-upload.service';
import { ConfirmDialog } from '../../shared/components/confirm-dialog/confirm-dialog';

@Component({
  selector: 'app-client-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    MatSelectModule,
    MatIconModule,
    MatMenuModule,
    OverviewComponent,
    StatementsComponent,
    TimelineComponent,
    transactionsComponent,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './client-details.component.html',
  styleUrl: './client-details.component.scss'
})
export class ClientDetailsComponent {
  search: any;
  allCustomers: any;
  customerId: string | null;
  selectedTabIndex: any = 0;
  selectedOption = 'accounts';
  readonly dialog = inject(MatDialog);
  isDropdownOpen = false;
  isAttacheFile = false;
  partnerDetails: any;
  comments: any;
  commentForm: FormGroup;
  fileName: any = null;
  file: any;
  documentDetails: any = [];
  fileUrl: any;
  uploadForm: FormGroup;

  constructor(
    private router: Router,
    private customerService: CustomerService,
    private documentUploadService: UploadFileService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.fetchPartners();
    this.route.paramMap.subscribe(params => {
      this.customerId = params.get('id');
      if (this.customerId) {
        this.fetchPartners();
        this.fetchComments();
        this.partnerDetailById(this.customerId);
      }
    });

    this.commentForm = this.fb.group({
      comments: ['']
    });
    this.uploadForm = this.fb.group({
      file: ['']
    });
  }

  selectTab(index: number) {
    this.selectedTabIndex = index;
    const selectedCustomer = this.allCustomers[index];
    if (selectedCustomer) {
      this.router.navigate(['/customers/customer-details', selectedCustomer.id]);
    }
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadClientDetailsDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.fetchPartners();
    });
  }

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggleAttachFile(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isAttacheFile = !this.isAttacheFile;
  }

  partnerDetailById(id: any) {
    this.customerService.partnerDetails(Number(id)).subscribe({
      next: (data: any) => {
        this.partnerDetails = data;
      },
      error: (error) => {
        this.toastr.error(error?.message);
      },
    });
  }

  viewDocument(url: any) {
    const token = localStorage.getItem('AUTH_TOKEN');
    fetch(url, {
      method: 'GET',
      headers: {
        'authorization': token ? `Bearer ${token}` : '',
      }
    })
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      })
      .catch(error => console.error('Error:', error));
  }

  fetchPartners() {
    this.customerService.fetchPartners(this.search).subscribe({
      next: (data: any) => {
        this.allCustomers = data.filter((item: any) => item.partnerType === 'Customer');
        if (this.customerId) {
          const targetId = +this.customerId;
          this.selectedTabIndex = this.allCustomers.findIndex((c: any) => {
            return c.id === targetId;
          });
          if (this.selectedTabIndex === -1) {
            this.selectedTabIndex = 0;
          }
        } else {
          this.selectedTabIndex = 0;
        }
      },
      error: (error) => {
        this.toastr.error(error?.message);
      },
    });
  }

  onEdit() {
    this.router.navigate([`/customers/customer-edit/${this.customerId}`]);
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.file = file;
      this.fileName = this.file.name;
      this.onUpload();
    }
  }

  onUpload() {
    if (this.file) {
      this.documentUploadService.uploadDocument(this.file, 'customers').subscribe({
        next: (response) => {
          this.fileUrl = response.url;
          this.documentDetails = {
            partnerId: Number(this.customerId),
            documentName: this.fileName,
            documentUrl: this.fileUrl,
          };
          this.onUploadDocument();
        },
        error: (error) => {
          this.toastr.error(error, 'Error');
        },
      });
    }
  }

  onUploadDocument() {
    if (this.customerId) {
      this.customerService.createDocument(
        this.documentDetails
      ).subscribe({
        next: (data) => {
          if (data) {
            this.toastr.success('Document uploaded successfully', 'Success');
            this.partnerDetailById(this.customerId);
          }
        },
        error: (error) => this.toastr.error(error, 'Error'),
      });
    }
  }

  onRemoveDocument(id: any) {
    if (id) {
      const dialogRef = this.dialog.open(ConfirmDialog);
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.customerService.removeDocument(
            Number(id)
          ).subscribe({
            next: (data) => {
              if (data) {
                this.toastr.success('Document removed successfully', 'Success');
                this.partnerDetailById(this.customerId);
              }
            },
            error: (error) => this.toastr.error(error, 'Error'),
          });
        }
      })
    }
  }

  fetchComments() {
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;
    const conditions = {
      employeeId: employeeId,
      refId: Number(this.customerId),
      type: 'Customer',
      activityType: 'Comment'
    }
    this.customerService.comments(conditions).subscribe({
      next: (data) => {
        if (data) {
          this.comments = data;
        }
      },
      error: (error) => {
        console.error(error, 'Error');
      }
    })
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.search = input.value;
    this.fetchPartners();
  }

  onComment() {
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee?.employeeData?.id;
    const commentData = {
      employeeId: employeeId,
      refId: Number(this.customerId),
      type: 'Customer',
      activityType: 'Comment',
      note: this.commentForm.value.comments
    }
    this.customerService.createComment(commentData).subscribe({
      next: (data) => {
        if (data) {
          this.toastr.success('Comment added successfully!', 'Success');
          this.commentForm.reset();
          this.fetchComments();
        }
      },
      error: (error) => {
        console.error(error, 'Error')
      }
    })
  }

}



@Component({
  selector: 'upload-client-details-dialog',
  templateUrl: 'upload-client-details-dialog.html',
  styleUrls: ['./client-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadClientDetailsDialog {
  file: any = null;

  constructor(
    private customerService: CustomerService,
    private toastr: ToastrService,
    private dialogRef: DialogRef,
  ) { }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }

  onUpload() {
    if (this.file) {
      this.customerService.bulkCreateCustomer(this.file).subscribe({
        next: (response) => {
          console.log(response);

          if (response && !response.errors) {
            this.toastr.success('Customers uploaded successfully!', 'Success');
            this.dialogRef.close(response);
          }
          else if (response?.errors) {
            this.toastr.error(response?.errors[0]?.message, 'Error');
          }
        },
        error: (error) => {
          this.toastr.error(error, 'Bulk customer creation failed');
          this.dialogRef.close();
        },
      });
    }
  }

  removeFile(): void {
    console.log('remove');

    this.file = null;
    const input = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

}

