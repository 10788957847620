import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {
  COMMENTS,
  DEPOSIT_TO,
  GET_ACCOUNT,
  GET_DUE_BILLS_BY_PARTNER,
  GET_DUE_INVOICES_BY_PARTNER,
  GET_PARTNERS,
  GET_PAYMENT_DETAILS,
  PAYMENT,
  PAYMENTS,
  SEND_PAYMENT_RECIEVED_MAIL,
} from '../graphql/payments.queries';
import {
  CREATE_COMMENT,
  CREATE_PAYMENT,
  UPDATE_PAYMENT,
} from '../graphql/payments.mutations';

@Injectable({
  providedIn: 'root',
})
export class PaymentsItemService {
  constructor(private http: HttpClient, private apollo: Apollo) {}

  // munawel-accounting
  fetchPayments(search: string = '', filter: {}): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: PAYMENTS,
        variables: { search, filter },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data?.payments));
  }

  // munawel-project
  fetchPartners(search: string, type: string): Observable<any> {
    return this.apollo
      .use('project')
      .watchQuery<any>({
        variables: {
          search: search,
          partnerType: type,
        },
        query: GET_PARTNERS,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        map((response) => response.data?.getPartnersDropDownItems)
      );
  }

  // munawel-accounting
  fetchGetAccount(): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_ACCOUNT,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        map((response) => response.data?.getAccountDropDownItems)
      );
  }

  // munawel-accounting
  fetchDepositTo(
    search: string,
    isPaymementReceive: boolean,
    isPaymementMode: boolean
  ): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        variables: {
          search: search,
          isPaymementReceive: isPaymementReceive,
          isPaymementMode: isPaymementMode,
        },
        query: DEPOSIT_TO,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        map((response) => response.data?.getChartOfAccountDropDownItems)
      );
  }

  // munawel-project
  getDueInvoicesByPartner(partnerId: number, dateRange: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        variables: {
          partnerId: partnerId,
          dateRange: dateRange,
        },
        query: GET_DUE_INVOICES_BY_PARTNER,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        map((response) => response.data?.getDueInvoicesByPartner)
      );
  }

  // munawel-accounting
  getDueBillsByPartner(partnerId: number, dateRange: any): Observable<any> {
    return (
      this.apollo
        .use('accounts')
        // .use('ghanashyam')
        .watchQuery<any>({
          variables: {
            partnerId: partnerId,
            dateRange: dateRange,
          },
          query: GET_DUE_BILLS_BY_PARTNER,
          fetchPolicy: 'no-cache',
        })
        .valueChanges.pipe(
          map((response) => response.data?.getDueBillsByPartner)
        )
    );
  }

  // munawel-accounting
  createPayment(
    createPaymentInput: any,
    createPaymentAttachment: any,
    invoiceIds: any,
    billIds: any
  ): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: CREATE_PAYMENT,
      fetchPolicy: 'no-cache',
      variables: {
        createPaymentInput: createPaymentInput,
        createPaymentAttachment: createPaymentAttachment,
        invoiceIds: invoiceIds,
        billIds: billIds,
      },
    });
  }

  // munawel-accounting
  updatePayment(
    updatePaymentInput: any,
    updatePaymentAttachment: any,
    invoiceIds: any,
    billIds: any
  ): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: UPDATE_PAYMENT,
      fetchPolicy: 'no-cache',
      variables: {
        updatePaymentInput: updatePaymentInput,
        updatePaymentAttachment: updatePaymentAttachment,
        invoiceIds: invoiceIds,
        billIds: billIds,
      },
    });
  }

  //bulk upload holiday calendar
  bulkCreatePayment(file: File): Observable<any> {
    const token = localStorage.getItem('AUTH_TOKEN');
    const formData = new FormData();
    formData.append(
      'operations',
      JSON.stringify({
        query: `
      mutation($file: Upload!) {
        bulkCreatePayment(file: $file)
      }      
      `,
        variables: { file: null },
      })
    );

    formData.append('map', JSON.stringify({ '0': ['variables.file'] }));
    formData.append('0', file);

    return from(
      fetch(environment.url.accountsUrl, {
        method: 'POST',
        body: formData,
        headers: {
          authorization: token ? `Bearer ${token}` : '',
          'x-apollo-operation-name': 'uploadFile',
        },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.errors) {
            throw new Error(
              result.errors[0].message || 'Bulk Create Payment creation failed'
            );
          }
          return result.data?.bulkCreatePayment;
        })
    );
  }

  // munawel-accounting
  fetchPayment(id: number): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: PAYMENT,
        variables: { id },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data?.payment));
  }

  // munawel-accounting
  comments(conditions: any): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: COMMENTS,
        variables: { conditions: conditions },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data?.comments));
  }

  // munawel-accounting

  createComment(createCommentInput: any): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: CREATE_COMMENT,
      variables: { createCommentInput: createCommentInput },
      fetchPolicy: 'no-cache',
    });
  }

  // munawel-accounts
  getPaymentDetails(id: number, invoiceOrBillId: number): Observable<any> {
    return this.apollo
      .use('accounts')
      .watchQuery<any>({
        query: GET_PAYMENT_DETAILS,
        variables: { id: id, invoiceOrBillIds: invoiceOrBillId },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((response) => response.data?.getPaymentDetails));
  }

  // munawel-accounts
  sendPaymentRecievedMail(
    paymentId: any,
    to: any[],
    cc: any[],
    subject: any,
    body: any,
  ): Observable<any> {
    return this.apollo.use('accounts').mutate({
      mutation: SEND_PAYMENT_RECIEVED_MAIL,
      variables: {
        paymentId: paymentId,
        to: to,
        cc: cc,
        subject: subject,
        body: body,
      },
      fetchPolicy: 'no-cache',
    });
  }
}
