import {
    Component,
    Inject,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    inject
} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {EmployeeService} from '../../../../../../features/employees/services/employee.service';
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators
} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {EmployeeCareerTypeEnum} from '../../../../../../../assets/employeeCareerType';
import {ConfirmDialog} from '../../../../../../shared/components/confirm-dialog/confirm-dialog';

@Component({
    selector: 'app-career-history',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './career-history.html',
    styleUrl: '../../../../employee-details.component.scss'
})
export class CareerHistoryComponent implements OnChanges {
    @Input()employeeData : any;
    profileId : number | undefined;
    public warnings : any[] = [];
    public promotions : any[] = [];
    readonly dialog = inject(MatDialog);
    private isDialogOpen = false;

    constructor(private employeeService : EmployeeService, private toastr : ToastrService) {}

    ngOnChanges(changes : SimpleChanges): void {
        if (changes['employeeData'] && this.employeeData) {
            console.log('employeeData', this.employeeData);
            this.profileId = this.employeeData.id;
            if (this.profileId && !this.isDialogOpen) {
                this.fetchEmployeeCareerById(this.profileId);
            }
        }
    }

    fetchEmployeeCareerById(profileId : number) {
        this.employeeService.getEmployeeById(profileId).subscribe({
            next: (profile) => {
                console.log('career data:', profile);
                if (profile && profile.employeeCareerData) {
                    this.promotions = profile.employeeCareerData.filter((data : any) => data.type === 'Promotion').map((data : any) => ({
                        id: data ?. id,
                        currentPosition: data ?. currentPosition,
                        previousPosition: data ?. previousPosition,
                        date: data ?. date
                    }));

                    this.warnings = profile.employeeCareerData.filter((data : any) => data.type === 'Warning').map((data : any) => ({
                        id: data ?. id,
                        date: data ?. date,
                        reason: data ?. reason
                    }));
                } else {
                    this.promotions = [];
                    this.warnings = [];
                    console.warn('No career data found for the profile');
                }
            },
            error: (err) => {
                console.error('Error fetching employee data:', err);
            }
        });
    }

    onDeleteCareer(careerId : number): void {
        const dialogRef = this.dialog.open(ConfirmDialog);

        dialogRef.afterClosed().subscribe((result) => {
            if (result === true) {
                this.deleteEmployeeCareer(careerId);
            }
        });
    }

    private deleteEmployeeCareer(careerId : number): void {
        const employeeId = this.profileId;
        this.employeeService.removeEmployeeCareer(careerId).subscribe({
            next: () => {
                this.toastr.success('Data deleted successfully!');
                if (employeeId !== undefined && employeeId !== null) {
                    this.fetchEmployeeCareerById(employeeId);
                }
            },
            error: () => {
                this.toastr.error('Failed to delete Data. Please try again.');
            }
        });
    }

    openEditDialog(careerId : number) {
        if (this.isDialogOpen) 
            return;
        


        this.isDialogOpen = true;

        const employeeId = this.profileId;
        this.employeeService.getEmployeeCareerById(careerId).subscribe({
            next: (careerData) => {
                const dialogRef = this.dialog.open(AddCareerDialog, {
                    disableClose: true,
                    data: {
                        careerData,
                        employeeId
                    }
                });

                dialogRef.afterClosed().subscribe((result) => {
                    this.isDialogOpen = false; // Reset flag on close
                    if (result === 'success' && employeeId !== undefined) {
                        this.fetchEmployeeCareerById(employeeId);
                    }
                });
            }
        });
    }

    openAddCareerDialog() {
        if (this.isDialogOpen) 
            return;
        
        // Prevent re-opening if already open
        this.isDialogOpen = true;
        const employeeId = this.profileId;
        // const dialogRef = this.dialog.open(AddCareerDialog);
        const dialogRef = this.dialog.open(AddCareerDialog, {
            disableClose: true,
            data: { employeeId: this.profileId } 
        });

        dialogRef.afterClosed().subscribe((result) => {
            this.isDialogOpen = false;
            if (result === 'success' && this.profileId !== undefined) {
                this.fetchEmployeeCareerById(this.profileId);
            }
        });
    }
}

@Component({
    selector: 'add-history-dialog',
    templateUrl: 'add-career-dialog.html',
    styleUrls: ['../../../../employee-details.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        FormsModule,
        ReactiveFormsModule,
    ]
})
export class AddCareerDialog implements OnInit {
    public employeeCareerTypes : {
        label: string;
        value: string
    }[] = [];
    public careerForm !: FormGroup;
    careerData : any;
    loading : boolean = false;
    careerId : number;
    employeeId : number;
    constructor(private employeeService : EmployeeService, private toastr : ToastrService, private fb : FormBuilder, private dialogRef : MatDialogRef < AddCareerDialog >, @Inject(MAT_DIALOG_DATA)public data : any, private datePipe : DatePipe) {}

    ngOnInit(): void {
        this.initForm();

        if (this.data) {
            console.log("DATA-----", this.data)
            this.careerData = this.data.careerData
            this.careerId = this.data.careerData?.id
            this.employeeId = this.data.employeeId
        }

        this.loadEmployeeCareerTypes();
        this.initForm();
        this.careerForm.get('type') ?. valueChanges.subscribe(value => {
            this.toggleFieldValidators(value);
        });
    }

    private initForm() {
        this.careerForm = this.fb.group({
            type: [
                this.careerData ?. type || '',
                Validators.required
            ],
            currentPosition: [this.careerData ?. currentPosition || ''],
            previousPosition: [this.careerData ?. previousPosition || ''],
            date: [
                this.careerData ?. date ? new Date(Number(this.careerData ?. date)) : null,
            ],
            reason: [this.careerData ?. reason || '']
        });
    }

    private toggleFieldValidators(type : string) { // Clear all validators
        this.careerForm.get('currentPosition') ?. clearValidators();
        this.careerForm.get('previousPosition') ?. clearValidators();
        this.careerForm.get('date') ?. clearValidators();
        this.careerForm.get('reason') ?. clearValidators();

        if (type === 'Promotion') {
            this.careerForm.get('currentPosition') ?. setValidators(Validators.required);
            this.careerForm.get('previousPosition') ?. setValidators(Validators.required);
            this.careerForm.get('date') ?. setValidators(Validators.required);
        } else if (type === 'Warning') {
            this.careerForm.get('reason') ?. setValidators(Validators.required);
            this.careerForm.get('date') ?. setValidators(Validators.required);
        }

        // Update form validity after setting validators
        this.careerForm.get('currentPosition') ?. updateValueAndValidity();
        this.careerForm.get('previousPosition') ?. updateValueAndValidity();
        this.careerForm.get('date') ?. updateValueAndValidity();
        this.careerForm.get('reason') ?. updateValueAndValidity();
    }

    onSubmit() {
        if (this.careerForm.invalid) {
            this.toastr.warning('Please fill in all required fields');
            return;
        }
        console.log("EMPLOYEE ID:", this.employeeId)
        const createEmployeeCareerInput = {
            ...this.careerForm.value,
            employeeId: this.employeeId
        };
        const id = this.careerId;
        console.log('career id',id);
        
        const updateEmployeeCareerInput = {
            id: id,
            ...this.careerForm.value,
            employeeId: this.employeeId
        }
        if (!this.careerData) {
            this.employeeService.createEmployeeCareer(createEmployeeCareerInput).subscribe((response) => {
                this.loading = false;
                this.toastr.success('Employee career created successfully.');
                this.dialogRef.close('success');
            }, (error) => {
                this.loading = false;
                let errorMessage = 'Failed to create employee career. Please try again.';
                if (error.error && error.error.message) {
                    errorMessage = error.error.message;
                } else if (error.message) {
                    errorMessage = error.message;
                }
                this.toastr.error(errorMessage);
            });
        } else {
            this.employeeService.updateEmployeeCareer(updateEmployeeCareerInput).subscribe((response) => {
                this.loading = false;
                this.toastr.success('Employee career updated successfully.');
                console.log('Closing dialog after update');
                this.dialogRef.close('success');
            }, (error) => {
                this.loading = false;
                let errorMessage = 'Failed to update employee career. Please try again.';
                if (error.error && error.error.message) {
                    errorMessage = error.error.message;
                } else if (error.message) {
                    errorMessage = error.message;
                }
                this.toastr.error(errorMessage);
            });
        }

    }

    private loadEmployeeCareerTypes() {
        this.employeeCareerTypes = Object.values(EmployeeCareerTypeEnum).map((type) => ({label: type.label, value: type.value}));
    }
}
