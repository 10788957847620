import { Routes, provideRouter, withRouterConfig } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { BranchComponent } from './pages/branch/branch.component';
import { EmployeesComponent } from './pages/employees/employees.component';
import { AuthGuard } from './core/guards/auth.guard';
import { AddEmployeeComponent } from './pages/add-employee/add-employee.component';
import { EmployeeDetailsComponent } from './pages/employee-details/employee-details.component';
import { AttendanceManagementComponent } from './pages/attendance-management/attendance-management.component';
import { HolidayComponent } from './pages/holiday/holiday.component';
import { LeaveManagementComponent } from './pages/leave-management/leave-management.component';
import { LeavePolicyComponent } from './pages/leave-policy/leave-policy.component';
import { LeaveGrantComponent } from './pages/leave-grant/leave-grant.component';
import { PayrollListComponent } from './pages/payroll-list/payroll-list.component';
import { PayrollComponent } from './pages/payroll/payroll.component';
import { PayrollIndividualComponent } from './pages/payroll-individual/payroll-individual.component';
import { LeaveApprovalsComponent } from './pages/leave-approvals/leave-approvals.component';
import { PayrollSettingsComponent } from './pages/payroll-settings/payroll-settings.component';
import { VacationManagementComponent } from './pages/vacation-management/vacation-management.component';
import { VacationApprovalsComponent } from './pages/vacation-approvals/vacation-approvals.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { RolesComponent } from './pages/roles/roles.component';
import { RolesManagementComponent } from './pages/roles-management/roles-management.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { ChartAccountsComponent } from './pages/chart-accounts/chart-accounts.component';
import { NewChartAccountComponent } from './pages/new-chart-account/new-chart-account.component';
import { ChartAccountsDetailsComponent } from './pages/chart-accounts-details/chart-accounts-details.component';
import { ManualJournalsComponent } from './pages/manual-journals/manual-journals.component';
import { ManualJournalNewComponent } from './pages/manual-journal-new/manual-journal-new.component';
import { ManualJournalDetailsComponent } from './pages/manual-journal-details/manual-journal-details.component';
import { BankingComponent } from './pages/banking/banking.component';
import { BankingDetailsComponent } from './pages/banking-details/banking-details.component';
import { AddBankAccountComponent } from './pages/add-bank-account/add-bank-account.component';
import { CreditNoteDetailsComponent } from './pages/credit-note-details/credit-note-details.component';
import { CurrencyExchangeLogsComponent } from './pages/currency-exchange-log/currency-exchange-log.component';
import { CurrencyAdjustmentsComponent } from './pages/currency-adjustment/currency-adjustments.component';
import { InvoiceDetailsComponent } from './pages/invoice-details/invoice-details.component';
import { WorkOrderDetailsComponent } from './pages/work-order-details/work-order-details.component';
import { QuotationDetailsComponent } from './pages/quotation-details/quotation-details.component';
import { DebitNoteDetailsComponent } from './pages/debit-note-details/debit-note-details.component';
import { GrnDetailsComponent } from './pages/grn-details/grn-details.component';
import { LocalPurchaseOrderDetailsComponent } from './pages/local-purchase-order-details/local-purchase-order-details.component';
import { PurchaseOrderDetailsComponent } from './pages/purchase-order-details/purchase-order-details.component';
import { PaymentReceivedDetailsComponent } from './pages/payment-received-details/payment-received-details.component';
import { PaymentsMadeDetailsComponent } from './pages/payments-made-details/payments-made-details.component';
import { ExpenseDetailsComponent } from './pages/expense-details/expense-details.component';
import { BillDetailsComponent } from './pages/bill-details/bill-details.component';
import { InventoryComponent } from './pages/inventory/inventory.component';
import { QuotationComponent } from './pages/quotation/quotation.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { WorkOrderComponent } from './pages/work-order/work-order.component';
import { InvoiceNewComponent } from './pages/invoice-new/invoice-new.component';
import { PaymentReceivedComponent } from './pages/payment-received/payment-received.component';
import { CreditNoteComponent } from './pages/credit-note/credit-note.component';
import { VendorsComponent } from './pages/vendors/vendors.component';
import { ExpenseComponent } from './pages/expense/expense.component';
import { PurchaseOrderComponent } from './pages/purchase-order/purchase-order.component';
import { LocalPurchaseOrderComponent } from './pages/local-purchase-order/local-purchase-order.component';
import { BillsComponent } from './pages/bills/bills.component';
import { GrnComponent } from './pages/grn/grn.component';
import { PaymentsMadeComponent } from './pages/payments-made/payments-made.component';
import { DebitNoteComponent } from './pages/debit-note/debit-note.component';
import { ExpenseNewComponent } from './pages/expense-new/expense-new.component';
import { InventoryNewComponent } from './pages/inventory-new/inventory-new.component';
import { InventoryDetailsComponent } from './pages/inventory-details/inventory-details.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { ProjectInventoryComponent } from './pages/project-inventory/project-inventory.component';
import { AddProjectInventoryComponent } from './pages/add-project-inventory/add-project-inventory.component';
import { ProjectNewComponent } from './pages/project-new/project-new.component';
import { ClientNewComponent } from './pages/client-new/client-new.component';
import { ClientDetailsComponent } from './pages/client-details/client-details.component';
import { AssetsComponent } from './pages/assets/assets.component';
import { VendorDetailsComponent } from './pages/vendor-details/vendor-details.component';
import { VendorNewComponent } from './pages/vendor-new/vendor-new.component';
import { QuotationNewComponent } from './pages/quotation-new/quotation-new.component';
import { WorkOrderNewComponent } from './pages/work-order-new/work-order-new.component';
import { BillNewComponent } from './pages/bill-new/bill-new.component';
import { CreditNoteNewComponent } from './pages/credit-note-new/credit-note-new.component';
import { DebitNoteNewComponent } from './pages/debit-note-new/debit-note-new.component';
import { GrnNewComponent } from './pages/grn-new/grn-new.component';
import { LocalPurchaseOrderNewComponent } from './pages/local-purchase-order-new/local-purchase-order-new.component';
import { PaymentNewComponent } from './pages/payment-new/payment-new.component';
import { PurchaseOrderNewComponent } from './pages/purchase-order-new/purchase-order-new.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { BillPaymentNewComponent } from './pages/bill-payment-new/bill-payment-new.component';
import { SendMailComponent } from './pages/send-mail/send-mail.component';
import { SendComponent } from './pages/mail/send-mail.component';

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  {
    path: 'notifications',
    component: NotificationComponent,
    data: { breadcrumb: 'Notifications' },
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    // data: { breadcrumb: 'Dashboard' },
    data: {breadcrumb: 'Dashboard'},
    canActivate: [AuthGuard],
  },
  {
    path: 'branch',
    component: BranchComponent,
    data: { breadcrumb: 'Branches', allowedRoles: ['admin', 'manager'] },
    canActivate: [AuthGuard],
  },
  {
    path: 'add-employee',
    component: AddEmployeeComponent,
    data: { breadcrumb: 'Add Employee', allowedRoles: ['admin', 'manager'] },
    canActivate: [AuthGuard],
  },
  {
    path: 'attendance-management',
    component: AttendanceManagementComponent,
    data: { breadcrumb: 'Attendance', allowedRoles: ['admin'] },
    canActivate: [AuthGuard],
  },
  {
    path: 'leaves',
    component: LeaveManagementComponent,
    data: { breadcrumb: 'Leave Management', allowedRoles: ['admin'] },
    canActivate: [AuthGuard],
  },
  {
    path: 'leave-policy',
    component: LeavePolicyComponent,
    data: { breadcrumb: 'Leave Policy', allowedRoles: ['admin']},
    canActivate: [AuthGuard],
  },
  {
    path: 'leave-grant',
    component: LeaveGrantComponent,
    data: { breadcrumb: 'Leave Grant', allowedRoles: ['admin'] },
    canActivate: [AuthGuard],
  },
  {
    path: 'payroll/payroll-list',
    component: PayrollListComponent,
    data: { breadcrumb: 'Payroll List', allowedRoles: ['admin'] },
    canActivate: [AuthGuard],
  },
  {
    path: 'payroll',
    component: PayrollComponent,
    data: { breadcrumb: 'Payroll' , allowedRoles: ['admin']},
    canActivate: [AuthGuard],
  },
  {
    path: 'payroll-process/:id',
    component: PayrollIndividualComponent,
    data: { breadcrumb: 'Payroll Process' , allowedRoles: ['admin']},
    canActivate: [AuthGuard],
  },
  {
    path: 'payroll-settings',
    component: PayrollSettingsComponent,
    data: { breadcrumb: 'Payroll Settings', allowedRoles: ['admin'] },
    canActivate: [AuthGuard],
  },
  {
    path: 'leaves/approvals/:id',
    component: LeaveApprovalsComponent,
    data: { breadcrumb: 'Leave Approvals', allowedRoles: ['admin'] },
    canActivate: [AuthGuard],
  },
  {
    path: 'employees',
    component: EmployeesComponent,
    data: { breadcrumb: 'Employees' , allowedRoles: ['admin']},
    canActivate: [AuthGuard],
  },
  {
    path: 'employees/detail/:id',
    component: EmployeeDetailsComponent,
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Employee Details', allowedRoles: ['admin'] },
  },
  {
    path: 'my-profile/:id',
    component: EmployeeDetailsComponent,
    canActivate: [AuthGuard],
    data: { breadcrumb: 'My Profile'},
  },
  {
    path: 'employees/edit/:id',
    component: AddEmployeeComponent,
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Employee Edit' , allowedRoles: ['admin']},
  },

  {
    path: 'holiday',
    component: HolidayComponent,
    data: { breadcrumb: 'Holiday', allowedRoles: ['admin'] },
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'payroll-individual',
  //   component: PayrollIndividualComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'vacation-management',
    component: VacationManagementComponent,
    data: { breadcrumb: 'Vacation Management', allowedRoles: ['admin'] },
    canActivate: [AuthGuard],
  },

  {
    path: 'vacation-approvals/:id',
    component: VacationApprovalsComponent,
    data: { breadcrumb: 'Vacation Approvals', allowedRoles: ['admin'] },
    canActivate: [AuthGuard],
  },

  {
    path: 'roles',
    component: RolesComponent,
    data: { breadcrumb: 'Roles and Permission', allowedRoles: ['admin', 'manager'] },
    canActivate: [AuthGuard],
  },

  {
    path: 'roles-management',
    component: RolesManagementComponent,
    data: { breadcrumb: 'Roles Management' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'currency-adjustments',
    component: CurrencyAdjustmentsComponent,
    data: { breadcrumb: 'Currency Adjustment', allowedRoles: ['admin', 'manager'] },
    canActivate: [AuthGuard],
  },

  {
    path: 'currency-adjustments/exchange-logs/:id',
    component: CurrencyExchangeLogsComponent,
    data: { breadcrumb: 'Exchange Logs', allowedRoles: ['admin', 'manager'] },
    canActivate: [AuthGuard],
  },

  {
    path: 'reports',
    component: ReportsComponent,
    data: { breadcrumb: 'Vacation Approvals', allowedRoles: ['admin', 'manager'] },
    canActivate: [AuthGuard],
  },

  {
    path: 'chart-of-accounts',
    component: ChartAccountsComponent,
    data: { breadcrumb: 'Chart Of Accounts', allowedRoles: ['admin', 'manager'] },
    canActivate: [AuthGuard],
  },

  {
    path: 'chart-of-accounts/chart-of-accounts-details/:id',
    component: ChartAccountsDetailsComponent,
    data: { breadcrumb: 'Chart Of Accounts Details', allowedRoles: ['admin', 'manager'] },
    canActivate: [AuthGuard],
  },

  {
    path: 'new-chart-of-accounts',
    component: NewChartAccountComponent,
    data: { breadcrumb: 'New Chart Of Accounts', allowedRoles: ['admin', 'manager'] },
    canActivate: [AuthGuard],
  },

  {
    path: 'manual-journals',
    component: ManualJournalsComponent,
    data: { breadcrumb: 'Manual Journals' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'manual-journals/add',
    component: ManualJournalNewComponent,
    data: { breadcrumb: 'New Manual Journal' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'manual-journals/detail/:id',
    component: ManualJournalDetailsComponent,
    data: { breadcrumb: 'Manual Journal Details', allowedRoles: ['admin', 'manager'] },
    canActivate: [AuthGuard],
  },

  {
    path: 'manual-journals/edit/:id',
    component: ManualJournalNewComponent,
    data: { breadcrumb: 'Manual Journal Edit' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'banking',
    component: BankingComponent,
    data: { breadcrumb: 'Banking' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'banking/banking-details/:id',
    component: BankingDetailsComponent,
    data: { breadcrumb: 'Banking Details', allowedRoles: ['admin', 'manager'] },
    canActivate: [AuthGuard],
  },

  {
    path: 'banking/add-bank-account',
    component: AddBankAccountComponent,
    data: { breadcrumb: 'Add Bank Account', allowedRoles: ['admin', 'manager'] },
    canActivate: [AuthGuard],
  },

  {
    path: 'invoice/invoice-details/:id',
    component: InvoiceDetailsComponent,
    data: { breadcrumb: 'Invoice Details' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'work-order-details',
    component: WorkOrderDetailsComponent,
    data: { breadcrumb: 'Work Order Details' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'quotation-details',
    component: QuotationDetailsComponent,
    data: { breadcrumb: 'Quotation Details' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'debit-note-details',
    component: DebitNoteDetailsComponent,
    data: { breadcrumb: 'Debit Note Details' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'grn-details',
    component: GrnDetailsComponent,
    data: { breadcrumb: 'GRN' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'local-purchase-order-details',
    component: LocalPurchaseOrderDetailsComponent,
    data: { breadcrumb: 'Local Purchase Order Details' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'purchase-order-details',
    component: PurchaseOrderDetailsComponent,
    data: { breadcrumb: 'Purchase Order Details' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },


  {
    path: 'expense-details',
    component: ExpenseDetailsComponent,
    data: { breadcrumb: 'Expense Details' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'bills/bill-details/:id',
    component: BillDetailsComponent,
    data: { breadcrumb: 'Bill Details' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'inventory',
    component: InventoryComponent,
    data: { breadcrumb: 'Inventory' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'quotation',
    component: QuotationComponent,
    data: { breadcrumb: 'Quotation' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'customers',
    component: ClientsComponent,
    data: { breadcrumb: 'Customers' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'projects',
    component: ProjectsComponent,
    data: { breadcrumb: 'Projects' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'work-order',
    component: WorkOrderComponent,
    data: { breadcrumb: 'Work Order' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  //payment module
  {
    path: 'payment-received',
    component: PaymentReceivedComponent,
    data: { breadcrumb: 'payment-received' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-received/payment-new',
    component: PaymentNewComponent,
    data: { breadcrumb: 'New Payment' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-received/payment-received-details',
    component: PaymentReceivedDetailsComponent,
    data: { breadcrumb: 'Payment Received Details' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },
  {
    path: ':dynamicType/:dynamicType/mail',
    component: SendComponent,
    data: { breadcrumb: 'Send Mail' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'payments-made',
    component: PaymentsMadeComponent,
    data: { breadcrumb: 'Payments Made', allowedRoles: ['admin', 'manager'] },
    canActivate: [AuthGuard],
  },
  
  {
    path: 'payments-made/payments-made-details',
    component: PaymentsMadeDetailsComponent,
    data: { breadcrumb: 'Payments Made Details' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'payments-made/bill-payment-new',
    component: BillPaymentNewComponent,
    data: { breadcrumb: 'New Bill Payment' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'credit-note',
    component: CreditNoteComponent,
    data: { breadcrumb: 'Credit Note' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'credit-note/credit-note-details',
    component: CreditNoteDetailsComponent,
    data: { breadcrumb: 'Credit Note Details' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'credit-note/credit-note-new',
    component: CreditNoteNewComponent,
    data: { breadcrumb: 'New Credit Note' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },


  {
    path: 'vendors',
    component: VendorsComponent,
    data: { breadcrumb: 'Vendors' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'expense',
    component: ExpenseComponent,
    data: { breadcrumb: 'Expense' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'purchase-order',
    component: PurchaseOrderComponent,
    data: { breadcrumb: 'Purchase Order' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'local-purchase-order',
    component: LocalPurchaseOrderComponent,
    data: { breadcrumb: 'Local Purchase Order' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'bills',
    component: BillsComponent,
    data: { breadcrumb: 'Bills' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'invoice/invoice-add',
    component: InvoiceNewComponent,
    data: { breadcrumb: 'New Invoice' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'invoice/invoice-edit/:id',
    component: InvoiceNewComponent,
    data: { breadcrumb: 'Update Invoice' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'grn',
    component: GrnComponent,
    data: { breadcrumb: 'GRN' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'debit-note',
    component: DebitNoteComponent,
    data: { breadcrumb: 'Debit Note' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'expense-new',
    component: ExpenseNewComponent,
    data: { breadcrumb: 'New Expense' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'inventory/inventory-add',
    component: InventoryNewComponent,
    data: { breadcrumb: 'New Inventory' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'inventory/inventory-edit/:id',
    component: InventoryNewComponent,
    data: { breadcrumb: 'Update Inventory' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'inventory/inventory-details/:id',
    component: InventoryDetailsComponent,
    data: { breadcrumb: 'Inventory Details' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'inventory/inventory-details',
    component: InventoryDetailsComponent,
    data: { breadcrumb: 'Inventory Details' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'invoice',
    component: InvoiceComponent,
    data: { breadcrumb: 'Invoice' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'project-inventory',
    component: ProjectInventoryComponent,
    data: { breadcrumb: 'Project Inventory' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'add-project-inventory',
    component: AddProjectInventoryComponent,
    data: { breadcrumb: 'Add Project Inventory' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'project-new',
    component: ProjectNewComponent,
    data: { breadcrumb: 'Project New' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'customers/customer-new',
    component: ClientNewComponent,
    data: { breadcrumb: 'New Customer' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },
  {
    path: 'customers/customer-edit/:id',
    component: ClientNewComponent,
    data: { breadcrumb: 'Edit Customer' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'customers/customer-details/:id',
    component: ClientDetailsComponent,
    data: { breadcrumb: 'Customer Details' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'assets',
    component: AssetsComponent,
    data: { breadcrumb: 'Assets' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'vendors/vendor-details/:id',
    component: VendorDetailsComponent,
    data: { breadcrumb: 'Vendor Details' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'vendors/vendor-new',
    component: VendorNewComponent,
    data: { breadcrumb: 'New Vendor' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },
  {
    path: 'vendors/vendor-edit/:id',
    component: VendorNewComponent,
    data: { breadcrumb: 'Edit Vendor' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'quotation-new',
    component: QuotationNewComponent,
    data: { breadcrumb: 'New Quotation' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'work-order-new',
    component: WorkOrderNewComponent,
    data: { breadcrumb: 'New Work Order' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'bills/bill-new',
    component: BillNewComponent,
    data: { breadcrumb: 'New Bill' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },
  {
    path: 'bills/bill-edit/:id',
    component: BillNewComponent,
    data: { breadcrumb: 'New Bill' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },


  {
    path: 'debit-note-new',
    component: DebitNoteNewComponent,
    data: { breadcrumb: 'New Debit Note' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },
  {
    path: 'debit-note-edit',
    component: DebitNoteNewComponent,
    data: { breadcrumb: 'New Debit Note' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'grn-new',
    component: GrnNewComponent,
    data: { breadcrumb: 'New GRN' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'local-purchase-order-new',
    component: LocalPurchaseOrderNewComponent,
    data: { breadcrumb: 'New Local Purchase Order' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'purchase-order-new',
    component: PurchaseOrderNewComponent,
    data: { breadcrumb: 'New Purchase Order' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },
  {
    path: 'purchase-order-edit',
    component: PurchaseOrderNewComponent,
    data: { breadcrumb: 'New Purchase Order' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
    data: { breadcrumb: 'Page Not Found' },
    canActivate: [AuthGuard],
  },

  {
    path: 'send-mail',
    component: SendMailComponent,
    data: { breadcrumb: 'Send Mail' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  {
    path: 'invoice/send-mail/:id',
    component: SendMailComponent,
    data: { breadcrumb: 'Send Mail' , allowedRoles: ['admin', 'manager']},
    canActivate: [AuthGuard],
  },

  // {
  //   path: 'employees',
  //   component: EmployeesComponent,
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: 'employee',
  //   loadChildren: () =>
  //     import('./features/employees/employees.module').then(
  //       (m) => m.EmployeesModule
  //     ),
  // },
  { path: '**', redirectTo: 'page-not-found' },
];

export const appRouterConfig = provideRouter(
  routes,
  withRouterConfig({
    onSameUrlNavigation: 'reload',
  })
);
