<div class="tab-content-wrapper">
  <form [formGroup]="documentForm" (ngSubmit)="onNext()">
    <div class="employee-form-wrapper">
      <div class="form-wrapper">
        <label for="">Select Visa Type</label>
        <mat-form-field>
          <mat-select placeholder="Select Visa Type" formControlName="visaType">
            <mat-option
              *ngFor="let visaType of visaTypeData"
              [value]="visaType.visaType"
            >
              {{ visaType.visaType }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              documentForm.get('visaType')!.touched &&
              documentForm.get('visaType')!.hasError('required')
            "
            >Visa Type is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Visa Number</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Visa Number"
            formControlName="visaNumber"
            maxlength="20"
          /><mat-error
            *ngIf="
              documentForm.get('visaNumber')?.hasError('required') &&
              documentForm.get('visaNumber')?.touched
            "
          >
            Visa Number is required
          </mat-error>
          <mat-error
            *ngIf="
              documentForm.get('visaNumber')?.hasError('invalidVisaNumber') &&
              !documentForm.get('visaNumber')?.hasError('required') &&
              documentForm.get('visaNumber')?.touched
            "
          >
            Please enter a valid Visa Number
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Select Visa Issue Date</label>
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="picker3"
            formControlName="visaIssueDate"
            [matDatepickerFilter]="issuedDateFilter"
            placeholder="Enter Visa Issue Date"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker3"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker3></mat-datepicker>
          <mat-error
            *ngIf="
              documentForm.get('visaIssueDate')!.touched &&
              documentForm.get('visaIssueDate')!.hasError('required')
            "
            >Visa Issue Date is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Select Visa Expiry Date</label>
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="picker4"
            formControlName="visaExpiryDate"
            placeholder="Enter Visa Expiry Date"
            [matDatepickerFilter]="dateFilter"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker4"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker4></mat-datepicker>
          <mat-error
            *ngIf="
              documentForm.get('visaExpiryDate')!.touched &&
              documentForm.get('visaExpiryDate')!.hasError('required')
            "
            >Visa Expiry Date is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Passport Number</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Passport Number"
            formControlName="passportNumber"
            maxlength="15"
          /><mat-error
            *ngIf="
              documentForm.get('passportNumber')?.hasError('required') &&
              documentForm.get('passportNumber')?.touched
            "
          >
            Passport Number is required
          </mat-error>
          <mat-error
            *ngIf="
              documentForm
                .get('passportNumber')
                ?.hasError('invalidPassportNumber') &&
              !documentForm.get('passportNumber')?.hasError('required') &&
              documentForm.get('passportNumber')?.touched
            "
          >
            Please enter a valid Passport Number
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Select Passport Issue Date</label>
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="picker11"
            formControlName="passportIssueDate"
            placeholder="Enter Passport Issue Date"
            [matDatepickerFilter]="issuedDateFilter"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker11"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker11></mat-datepicker>
          <mat-error
            *ngIf="
              documentForm.get('passportIssueDate')!.touched &&
              documentForm.get('passportIssueDate')!.hasError('required')
            "
            >Passport Issue Date is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Select Passport Expiry Date</label>
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="picker12"
            formControlName="passportExpiryDate"
            placeholder="Enter Passport Expiry Date"
            [matDatepickerFilter]="dateFilter"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker12"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker12></mat-datepicker>
          <mat-error
            *ngIf="
              documentForm.get('passportExpiryDate')!.touched &&
              documentForm.get('passportExpiryDate')!.hasError('required')
            "
            >Passport Expiry Date is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Emirates ID Number</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Emirates ID Number"
            formControlName="emiratesIDNo"
            maxlength="20"
          /><mat-error
            *ngIf="
              documentForm.get('emiratesIDNo')?.hasError('required') &&
              documentForm.get('emiratesIDNo')?.touched
            "
          >
            Emirates ID Number is required
          </mat-error>
          <mat-error
            *ngIf="
              documentForm.get('emiratesIDNo')?.hasError('invalidEmiratesId') &&
              !documentForm.get('emiratesIDNo')?.hasError('required') &&
              documentForm.get('emiratesIDNo')?.touched
            "
          >
            Please enter a valid Emirates ID
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Driving License Number</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Driving License Number"
            formControlName="drivingLicenseNumber"
            maxlength="15"
          /><mat-error
            *ngIf="
              documentForm.get('drivingLicenseNumber')?.hasError('required') &&
              documentForm.get('drivingLicenseNumber')?.touched
            "
          >
            Driving License Number is required
          </mat-error>
          <mat-error
            *ngIf="
              documentForm
                .get('drivingLicenseNumber')
                ?.hasError('invalidDrivingLicense') &&
              !documentForm.get('drivingLicenseNumber')?.hasError('required') &&
              documentForm.get('drivingLicenseNumber')?.touched
            "
          >
            Please enter a valid Driving License Number
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Select Labour Card Expiry Date</label>
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="picker13"
            formControlName="laborCardExpiryDate"
            placeholder="Enter Labour Card Expiry Date"
            [matDatepickerFilter]="dateFilter"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker13"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker13></mat-datepicker>
          <mat-error
            *ngIf="
              documentForm.get('laborCardExpiryDate')!.touched &&
              documentForm.get('laborCardExpiryDate')!.hasError('required')
            "
            >Labour Card Expiry Date is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Bank Name</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Bank Name"
            formControlName="bankName"
            maxlength="50"
          /><mat-error
            *ngIf="
              documentForm.get('bankName')!.touched &&
              documentForm.get('bankName')!.hasError('required')
            "
            >Bank Name is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Account Number</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Account No."
            formControlName="accountNumber"
            maxlength="30"
          />
          <mat-error
            *ngIf="
              documentForm.get('accountNumber')?.hasError('required') &&
              documentForm.get('accountNumber')?.touched
            "
          >
            Account Number is required
          </mat-error>
          <mat-error
            *ngIf="
              documentForm
                .get('accountNumber')
                ?.hasError('invalidBankAccount') &&
              !documentForm.get('accountNumber')?.hasError('required') &&
              documentForm.get('accountNumber')?.touched
            "
          >
            Please enter a valid Account Number
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">IFSC Code</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter IFSC Code"
            formControlName="IFSCCode"
            maxlength="11"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="submit-btn-wrapper">
      <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
      <button class="cmn-next-btn" type="submit">Next</button>
    </div>
  </form>
</div>
