import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { EmployeeService } from '../../../../features/employees/services/employee.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-branch-access',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
  ],
  templateUrl: './branch-access.component.html',
  styleUrl: '../../add-employee.component.scss',
})
export class BranchAccessComponent implements OnInit, OnChanges {
  branchAccessForm : FormGroup;
  public userRolesData : any[] = [];
  public branchData : any[] = [];
  public isPortalUserEnabled : boolean = false;
  public initialIsPortalUser : boolean = false; // Track initial portal status for edit mode


  @Input() employee: any;
  @Input() isEditMode: boolean = false;
  @Input() employeeData: any = {};
  @Output() formSubmit = new EventEmitter<any>();
  approversData: any = [];
  approverList: any[] = [];
  approverNames: any[] = [];
  employeeId: any;
  approverNamesData: any;

  constructor(
    private fb: FormBuilder,
    private employeeService: EmployeeService, 
    private router : Router,
    private route : ActivatedRoute,
    private toastr : ToastrService,
  ) {
    this.branchAccessForm = this.fb.group({
      branchName: ['', Validators.required],
      isPortalUser: [false],
      employeeApprover: [
        '', 
        this.isEditMode ? [] : [Validators.required]
      ],
      userRole: [''],
      // userName: [''],
      password: [''],
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      console.log(id);
      
      if (id) {
        this.employeeId = +id;
        this.loadEmployeeData();
      }
    })
    this.loadUserRoles();
    this.loadBranches();
    this.loadEmployeeApprovers();


     // Observe changes to isPortalUser checkbox
     this.branchAccessForm.get('isPortalUser') ?. valueChanges.subscribe((isPortalUser : boolean) => {
      this.togglePortalUserFields(isPortalUser);
  });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['employee'] && this.isEditMode && this.employee) {
      this.populateFormWithEmployeeData();
      this.initialIsPortalUser = this.employee.isPortalUser; // Store initial portal status
      this.setEmployeeApproverValidation();
    }
  }

  // Set employeeApprover validation based on edit mode
private setEmployeeApproverValidation() {
  if (this.isEditMode) {
    this.branchAccessForm.get('employeeApprover')?.clearValidators();
  } else {
    this.branchAccessForm.get('employeeApprover')?.setValidators([Validators.required]);
  }
  this.branchAccessForm.get('employeeApprover')?.updateValueAndValidity();
}

  private loadUserRoles() {
    this.employeeService.getUserRoles().subscribe({
      next: (userRoles) => {
        this.userRolesData = userRoles;
      },
      error: (error) => console.error(error),
    });
  }

    loadEmployeeData() {
      this.employeeService.getEmployeeApprover(Number(this.employeeId)).subscribe({
        next: (data) => {
          if (data?.employeeApprovers) {
            console.log('branch', data?.employeeApprovers);
            
            const levels = ['levelOneData', 'levelTwoData', 'levelThreeData', 'levelFourData'];
            this.approverNamesData = []; // Ensure the array is empty before pushing
    
            const approver = data.employeeApprovers[0];
            console.log(approver);
            
            levels.forEach(level => {
              if (approver?.[level]) {
                this.approverNamesData.push(approver?.[level].firstName);
              }
            });
    
            console.log(this.approverNamesData);
          }
        },
        error: (error) => {
        console.error(error,'Error')
        }
      });
    }
  
  

 loadEmployeeApprovers() {
    const search = "";
    const filter = {};
      this.employeeService.getEmployees(search, filter).subscribe((employees: any[]) => {
        this.approversData = employees.filter((approver: any) => 
        approver?.user?.profileData?.profileName === 'manager'
        );
      });
    }

  private loadBranches() {
    this.employeeService.getBranches().subscribe({
      next: (branches) => {
        this.branchData = branches;
      },
      error: (error) => console.error(error),
    });
  }

  populateFormWithEmployeeData(): void {
    this.branchAccessForm.patchValue({
      branchName: this.employee.branch?.id,
      employeeApprover: this.employee.employeeApprover,
      isPortalUser: this.employee.isPortalUser,
      userRole: this.employee.user?.profileId,
    });
    this.isPortalUserEnabled = this.employee.isPortalUser;
    this.togglePortalUserFields(this.employee.isPortalUser);
    console.log('employee.isPortalUser::', this.employee.isPortalUser);
  }

  onApproverSelected(event: any): void {
    const selectedApprover = event.value;
    const employeeId = Number(this.employeeId);
  
    const approverExistsInList = this.approverList.some(approver =>
      approver.levelOne === selectedApprover || 
      approver.levelTwo === selectedApprover || 
      approver.levelThree === selectedApprover || 
      approver.levelFour === selectedApprover
    );
  
    if (!approverExistsInList) {
      this.employeeService.getEmployeeById(selectedApprover).subscribe((employee) => {
        this.approverNames.push(employee?.firstName);
      });
      
      // Assign the selected approver to the corresponding level
      if (this.approverList.length === 0) {
        this.approverList.push({
          employeeId,
          levelOne: selectedApprover
        });      
      } else if (this.approverList.length === 1) {
        this.approverList[0].levelTwo = selectedApprover;
      } else if (this.approverList.length === 2) {
        this.approverList[1].levelThree = selectedApprover;
      } else if (this.approverList.length === 3) {
        this.approverList[2].levelFour = selectedApprover;
      } else {
        this.approverList.push({
          employeeId,
          levelOne: selectedApprover
        });      
      }
  
      console.log(this.approverNames);
      console.log(this.approverList);
  
    } else {
      this.toastr.warning('Approver is already selected', 'Warning');
    }
  }

    // Function to toggle validators for portal user fields
    private togglePortalUserFields(isPortalUser : boolean) {
      this.isPortalUserEnabled = isPortalUser;

      if (isPortalUser) { // Only add validators if creating new or toggling from non-portal to portal user
          if (!this.isEditMode || !this.initialIsPortalUser) {
              this.branchAccessForm.get('userRole') ?. setValidators([Validators.required]);
              this.branchAccessForm.get('password') ?. setValidators([Validators.required]);
          }
      } else { // If isPortalUser is false, remove validators
        console.log('clear');
        
          this.branchAccessForm.get('userRole') ?. clearValidators();
          this.branchAccessForm.get('password') ?. clearValidators();
      }

      // Update validity after changing validators
      this.branchAccessForm.get('userRole') ?. updateValueAndValidity();
      this.branchAccessForm.get('password') ?. updateValueAndValidity();
  }

  onNext(): void {
    if (this.branchAccessForm.invalid) {
      this.branchAccessForm.markAllAsTouched();
      console.log('Form is Invalid');
    } else {
      const formValue = this.branchAccessForm.value;
      if (! formValue.isPortalUser) {
                formValue.userRole = null;
                formValue.password = null;
            }
      // if(!this.isEditMode){
      //   const approverData = this.approverList[0];
      //   this.employeeService.createEmployeeApprover(approverData).subscribe({
      //     next: (data) => {
      //       if(data){
      //         console.log('Employee Approver created successfully');
      //       }
      //     },
      //     error: (error) => {
      //       console.log(error,'Error')
      //     }
      //   })
      // } else if (this.isEditMode){
      //   console.log('update employee',this.employee);
        
      //   const approverData = this.approverList[0];
      //   this.employeeService.updateEmployeeApprover(approverData).subscribe({
      //     next: (data) => {
      //       if(data){
      //         console.log('Employee Approver updated successfully');
      //       }
      //     },
      //     error: (error) => {
      //       console.log(error,'Error')
      //     }
      //   })
      // }

      console.log('Branch Data Submitted-----:', formValue);
      this.formSubmit.emit({ formValue, approverList: this.approverList });
    }
  }

  onCancel(): void {
    // this.branchAccessForm.reset();
    this.router.navigate(['/employees']);
  }
}
