<div class="profile-inner-main-wrapper">
  <div class="details-wrapper">
    <h2>Branch Name</h2>
    <h3>{{employeeData?.branch?.branchName}}</h3>
  </div>
  <div *ngIf="employeeData?.isPortalUser; else notPortalUser">
    <div class="details-wrapper">
      <h2>Is portal user?</h2>
      <h3>Yes</h3>
    </div>
    <div class="details-wrapper">
      <h2>User Role</h2>
      <h3>{{profileName}}</h3>
    </div>
    <!-- <div class="details-wrapper password">
      <h2>Password</h2>
      <h3>
        <span>{{ isVisible ? value : hiddenValue }}</span>
        <button (click)="toggleClass()" [class.active]="isVisible"></button>
      </h3>
    </div> -->
  </div>
  <ng-template #notPortalUser>
    <div class="details-wrapper">
        <h2>Is portal user?</h2>
        <h3>No</h3>
    </div>
  </ng-template>
</div>
