
    <section class="cmn-innerpage-wrapper work-order">
        <div class="head">
            <div class="search-wrapper">
                <input type="text" class="cmn-header-search" placeholder="Search" />
            </div>
            <button class="cmn-upload-btn" (click)="openDialog()">
                <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                <span>Upload</span>
            </button>
            <button class="cmn-add-btn" (click)="navigateTo('/work-order-new')">
                <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> New Work Order
            </button>
        </div>
        <div class="inner-content">
            <ag-grid-angular style="width: 100%;" [rowData]="rowData" [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [suppressRowClickSelection]="true"
                [pagination]="true" [paginationPageSize]="paginationPageSize"
                [paginationPageSizeSelector]="paginationPageSizeSelector" [class]="themeClass" />

                <button (click)="navigateTo('/work-order-details')">Details</button>
        </div>
    </section>
