<div class="tab-content-wrapper">
  <form [formGroup]="professionalInfoForm" (ngSubmit)="onNext()">
    <div class="employee-form-wrapper">
      <div class="form-wrapper">
        <label for="">Employee Code</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Employee Code"
            formControlName="employeeCode"
            maxlength="10"
          />
          <mat-error
            *ngIf="
              professionalInfoForm.get('employeeCode')!.touched &&
              professionalInfoForm.get('employeeCode')!.hasError('required')
            "
            >Employee Code is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Select Employee Type</label>
        <mat-form-field>
          <mat-select
            placeholder="Select Employee Type"
            formControlName="employeeType"
          >
            <mat-option
              *ngFor="let type of employeeTypeData"
              [value]="type.value"
            >
              {{ type.label }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              professionalInfoForm.get('employeeType')!.touched &&
              professionalInfoForm.get('employeeType')!.hasError('required')
            "
            >Employee Type is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Select Employment Type</label>
        <mat-form-field>
          <mat-select
            placeholder="Select Employment Type"
            formControlName="employmentType"
          >
            <mat-option
              *ngFor="let employmentType of employmentTypeData"
              [value]="employmentType.employmentType"
            >
              {{ employmentType.employmentType }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              professionalInfoForm.get('employmentType')!.touched &&
              professionalInfoForm.get('employmentType')!.hasError('required')
            "
            >Employment Type is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Select Employee Status</label>
        <mat-form-field>
          <mat-select
            placeholder="Select Employee Status"
            formControlName="employeeStatus"
          >
            <mat-option
              *ngFor="let empStatus of employeeStatusData"
              [value]="empStatus.employeeStatus"
            >
              {{ empStatus.employeeStatus }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              professionalInfoForm.get('employeeStatus')!.touched &&
              professionalInfoForm.get('employeeStatus')!.hasError('required')
            "
            >Employee Status is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Department</label>
        <mat-form-field>
          <mat-select
            placeholder="Select Department"
            formControlName="department"
          >
            <mat-option *ngFor="let dept of departmentData" [value]="dept.id">
              {{ dept.departmentName }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              professionalInfoForm.get('department')!.touched &&
              professionalInfoForm.get('department')!.hasError('required')
            "
            >Department is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Designation</label>
        <mat-form-field>
          <mat-select
            placeholder="Select Designation"
            formControlName="designation"
          >
            <mat-option
              *ngFor="let designation of designationData"
              [value]="designation.id"
            >
              {{ designation.designationName }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              professionalInfoForm.get('designation')!.touched &&
              professionalInfoForm.get('designation')!.hasError('required')
            "
            >Designation is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Select Joining Date</label>
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="picker2"
            formControlName="joiningDate"
            [matDatepickerFilter]="dateFilter"
            placeholder="Enter Joining Date"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          <mat-error
            *ngIf="
              professionalInfoForm.get('joiningDate')!.touched &&
              professionalInfoForm.get('joiningDate')!.hasError('required')
            "
            >Joining Date is required.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-wrapper">
        <label for="">Current CTC</label>
        <mat-form-field>
          <input
            matInput
            placeholder="Enter Current CTC"
            formControlName="currentCTC"
            type="number"
            maxlength="10"
          />
          <mat-error
            *ngIf="
              professionalInfoForm.get('currentCTC')!.touched &&
              professionalInfoForm.get('currentCTC')!.hasError('required')
            "
            >Current CTC is required.</mat-error
          ><mat-error
            *ngIf="
              professionalInfoForm.get('currentCTC')?.hasError('nonNegative')
            "
          >
            Current CTC cannot be negative. </mat-error
          ><mat-error
            *ngIf="
              professionalInfoForm.get('currentCTC')?.hasError('maxCTCExceeded')
            "
            >Current CTC cannot exceed 100 crore.</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div class="submit-btn-wrapper">
      <button class="cmn-cancel-btn" (click)="onCancel()">Cancel</button>
      <button class="cmn-next-btn" type="submit">Next</button>
    </div>
  </form>
</div>
