<mat-dialog-content class="mat-typography">
  <section class="add-branch-dialog-wrapper">
    <h2 class="cmn-popup-title">Add Career</h2>
    <button class="cmn-close" mat-dialog-close></button>
    <form [formGroup]="careerForm" (ngSubmit)="onSubmit()">
      <div class="branch-form-wrapper full-width">
        <label class="radio-label">Item Type</label>
        <div class="type-selection">
          <label
            *ngFor="let type of employeeCareerTypes"
            class="radiobtn-wrapper"
          >
            <input
              type="radio"
              [value]="type.value"
              name="type"
              formControlName="type"
            />

            <span class="radiomark on-dialog"></span>
            {{ type.label }}
            <mat-error
              *ngIf="
                  careerForm.get('type')!.touched &&
                  careerForm.get('type')!.hasError('required')
                "
            >
              Item Type is required.
            </mat-error>
          </label>
        </div>
        <div
          class="form-wrapper"
          *ngIf="careerForm.get('type')?.value === 'Promotion'"
        >
          <label for="">New Designation</label>
          <mat-form-field>
            <input
              matInput
              placeholder="Enter New Designation"
              formControlName="currentPosition"
            />
            <mat-error
              *ngIf="
                  careerForm.get('currentPosition')!.touched &&
                  careerForm.get('currentPosition')!.hasError('required')
                "
            >
              New Designation is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div
          class="form-wrapper"
          *ngIf="careerForm.get('type')?.value === 'Promotion'"
        >
          <label for="">Previous Designation</label>
          <mat-form-field>
            <input
              matInput
              placeholder="Enter Previous Designation"
              formControlName="previousPosition"
            />
            <mat-error
              *ngIf="
                  careerForm.get('previousPosition')!.touched &&
                  careerForm.get('previousPosition')!.hasError('required')
                "
            >
              Previous Designation is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div
          class="form-wrapper"
          *ngIf="careerForm.get('type')?.value === 'Promotion' || careerForm.get('type')?.value === 'Warning'"
        >
          <label for="">Date</label>
          <mat-form-field>
            <input
              matInput
              [matDatepicker]="picker14"
              placeholder="Select Date"
              formControlName="date"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker14"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker14></mat-datepicker
            ><mat-error
              *ngIf="
              careerForm.get('date')!.touched &&
              careerForm.get('date')!.hasError('required')
            "
              >Date is required.</mat-error
            >
          </mat-form-field>
        </div>
        <div
          class="form-wrapper"
          *ngIf="careerForm.get('type')?.value === 'Warning'"
        >
          <label for="">Reason</label>
          <mat-form-field>
            <textarea
              matInput
              placeholder="Reason"
              formControlName="reason"
            ></textarea
            ><mat-error
              *ngIf="
            careerForm.get('reason')!.touched &&
            careerForm.get('reason')!.hasError('required')
          "
              >Reason is required.</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="btn-wrapper">
        <button type="submit" class="cmn-popup-btn">Submit</button>
      </div>
    </form>
  </section>
</mat-dialog-content>
