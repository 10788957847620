
    <section class="cmn-innerpage-wrapper expense-new">
        <h2 class="cmn-inner-heading">Record Expense</h2>
        <form>
            <div class="chart-account-form-wrapper">
                <div class="form-wrapper">
                    <label for="">Date</label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker" placeholder="Select date" />
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Expense Account</label>
                    <mat-form-field>
                        <mat-select placeholder="Select an account">
                            <mat-option value="Single">Single</mat-option>
                            <mat-option value="Married">Married</mat-option>
                            <mat-option value="Divorced">Divorced</mat-option>
                            <mat-option value="Widowed">Widowed</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Amount</label>
                    <mat-form-field>
                        <mat-select placeholder="AED">
                            <mat-option value="Single">Single</mat-option>
                            <mat-option value="Married">Married</mat-option>
                            <mat-option value="Divorced">Divorced</mat-option>
                            <mat-option value="Widowed">Widowed</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Expense Account</label>
                    <mat-form-field>
                        <mat-select placeholder="Select an account">
                            <mat-option value="Single">Single</mat-option>
                            <mat-option value="Married">Married</mat-option>
                            <mat-option value="Divorced">Divorced</mat-option>
                            <mat-option value="Widowed">Widowed</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Paid Through</label>
                    <mat-form-field>
                        <mat-select placeholder="Select an account">
                            <mat-option value="Single">Single</mat-option>
                            <mat-option value="Married">Married</mat-option>
                            <mat-option value="Divorced">Divorced</mat-option>
                            <mat-option value="Widowed">Widowed</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Vendor</label>
                    <mat-form-field>
                        <mat-select placeholder="Select vendor">
                            <mat-option value="Single">Single</mat-option>
                            <mat-option value="Married">Married</mat-option>
                            <mat-option value="Divorced">Divorced</mat-option>
                            <mat-option value="Widowed">Widowed</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Customer</label>
                    <mat-form-field>
                        <mat-select placeholder="Choose customer">
                            <mat-option value="Single">Single</mat-option>
                            <mat-option value="Married">Married</mat-option>
                            <mat-option value="Divorced">Divorced</mat-option>
                            <mat-option value="Widowed">Widowed</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Invoice#</label>
                    <mat-form-field>
                        <input matInput placeholder="IN-0023" />
                    </mat-form-field>
                </div>
                <div class="form-wrapper">
                    <label for="">Description</label>
                    <mat-form-field>
                        <textarea matInput placeholder="Enter description"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="submit-btn-wrapper">
                <button class="cmn-cancel-btn">Cancel</button>
                <button class="cmn-next-btn">Save</button>
            </div>
        </form>
    </section>
