<section class="sidebar-wrapper" [ngClass]="{ collapsedClass: sidebarData }">
  <div class="header">
    <img src="assets/images/logo.svg" alt="Logo" />
  </div>
  <div class="nav-items-wrapper">
    <ul class="main-nav-list">
      <li
        class="nav"
        [ngClass]="{ 'active-class': isRouteActive('/dashboard') }"
        (click)="navigateTo('/dashboard',0)" *ngIf="['admin', 'manager', 'employee'].includes(profileName)">
        <img
          src="assets/images/icons/icon-dashboard-2.svg"
          alt="icon Dashboard"
        />
        <h3>Dashboard</h3>
      </li>
      <li class="nav" [ngClass]="{ 'active-class': isRouteActive('/inventory') }"
      (click)="navigateTo('/inventory',0)" *ngIf="['admin', 'manager'].includes(profileName)">
        <img
          src="assets/images/icons/icon-inventory-2.svg"
          alt="icon Inventory"
        />
        <h3>Inventory</h3>
      </li>
      <li
        class="nav"
        [ngClass]="{ 'active-class': isRouteActive('/banking') }"
        (click)="navigateTo('/banking',0)" *ngIf="['admin', 'manager'].includes(profileName)"
      >
        <img src="assets/images/icons/icon-bank-2.svg" alt="icon Banking" />
        <h3>Banking</h3>
      </li>
      <li class="nav dropdown-nav" [ngClass]="{
        'active-class': isRouteActive2([
          '/customers',
          '/customer-new',
          '/quotation',
          '/quotation-new',
          '/quotation-details',
          '/projects',
          '/project-inventory',
          '/add-project-inventory',
          '/work-order',
          '/work-order-details',
          '/work-order-new',
          '/invoice',
          '/invoice-new',
          '/invoice-details',
          '/payment-received/payment-new',
          '/payment-received',
          '/payment-received/payment-received-details',
          '/credit-note',
          '/credit-note-details',
          '/credit-note-new',
        ])
      }"
      *ngIf="['admin', 'manager'].includes(profileName)"
      >
        <mat-accordion>
          <mat-expansion-panel  [expanded]="mattabnumber == 1">
            <mat-expansion-panel-header (click)="onPanelClick()">
              <mat-panel-title>
                <img
                  src="assets/images/icons/icon-sales.svg"
                  alt="icon Sales"
                />
                <h3>Sales</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sub-nav">
              <li [ngClass]="{ active: isRouteActive('/customers') }"
              (click)="navigateTo('/customers',1)">Customers</li>
              <li [ngClass]="{ active: isRouteActive('/quotation') }"
              (click)="navigateTo('/quotation',1)">Quotes</li>
              <li [ngClass]="{ active: isRouteActive('/projects') }"
              (click)="navigateTo('/projects',1)">Project</li>   
              <li [ngClass]="{ active: isRouteActive('/work-order') }"
              (click)="navigateTo('/work-order',1)">Work Order</li>
              <li [ngClass]="{ active: isRouteActive('/invoice') }"
              (click)="navigateTo('/invoice',1)">Invoice</li>
              <li [ngClass]="{ active: isRouteActive('/payment-received') }"
              (click)="navigateTo('/payment-received',1)">Payment Received</li>
              <li [ngClass]="{ active: isRouteActive('/credit-note') }"
              (click)="navigateTo('/credit-note',1)">Credit Note</li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      </li>
      <li class="nav dropdown-nav" [ngClass]="{
        'active-class': isRouteActive2([
          '/vendors',
          '/vendor-details',
          '/vendor-new',
          '/expense',
          '/expense-details',
          '/expense-new',
          '/purchase-order',
          '/purchase-order-details',
          '/purchase-order-new',
          '/local-purchase-order',
          '/local-purchase-order-details',
          '/local-purchase-order-new',
          '/bills',
          '/bill-new',
          '/bill-details',
          '/grn',
          '/grn-details',
          '/grn-new',
          '/payments-made',
          '/payments-made-details',
          '/payments-made/bill-payment-new',
          '/debit-note',
          '/debit-note-details',
          '/debit-note-new',
        ])
      }"
      *ngIf="['admin', 'manager'].includes(profileName)"
      >
        <mat-accordion>
          <mat-expansion-panel [expanded]="mattabnumber == 2">
            <mat-expansion-panel-header (click)="onPanelClick()">
              <mat-panel-title>
                <img
                  src="assets/images/icons/icon-purchase.svg"
                  alt="icon Purchase"
                />
                <h3>Purchase</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sub-nav">
              <li [ngClass]="{ active: isRouteActive('/vendors') }"
              (click)="navigateTo('/vendors',2)">Vendors</li>
              <li [ngClass]="{ active: isRouteActive('/expense') }"
              (click)="navigateTo('/expense',2)">Expenses</li>
              <li [ngClass]="{ active: isRouteActive('/purchase-order') }"
              (click)="navigateTo('/purchase-order',2)">Purchase Order</li>
              <li [ngClass]="{ active: isRouteActive('/local-purchase-order') }"
              (click)="navigateTo('/local-purchase-order',2)">Local Purchase Order</li>
              <li [ngClass]="{ active: isRouteActive('/bills') }"
              (click)="navigateTo('/bills',2)">Bills</li>
              <li [ngClass]="{ active: isRouteActive('/grn') }"
              (click)="navigateTo('/grn',2)">GRN</li>
              <li [ngClass]="{ active: isRouteActive('/payments-made') }"
              (click)="navigateTo('/payments-made',2)">Payment Made</li>
              <li [ngClass]="{ active: isRouteActive('/debit-note') }"
              (click)="navigateTo('/debit-note',2)">Debit Note</li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      </li>
      <li
        class="nav"
        [ngClass]="{ 'active-class': isRouteActive('/branch') }"
        (click)="navigateTo('/branch',0)" *ngIf="['admin', 'manager'].includes(profileName)"
      >
        <img src="assets/images/icons/icon-branch.svg" alt="icon Branch" />
        <h3>Branch</h3>
      </li>
      <li
        class="nav dropdown-nav"
        [ngClass]="{
          'active-class': isRouteActive2([
            '/employees',
            '/add-employee',
            '/attendance-management',
            '/holiday',
            '/leaves',
            '/leave-policy',
            '/payroll',
            '/payroll-list',
            '/vacation-management'
          ])
        }"
        *ngIf="['admin'].includes(profileName)"
      >
        <mat-accordion>
          <mat-expansion-panel [expanded]="mattabnumber == 3">
            <mat-expansion-panel-header (click)="onPanelClick()">
              <mat-panel-title>
                <img src="assets/images/icons/icon-hrms.svg" alt="icon HRMS" />
                <h3>HRMS</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sub-nav">
              <li
                [ngClass]="{ active: isRouteActive('/employees') }"
                (click)="navigateTo('/employees',3)"
              >
                Employees
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/attendance-management') }"
                (click)="navigateTo('/attendance-management',3)"
              >
                Attendance
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/leaves') }"
                (click)="navigateTo('/leaves',3)"
              >
                Leave
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/payroll') }"
                (click)="navigateTo('/payroll',3)"
              >
                Payroll
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/holiday') }"
                (click)="navigateTo('/holiday',3)"
              >
                Holiday
              </li>
              <!-- <li [ngClass]="{'active': isRouteActive('/leave-policy')}" (click)="navigateTo('/leave-policy')">Leave Policy</li> -->
              <li
                [ngClass]="{ active: isRouteActive('/vacation-management') }"
                (click)="navigateTo('/vacation-management',3)"
              >
                Vacation
              </li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      </li>
      <li class="nav" *ngIf="['admin', 'manager'].includes(profileName)">
        <img
          src="assets/images/icons/icon-approvals.svg"
          alt="icon Approvals"
        />
        <h3>Approvals</h3>
      </li>
      <li class="nav" [ngClass]="{ 'active-class': isRouteActive('/assets') }"
      (click)="navigateTo('/assets',0)" *ngIf="['admin', 'manager'].includes(profileName)">
        <img src="assets/images/icons/icon-assets.svg" alt="icon Assets" />
        <h3>Assets</h3>
      </li>
      <li
        class="nav dropdown-nav"
        [ngClass]="{
          'active-class': isRouteActive2([
            '/currency-adjustments',
            '/chart-of-accounts',
            '/manual-journals'
          ])
        }"
        *ngIf="['admin', 'manager'].includes(profileName)"
      >
        <mat-accordion>
          <mat-expansion-panel [expanded]="mattabnumber == 4">
            <mat-expansion-panel-header (click)="onPanelClick()">
              <mat-panel-title>
                <img
                  src="assets/images/icons/icon-accounts.svg"
                  alt="icon Accounts"
                />
                <h3>Accounts</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="sub-nav">
              <li
                [ngClass]="{ active: isRouteActive('/currency-adjustments') }"
                (click)="navigateTo('/currency-adjustments',4)"
              >
                Currency Adjustments
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/manual-journals') }"
                (click)="navigateTo('/manual-journals',4)"
              >
                Manual Journal
              </li>
              <li
                [ngClass]="{ active: isRouteActive('/chart-of-accounts') }"
                (click)="navigateTo('/chart-of-accounts',4)"
              >
                Chart Of Accounts
              </li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      </li>
      <li
        class="nav"
        [ngClass]="{ 'active-class': isRouteActive('/reports') }"
        (click)="navigateTo('/reports',4)"
        *ngIf="['admin', 'manager'].includes(profileName)"
      >
        <img src="assets/images/icons/icon-report.svg" alt="icon Report" />
        <h3>Report</h3>
      </li>
      <li class="nav" *ngIf="['admin', 'manager'].includes(profileName)">
        <img src="assets/images/icons/icon-roles.svg" alt="icon Roles" />
        <h3>Roles & Permissions</h3>
      </li>
    </ul>
  </div>
</section>
