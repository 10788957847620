import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';

@Component({
  selector: 'app-roles',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    AgGridAngular
  ],
  templateUrl: './roles.component.html',
  styleUrl: './roles.component.scss'
})
export class RolesComponent {

  isDropdownOpen = false;

  readonly dialog = inject(MatDialog);

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  openDialog() {
    const dialogRef = this.dialog.open(RolesDialog);
  }

  rowData = [
    {
      name: 'Brooklyn Simmons',
      id: "124335111",
      branch: "Creative Spaces",
      designation: "Marketing Coordinator",
      type: "Office",

    },
    {
      name: 'Brooklyn Simmons',
      id: "124335111",
      branch: "Creative Spaces",
      designation: "Marketing Coordinator",
      type: "Office",
    },

  ];

  columnDefs = [

    {
      headerName: 'Employee Name',
      field: 'name',
      flex: 1,

    },
    { headerName: 'Employee ID', field: 'id', flex: 1 },
    { headerName: 'Branch Name', field: 'branch', flex: 1 },
    { headerName: 'Designation', field: 'designation', flex: 1 },
    { headerName: 'Type', field: 'type', flex: 1 },
  ];

  public defaultColDef: ColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public paginationPageSize = 10;
  public paginationPageSizeSelector: number[] | boolean = [10, 25, 50];
  public themeClass: string = 'ag-theme-quartz';


}


@Component({
  selector: 'add-profile-dialog',
  templateUrl: 'add-profile-dialog.html',
  styleUrls: ['./roles.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule
  ],
})
export class RolesDialog {
  file: any = null;


  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }


}
