import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryItemService } from '../../features/inventory/services/inventory.service';
import { PaymentsItemService } from '../../features/payments/services/payments.service';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { PAYMENT_MODES } from '../../helpers/helper-file';

@Component({
  selector: 'app-payment-received-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    MatSelectModule,
    MatIconModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './payment-received-details.component.html',
  styleUrl: './payment-received-details.component.scss',
})
export class PaymentReceivedDetailsComponent {
  commentsForm: FormGroup;
  public loading: boolean = true;
  allItems: any[] = [];
  itemData: any = {};
  isLoading = false;
  private searchSubject = new Subject<string>();
  commentsList: any;
  paymentModeList: any = PAYMENT_MODES;
  selectedModes: string[] = [];
  selectedCount: number = 0;
  itemId: any;

  constructor(
    private itemService: PaymentsItemService,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.fetchItems();
    this.initForm();

    this.searchSubject
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchTerm) => {
        this.fetchItems(searchTerm);
      });

    this.route.queryParams.subscribe((params) => {
      const id = params['id'];
      this.itemId = id;
    });
  }

  initForm(): void {
    this.commentsForm = this.fb.group({
      comments: ['', Validators.required],
    });
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  navigateToEdit(path: string) {
    const selectedIteam = this.allItems[this.selectedTabIndex];
    const itemId = selectedIteam.id;
    this.router.navigate([path], { queryParams: { id: itemId } });
  }

  selectedOption = 'today';

  selectedTabIndex = 0; // Default to the first tab

  selectTab(index: number) {
    this.selectedTabIndex = index;
    this.itemData = this.allItems[index];
    if (this.itemData) {
      this.router.navigate(['payment-received/payment-received-details'], {
        queryParams: { id: this.itemData.id },
      });
    }
  }

  readonly dialog = inject(MatDialog);

  openDialog() {
    const dialogRef = this.dialog.open(UploadPaymentReceivedDetailsDialog);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'refresh') {
        this.fetchItems();
      }
    });
  }

  isDropdownOpen = false;
  isDropdownOpenCmd = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  navigateToMail(): void {
    const paymentID = this.itemData.id;
    const ids =
      this.itemData?.paymentInfo?.map((info: any) => info?.invoice?.id) || [];

    this.router.navigate([`payment-received/payment-received-details/mail`], {
      queryParams: { paymentID, ids },
    });
  }

  toggleDropdownCmd(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    const selectedIteam = this.allItems[this.selectedTabIndex];
    const itemId = selectedIteam.id;
    this.fetchComments(itemId);
    this.isDropdownOpenCmd = !this.isDropdownOpenCmd;
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value;
    this.searchSubject.next(searchTerm);
  }

  toggleMode(mode: string) {
    if (this.selectedModes.includes(mode)) {
      this.selectedModes = this.selectedModes.filter((m) => m !== mode);
    } else {
      this.selectedModes.push(mode);
    }
    this.selectedCount = this.selectedModes?.length;
  }

  onApplyFilters() {
    this.fetchItems('', {
      paymentType: 'Invoice',
      paymentMode: this.selectedModes,
    });
    this.toggleDropdown();
  }

  clearFilters() {
    this.selectedModes = [];
    this.fetchItems('', { paymentType: 'Invoice', paymentMode: [] });
    this.toggleDropdown();
    this.selectedCount = 0;
  }

  fetchItems(search: string = '', filter: any = { paymentType: 'Invoice' }) {
    this.loading = true;
    this.itemService.fetchPayments(search, filter).subscribe({
      next: (items) => {
        this.allItems = items;
        if (this.allItems.length > 0) {
          this.selectTab(0);
        }

        if (this.itemId) {
          const targetId = +this.itemId;
          this.selectedTabIndex = this.allItems.findIndex((c: any) => {
            return c.id === targetId;
          });
          if (this.selectedTabIndex === -1) {
            this.selectedTabIndex = 0;
          }
        } else {
          this.selectedTabIndex = 0;
        }
      },
      error: (err) => {
        console.error('Error fetching all items:', err);
      },
    });
  }

  fetchComments(refId: number) {
    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee.employeeId;

    const value = {
      employeeId: employeeId,
      refId: refId,
      activityType: 'Comment',
      type: 'Payment',
    };
    this.loading = true;
    this.itemService.comments(value).subscribe({
      next: (items) => {
        this.commentsList = items;
      },
      error: (err) => {
        console.error('Error fetching all items:', err);
      },
    });
  }

  onCommentSubmit(): void {
    if (this.commentsForm.invalid) {
      this.commentsForm.markAllAsTouched();
      return;
    }

    const loginEmployee: any = localStorage.getItem('loggedInUser');
    const employee = JSON.parse(loginEmployee);
    const employeeId = employee.employeeId;
    const selectedIteam = this.allItems[this.selectedTabIndex];
    const refId = selectedIteam.id;

    const data = this.commentsForm.value;

    const createCommentInput = {
      employeeId: employeeId,
      refId: refId,
      type: 'Payment',
      activityType: 'Comment',
      note: data?.comments,
    };

    this.itemService.createComment(createCommentInput).subscribe({
      next: () => {
        this.isLoading = false;
        const selectedIteam = this.allItems[this.selectedTabIndex];
        const itemId = selectedIteam.id;
        this.fetchComments(itemId);
        this.toastr.success('Successfully.');
        this.commentsForm.reset();
      },
      error: (error) => {
        this.isLoading = false;
        this.toastr.error(error.message || 'Failed.');
      },
    });
  }

  // selectTab(index: number) {
  //   this.selectedTabIndex = index;
  //   this.itemData = this.allItems[index];
  // }
}

@Component({
  selector: 'upload-payment-received-details-dialog',
  templateUrl: 'upload-payment-received-details-dialog.html',
  styleUrls: ['./payment-received-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadPaymentReceivedDetailsDialog {
  file: any = null;
  url: any = null;

  constructor(
    private toastr: ToastrService,
    private itemService: PaymentsItemService,
    private dialogRef: MatDialogRef<UploadPaymentReceivedDetailsDialog>
  ) {}

  ngOnInit() {
    document.addEventListener('dragover', this.preventDefaultBehavior);
    document.addEventListener('drop', this.preventDefaultBehavior);
  }

  preventDefaultBehavior(event: DragEvent) {
    event.preventDefault();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();

    if (event.dataTransfer?.files.length) {
      this.onFileSelected({ target: { files: event.dataTransfer.files } });
    }
  }

  onFileSelected(event: any): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.file = file;
    }
  }

  uploadDocuments(csvFile: any) {
    this.itemService.bulkCreatePayment(csvFile).subscribe({
      next: (response) => {
        if (response == true) {
          this.toastr.success('Bulk updated successfully!');
          this.dialogRef.close('refresh');
        }
      },
      error: (error) => {
        this.toastr.error(error.message, 'Bulk updated failed');
        this.dialogRef.close();
      },
    });
  }

  removeFile(): void {
    this.file = null;
    const input = document.querySelector(
      'input[type="file"]'
    ) as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

  onUpload() {
    this.uploadDocuments(this.file);
  }

  ngOnDestroy() {
    document.removeEventListener('dragover', this.preventDefaultBehavior);
    document.removeEventListener('drop', this.preventDefaultBehavior);
  }
}
