
    <section class="cmn-innerpage-wrapper expense-details">
        <div class="head">
            <div class="search-wrapper">
                <input type="text" class="cmn-header-search" placeholder="Search" />
            </div>
            <button class="cmn-upload-btn" (click)="openDialog()">
                <img src="assets/images/icons/icon-upload.svg" alt="Upload Icon" />
                <span>Upload</span>
            </button>
            <button class="cmn-add-btn" (click)="navigateTo('/expense-new')">
                <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> Add Expense
            </button>
        </div>
        <div class="sub-head">
            <ul>
                <li class="main">Expenses</li>
                <li class="edit" (click)="navigateTo('/expense-new')"><img src="assets/images/icons/icon-edit-accounts.svg" alt="Edit"> Edit</li>
            </ul>
            <div class="mat-menu-wrapper">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item>
                        <span>Mark as Sent</span>
                    </button>
                    <button mat-menu-item>
                        <span>Credit Available</span>
                    </button>
                    <button mat-menu-item>
                        <span>Create Credit Note</span>
                    </button>
                    <button mat-menu-item>
                        <span>Credit Applied</span>
                    </button>
                    <button mat-menu-item>
                        <span>Clone</span>
                    </button>
                    <button mat-menu-item>
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </div>
        </div>
        <div class="manual-journal-details-tab-wrapper">
            <div class="tab-left">
                <div class="tab-box-main-wrapper">
                    <button class="tab-custom-button" [class.active]="selectedTabIndex === 0" (click)="selectTab(0)">
                        <div class="inn-box">
                            <h2>Cost of Goods Sold</h2>
                            <h6>12-7-2024</h6>
                        </div>
                        <div class="inn-box">
                            <h3>Dan Bizler</h3>
                            <h4 class="green">Reimbursed</h4>
                            <h5>AED <span>1200</span></h5>
                        </div>
                    </button>
                    <button class="tab-custom-button" [class.active]="selectedTabIndex === 1" (click)="selectTab(1)">
                        <div class="inn-box">
                            <h2>Cost of Goods Sold</h2>
                            <h6>12-7-2024</h6>
                        </div>
                        <div class="inn-box">
                            <h3>Dan Bizler</h3>
                            <h4 class="green">Reimbursed</h4>
                            <h5>AED <span>1200</span></h5>
                        </div>
                    </button>
                    <button class="tab-custom-button" [class.active]="selectedTabIndex === 2" (click)="selectTab(2)">
                        <div class="inn-box">
                            <h2>Cost of Goods Sold</h2>
                            <h6>12-7-2024</h6>
                        </div>
                        <div class="inn-box">
                            <h3>Dan Bizler</h3>
                            <h4>Unbilled</h4>
                            <h5>AED <span>1200</span></h5>
                        </div>
                    </button>
                    <button class="tab-custom-button" [class.active]="selectedTabIndex === 3" (click)="selectTab(3)">
                        <div class="inn-box">
                            <h2>Cost of Goods Sold</h2>
                            <h6>12-7-2024</h6>
                        </div>
                        <div class="inn-box">
                            <h3>Dan Bizler</h3>
                            <h4>Unbilled</h4>
                            <h5>AED <span>1200</span></h5>
                        </div>
                    </button>
                    <button class="tab-custom-button" [class.active]="selectedTabIndex === 4" (click)="selectTab(4)">
                        <div class="inn-box">
                            <h2>Cost of Goods Sold</h2>
                            <h6>12-7-2024</h6>
                        </div>
                        <div class="inn-box">
                            <h3>Dan Bizler</h3>
                            <h4>Unbilled</h4>
                            <h5>AED <span>1200</span></h5>
                        </div>
                    </button>

                </div>
            </div>
            <div class="manual-journal-details-content-wrapper">
                <div *ngIf="selectedTabIndex === 0">
                    <div class="chart-tab-content">
                        <div class="tab-inner">
                            <div class="all-transaction-sidedialog-content-wrapper">
                                <div class="type-head">
                                    <div>
                                        <h2>AED 3,500.00</h2>
                                        <h3>On 26 Aug 2024</h3>
                                    </div>
                                    <span>Invoice Payment</span>
                                </div>
                                <div class="transaction-table-wrapper">
                                    <table>
                                        <tr>
                                            <td>Customer</td>
                                            <td>Bank Charges</td>
                                            <td>Received Via</td>
                                        </tr>
                                        <tr class="bold border">
                                            <td>Laura Dizler</td>
                                            <td>AED 0.00</td>
                                            <td>Cash</td>
                                        </tr>
                                        <tr>
                                            <td>Invoice Details</td>
                                            <td></td>
                                            <td>Payment</td>
                                        </tr>
                                        <tr class="bold">
                                            <td>IN-0012455</td>
                                            <td></td>
                                            <td>AED 3,500.00</td>
                                        </tr>
                                        <tr class="bold">
                                            <td>AED 0.00</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr class="border">
                                            <td>02/06/2024</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr class="title">
                                            <td>Journal</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Account</td>
                                            <td>Debit</td>
                                            <td>Credit</td>
                                        </tr>
                                        <tr class="bold">
                                            <td>Accounts Receivable</td>
                                            <td>AED 0.00</td>
                                            <td>AED 3,500.00</td>
                                        </tr>
                                        <tr class="bold border">
                                            <td>Personal (Current)</td>
                                            <td>AED 3,500.00</td>
                                            <td>AED 0.00</td>
                                        </tr>
                                        <tr class="bold">
                                            <td></td>
                                            <td>AED 3,500.00</td>
                                            <td>AED 3,500.00</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedTabIndex === 1">
                    <div class="chart-tab-content">
                        content 2
                    </div>
                </div>
                <div *ngIf="selectedTabIndex === 2">
                    <div class="chart-tab-content">
                        content 3
                    </div>
                </div>
                <div *ngIf="selectedTabIndex === 3">
                    <div class="chart-tab-content">
                        content 4
                    </div>
                </div>
                <div *ngIf="selectedTabIndex === 4">
                    <div class="chart-tab-content">
                        content 5
                    </div>
                </div>
            </div>
        </div>
    </section>
