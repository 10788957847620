import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Router } from '@angular/router';

@Component({
  selector: 'app-quotation-details',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatInputModule,
    MatTabsModule,
    MatMenuModule,
    MatIconModule,
    MatSelectModule
  ],
  templateUrl: './quotation-details.component.html',
  styleUrl: './quotation-details.component.scss'
})
export class QuotationDetailsComponent {

  constructor(
    private router: Router
  ) {}

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  selectedOption = 'today';


  selectedTabIndex = 0; // Default to the first tab

  selectTab(index: number) {
    this.selectedTabIndex = index;
  }

  readonly dialog = inject(MatDialog);



  openDialog() {
    const dialogRef = this.dialog.open(UploadQuotationDetailsDialog);
  }

  openQuotaionDialog() {
    const dialogQuotaionRef = this.dialog.open(QuotaionRejectDialog);
  }

  isDropdownOpen = false;
  isCommentsOpen = false;

  toggleDropdown(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }


  toggleComments(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.isCommentsOpen = !this.isCommentsOpen;
  }




}



@Component({
  selector: 'upload-quotation-details-dialog',
  templateUrl: 'upload-quotation-details-dialog.html',
  styleUrls: ['./quotation-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class UploadQuotationDetailsDialog {

  file: any = null;

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file);
      this.file = file;
    }
  }

}


@Component({
  selector: 'quotaion-reject-dialog',
  templateUrl: 'quotation-reject-dialog.html',
  styleUrls: ['./quotation-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, FormsModule, MatFormFieldModule, MatInputModule],
})


export class QuotaionRejectDialog {


}