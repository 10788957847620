import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, from, map } from 'rxjs';
import {
  GET_HOLIDAY_CALENDAR,
  GET_HOLIDAY_CALENDAR_WITH_PAGINATION,
} from '../graphql/query/holiday-calendar.queries';
import {
  CREATE_HOLIDAY_CALENDAR,
  REMOVE_HOLIDAY_CALENDAR,
  UPDATE_HOLIDAY_CALENDAR,
} from '../graphql/mutation/holiday-calendar.mutation';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HolidayCalendarService {
  constructor(private apollo: Apollo) {}

  //fetch holiday calendar
  getHolidayCalendarList(
    holidayDate: string = '',
    year: string = '',
    search: string = ''
  ): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_HOLIDAY_CALENDAR,
        variables: { holidayDate, year, search },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data?.holidayCalendars || []));
  }

  //fetch holiday calendar with pagination
  getHolidayCalendarListWithPagination(
    holidayDate: string = '',
    year: string = '',
    search: string = '',
    limit: number = 10,
    offset: number = 1
  ): Observable<any> {
    return this.apollo
      .use('hrms')
      .watchQuery<any>({
        query: GET_HOLIDAY_CALENDAR_WITH_PAGINATION,
        variables: { holidayDate, year, search, limit, offset },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((result) => {
          const data = result.data?.findAllHolidayCalendarsWithPagination;
          return {
            holidayCalendars: data.holidayCalendars,
            total: data.total,
            limit: data.limit,
            offset: data.offset,
          };
        })
      );
  }

  //create holiday calendar
  createHolidayCalendar(createHolidayCalendarInput: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: CREATE_HOLIDAY_CALENDAR,
      variables: {
        createHolidayCalendarInput: createHolidayCalendarInput,
      },
    });
  }

  //update holiday calendar
  updateHolidayCalendar(updateHolidayCalendarInput: any): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: UPDATE_HOLIDAY_CALENDAR,
      variables: {
        updateHolidayCalendarInput: updateHolidayCalendarInput,
      },
    });
  }

  //remove holiday calendar
  removeHolidayCalendarById(id: number): Observable<any> {
    return this.apollo.use('hrms').mutate({
      mutation: REMOVE_HOLIDAY_CALENDAR,
      variables: { id },
    });
  }

  //bulk upload holiday calendar
  bulkCreateHolidayCalendar(file: File): Observable<any> {
    const token = localStorage.getItem('AUTH_TOKEN');
    const formData = new FormData();
    formData.append(
      'operations',
      JSON.stringify({
        query: `
      mutation($file: Upload!) {
        bulkCreateHolidayCalendar(file: $file)
      }      
      `,
        variables: { file: null },
      })
    );

    formData.append('map', JSON.stringify({ '0': ['variables.file'] }));
    formData.append('0', file);

    return from(
      fetch(environment.url.hrmsModuleUrl, {
        method: 'POST',
        body: formData,
        headers: {
          authorization: token ? `Bearer ${token}` : '',
          'x-apollo-operation-name': 'uploadFile',
        },
      })
        .then((response) => response.json())
        .then((result) => result)
    );
  }
}
