export const PAYMENT_MODES = [
  'Cash',
  'Cheque',
  'Bank Transfer',
  'Credit Card',
  'Bank Remittance',
  // 'UPI',
];

export const PAYMENT_TERMS = [
  'Net 15',
  'Net 30',
  'Net 45',
  'Net 60',
  'Due On Receipt',
  'Due end of the month',
  'Due end of next month',
  'Custom',
];

export enum PartnerTypesEnum {
  Customer = 'Customer',
  Vendor = 'Vendor',
}

export enum PaymentTypesEnum {
  INVOICE = 'INVOICE',
  BILL = 'BILL'
}

export enum AddressTypeEnum {
  billing = 'BILLING',
  shipping = 'SHIPPING'
}
