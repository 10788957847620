<mat-dialog-content>
  <section class="select-address-dialog-wrapper">
    <button class="cmn-close" mat-dialog-close></button>
    <h2 class="cmn-inner-heading">Billing Address</h2>
    <div class="select-address-wrapper">

      <div class="select-box" [class.active]="selectedAddressId === address.id"
        *ngFor="let address of billingAddressList" (click)="selectAddress(address.id)">

        <div class="head">
          <h2>{{address?.attention}}</h2>
          <button (click)="openBillingAddressDialog('edit')">
            <img src="assets/images/icons/icon-edit.svg" alt="Edit Icon" />
          </button>
        </div>
        <h3>{{address?.addressLine1}} {{address?.addressLine2}} {{address?.city}}</h3>
        <h3>{{address?.country}} {{address?.pinCode}}</h3>

      </div>
    </div>
    <button class="cmn-add-btn" (click)="openBillingAddressDialog('add')">
      <img src="assets/images/icons/icon-add.svg" alt="Add icon" /> Add New
      Address
    </button>
  </section>
</mat-dialog-content>