import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatTabsModule} from '@angular/material/tabs';
import {MatRippleModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    ValidationErrors,
    Validators
} from '@angular/forms';
import {Router} from '@angular/router';
import {nonNegativeValidator} from '../../../../shared/services/validations';

@Component({
    selector: 'app-salary-details',
    standalone: true,
    imports: [
        CommonModule,
        MatTabsModule,
        MatRippleModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    templateUrl: './salary-details.component.html',
    styleUrl: '../../add-employee.component.scss'
})
export class SalaryDetailsComponent implements OnChanges {
    salaryDetailForm : FormGroup;

    @Input()employee : any;
    @Input()isEditMode : boolean = false;
    @Input()employeeData : any = {};
    @Input()salaryDetails : any;
    @Output()formSubmit = new EventEmitter<any>();

    @Input()currentCTC : number; // Receive the current CTC value

    constructor(private fb : FormBuilder, private router : Router) {
        this.salaryDetailForm = this.fb.group({
            basicSalary: [
                '',
                [
                    Validators.required, this.basicSalaryValidator.bind(this), nonNegativeValidator
                ],
            ],
            hra: [
                '',
                [
                    Validators.required, nonNegativeValidator
                ]
            ],
            allowances: [
                '',
                [
                    Validators.required, nonNegativeValidator
                ]
            ],
            deductions: [
                '',
                [
                    Validators.required, nonNegativeValidator, this.deductionsValidator.bind(this)
                ]
            ],
            grossSalary: [
                {
                    value: '',
                    disabled: true
                },
                Validators.required
            ],
            netSalary: [
                {
                    value: '',
                    disabled: true
                },
                [
                    Validators.required, nonNegativeValidator
                ]
            ]
        });
        this.salaryDetailForm.valueChanges.subscribe(() => {
            this.updateSalaries();
        });
    }

    ngOnChanges(changes : SimpleChanges): void {
        if (changes['salaryDetails'] && this.isEditMode && this.employee) {
            this.populateFormWithEmployeeData();
        }
    }

    populateFormWithEmployeeData() {
        if (this.salaryDetails) {
            this.salaryDetailForm.patchValue({
                basicSalary: this.salaryDetails.basicSalary || 0,
                hra: this.salaryDetails.hra || 0,
                allowances: this.salaryDetails.allowances || 0,
                deductions: this.salaryDetails.deductions || 0,
                grossSalary: this.salaryDetails.grossSalary || 0,
                netSalary: this.salaryDetails.netSalary || 0,
                remarks: this.salaryDetails.remarks || ''
            });
        }
    }
    // Custom Validator Function
    basicSalaryValidator(control : AbstractControl): ValidationErrors | null {
        const basicSalary = control.value;
        if (this.currentCTC && basicSalary > this.currentCTC) {
            return {salaryExceedsCTC: true};
        }
        return null;
    }

    // Custom Validator for Deductions
    deductionsValidator(control : AbstractControl): ValidationErrors | null {
        const deductions = control.value;
        const basicSalary = this.salaryDetailForm ?. get('basicSalary') ?. value || 0;
        if (deductions > basicSalary) {
            return {deductionsExceedBasic: true};
        }
        return null; // No error
    }

    // Calculate and update gross and net salary
    updateSalaries(): void {
        const basicSalary = + this.salaryDetailForm.get('basicSalary') ?. value || 0;
        const hra = + this.salaryDetailForm.get('hra') ?. value || 0;
        const allowances = + this.salaryDetailForm.get('allowances') ?. value || 0;
        const deductions = + this.salaryDetailForm.get('deductions') ?. value || 0;

        const grossSalary = basicSalary + hra + allowances;
        const netSalary = grossSalary - deductions;

        this.salaryDetailForm.patchValue({
            grossSalary,
            netSalary
        }, {emitEvent: false});
    }

    onNext(): void {
        this.salaryDetailForm.get('grossSalary') ?. enable();
        this.salaryDetailForm.get('netSalary') ?. enable();

        this.salaryDetailForm.markAllAsTouched();
        const basicSalary = + this.salaryDetailForm.get('basicSalary') ?. value;

        if (this.salaryDetailForm.valid) {
            console.log('Salary Info SUbmitted--------', this.salaryDetailForm.value);
            this.formSubmit.emit(this.salaryDetailForm.value);
        } else {
            console.log('Form is Invalid');
        }

        this.salaryDetailForm.get('grossSalary') ?. disable();
        this.salaryDetailForm.get('netSalary') ?. disable();
    }

    onCancel(): void {
        this.router.navigate(['/employees']);
    }
}
