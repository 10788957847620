<div class="layout-container">
  <div class="sidebar" *ngIf="!isSpecialRoute" [class.collapsed]="isCollapsed">
    <button (click)="toggleSidebar()" class="toggle-btn"></button>
    <nav class="sidebar-nav">
      <app-sidebar
        [sidebarData]="dataFromLayout"
        (triggerSidebarAction)="triggerSidebarOnce()"
      ></app-sidebar>
    </nav>
  </div>
  <div
    class="main-content"
    [class.collapsedMain]="isCollapsed"
    [ngClass]="{ excluded: isSpecialRoute }"
  >
      <div class="headerFlexWrapper" *ngIf="!isSpecialRoute">
        <div class="left-set">
          <!-- <h2 class="cmn-title">Dashboard</h2> -->
          <h2 class="cmn-title">
            {{ breadcrumbs.length ? breadcrumbs[0]?.name : "Dashboard" }}
          </h2>
          <div class="cmn-sub-title">
            <ul>
              <li *ngFor="let breadcrumb of breadcrumbs; let i = index">
                <a
                  [routerLink]="breadcrumb.path"
                  *ngIf="i < breadcrumbs.length - 1"
                  >{{ breadcrumb.name }}</a
                >
                <span *ngIf="i === breadcrumbs.length - 1">{{
                  breadcrumb.name
                }}</span>
              </li>
            </ul>
            <h6 class="back" *ngIf="shouldShowBackButton()" (click)="goBack()">
              <img src="assets/images/icons/icon-back.svg" alt="Icon Back" />
              Back
            </h6>
          </div>
        </div>

        <app-header [sidebarData]="dataFromLayout"></app-header>
      </div>
    <router-outlet></router-outlet>
  </div>
</div>

<!-- <app-loading-spinner></app-loading-spinner> -->

<button (click)="toggleSidebar()" class="responsive-nav-btn"></button>
