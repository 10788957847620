<div class="contact-persons-main-wrapper">
    <form [formGroup]="contactPersonForm" (ngSubmit)="onSubmit()">
        <div class="table-wrapper">
            <table>
                <thead>
                    <tr>
                        <th>Primary</th>
                        <th>Salutation</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email Address</th>
                        <th>Work Phone</th>
                        <th>Mobile</th>
                    </tr>
                </thead>

                <tbody formArrayName="contactPersons">
                    <tr *ngFor="let contact of contactPersonsArray.controls; let i = index" [formGroupName]="i">
                        <td>
                            <div class="form-wrapper">
                                <label class="radiobtn-wrapper">
                                    <input type="radio" *ngIf="contact.get('primaryContact')?.value" checked
                                        (change)="setPrimaryContactOnly(contact)" />
                                    <span class="radiomark"></span>
                                </label>
                            </div>

                        </td>
                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <mat-select placeholder="Mr" formControlName="salutation"
                                        [disabled]="contact.get('primaryContact')?.value">
                                        <mat-option value="Mr">Mr.</mat-option>
                                        <mat-option value="Mrs">Mrs.</mat-option>
                                        <mat-option value="Miss">Miss</mat-option>
                                        <mat-option value="Dr">Dr.</mat-option>
                                        <mat-option value="Prof">Prof.</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </td>
                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput placeholder="Enter name" formControlName="firstName"
                                        [readonly]="contact.get('primaryContact')?.value" />
                                </mat-form-field>
                            </div>
                        </td>
                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput placeholder="Enter name" formControlName="lastName"
                                        [readonly]="contact.get('primaryContact')?.value" />
                                </mat-form-field>
                            </div>
                        </td>
                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput placeholder="Enter email" formControlName="email"
                                        [readonly]="contact.get('primaryContact')?.value" />
                                </mat-form-field>
                            </div>
                        </td>
                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput placeholder="Enter phone num" formControlName="phone"
                                        [readonly]="contact.get('primaryContact')?.value" />
                                </mat-form-field>
                            </div>
                        </td>
                        <td>
                            <div class="form-wrapper">
                                <mat-form-field>
                                    <input matInput placeholder="Enter mobile num" formControlName="mobile"
                                        [readonly]="contact.get('primaryContact')?.value" />
                                </mat-form-field>
                            </div>
                            <span class="delete" *ngIf="!contact.get('primaryContact')?.value"
                                (click)="onRemoveContact(i,contact.get('id')?.value)">
                                <img src="assets/images/icons/icon-close.svg" alt="Delete" />
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <button class="add-btn" (click)="addContactPersons()">
            <img src="assets/images/icons/icon-add-color.svg" alt="Add" /> Add
        </button>
    </form>
</div>