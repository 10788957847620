
  <section class="cmn-innerpage-wrapper invoice-new">
    <h2 class="cmn-inner-heading">
      {{ invoiceId ? "Update Invoice" : "New Invoice" }}
    </h2>
    <form [formGroup]="invoiceForm">
      <div class="invoice-new-form-wrapper">
        <div class="form-wrapper invoice-form-wrapper">
          <!-- Client Name Section -->
          <div class="cmn-Search-select-container">
            <label for="clientName">Customer Name</label>
            <mat-form-field>
              <mat-select
                placeholder="Search Customer"
                formControlName="partnerId"
                (selectionChange)="onCustomerSelected($event)"
              >
                <mat-option>
                  <ngx-mat-select-search
                    (input)="onSearch($event)"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let partner of partnersData"
                  [value]="partner.id"
                >
                  {{ partner.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button (click)="openCreateClientDialog()">
              <div>
                <img
                  src="../../../assets/images/icons/icon-add-color.svg"
                  alt="Add client"
                />
              </div>
              <span>Add customer</span>
            </button>
          </div>

          <div>
            <label for="invoiceNumber">Invoice Number</label>
            <mat-form-field>
              <input
                matInput
                placeholder="Invoice Number"
                id="invoiceNumber"
                formControlName="invoiceNo"
                maxlength="20"
              />
              <mat-error
                *ngIf="
                  invoiceForm.get('invoiceNo')!.touched &&
                  invoiceForm.get('invoiceNo')!.hasError('required')
                "
              >
                Invoice Number is required.
              </mat-error>
            </mat-form-field>
          </div>

          <div>
            <label for="orderNumber">Order Number</label>
            <mat-form-field>
              <input
                matInput
                placeholder="Order Number"
                id="orderNumber"
                formControlName="orderNo"
                maxlength="20"
              />
            </mat-form-field>
          </div>

          <div>
            <label for="invoiceDate">Invoice Date</label>
            <mat-form-field>
              <input
                matInput
                [matDatepicker]="invoiceDatePicker"
                placeholder="Select Date"
                id="invoiceDate"
                formControlName="invoiceDate"
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="invoiceDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #invoiceDatePicker></mat-datepicker>
              <mat-error
                *ngIf="
                  invoiceForm.get('invoiceDate')!.touched &&
                  invoiceForm.get('invoiceDate')!.hasError('required')
                "
              >
                Invoice Date is required.
              </mat-error>
            </mat-form-field>
          </div>

          <div>
            <label for="paymentTerms">Payment Terms</label>
            <mat-form-field>
              <mat-select
                placeholder="Due on receipt"
                id="paymentTerms"
                formControlName="paymentTerm"
              >
                <mat-option
                  *ngFor="let term of paymentTermsValue"
                  [value]="term"
                >
                  {{ term }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  invoiceForm.get('paymentTerm')!.touched &&
                  invoiceForm.get('paymentTerm')!.hasError('required')
                "
              >
                Payment Term is required.
              </mat-error>
            </mat-form-field>
          </div>

          <div>
            <label for="dueDate">Due Date</label>
            <mat-form-field>
              <input
                matInput
                [matDatepicker]="dueDatePicker"
                placeholder="Select Date"
                id="dueDate"
                formControlName="dueDate"
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="dueDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #dueDatePicker></mat-datepicker>

              <mat-error
                *ngIf="
                  invoiceForm.get('dueDate')!.touched &&
                  invoiceForm.get('dueDate')!.hasError('required')
                "
              >
                Due Date is required.
              </mat-error>
            </mat-form-field>
          </div>

          <div>
            <label for="subject">Subject</label>
            <mat-form-field>
              <input
                matInput
                placeholder="Invoice subject"
                id="subject"
                formControlName="subject"
              />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="billing-shipping-address-wrapper" *ngIf="selectedCustomerId">
        <div>
          <div class="header-wrapper">
            <h5>Billing Address</h5>
            <div (click)="openSelectBillingAddressDialog()">
              <img
                src="../../../assets/images/icons/icon-edit-accounts.svg"
                alt=""
              />
            </div>
          </div>
          <div *ngIf="selectedBillingAddress">
            <h6>{{ selectedBillingAddress.attention }}</h6>
            <ul>
              <li>{{ selectedBillingAddress.addressLine1 }}</li>
              <li>{{ selectedBillingAddress.addressLine2 }}</li>
              <li>{{ selectedBillingAddress.city }}</li>
              <li>{{ selectedBillingAddress.state }}</li>
              <li>{{ selectedBillingAddress.country }}</li>
              <li>{{ selectedBillingAddress.phone }}</li>
            </ul>
          </div>
          <button (click)="openBillingAddressDialog('add')">
            <!-- *ngIf="!selectedBillingAddress" -->
            <span>Add Address</span>
          </button>
        </div>

        <div>
          <div class="header-wrapper">
            <h5>Shipping Address</h5>
            <div (click)="openSelectShippingAddressDialog()">
              <img
                src="../../../assets/images/icons/icon-edit-accounts.svg"
                alt=""
              />
            </div>
          </div>
          <div *ngIf="selectedShippingAddress">
            <h6>{{ selectedShippingAddress.attention }}</h6>
            <ul>
              <li>{{ selectedShippingAddress.addressLine1 }}</li>
              <li>{{ selectedShippingAddress.addressLine2 }}</li>
              <li>{{ selectedShippingAddress.city }}</li>
              <li>{{ selectedShippingAddress.state }}</li>
              <li>{{ selectedShippingAddress.country }}</li>
              <li>{{ selectedShippingAddress.phone }}</li>
            </ul>
          </div>
          <button (click)="openShippingAddressDialog('add')">
            <!-- *ngIf="!selectedShippingAddress" -->
            <span>Add Address</span>
          </button>
        </div>
      </div>

      <div class="item-service-adding-table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Item/Service</th>
              <th>Quantity</th>
              <th>Rate</th>
              <th>Discount</th>
              <th>Amount</th>
            </tr>
          </thead>

          <tbody formArrayName="items">
            <tr
              *ngFor="let item of items.controls; let i = index"
              [formGroupName]="i"
            >
              <td>
                <div class="form-wrapper">
                  <div>
                    <mat-form-field>
                      <mat-select
                        placeholder="Select Item"
                        id="itemName"
                        formControlName="itemId"
                      >
                        <mat-option
                          *ngFor="let item of itemData"
                          [value]="item.id"
                        >
                          {{ item.itemName }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          item.get('itemId')?.touched &&
                          item.get('itemId')?.hasError('required')
                        "
                      >
                        Item is required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </td>
              <td>
                <div class="form-wrapper">
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="quantity"
                      formControlName="quantity"
                    />
                    <mat-error
                      *ngIf="
                        item.get('quantity')?.touched &&
                        item.get('quantity')?.hasError('required')
                      "
                    >
                      Quantity is required. </mat-error
                    ><mat-error
                      *ngIf="item.get('quantity')?.hasError('nonNegative')"
                    >
                      Quantity cannot be negative.
                    </mat-error>
                  </mat-form-field>
                </div>
              </td>
              <td>
                <div class="form-wrapper">
                  <mat-form-field>
                    <input matInput placeholder="rate" formControlName="rate" />
                    <mat-error
                      *ngIf="
                        item.get('rate')?.touched &&
                        item.get('rate')?.hasError('required')
                      "
                    >
                      Rate is required.
                    </mat-error>
                  </mat-form-field>
                </div>
              </td>
              <td>
                <div class="form-wrapper">
                  <div class="split-parent">
                    <div class="left-set">
                      <mat-form-field>
                        <input
                          matInput
                          placeholder="0.00"
                          formControlName="discount"
                          type="number"
                        />
                      </mat-form-field>
                    </div>
                    <div class="right-set">
                      <mat-form-field>
                        <mat-select placeholder="%">
                          <!-- <mat-option value="%">%</mat-option> -->
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div class="form-wrapper">
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="100"
                      formControlName="amount"
                      type="number"
                    />
                    <mat-error
                      *ngIf="
                        item.get('amount')?.touched &&
                        item.get('amount')?.hasError('required')
                      "
                    >
                      Amount is required.
                    </mat-error>
                  </mat-form-field>
                </div>
                <span class="delete">
                  <img
                    src="../../../assets/images/icons/icon-close.svg"
                    alt="Delete"
                    (click)="onRemoveInvoice(i)"
                  />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <button type="button" (click)="addItem()">
          <img
            src="../../../assets/images/icons/icon-add-color.svg"
            alt="Add"
          />
          Add
        </button>
      </div>

      <div class="notes-tatal-amount-container">
        <div class="form-container">
          <div class="form-wrapper">
            <label for="customerNotes">Customer Notes</label>
            <mat-form-field>
              <textarea
                matInput
                placeholder="Looking forward to your business"
                formControlName="customerNote"
              ></textarea>
            </mat-form-field>
          </div>
          <!-- <div class="checkbox-container">
            <label>
              <input type="checkbox" formControlName="isPayment" />
              <span>I have received the payment</span>
            </label>
          </div> -->
        </div>
        <div class="amount-table-div">
          <div>
            <h6>
              SubTotal<span>: {{ getTotalAmount() }}</span>
            </h6>
          </div>
          <div>
            <h4>
              Total(AED) <span>: {{ getTotalAmount() }}</span>
            </h4>
          </div>
        </div>
      </div>

      <!-- <div class="payment-mode-wrapper">
        <div class="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Payment Mode</th>
                <th>Deposit To</th>
                <th>Amount Received</th>
              </tr>
            </thead>
            <tbody formArrayName="payments">
              <tr
                *ngFor="let payment of payments.controls; let j = index"
                [formGroupName]="j"
              >
                <td>
                  <div class="form-wrapper">
                    <mat-form-field>
                      <mat-select
                        placeholder="Select Payment Mode"
                        formControlName="paymentMode"
                      >
                        <mat-option
                          *ngFor="let mode of paymentModes"
                          [value]="mode"
                        >
                          {{ mode }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </td>
                <td>
                  <div class="form-wrapper">
                    <div>
                      <mat-form-field>
                        <mat-select
                          placeholder="Select Account"
                          formControlName="depositId"
                        >
                          <mat-option>
                            <ngx-mat-select-search
                              (input)="onSearch($event)"
                            ></ngx-mat-select-search>
                          </mat-option>
                          <mat-option
                            *ngFor="let deposit of depositAccountData"
                            [value]="deposit.id"
                          >
                            {{ deposit.accountName }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="form-wrapper">
                    <mat-form-field>
                      <input
                        matInput
                        placeholder="Enter Amount"
                        formControlName="amount"
                        type="number"
                      />
                    </mat-form-field>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="total-amount-wrapper">
          <div class="span-2">
            <button (click)="addPayment()" type="button">
              <div>
                <img
                  src="../../../assets/images/icons/icon-add-color.svg"
                  alt="Add Payment"
                />
              </div>
              <span>Add Split Payment</span>
            </button>
          </div>

          <div class="total-content-wrapper">
            <h5><span>Total (AED)</span> <span>AED 340.00</span></h5>
            <h6><span>Balance Amount</span> <span>AED 0.00</span></h6>
          </div>
        </div>
      </div> -->

      <div class="documents-wrapper">
        <h2>Documents</h2>
        <div class="upload-wrapper">
          <div class="cmn-upload-box">
            <h4>Upload Documents (optional)</h4>
            <input
              multiple
              #fileInput
              type="file"
              (change)="onFileSelected($event)"
              style="display: none"
              accept=" .pdf, .jpg, .jpeg, .png, .doc"
            />
            <button
              class="cmn-choose-file-btn"
              (click)="fileInput.click()"
              type="button"
            >
              <img
                src="assets/images/icons/icon-add-color.svg"
                alt="Icon Add"
              />Choose a File
            </button>
          </div>
        </div>

        <div class="uploaded-file" *ngIf="fileDetailsArray.length > 0">
          <div *ngFor="let file of fileDetailsArray; let i = index">
            <h6>
              {{ file.fileName }}
              <img
                src="assets/images/icons/icon-close.svg"
                alt="Close Icon"
                (click)="removeFile(i)"
              />
            </h6>
          </div>
        </div>
      </div>

      <div class="submit-btn-wrapper">
        <button class="cmn-cancel-btn" type="button" (click)="onCancel()">
          Cancel
        </button>
        <button class="cmn-draft-btn" type="button" (click)="saveAsDraft()">
          Save as Draft
        </button>
        <button class="cmn-next-btn" type="button" (click)="saveAndSend()">
          Save and Send
        </button>
      </div>
    </form>
  </section>

