
    <section class="cmn-innerpage-wrapper payroll-individual">
        <div class="head">
            <div class="employee-head">
                <div class="img-wrapper">
                    <img [src]="profilePicture || 'assets/images/dummy-profile-image.jpg'" alt="profile">
                </div>
                <div class="content">
                    <h2>
                        {{data?.employees?.firstName + ' ' + data?.employees?.lastName}}
                        <span [routerLink]="['/employees/edit', data?.employeeId]">
                            <img src="assets/images/icons/icon-edit-profile.svg" alt="Edit Profile" />
                            Edit Profile
                        </span>
                    </h2>
                    <h3>{{ data?.employees?.designation?.designationName || '--' }}</h3>
                    <h4>
                        <img src="assets/images/icons/icon-mail.svg" alt="Icon Mail" />
                        {{ data?.employees?.email || '--' }}
                    </h4>
                </div>
            </div>
        </div>
        <div class="individual-details-wrapper">
            <div class="details-head">
                <div class="box-parent left-set">
                    <div class="box">
                        <h2>Annual Pay</h2>
                        <h3>AED {{data?.netPay || '--' }}</h3>
                    </div>
                    <div class="box">
                        <h2>Deduction amount</h2>
                        <h3>AED {{data?.deductions || '--' }}</h3>
                    </div>
                    <div class="box">
                        <h2>Gross salary</h2>
                        <h3>AED {{data?.grossSalary || '--' }}</h3>
                    </div>
                </div>
                <div class="box-parent right-set">
                    <div class="box">
                        <h2>Working days</h2>
                        <h3>{{'--'}}</h3>
                    </div>
                    <div class="box">
                        <h2>Attendance</h2>
                        <h3>{{'--'}}</h3>
                    </div>
                    <div class="box">
                        <h2>Paid Leave</h2>
                        <h3>{{'--'}}</h3>
                    </div>
                    <div class="box">
                        <h2>Net</h2>
                        <h3>{{'--'}}</h3>
                    </div>
                </div>
            </div>
            <div class="details-inner">
                <div class="details-row row-1">
                    <h4>Earnings</h4>
                    <div class="box">
                        <h2>Basic Pay </h2>
                        <h3>AED <span>{{data?.basicSalary || '--' }}</span> </h3>
                    </div>
                    <div class="box">
                        <h2>Travel Allowance </h2>
                        <h3>AED <span>{{'--'}}</span> </h3>
                    </div>
                    <div class="box">
                        <h2>Housing Allowance </h2>
                        <h3>AED <span>{{'--'}}</span> </h3>
                    </div>
                    <div class="total">
                        <h5>Earnings : <span>AED {{'--'}}</span></h5>
                    </div>

                </div>
                <div class="details-row row-2">
                    <div class="flex-title">
                        <h4>Deduction</h4><button (click)="openDialog(true)"><img src="assets/images/icons/icon-add-color.svg" alt="Add Icon">
                            Add</button>
                    </div>
                    <div class="box">
                        <h2>Absence Deductions </h2>
                        <h3>-AED {{'--'}} </h3>
                    </div>
                    <div class="box">
                        <h2>Health Insurance </h2>
                        <h3>-AED {{'--'}} </h3>
                    </div>
                    <div class="box">
                        <h2>Fines and Penalties </h2>
                        <h3>-AED {{'--'}} </h3>
                    </div>
                    <div class="total">
                        <h5>Deduction : <span>AED {{'--'}}</span></h5>
                    </div>

                </div>
                <div class="details-row row-3">
                    <div class="flex-title">
                        <h4>Reimbursement</h4><button (click)="openDialog(false)"><img src="assets/images/icons/icon-add-color.svg" alt="Add Icon">
                            Add</button>
                    </div>

                    <div class="box">
                        <h4>OT :</h4>
                        <h5>AED {{'--'}}</h5>
                        <h6>(Abu Dhabi, Project123)</h6>
                    </div>
                    <div class="box">
                        <h4>Bonus :</h4>
                        <h5>AED {{'--'}}</h5>
                        <h6>(Example employee of the month)</h6>
                    </div>
                    <div class="box">
                        <h4>Accommodation :</h4>
                        <h5>AED {{'--'}}</h5>
                    </div>
                    <div class="box">
                        <h4>Travel Expense :</h4>
                        <h5>AED +{{'--'}}</h5>
                    </div>
                    <div class="total">
                        <h5>Reimbursement : <span>AED {{'--'}}</span></h5>
                    </div>

                </div>
                <div class="details-row row-4">
                    <div>
                        <div class="box">
                            <h4>+Earnings</h4>
                            <h6>: AED {{'--'}}</h6>
                        </div>
                        <div class="box">
                            <h4>-Deduction</h4>
                            <h6>: AED {{'--'}}</h6>
                        </div>
                        <div class="box">
                            <h4>+Reimbursement</h4>
                            <h5>: AED {{'--'}}</h5>
                        </div>
                        <div class="total">
                            <h5>Total Net Pay</h5>
                            <h6>: AED {{'--'}}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="submit-btn-wrapper">
            <button class="cmn-cancel-btn" [routerLink]="['/payroll']">Cancel</button>
            <button class="cmn-next-btn" (click)="onProcessPayment()">Proceed Payment</button>
        </div>
    </section>
