<mat-dialog-content>
  <section class="upload-employees-wrapper">
    <div class="add-project-inventory">
      <form [formGroup]="shippingAddressForm" (ngSubmit)="onSubmitShippingAddress()">
        <div class="form-box">
          <button class="cmn-close" mat-dialog-close></button>
          <div class="form-sub-head">
            <h2 class="cmn-inner-heading">Shipping Address</h2>
            <div class="check-wrapper">
              <div class="check-box">
                <!-- <label class="checkbox-wrapper">
                  <input
                    type="checkbox"
                    (change)="onCopyBillingAddress($event)"
                  />
                  Copy billing address
                  <span class="checkmark"></span>
                </label> -->
              </div>
            </div>
          </div>
          <div class="form-wrapper">
            <label for="">Attention</label>
            <mat-form-field>
              <input matInput placeholder="Attention" formControlName="attention" />
            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label for="">Country/Region</label>
            <mat-form-field>
              <mat-select placeholder="Select country" formControlName="country">
                @for (country of countries; track country) {
                <mat-option [value]="country.name">{{
                  country.name
                  }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label for="">Address</label>
            <mat-form-field>
              <textarea matInput placeholder="Street 1" formControlName="addressLine1"></textarea>
            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <mat-form-field>
              <textarea matInput placeholder="Street 2" formControlName="addressLine2"></textarea>
            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label for="">City</label>
            <mat-form-field>
              <input matInput placeholder="Enter city" formControlName="city" />
            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label for="">State</label>
            <mat-form-field>
              <input matInput placeholder="Enter state" formControlName="state" />
            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label for="">P.O Box Number</label>
            <mat-form-field>
              <input matInput placeholder="Enter number" formControlName="pinCode" />
            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label for="">Fax Number</label>
            <mat-form-field>
              <input matInput placeholder="Enter number" formControlName="fax" />
            </mat-form-field>
          </div>
          <div class="form-wrapper">
            <label for="">Phone</label>
            <mat-form-field>
              <input matInput placeholder="Enter number" formControlName="phone" />
            </mat-form-field>
          </div>
        </div>
        <div class="submit-btn-wrapper">
          <button class="cmn-cancel-btn" type="button" (click)="onCancel()">
            Cancel
          </button>

          <button class="cmn-next-btn" type="submit">Save</button>
        </div>
      </form>
    </div>
  </section>
</mat-dialog-content>