import { Component } from '@angular/core';

import { CommonModule } from '@angular/common';
import { LayoutComponent } from '../../core/layout/layout.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { generatedNo } from '../../helpers/helper';

@Component({
  selector: 'app-credit-note-new',
  standalone: true,
  imports: [
    CommonModule,
    LayoutComponent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
  ],
  templateUrl: './credit-note-new.component.html',
  styleUrl: './credit-note-new.component.scss',
})
export class CreditNoteNewComponent {
  public arr = [1, 2, 3, 4, 5, 6, 7];

  FormGroupItems: FormGroup;

  constructor(private fb: FormBuilder) {
    this.initForm();
  }

  ngOnInit(): void {
    const generatedId = generatedNo('CN');
    this.FormGroupItems.get('creditNoteNo')?.setValue(generatedId);
  }

  initForm(): void {
    this.FormGroupItems = this.fb.group({
      id: [''],
      customerName: ['', Validators.required],
      creditNoteNo: ['', Validators.required],
      reference: [''],
      creditNoteDate: ['', Validators.required],
      salesPerson: ['', Validators.required],
      subject  : ['', Validators.required],
    });
  }

  onFormubmit(): void {
    if (this.FormGroupItems.invalid) {
      this.FormGroupItems.markAllAsTouched();
      return;
    }
    const data = this.FormGroupItems.value;
    console.log(data);
  }
}
